import { AbstractControl, ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';


export class InputHelper {

  public static clearStatus(
    formControl: AbstractControl,
  ): void {
    formControl.markAsUntouched();
    formControl.markAsPristine();
  }

  public static getError(
    formControl: AbstractControl,
  ): string | null {

    if ( (formControl == null) || formControl.untouched ) {
      // ignore errors until the field has been touched
      return null;
    }

    if ( formControl.hasError('required') ) {
      return $localize`:@@validation_required:This field is required`;
    }

    const errors = formControl.errors;
    const keys = Object.keys(errors ?? {});
    if ( keys.length === 0 ) {
      return null;
    }

    for (const key of keys) {
      const errorMessage = errors[key];
      if ( typeof errorMessage === 'string' ) {
        return errorMessage;
      }
    }
    return null;
  }

  public static setInitialValue<T>(
    formControl: AbstractControl | null,
    value: T,
  ): void {
    if (formControl == null) {
      return;
    }
    formControl.setValue(value);
    InputHelper.clearStatus(formControl);
  }

  public static toggleEnabled(
    formControl: AbstractControl,
    enabled: boolean,
  ): void {

    if ( enabled && !formControl.enabled ) {
      formControl.enable();

    } else if ( formControl.enabled && !enabled ) {
      formControl.disable();
    }
  }

}

export namespace InputTypes {

  export class Util {

    static initializeValueState<T>(valueState: ValueState<T>): void {
      if ( valueState == null ) {
        return;
      }

      if ( valueState.originalValue === undefined ) {
        valueState.originalValue = valueState.currentValue;
      }
      if ( valueState.originalIndeterminate === undefined ) {
        valueState.originalIndeterminate = valueState.indeterminate;
      }
    }

    static resetValue<T>(valueState: ValueState<T>): void {
      valueState.currentValue = valueState.originalValue;
      valueState.indeterminate = valueState.originalIndeterminate;
      valueState.changed = false;
    }

    static updateFormControl<T>(formControl: UntypedFormControl, valueState: ValueState<T>): void {
      if ( (formControl == null) || (valueState == null) ) {
        return;
      }

      formControl.setValue(valueState.currentValue);
      if ( valueState.changed ) {
        formControl.markAsDirty();
      } else {
        formControl.markAsPristine();
      }
    }

    static updateValueStateChange<T>(valueState: ValueState<T>, value: T, indeterminate?: boolean): void {
      if ( valueState == null ) {
        return;
      }

      // apply currentValue
      if ( valueState.originalValue === undefined ) {
        valueState.originalValue = valueState.currentValue ?? value;
      }
      valueState.currentValue = value;

      if ( indeterminate != null ) {
        // apply indeterminate
        if ( valueState.originalIndeterminate == null ) {
          valueState.originalIndeterminate = valueState.indeterminate ?? indeterminate;
        }
        valueState.indeterminate = indeterminate;
      }

      // calculate changed state
      valueState.changed =
        // compare value if original is defined
        ((valueState.originalValue !== undefined) &&
          (valueState.currentValue !== valueState.originalValue)) ||
        // compare indeterminate state if original is defined
        ((valueState.originalIndeterminate !== undefined) &&
          (valueState.indeterminate !== valueState.originalIndeterminate));
    }

  }

  export interface ValueState<T> {
    changed?: boolean;
    currentValue?: T;
    disabled?: boolean;
    indeterminate?: boolean;
    originalIndeterminate?: boolean;
    originalValue?: T;
  }

  declare type fnOnChange = <T>(checked: T) => any;
  declare type fnOnTouched = () => any;

  export abstract class AbstractControlValueAccessor<T>
    implements ControlValueAccessor {

    onChange: fnOnChange;
    onTouched: fnOnTouched;
    private _ngControl: NgControl;
    private _valueState: InputTypes.ValueState<T>;

    protected constructor() {
    }

    get formControl(): UntypedFormControl {
      return this._ngControl?.control as UntypedFormControl;
    }

    get isDirty(): boolean {
      return this._valueState?.changed === true;
    }

    set value(value: T) {
      this.writeValue(value);
    }

    getValueState(): InputTypes.ValueState<T> {
      return this._valueState;
    }

    registerOnChange(fn: fnOnChange): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: fnOnTouched): void {
      this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      if ( this._valueState == null ) {
        return;
      }

      this._valueState.disabled = isDisabled;
    }

    setNgControl(value: NgControl): void {
      if ( value == null ) {
        return;
      }

      this._ngControl = value;
      this._ngControl.valueAccessor = this;
    }

    /**
     * Add a setter for valueState and call this method.
     * <pre>
     * | @Input() set valueState(value: InputTypes.ValueState<boolean>) {
     * |   this.setValueState(value);
     * | }
     * </pre>
     */
    setValueState(value: InputTypes.ValueState<T>) {
      this._valueState = value;
    }

    writeValue(value: T): void {
      const valueState = this._valueState;
      Util.updateValueStateChange(valueState, value);
      Util.updateFormControl(this.formControl, valueState);
    }

  }

}
