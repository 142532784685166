import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Certificate } from '../certificates.types';
import { Core, ImageableContentReference } from '../../../../core/core.types';
import { UrlHelper } from '../../../../core/url.helper';
import { ViewHelper } from '../../../../core/view-helper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ContentCardComponent } from 'src/app/component/cards/content-card/content-card.component';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ContentCardComponent,
    PipesModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  selector: 'rag-certificate-card-v2',
  templateUrl: './certificate-card-v2.component.html',
  styleUrls: [ './certificate-card-v2.component.scss' ],
})
export class CertificateCardV2Component
  implements OnChanges {

  @Input() certificate: Certificate;
  @Output() readonly detailsClicked;
  @Output() readonly downloadClicked;

  content: ImageableContentReference;
  contentLink: string;
  today = new Date().getTime();
  private _detailsClicked = new EventEmitter<void>();
  private _downloadClicked = new EventEmitter<void>();

  constructor() {
    this.detailsClicked = this._detailsClicked.asObservable();
    this.downloadClicked = this._downloadClicked.asObservable();
  }

  ngOnChanges(
    changes: SimpleChanges,
  ): void {

    const certificate = this.certificate;
    if ( certificate == null ) {
      return;
    }

    const curriculum = ViewHelper.getViewData(certificate)?.curriculum;

    const id = certificate.curid;
    this.content = {
      id,
      objType: Core.DistributableType.lms_curriculum,
      pictureId: parseInt(String(curriculum?.pictureId), 10),
      pictureUUID: curriculum?.pictureUUID,
      title: curriculum?.title ?? certificate.curriculum,
    };

    this.contentLink = UrlHelper
      .execUrlFor({ id, objType: Core.DistributableType.lms_curriculum });

  }

  onDetailsClicked(): void {
    this._detailsClicked.emit();
  }

  onDownloadClicked(): void {
    this._downloadClicked.emit();
  }

}
