import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { OAuthUserInfoResponse } from './ext-oauth2-users.types';
import { ApiUrls } from '../../../../../core/api.urls';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { AssignmentDialogTypes } from '../../../../../component/assignment-dialog/assignment-dialog.types';
import { AssignmentDialogComponent } from '../../../../../component/assignment-dialog/assignment-dialog.component';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { InfoService } from '../../../../../core/info/info.service';
import { ControllingSingleUserTypes } from '../../../../../core/ctrl-single-user.types';
import { UserNameHelper } from '../../../../../core/user-name.helper';
import { InfoType, MessageConstants } from '../../../../../core/info/info.types';
import { ExtOauth2UsersRow } from './ext-oauth2-users.columns';


export const extOauth2UsersResolver: ResolveFn<OAuthUserInfoResponse> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  extOauth2UsersService: ExtOauth2UsersService = inject(ExtOauth2UsersService)
): Observable<OAuthUserInfoResponse> => {
  const registrationId = route.params['registrationId'];
  if (!registrationId) {
    throw Error('registrationId missing from URL!');
  }

  return extOauth2UsersService.getUsers(registrationId);
};


@Injectable({ providedIn: 'root' })
export class ExtOauth2UsersService {

  constructor(
    private http: HttpClient,
    private infoService: InfoService,
  ) {
  }

  getUsers(registrationId: string): Observable<OAuthUserInfoResponse> {
    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUsers')
      .replaceAll(/\{registrationId}/gi, registrationId);
    return this.http.get<OAuthUserInfoResponse>(url);
  }

  setUserId(
    registrationId: string, principalName: string, id: number,
  ): Observable<OAuthUserInfoResponse> {
    const url = ApiUrls.getKey('ExtOAuth2ClientRegistrationUsers')
      .replaceAll(/\{registrationId}/gi, registrationId);
    return this.http.post<OAuthUserInfoResponse>(url, {
      principalName, userId: id,
    })
      .pipe(catchError(() => {
        this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(() => this.infoService
        .showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success })));
  }

  changeUser(
    registrationId: string,
    row: ExtOauth2UsersRow,
    principalName: string,
    userId: number | null,
    systemUsers: ControllingSingleUserTypes.ControllingUser[],
  ): Observable<OAuthUserInfoResponse | never> {
    const data = ExtOauth2UsersService.convertToDialogData(userId, systemUsers);
    const title = UserNameHelper.getFullNameWithLogin(row.userFirstname, row.userLastname, row.userEmail,
      row.userLogin ?? principalName, row.userId);
    if (title) {
      data.i18n.title += ` - ${title}`;
    }
    return this.showAssignmentDialog(data)
      .pipe(switchMap(id => this.setUserId(registrationId, principalName, id)))
      .pipe(take(1));
  }

  private showAssignmentDialog(
    data: AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser>
  ): Observable<number | null | never> {
    return this.infoService.showDialog<AssignmentDialogComponent<ControllingSingleUserTypes.ControllingUser>,
      AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser>,
      AssignmentDialogTypes.AssignmentDialogEntries<ControllingSingleUserTypes.ControllingUser>>(
      AssignmentDialogComponent, data
    )
      .pipe(take(1))
      .pipe(takeWhile(result =>
        (result != null) && (result.available != null) && (result.selected != null)))
      .pipe(map(result => result.selected[0]?.value?.userId));
  }

  private static convertToDialogData(
    selectedUserId: number | null,
    systemUsers: ControllingSingleUserTypes.ControllingUser[],
  ): AssignmentDialogTypes.AssignmentDialogData<ControllingSingleUserTypes.ControllingUser> {
    const available = (systemUsers ?? [])
      .filter(user => user.userId !== selectedUserId)
      .map(user => ExtOauth2UsersService.convertToDialogEntry(user, selectedUserId));
    const selected = (systemUsers ?? [])
      .filter(user => user.userId === selectedUserId)
      .map(user => ExtOauth2UsersService.convertToDialogEntry(user, selectedUserId));
    return ({
      data: { maxSelections: 1, available, selected, },
      i18n: {
        available: $localize`:@@assignment_dialog_service_dlg_available:All users and groups`,
        search: $localize`:@@assignment_dialog_service_dlg_search:Search in users and groups...`,
        selected: $localize`:@@assignment_dialog_service_dlg_selected:Currently assigned`,
        title: $localize`:@@assignment_dialog_service_dlg_title:Assignment`,
        tooManySelections: $localize`:@@assignment_dialog_service_dlg_too_many_selections:There are more entries selected than are allowed!`,
      },
    });
  }

  private static convertToDialogEntry(
    user: ControllingSingleUserTypes.ControllingUser,
    selectedUserId: number | null,
  ): AssignmentDialogTypes.AssignmentEntry<ControllingSingleUserTypes.ControllingUser> {
    const title = UserNameHelper.getFullNameWithUserId(
      user.userFirstname,
      user.userLastname,
      user.userId,
    );
    return ({
      changed: false,
      selected: user.userId == selectedUserId,
      title, value: user, type: 'user',
    });
  }
}
