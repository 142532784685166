import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from '../route/user/home/home.component';

export const SideNavContainerHeightGuard: CanDeactivateFn<any> = (
  component: HomeComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
): boolean => {
  const sidenavContainerStyle = document.getElementById('sidenavContainer').style;
  sidenavContainerStyle.height = 'fit-content'; // - headre - footer
  return true;
};

