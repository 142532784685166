<!-- carousel -->
<div class="carousel">
  <ng-container
    *ngFor="let slide of slides; let i = index"
  >
    <div
      (click)="onClick(slide)"
      *ngIf="i === currentSlide"
      @carouselAnimation
      [style]="slide._backgroundStyles"
      class="slide"
    >
      <div
        *ngIf="(slide.title | localeText) as title"
        class="caption"
      >
        <span class="title">{{title}}</span>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="slides?.length > 1"
  >
    <!-- controls -->
    <button
      (click)="onPreviousClick()"
      class="control prev"
    >
      <span class="arrow left"></span>
    </button>
    <button
      (click)="onNextClick()"
      class="control next"
    >
      <span class="arrow right"></span>
    </button>
  </ng-container>
</div>
