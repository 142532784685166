import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { AdminConfiguration } from "src/app/route/admin/admin-configuration/admin-configuration.types";
import { ProfileExportData } from "src/app/route/admin/admin-profile/admin-profile.types";
import { ApiUrls } from "../../api.urls";
import { CachedSubject } from "../../cached-subject";
import { ApiResponse } from "../../global.types";

@Injectable({
  providedIn: 'root'
})
export class AdminConfigurationService {

  saveButtonVisible$: Observable<boolean>;
  saveButtonDisabled$: Observable<boolean>;
  eventListener$: Observable<AdminConfiguration.Event>;

  private _saveButtonVisible$ = new CachedSubject(false);
  private _saveButtonDisabled$ = new CachedSubject(true);
  private _eventListener$ = new CachedSubject(null);

  constructor(
    private http: HttpClient
  ) {
    this.saveButtonDisabled$ = this._saveButtonDisabled$.asObservable();
    this.saveButtonVisible$ = this._saveButtonVisible$.asObservable();
    this.eventListener$ = this._eventListener$.withoutEmptyValues();
  }

  setSaveButtonVisible(visible: boolean) {
    this._saveButtonVisible$.next(visible);
  }

  setSaveButtonDisabled(disabled: boolean) {
    this._saveButtonDisabled$.next(disabled);
  }

  emitEvent(event: AdminConfiguration.Event) {
    this._eventListener$.next(event);
  }

  saveProfileExportSettings(selectedFile: File, selectedFields: string[], header: string, footer: string): Observable<ProfileExportData> {
    const url = ApiUrls.getKey('AdminUserProfileExport');
    const formData = new FormData();
    if (selectedFile != undefined) {
      formData.append('file', selectedFile);
    }
    formData.append('fields', selectedFields.join(','));
    formData.append('header', header??'');
    formData.append('footer', footer??'');

    return this.http.post<ApiResponse<ProfileExportData>>(url, formData)
      .pipe(map(response => response.settings));
  }
}
