import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderComponent } from '../../../../component/page-header/page-header.component';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import {
  BadgeTemplate,
  BadgeTemplateSaveData,
  BadgeTemplateUsages,
} from '../../../../core/gamification/gamification.types';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FileComponent } from '../../../../component/file/file.component';
import { EMPTY, Observable } from 'rxjs';
import { FileInfo } from '../../../../core/core.types';
import { CachedSubject } from '../../../../core/cached-subject';
import { FileUploadService } from '../../../../component/input/file-upload/file-upload.service';
import * as uuid from 'uuid';
import {
  FileAuthorizationRefType,
  FileAuthorizationType,
} from '../../../../component/input/file-upload/file-upload.types';
import { AdminGamificationService } from '../../../../core/gamification/admin-gamification.service';
import { InfoService } from '../../../../core/info/info.service';
import { ConfirmButton, InfoType, MessageConstants } from '../../../../core/info/info.types';
import { MatDividerModule } from '@angular/material/divider';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { StickyScrollComponent } from '../../../../component/sticky-scroll/sticky-scroll.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { TablesColumnRenderDefaultModule } from '../../../../component/table/tables-column-render-default/tables-column-render-default.module';
import { TableHeaderFilterDefaultModule } from '../../../../component/table/table-header-filter-default/table-header-filter-default.module';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { TableColumnMenuModule } from '../../../../component/table/table-column-menu/table-column-menu.module';
import { ADMIN_GAMIFICATION_DETAILS_DEFAULT_MENU_COLUMNS } from './admin-gamification-details.columns';
import { LoadingIndicatorComponent } from '../../../../component/loading-indicator/loading-indicator.component';
import { DirtyCheckService } from '../../../../core/dirty-check.service';

@Component({
  standalone: true,
  selector: 'rag-admin-gamification-details',
  templateUrl: './admin-gamification-details.component.html',
  imports: [
    PageHeaderComponent,
    MatButtonModule,
    MatInputModule,
    CommonModule,
    ReactiveFormsModule,
    FileComponent,
    MatDividerModule,
    StickyScrollComponent,
    MatTableModule,
    MatSortModule,
    PipesModule,
    TablesColumnRenderDefaultModule,
    TableHeaderFilterDefaultModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    TableColumnMenuModule,
    LoadingIndicatorComponent,
  ],
  styleUrls: [ './admin-gamification-details.component.scss' ],
})
export class AdminGamificationDetailsComponent
  extends TableControllerComponent<BadgeTemplateUsages> implements OnInit, OnDestroy {

  title: string = $localize`:@@global_new_badge_template:New badge template`;
  badgeTemplate: BadgeTemplate;
  usages: BadgeTemplateUsages[];
  form: FormGroup;
  uploadedPicture: File;
  currentPictureUUID: string;

  readonly fileInfo$: Observable<FileInfo>;
  readonly saveButtonDisabled$: Observable<boolean>;
  private _fileInfo$ = new CachedSubject<FileInfo>(null);
  private _saveButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private adminGamificationService: AdminGamificationService,
    private dirtyCheckService: DirtyCheckService,
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private infoService: InfoService,
    protected tableColumnMenuService: TableColumnMenuService,
    private router: Router,
  ) {
    super(tableColumnMenuService);
    this.fileInfo$ = this._fileInfo$.asObservable();
    this.saveButtonDisabled$ = this._saveButtonDisabled$.asObservable();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onEditUsage(usage: BadgeTemplateUsages): void {
    this.adminGamificationService.onOpenBadgeTemplateDialog(usage.targetId, usage.targetType, button => {
      // reload
      this.adminGamificationService.getBadgeTemplateUsages(this.badgeTemplate.templateUUID)
        .pipe(tap(data => {
          this.setTableData(data);
        }))
        .subscribe()
      return EMPTY;
    });
  }

  onSave(): void {
    const saveData: BadgeTemplateSaveData = {
      badgeTemplateUUID: this.badgeTemplate.templateUUID,
      adminTitle: this.form.get('adminTitle').value,
      adminDescription: this.form.get('adminDescription').value,
      pictureId: this.badgeTemplate.pictureId,
    }
    if (this.currentPictureUUID != this.badgeTemplate.pictureUUID) {
      this.fileUploadService.uploadFileV2MarkAsUsed(this.badgeTemplate.pictureUUID, FileAuthorizationRefType.badge_template_pic)
        .pipe(switchMap(() => this.adminGamificationService.saveBadgeTemplate(saveData)))
        .pipe(switchMap(savedBadgeTemplate =>
          this.initializeDataAfterSaving(savedBadgeTemplate)
        ))
        .pipe(catchError(() => {
          this.infoService.showMessage(MessageConstants.API.ERROR, {
            infoType: InfoType.Error,
          })
          return EMPTY;
        }))
        .subscribe();
    } else {

      this.adminGamificationService.saveBadgeTemplate(saveData)
        .pipe(switchMap(savedBadgeTemplate =>
          this.initializeDataAfterSaving(savedBadgeTemplate)
        ))
        .pipe(catchError(() => {
          this.infoService.showMessage(MessageConstants.API.ERROR, {
            infoType: InfoType.Error,
          })
          return EMPTY;
        }))
        .subscribe();
    }
  }

  private initializeDataAfterSaving(savedBadgeTemplate: BadgeTemplate) {
    this.currentPictureUUID = savedBadgeTemplate.pictureUUID;
    this.badgeTemplate.templateUUID = savedBadgeTemplate.templateUUID;
    this.infoService.showMessage(MessageConstants.API.SUCCESS, {
      infoType: InfoType.Success,
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.emitChange(false);
    return this.router
      .navigateByUrl('/admin/configuration/gamification/edit/' + this.badgeTemplate.templateUUID);
  }

  onRemovePicture(): void {
    this.uploadedPicture = null;
    this._fileInfo$.next(null);
    this.badgeTemplate.pictureId = null;
    this.badgeTemplate.pictureUUID = null;
    this.form.markAsTouched();
    this.form.markAsDirty();
    this.emitChange(true);
  }

  onSelectedPicture(picture: File): void {
    this.uploadedPicture = picture;
    const pictureFormControl = this.form.get('picture');
    pictureFormControl.setErrors({'uploading': true})
    this.form.markAsTouched();
    this.form.markAsDirty();
    this.fileUploadService.uploadFileV2(uuid.v4(), this.uploadedPicture, FileAuthorizationType.login_required, FileAuthorizationRefType.badge_template_pic)
      .pipe(tap(fileInfo => {
        this.badgeTemplate.pictureId = fileInfo.id;
        this.badgeTemplate.pictureUUID = fileInfo.uuid;
        this._fileInfo$.next(fileInfo);
        pictureFormControl.setErrors(null);
        this.emitChange(true);
      }))
      .pipe(catchError(() => {
        pictureFormControl.setErrors(null);
        this.uploadedPicture = null;
        return EMPTY;
      }))
      .pipe(take(1))
      .subscribe();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      adminTitle: [this.badgeTemplate.adminTitle, Validators.required],
      adminDescription: [this.badgeTemplate.adminDescription, Validators.required],
      picture: [this.badgeTemplate?.picture],
    })

    this.form.valueChanges.pipe(tap(_ => {
      this.emitChange(true);
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(ADMIN_GAMIFICATION_DETAILS_DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateRouteData(data): void {
    this.badgeTemplate = data.data.badgeTemplate;
    this.usages = data.data.usages;
    this.currentPictureUUID = this.badgeTemplate.pictureUUID;
    if (this.badgeTemplate.templateUUID != null) {
      this.title = this.badgeTemplate.adminTitle;
    }
    this._fileInfo$.next(this.badgeTemplate.picture);
    this.buildForm()
    this.updateAvailableColumns();
    this.setTableData(this.usages)
    this.inputDisabled = false;
    this.checkFilter();
  }

  private emitChange(isDirty: boolean): void {
    this._saveButtonDisabled$.next( this.form.invalid ||
      (this.badgeTemplate.pictureUUID === null) ||
      this.form.pristine);
    this.dirtyCheckService.submitNextState('AdminGamificationDetails', isDirty);
  }
}
