<div class="container">
  <rag-page-header>
    <div class="page-header-title" i18n="@@header_top_messages">Messages</div>
  </rag-page-header>

  <div class="content" id="mail">
    <div *ngIf="canSendMessages$ | async" id="left-pane">
      <button
        (click)="onCompose()"
        [disabled]="composeButtonmDisabled$ | async"
        color="primary"
        id="compose-button"
        mat-flat-button>
        <mat-icon aria-label="compose new email" class="action" svgIcon="pencil"></mat-icon>
        <span class="hidden-when-sm" i18n="@@compose">Compose</span>
      </button>
      <div id="box_links">
        <a (click)="uncheckMail('inbox')" routerLink="/mailbox/inbox" routerLinkActive="active-link">
          <mat-icon svgIcon="inbox"></mat-icon>
          <span *ngIf="viewportState.isGtEqMedium()" class="reduced-when-sx" i18n="@@inbox">Inbox</span>
        </a>
        <a (click)="uncheckMail('outbox')" routerLink="/mailbox/outbox" routerLinkActive="active-link">
          <mat-icon svgIcon="send"></mat-icon>
          <span *ngIf="viewportState.isGtEqMedium()" class="reduced-when-sx" i18n="@@outbox">Outbox</span>
        </a>
        <a *ngIf="canDraftMessages$ | async" routerLink="/mailbox/drafts"
           routerLinkActive="active-link">
          <mat-icon svgIcon="email-open-outline"></mat-icon>
          <span *ngIf="viewportState.isGtEqMedium()" class="reduced-when-sx" i18n="@@drafts">Drafts</span>
        </a>
        <a *ngIf="canPlanMessages$ | async" routerLink="/mailbox/planned"
           routerLinkActive="active-link">
          <mat-icon svgIcon="clock-outline"></mat-icon>
          <span *ngIf="viewportState.isGtEqMedium()" class="reduced-when-sx" i18n="@@planned">Planned</span>
        </a>
      </div>
    </div>
    <div id="work-pane">

      <ng-template #no_data>
        <div class="mails_no_data" i18n="@@mails_no_data">You do not have any messages.</div>
      </ng-template>

      <div *ngIf="hasData(); else no_data" id="toolbox">
        <!-- actions when there is no selected email -->
        <ng-container *ngIf="!selectedMailEntry">

          <mat-menu #selectorMenu="matMenu">
            <button (click)="selectAll(true)" mat-menu-item>
              <span i18n="@@all">All</span>
            </button>
            <button (click)="selectAll(false)" mat-menu-item>
              <span i18n="@@none">None</span>
            </button>
            <button (click)="selectRead(true)" mat-menu-item>
              <span i18n="@@read">Read</span>
            </button>
            <button (click)="selectRead(false)" mat-menu-item>
              <span i18n="@@unread">Unread</span>
            </button>
          </mat-menu>

          <mat-checkbox
            [(ngModel)]="toggleSelectAll"
            (ngModelChange)="toggleSelectAllChanged($event)"
            aria-label="select all emails"
            color="primary"></mat-checkbox>

          <button [matMenuTriggerFor]="selectorMenu" mat-icon-button>
            <mat-icon svgIcon="chevron-down"></mat-icon>
          </button>

          <button (click)="onRefresh()" color="primary" i18n-matTooltip="@@refresh" mat-icon-button
                  matTooltip="Refresh">
            <mat-icon svgIcon="refresh"></mat-icon>
          </button>
        </ng-container>

        <!-- Menu devider -->
        <div *ngIf="!selectedMailEntry && hasSelected()" id="menu-devider">
          |
        </div>

        <!-- actions when there is one or multiple selected email/s -->
        <ng-container *ngIf="selectedMailEntry || hasSelected()">
          <button (click)="clearSelected()" *ngIf="selectedMailEntry" color="primary" mat-button>
            <mat-icon svgIcon="arrow-left"></mat-icon>
          </button>
          <button (click)="onDelete($event)" i18n-matTooltip="@@delete_button" mat-icon-button matTooltip="Delete">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
          <button (click)="setSelectedReadStatus($event, invertedReadStatusForSelected())" mat-icon-button
                  matTooltip="{{tooltipByReadStatus(!invertedReadStatusForSelected())}}">
            <mat-icon svgIcon="email"></mat-icon>
          </button>
        </ng-container>

        <!-- Pager for selected mail-->
        <div *ngIf="selectedMailEntry" class="pager">
          <span class="hidden-when-sx">{{fromIndexMail(selectedMailEntry)||1}} of {{msgTotalCount}}</span>
          <button (click)="setMailIndexBefore(selectedMailEntry)" i18n-matTooltip="@@before"
                  mat-icon-button matTooltip="Previous">
            <mat-icon aria-label="Previous page of emails" svgIcon="chevron-left"></mat-icon>
          </button>
          <button (click)="setMailIndexAfter(selectedMailEntry)" i18n-matTooltip="@@after"
                  mat-icon-button matTooltip="Next">
            <mat-icon aria-label="Next page of emails" svgIcon="chevron-right"></mat-icon>
          </button>
        </div>
      </div>

      <!-- Table view -->
      <ng-container *ngIf="!selectedMailEntry">
        <div class="table-wrapper" id="mails">

          <table *ngIf="resultsState === 'data'"
                 [dataSource]="dataSource"
                 class="table-container"
                 id="mails-table" mat-table
                 matSort
                 matSortActive="name"
                 matSortDirection="desc">

            <ng-container matColumnDef="checkbox">
              <th *matHeaderCellDef class="hidden"></th>
              <td *matCellDef="let mailEntry" mat-cell>
                <div class="row">
                  <mat-checkbox [(ngModel)]="mailEntry.selected" color="primary"></mat-checkbox>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="from">
              <th *matHeaderCellDef class="hidden"></th>
              <td (click)="onClick(mailEntry)" *matCellDef="let mailEntry" class="ellipsis" class="from"
                  mat-cell>
                <div class="row">
                  {{mailCorrespondentFor(mailEntry)}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="subject">
              <th *matHeaderCellDef class="hidden"></th>
              <td *matCellDef="let mailEntry" mat-cell>
                <div class="subject-container">
                  <div (click)="onClick(mailEntry)" class="subject">
                    <div class="subjectWrapper">
                      <div class="subject-text-container">
                        <div *ngIf="mailEntry.draftTitle" class="draft-title">
                          ( {{mailEntry.draftTitle}} )
                        </div>
                        <div class="subject-text">
                          <rag-clamp
                            [clamp]="1"
                            [input]="mailEntry.subject"
                          ></rag-clamp>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="hasAttachments(mailEntry)" class="attachments">
                      <rag-attachments [attachments]="mailEntry.attachments"></rag-attachments>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th *matHeaderCellDef></th>
              <td (click)="onClick(mailEntry)" *matCellDef="let mailEntry" mat-cell>
                <div class="row">
                <span [matTooltip]="mailEntryDatePlannedTitle(mailEntry)"
                      class="date-info">
                  {{dateFormatted(mailEntryDate(mailEntry))}}
                </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th *matHeaderCellDef></th>
              <td (click)="onClick(mailEntry)" *matCellDef="let mailEntry" mat-cell>
                <div class="rowStatus">
                  <div (click)="$event.stopPropagation()" class="menu">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon svgIcon="dots-vertical"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">

                      <a (click)="onDelete($event, mailEntry)"
                         mat-menu-item>
                        <mat-icon svgIcon="delete-outline"></mat-icon>
                        <span i18n="@@delete_Email">Delete Email</span>
                      </a>

                      <a (click)="toggleReadStatus($event, mailEntry)" *ngIf="box === 'inbox'"
                         mat-menu-item
                      >
                        <mat-icon matTooltip="{{tooltipByReadStatus(mailEntry.read, true)}}"
                                  svgIcon="email"></mat-icon>
                        <span>{{tooltipByReadStatus(mailEntry.read, true)}}</span>
                      </a>

                    </mat-menu>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let mailEntry; columns: displayedColumns;"
                [@row]
                [ngClass]="{'unread': !mailEntry.read, 'flex-start': hasAttachments(mailEntry)}"
                mat-row
            ></tr>
          </table>
        </div>

        <mat-paginator
          *ngIf="hasData()"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [length]="msgTotalCount"
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          (page)="setPageIndex($event.pageIndex, $event.pageSize)"
          showFirstLastButtons
        ></mat-paginator>
      </ng-container>
      <!-- /Table view -->

      <div *ngIf="selectedMailEntry" id="mail-preview">
        <div class="receivers">
          <div *ngIf="this.box==='inbox'" class="receivers">
            <div class="receiver" i18n="@@mailbox_sender">From</div>
            :
            <div class="receiver">
              <div class="avatar">
                <mat-icon svgIcon="account"></mat-icon>
              </div>
              {{selectedMailEntry.from}}
            </div>
          </div>
          <div *ngIf="this.box==='outbox'" class="receivers">
            <div class="receiver to" i18n="@@mailbox_to">To</div>
            :
            <ng-container *ngFor="let receiver of selectedMailEntry.receivers, let i = index" >
              <div class="receiver" *ngIf="showAllReceivers || i < 2" [ngSwitch]="receiver.type">
                <mat-icon *ngSwitchCase="'user'" svgIcon="account"></mat-icon>
                <mat-icon *ngSwitchDefault svgIcon="account-multiple"></mat-icon>
                {{receiver.name}}
              </div>
              <div class="show-more" *ngIf="shouldShowShowMore(i)">
                <a (click)="onToggleAllReceivers()">
                  {{selectedMailEntry.receivers.length - 2}}&nbsp;<span i18n="@@global_more">more</span>
                </a>
              </div>
              <div class="show-more" *ngIf="shouldShowShowLess(i)">
                <a (click)="onToggleAllReceivers()">
                  <span i18n="@@global_less">less</span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- Sender/ receiver date mail-preview  -->
        <div class="dateInfo">
          <h3 class="dateInfo-subject">
            <rag-clamp
              [clamp]="1"
              [input]="selectedMailEntry.subject"
            ></rag-clamp>
          </h3>
          <span class="dateInfo-date" *ngIf="this.box==='inbox'">{{dateFormatted(selectedMailEntry.received)}}</span>
          <span class="dateInfo-date" *ngIf="this.box==='outbox'">{{dateFormatted(selectedMailEntry.sent)}}</span>
        </div>
        <div [innerHTML]="selectedMailEntry.body | safe:'html'" id="mail-body"></div>
        <rag-attachments [attachments]="selectedMailEntry.attachments">
        </rag-attachments>

        <div id="footer">
          <a (click)="onReplay(selectedMailEntry.id)" *ngIf="canSendMessages$ | async" mat-stroked-button>
            <mat-icon svgIcon="reply"></mat-icon>
            <span class="button-text" i18n="@@reply">Reply</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Translations -->
<span #mailbox_delete_single_confirmation class="hidden"
      i18n="@@mailbox_delete_single_confirmation">Are you sure you want to delete this email?</span>
<span #mailbox_delete_multiple_confirmation class="hidden"
      i18n="@@mailbox_delete_multiple_confirmation">Are you sure you want to delete selected emails?</span>
<span #mailbox_mark_as_read class="hidden"
      i18n="there is only one selected email@@mailbox_mark_as_read">Mark as read</span>
<span #mailbox_mark_as_read_multiple class="hidden"
      i18n="there are more than one selected emails@@mailbox_mark_as_read_multiple">Mark all as read</span>
<span #mailbox_mark_as_unread class="hidden"
      i18n="there is only one selected email@@mailbox_mark_as_unread">Mark as unread</span>
<span #mailbox_mark_as_unread_multiple class="hidden"
      i18n="there is more than one selected email@@mailbox_mark_as_unread_multiple">Mark all as unread</span>
