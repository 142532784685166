import {
  columnFilterFactory,
  ColumnFilterMethod,
  filterDateV2,
  filterDisplayStatusV2,
  filterDropDownV2,
  filterHtmlV2,
  filterNumberV2,
  FilterOperator,
  filterTextV2,
} from '../../../core/column-settings/column-filter.types';
import { AnyObject } from '../../../core/core.types';
import { TableHelper } from '../table-helper';
import { DisplayStatusOrder } from '../../../core/display-status.enum';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { TableColumnBuilder } from '../table-column.builder';
import { TableColumnDataType, TableColumnOptions } from '../table-column.types';
import { TableColumnMenu } from '../table-column-menu/table-column-menu.types';

/**
 * @deprecated replace with direct import
 */
export { TableColumnDataType } from '../table-column.types';


export namespace TableControllerTypes {

  /**
   * @deprecated use {@link TableColumnBuilder} instead!
   */
  export class Util {

    static baseColumnFactory(columnId: string, title: string, dataType: TableColumnDataType,
      filterMethod: ColumnFilterMethod, orderIndex: number = null, selected = false): ColumnMenuItem {
      const result = this.createBaseColumn(columnId, title, dataType, filterMethod, selected);
      this.withOrderIndex(result, orderIndex);
      return result;
    }

    static createBaseColumn(columnId: string, title: string, dataType: TableColumnDataType, filterMethod: ColumnFilterMethod,
      selected: boolean): ColumnMenuItem {
      return {
        id: columnId,
        selected,
        options: {
          dataType,
          filter: columnFilterFactory(columnId),
          filterMethod,
        },
        title,
      };
    }

    static dateColumnFactory(columnId: string, title: string,
      orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.date,
        filterDateV2, orderIndex, selected);
      const options = column.options;
      options.filter.action = FilterOperator.GTE;
      return column;
    }

    static dateTimeColumnFactory(columnId: string, title: string,
      orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.dateTime,
        filterDateV2, orderIndex, selected);
      const options = column.options;
      options.filter.action = FilterOperator.GTE;
      return column;
    }

    static displayStatusColumnFactory(columnId: string, title: string, dropDownOptions: AnyObject<string>,
      placeholder: string, orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.dropdown,
        filterDisplayStatusV2, orderIndex, selected);
      const options = column.options;
      options.dropDownOptions = dropDownOptions;
      options.placeholder = placeholder;
      options.sortingAccessor = value => {
        const status = value?.[columnId];
        return DisplayStatusOrder[String(DisplayStatusHelper.toDisplayStatus(status))] ??
          TableHelper.toString(status);
      };
      return column;
    }

    static dropdownColumnFactory(columnId: string, title: string, dropDownOptions: AnyObject<string>,
      placeholder: string, orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.dropdown,
        filterDropDownV2, orderIndex, selected);
      const options = column.options;
      options.dropDownOptions = dropDownOptions;
      options.placeholder = placeholder;
      options.sortingAccessor = value => {
        const option = value?.[columnId];
        const ddOptions = options.dropDownOptionsOriginal ?? options.dropDownOptions;
        return ddOptions[String(option)] ?? TableHelper.toString(option);
      };
      return column;
    }

    static htmlColumnFactory(columnId: string, title: string,
      orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.html,
        filterHtmlV2, orderIndex, selected);
      const options = column.options;
      options.filter.action = FilterOperator.LIKE;
      return column;
    }

    static numberColumnFactory(columnId: string, title: string,
      orderIndex: number = null, selected = false): ColumnMenuItem {
      return TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.number, filterNumberV2,
        orderIndex, selected);
    }

    /**
     * Columns created with this factory are enabled by default and cannot be deselected.
     * These columns will not be included in TableControllerComponent.renderColumns
     */
    static structuralColumnFactory(columnId: string, title: string = null, type: TableColumnDataType = TableColumnDataType.text): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, type, null);
      Util.withForcedSelect(column);
      return column;
    }

    static textColumnFactory(columnId: string, title: string,
      orderIndex: number = null, selected = false): ColumnMenuItem {
      const column = TableControllerTypes.Util.baseColumnFactory(columnId, title, TableColumnDataType.text,
        filterTextV2, orderIndex, selected);
      const options = column.options;
      options.filter.action = FilterOperator.LIKE;
      return column;
    }

    static withDefaultFilter(menuItem: ColumnMenuItem, filterValue: string): ColumnMenuItem {
      menuItem.options.filter.value = menuItem.options.filter.defaultValue = filterValue;
      menuItem.hasFilter = true;
      return menuItem;
    }

    static withFilterMethod(menuItem: ColumnMenuItem, filterMethod: ColumnFilterMethod): ColumnMenuItem {
      menuItem.options.filterMethod = filterMethod;
      return menuItem;
    }

    /**
     * Enable by default and prevent de-selecting.
     */
    static withForcedSelect(result: ColumnMenuItem): ColumnMenuItem {
      result.hidden = true;
      result.selected = true;
      return result;
    }

    static withOptions(result: ColumnMenuItem, additionalOptions: Partial<TableColumnOptions>,
      additionalSettings?: Partial<ColumnMenuItem>): ColumnMenuItem {
      result.options = {
        ...result.options,
        ...additionalOptions,
      };

      if ( additionalSettings != null ) {
        // merge with additional options
        return {
          ...result,
          ...additionalSettings,
        };
      }

      return result;
    }

    static withOrderIndex(result: ColumnMenuItem, orderIndex: number): ColumnMenuItem {
      if ( orderIndex == null ) {
        result.orderIndex = null;
      } else {
        result.orderIndex = orderIndex;
      }
      return result;
    }

  }

  export interface ColumnMenuData<T = any>
    extends TableColumnMenu.MenuData<TableColumnOptions<T>> {
    menuItems: ColumnMenuItemMap<T>;
  }

  export type ColumnMenuItem<T = any> = TableColumnMenu.MenuItem<TableColumnOptions<T>>;

  export type ColumnMenuItemMap<T = any> = TableColumnMenu.MenuItemMap<TableColumnOptions<T>>;

  /**
   * @deprecated use {@link TableColumnOptions} instead!
   */
  export type ColumnOptions<ROW_TYPE = any, ID = string, COLUMN_TYPE = any> = TableColumnOptions<ROW_TYPE, ID, COLUMN_TYPE>;

}
