import {
  Curriculum,
  Identifiable,
  ImageableContentReference,
  Nameable,
  Titleable,
  Translateable
} from '../../../core/core.types';
import { CurriculumAdvSettingsRow } from './admin-curriculum-adv-settings/admin-curriculum-adv-settings.types';
import { AdminCurriculumPath } from "./admin-curriculum-paths-per-tg/admin-curriculum-paths-per-tg.types";


export namespace AdminCurriculum {

  interface CurriculumBaseTransient {
    picture: File;
  }

  export type Status = 'deleted' | 'disabled' | 'draft' | 'published';
  export type BehaviourLevel = 'curriculum' | 'item';

  export interface CurriculumMeta extends Curriculum, Titleable, ImageableContentReference<CurriculumBaseTransient> {
    hasCertificate?: boolean;
    status: Status;
    type: string;
    tags?: Array<string>;
    sysDescription?: string;
  }

}

export interface AdminCurriculumLegacy extends Identifiable, Nameable {
  description?: string;
  languageData?: Translateable;
  accessControl: AccessControl;
  status: AdminCurriculum.Status;
  curriculumType?: string;
  viewType: string;
  tags?: string;
}

export interface AdminCurriculumLegacyResolver extends Identifiable, Nameable {
  description?: string;
  languageData?: Translateable;
  accessControl: AccessControl;
  status: AdminCurriculum.Status;
  curriculumType?: string;
  viewType: string;
  tags?: AdminCurriculumTags[];
}

export interface AdminCurriculumTags {
  id: number,
  title: string,
}

/**
 * @deprecated To be replaced with {@link RbacActions}
 */
export interface AccessControl {
  c: boolean;
  r: boolean;
  w: boolean;
  d: boolean;
  a: boolean;
}

export interface CurriculumMassActionNextForNewIterationForUsers {
  count: string;
  url: string;
}

export interface NewIterationForUsersResponse {
  timestamp?: string;
  msg: string;
}

export interface CurriculumStatusListInfo {
  userId: number;
  status: string;
  userName: string;
  iteration: number;
  validSince: string;
  displayStatus: string;
  path: number;
  completed: string;
  validUntil: string;
}

export enum BehaviourEnum {
  conditional_switch_path = 'conditional_switch_path',
  followup_path = 'followup_path',
  followup_path_after_fail = 'followup_path_after_fail',
  new_iteration = 'new_iteration',
  new_iteration_for_sub_cur = 'new_iteration_for_sub_cur',
  new_iteration_on_repetition = 'new_iteration_on_repetition',
  pass_on_assets = 'pass_on_assets',
  reset_all_target_courses_on_repetition = 'reset_all_target_courses_on_repetition',
  reset_all_targetcourses = 'reset_all_targetcourses',
  reset_item_targetcourse = 'reset_item_targetcourse',
  reset_mandatory_targetcourses = 'reset_mandatory_targetcourses',
  reset_offline_content = 'reset_offline_content',
  scan_all_targetcourses = 'scan_all_targetcourses',
  scan_targetcourse = 'scan_targetcourse',
  switch_path_definition = 'switch_path_definition',
  switch_path_after_item_execution = 'switch_path_after_item_execution',
  switch_path_after_iteration_start = 'switch_path_after_iteration_start',
}

export enum BehaviourIndicatorEnum {
  on_start = 'on_start',
  on_complete = 'on_complete',
  on_certified = 'on_certified',
  on_fail = 'on_fail',
  on_new = 'on_new',
  on_pass = 'on_pass',
  on_new_first = 'on_new_first',
  on_invalidity_start = 'on_invalidity_start',
  on_skill_switched_to_green = 'on_skill_switched_to_green',
  on_skill_switched_to_red = 'on_skill_switched_to_red',
  on_this_cur_started = 'on_this_cur_started'
}

export interface Behaviour extends Identifiable, Titleable {
  behaviour: BehaviourEnum;
  indicator: BehaviourIndicatorEnum;
  itemId?: number;
  context?: string;
}

export interface CurriculumBehaviourItemDTO {
  id: number;
  title: string;
  orderIndex: number;
  variationCounter: number;
}

export interface CurriculumAdvSettingsResponse {
  settings: Array<Behaviour>;
}

export interface AdminCurriculumAdvSettingsRouteData {
  settings?: Array<CurriculumAdvSettingsRow>;
  items?: Array<CurriculumBehaviourItemDTO>;
  paths?: Array<AdminCurriculumPath>;
  restricted?: boolean;
}

export interface AdminCurriculumAdvSettingsDialogData {
  level: AdminCurriculum.BehaviourLevel;
  behaviour?: CurriculumAdvSettingsRow;
  behaviours: Array<CurriculumAdvSettingsRow>;
  items: Array<CurriculumBehaviourItemDTO>;
  paths: Array<AdminCurriculumPath>;
  restricted: boolean;
}

export enum CurriculumViewType {
  OnlineAgenda ='a294578e-ab2f-4576-a106-0177600b67ad',
  LearnerCockpit = '174b90c1-5781-4c1e-a5dd-989a3618af4e',
}
