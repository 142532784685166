import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth.guard';
import { ColumnSettingsResolver } from '../../core/column-settings/column-settings.resolver';
import { DirtyCheckService } from '../../core/dirty-check.service';
import { NotFound404Component } from '../main/not-found-404/not-found-404.component';
import { AccountDesignComponent } from './account-design/account-design.component';
import { FooterConfComponent } from './account-design/footer-conf/footer-conf.component';
import { GeneralConfComponent } from './account-design/general-conf/general-conf.component';
import { LoginPageConfComponent } from './account-design/login-page-conf/login-page-conf.component';
import { WidgetConfComponent } from './account-design/widget-conf/widget-conf.component';
import { AdminScriptComponent } from './admin-script/admin-script.component';
import { AdminScriptResolver } from './admin-script/admin-script.resolver';
import { LicenseGroupToTgComponent } from './development-goals/license-group-to-tg/license-group-to-tg.component';
import { LicenseGroupToTgResolver } from './development-goals/license-group-to-tg/license-group-to-tg.resolver';
import { ExtLoginsResolver } from './externals/ext-logins/ext-logins-resolver.service';
import { ExtLoginsComponent } from './externals/ext-logins/ext-logins.component';
import { ReportGenAdminComponent } from './report-gen-admin/report-gen-admin.component';
import { ExtOauth2ListComponent } from './externals/ext-oauth2/ext-oauth2-list/ext-oauth2-list.component';
import { ExtOauth2ListResolver } from './externals/ext-oauth2/ext-oauth2-list/ext-oauth2-list.resolver';
import { ExtPaymentModuleComponent } from './externals/ext-payment-module/ext-payment-module.component';
import { ExtPaymentModuleResolver } from './externals/ext-payment-module/ext-payment-module.resolver';
import { AdminSelfRegistrationComponent } from './admin-self-registration/admin-self-registration.component';
import { AdminSelfRegistrationResolver } from './admin-self-registration/admin-self-registration.resolver';
import { AdminConfigurationComponent } from './admin-configuration/admin-configuration.component';
import { AdminConfigurationModule } from './admin-configuration/admin-configuration.module';
import { AdminSignaturesListComponent } from './admin-signatures/admin-signatures-list/admin-signatures-list.component';
import { AdminSignaturesListResolver } from './admin-signatures/admin-signatures-list/admin-signatures-list.resolver';
import {
  AdminSignaturesDetailsComponent
} from './admin-signatures/admin-signatures-details/admin-signatures-details.component';
import {
  AdminSignaturesDetailsResolver
} from './admin-signatures/admin-signatures-details/admin-signatures.details.resolver';
import {
  AdminNotificationsListComponent
} from './admin-event-actions/admin-notifications/admin-notifications-list/admin-notifications-list.component';
import {
  AdminNotificationsListResolver
} from './admin-event-actions/admin-notifications/admin-notifications-list/admin-notifications-list.resolver';
import { AdminQAListComponent } from './admin-qa/admin-qa-list/admin-qa-list.component';
import { AdminQAEditComponent } from './admin-qa/admin-qa-edit/admin-qa-edit.component';
import { AdminQAListResolver } from './admin-qa/admin-qa-list/admin-qa-list.resolver';
import { AdminQAEditResolver } from './admin-qa/admin-qa-edit/admin-qa-edit.resolver';
import {
  AdminTextMacrosListResolver
} from './admin-text-macros/admin-text-macros-list/admin-text-macros-list.resolver';
import {
  AdminTextMacrosListComponent
} from './admin-text-macros/admin-text-macros-list/admin-text-macros-list.component';
import {
  AdminTextMacrosDetailsComponent
} from './admin-text-macros/admin-text-macros-details/admin-text-macros-details.component';
import {
  AdminTextMacrosDetailsResolver
} from './admin-text-macros/admin-text-macros-details/admin-text-macros-details.resolver';
import {
  AdminGamificationDetailsComponent
} from './admin-gamification/admin-gamification-details/admin-gamification-details.component';
import {
  AdminGamificationDetailsResolver
} from './admin-gamification/admin-gamification-details/admin-gamification-details.resolver';
import { AdminGamificationComponent } from './admin-gamification/admin-gamification.component';
import { AdminGamificationResolver } from './admin-gamification/admin-gamification.resolver';
import { ExtOauth2UsersComponent } from './externals/ext-oauth2/ext-oauth2-users/ext-oauth2-users.component';
import { extOauth2UsersResolver } from './externals/ext-oauth2/ext-oauth2-users/ext-oauth2-users.service';


const routes: Routes = [
  {
    path: 'admin',
    children: [
      {
        path: 'account-design',
        component: AccountDesignComponent,
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navAdminAccountDesign',
        },
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'general' },
          { path: 'general', component: GeneralConfComponent, canDeactivate: [ DirtyCheckService ] },
          {
            path: 'login',
            component: LoginPageConfComponent,
            canDeactivate: [ DirtyCheckService ],
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navAdminAccountDesignLogin',
            }
          },
          { path: 'footer', component: FooterConfComponent, canDeactivate: [ DirtyCheckService ] },
        ],
      },
      {
        path: 'development-goals',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'licence-group/assignment' },
          {
            path: 'licence-group',
            children: [
              {
                path: 'assignment',
                component: LicenseGroupToTgComponent,
                resolve: {
                  assignments: LicenseGroupToTgResolver,
                },
                canActivate: [ AuthGuard ],
                canDeactivate: [ DirtyCheckService ],
                data: {
                  checkPermission: 'navAdminDistributionLimited',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'offline',
        loadChildren: () => import('./admin-offline/admin-offline.module').then(m => m.AdminOfflineModule),
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navAdminOffline',
        },
      },
      {
        path: 'courses',
        loadChildren: () => import('./admin-courses/admin-courses.module').then(m => m.AdminCoursesModule),
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navAdminCourse',
        },
      },
      {
        path: 'ext',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'login' },
          {
            path: 'login',
            component: ExtLoginsComponent,
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navAdminExternalLogin',
            },
            resolve: {
              logins: ExtLoginsResolver,
            },
          },
          {
            path: 'oauth2',
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navAdminExternalOAuth2',
            },
            children: [
              {
                path: '', pathMatch: 'full',
                component: ExtOauth2ListComponent,
                resolve: {
                  clientRegistrations: ExtOauth2ListResolver,
                },
              },
              {
                path: ':registrationId/users',
                component: ExtOauth2UsersComponent,
                resolve: {
                  data: extOauth2UsersResolver,
                },
              }
            ],
          },
          {
            path: 'dsbGateway',
            component: ExtPaymentModuleComponent,
            canActivate: [ AuthGuard ],
            canDeactivate: [ DirtyCheckService ],
            data: {
              checkPermission: 'navAdminExternalDSBGateway',
            },
            resolve: {
              paymentSettings: ExtPaymentModuleResolver,
            },
          },
        ],
      },
      {
        path: 'configuration',
        canActivate: [ AuthGuard ],
        component: AdminConfigurationComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'widgets' },
          { path: 'widgets', component: WidgetConfComponent, canDeactivate: [ DirtyCheckService ] },
          {
            path: 'gamification',
            canActivate: [ AuthGuard ],
            data: {
              checkPermission: 'navAdminGamification'
            },
            children: [
              {
                path: '', pathMatch: 'full',
                component: AdminGamificationComponent,
                resolve: {
                  badgeTemplates: AdminGamificationResolver,
                }
              },
              {
                path: 'edit/:badgeTemplateUUID',
                canDeactivate: [ DirtyCheckService ],
                component: AdminGamificationDetailsComponent,
                resolve: {
                  data: AdminGamificationDetailsResolver,
                }
              },
            ]
          },
          {
            path: 'report-gen-admin',
            children: [
              { path: '', redirectTo: 'Curriculum', pathMatch: 'full' },
              {
                path: ':targetType',
                component: ReportGenAdminComponent,
                resolve: {
                  columnSettings: ColumnSettingsResolver,
                },
                canActivate: [ AuthGuard ],
                canDeactivate: [ DirtyCheckService ],
                runGuardsAndResolvers: 'always',
                data: {
                  checkPermission: 'navAdminReportGenerator',
                  columnsForUpdate: true,
                },
              },
            ],
          },
          {
            path: 'start-page',
            loadChildren: () => import('./account-design/landing-page-conf/landing-page-conf.module').then(m => m.LandingPageConfModule)
          },
          {
            path: 'profile',
            loadChildren: () => import('./admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
          }
        ],
      },
      {
        path: 'script',
        canActivate: [ AuthGuard ],
        canDeactivate: [ DirtyCheckService ],
        component: AdminScriptComponent,
        data: {
          checkPermission: 'navAdminJavascript',
        },
        resolve: {
          accountScript: AdminScriptResolver,
        },
      },
      {
        path: 'self-registration',
        canActivate: [ AuthGuard ],
        canDeactivate: [ DirtyCheckService ],
        component: AdminSelfRegistrationComponent,
        data: {
          checkPermission: 'navAdminSelfRegistration',
        },
        resolve: {
          data: AdminSelfRegistrationResolver,
        },
      },
      {
        path: 'reduced-attributes',
        loadChildren: () => import('./admin-reduced-user-attributes/admin-reduced-user-attributes.module').then(m => m.AdminReducedUserAttributesModule)
      },
      {
        path: 'curricula-groups',
        loadChildren: () => import('./admin-curriculum/admin-curriculum-invoice-groups/admin-curriculum-invoice-groups.module').then(m => m.AdminCurriculumInvoiceGroupsModule),
      },
      {
        path: 'curriculum',
        loadChildren: () => import('./admin-curriculum/admin-curriculum.module').then(m => m.AdminCurriculumModule),
      },
      {
        path: 'news',
        loadChildren: () => import('./admin-news/admin-news.module').then(m => m.AdminNewsModule),
      },
      {
        path: 'ac',
        loadChildren: () => import('./admin-action-codes/admin-action-codes.module').then(m => m.AdminActionCodesModule),
      },
      {
        path: 'textMacros',
        children: [
          {
            path: '',
            pathMatch: 'full',
            resolve: {
              textMacros: AdminTextMacrosListResolver,
            },
            component: AdminTextMacrosListComponent,
          },
          {
            path: 'details/:textMacroId',
            resolve: {
              data: AdminTextMacrosDetailsResolver,
            },
            component: AdminTextMacrosDetailsComponent,
          }
        ]
      },
      {
        path: 'notifications',
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navAdminNotifications'
        },
        children: [
          {
            path: '', pathMatch: 'full',
            component: AdminNotificationsListComponent,
            resolve: {
              notifications: AdminNotificationsListResolver,
            },
            // loadChildren: () => import('./admin-event-actions/admin-notifications/admin-notifications-list/admin-notifications-list.module')
            //   .then(m => m.AdminNotificationsListModule),
          },
          {
            path: 'message',
            data: {
              filterType: 'notification',
            },
            loadChildren: () => import('./admin-event-actions/admin-event-actions-message.routing')
              .then(m => m.AdminEventActionsMessageRouting),
          },
          {
            path: 'urlCall',
            data: {
              filterType: 'urlCall',
            },
            loadChildren: () => import('./admin-event-actions/admin-event-actions-url-call.routing')
              .then(m => m.AdminEventActionsUrlCallRouting),
          },
          {
            path: 'signatures',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: AdminSignaturesListComponent,
                resolve: {
                  signatures: AdminSignaturesListResolver,
                },
              },
              {
                path: 'details/:signatureId',
                component: AdminSignaturesDetailsComponent,
                resolve: {
                  data: AdminSignaturesDetailsResolver,
                }
              }
            ]
          }
        ]
      },
      {
        path: 'qa',
        canActivate: [ AuthGuard ],
        data: {
          checkPermission: 'navAdminQA'
        },
        children: [
          {
            path: '', pathMatch: 'full',
            component: AdminQAListComponent,
            resolve: {
              questions: AdminQAListResolver
            },
          },
          {
            path: 'edit/:qaUUID',
            component: AdminQAEditComponent,
            resolve: {
              details: AdminQAEditResolver,
            }
          }
        ]
      },
      {
        path: 'categories',
        loadChildren: () => import('./admin-categories/admin-categories.module').then(m => m.AdminCategoriesModule)
      },
      {
        path: 'catalog',
        loadChildren: () => import('./admin-catalog/admin-catalog.module').then(m => m.AdminCatalogModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./admin-users/admin-users.module').then(m => m.AdminUsersModule)
      },
      {
        path: 'rduser',
        loadChildren: () => import('./admin-users-reduced/admin-users-reduced.module').then(m => m.AdminUsersReducedModule)
      },
      {
        path: 'target-groups',
        loadChildren: () => import('./admin-target-groups/admin-target-groups.module').then(m => m.AdminTargetGroupsModule)
      },
      {
        path: 'user-attribute',
        loadChildren: () => import('./admin-user-attribute/admin-user-attribute.module').then(m => m.AdminUserAttributeModule)
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./admin-user-groups/admin-user-groups.module').then(m => m.AdminUserGroupsModule)
      },
      {
        path: 'user-import',
        loadChildren: () => import('./admin-user-import/admin-user-import-list/admin-user-import.module').then(m => m.AdminUserImportModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./admin-roles/admin-roles.module').then(m => m.AdminRolesModule)
      },
      {
        path: 'learner-state',
        loadChildren: () => import('./admin-learner-state/admin-learner-state.module').then(m => m.AdminLearnerStateModule)
      },
      {
        path: 'handbook',
        loadChildren: () => import('./admin-handbook/admin-handbook.module')
          .then(m => m.AdminHandbookModule)
      },
      // KEEP AS LAST ENTRY!
      // display 404 for all others
      { path: '**', component: NotFound404Component },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
    AdminConfigurationModule,
  ],
})
export class AdminRoutingModule {
}
