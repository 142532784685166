import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ColumnFilterV2, FilterOperator } from '../../core/column-settings/column-filter.types';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { MatOption } from '@angular/material/core';
import { PivotFilterService } from './pivot-filter.service';


@Component({ template: '' })
export class AbstractPivotFilterComponent<T = string>
  implements AfterViewInit, OnDestroy {

  @Output() readonly change$: Observable<ColumnFilterV2<string, T>>;
  @Input() debounceTimeout = 1500;
  @Output() readonly dirty$: Observable<boolean>;
  @Output() readonly blur: EventEmitter<null>;
  @Input() label: string;
  readonly formControl = new UntypedFormControl();
  @Input() placeholder: string;
  @Input() showActions = true;
  dateFormGroup: FormGroup;

  private _action: FilterOperator;
  private _actions: string[];
  // hasValue = false;
  private _change = new EventEmitter<ColumnFilterV2<string, T>>();
  private _debounce = null;
  private _dirty = new EventEmitter<boolean>();
  private _filter: ColumnFilterV2<string, T>;
  private _initialAction: FilterOperator = null;
  private _initialValue: T = null;
  private _isDirty = false;
  private _previousValue: T = null;
  private _value: T = null;

  constructor() {
    this.change$ = this._change.asObservable();
    this.dirty$ = this._dirty.asObservable().pipe(distinctUntilChanged());
    this.blur = new EventEmitter();

    PivotFilterService.filterReset$
      .pipe(tap(() => this.clearValue()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get action(): FilterOperator {
    return this._action;
  }

  set action(value: FilterOperator) {
    this._action = this.checkAction(value);
  }

  get actions(): string[] {
    return this._actions;
  }

  set actions(value: string[]) {
    this._actions = value;
    this._action = this.checkAction(this._action);
  }

  get defaultAction(): string {
    return this._initialAction;
  }

  @Input()
  set defaultAction(value: string) {
    this._initialAction = value as FilterOperator;
    this._action = value as FilterOperator;
  }

  get filter(): ColumnFilterV2<string, T> {
    return this._filter || {} as any;
  }

  @Input()
  set filter(value: ColumnFilterV2<string, T>) {
    this._filter = value;
    if ( !value ) {
      return;
    }

    this.setFilterState(value);
    this.afterFilterSet();
  }

  get hasChanged(): boolean {
    return (this._value !== this._filter?.defaultValue);
  }

  get hasDefaultValue(): boolean {
    const defaultValue = this._filter?.defaultValue;
    return (defaultValue != null) && (defaultValue !== '');
  }

  get hasValue(): boolean {
    return (this._value != null) && (this._value !== '');
  }

  get isDirty(): boolean {
    return this._isDirty;
  }

  set isDirty(value: boolean) {
    this._isDirty = value;
    this._dirty.next(value);
  }

  /**
   * If label is defined, it is used for the mat-label. If label is null-ish, but placeholder is defined, uses
   * placeholder.
   * Otherwise, the text matching the current action is displayed - if available.
   */
  get labelText(): string {
    if ( this.label != null ) {
      return this.label;
    }

    if ( this.placeholder != null ) {
      return this.placeholder;
    }

    return this.getActionText();
  }

  /**
   * If the label is defined, placeholder is used as placeholder. If neither placeholder, nor label are defined,
   * the actionText is used as placeholder.
   */
  get placeholderText(): string {
    if ( (this.placeholder != null) && (this.label != null) ) {
      return this.placeholder;
    }

    return this.getActionText();
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
  }

  afterFilterSet(): void {
    this.formControl.setValue(this._value, { emitEvent: false });
  }

  cancelDebounce(): void {
    if ( this._debounce ) {
      clearTimeout(this._debounce);
      this._debounce = null;
    }
  }

  checkAction(value: FilterOperator): FilterOperator {
    if ( this._actions == null ) {
      return this._initialAction;
    }
    if ( this._actions.length === 0 || (this._actions.indexOf(value) < 0) ) {
      return this._initialAction;
    }
    return value;
  }

  checkValueChanged(value: T): boolean {
    if ( this._previousValue !== value ) {
      this._value = value;
      return true;
    }
    return false;
  }

  cleanValue(value: any): T {
    return 'null' === value ? null : value;
  }

  clearValue(): void {
    if ( this.dateFormGroup != null ) {
      this.dateFormGroup.reset();
    }
    this.cancelDebounce();
    this._value = this._filter.defaultValue;
    this.afterFilterSet();
    this.updateValue();
  }

  clearValueDropdown(matSelect: MatSelect): void {
    this.cancelDebounce();
    this.value = this.filter.defaultValue;

    // fix styling issue on filter reset
    matSelect?.options?.forEach(option => option.setInactiveStyles());

    this.afterFilterSet();
    this.updateValue();
  }

  /**
   * fix for active state of options when filter value is changed externally
   */
  fixMatOptionHighlight(eltOption: MatOption): false {
    if ( eltOption.selected ) {
      eltOption.setActiveStyles();
    } else {
      eltOption.setInactiveStyles();
    }
    return false;
  }

  getActionText(): string | null {
    return null;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.formControl.markAsTouched();
      // update the initial values once, after queryParamsService parsed the url
      this.setFilterState(this._filter);
    }, 100);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onChange(value: any): void {
    const cleanValue: T = this.cleanValue(value);
    this.cancelDebounce();
    if ( this.checkValueChanged(cleanValue) ) {
      this._debounce = setTimeout(() => this.updateValue(), this.debounceTimeout);
    }
  }

  onKeyDown($event: KeyboardEvent): void {
    this.cancelDebounce();
    if ( $event && ($event.key === 'Enter') ) {
      this.updateValue();
    } else {
      this.onChange(this._filter.value);
    }
  }

  setAction(action: FilterOperator): void {
    this.action = action;
    this.cancelDebounce();
    this.updateAction();
  }

  updateAction(): void {
    this.writeToFilter(this._action, this.filter?.value);
    this.emitEvents();
  }

  updateValue(): void {
    this._previousValue = this._value;
    this.writeToFilter(this.filter?.action, this._value);
    this.emitEvents();
  }

  writeToFilter(action: FilterOperator, value: T): void {
    if ( this._filter == null ) {
      // maybe the filter has not yet been initialized?
      return;
    }

    this._filter.action = action;
    this._filter.value = value;
  }

  protected emitEvents(): void {
    let isDirty;

    if ( this._initialValue ) {

      isDirty =
        // the value has changed from the initial one
        (this._value !== this._initialValue) ||
        // the action differs from the initial action *and* the initial value is not empty -> this has changed
        // in a meaningful way
        (this.hasValue && (this._action !== this._initialAction));

    } else {
      // we have some non-empty value (without any initial state) -> this has changed
      isDirty = this.hasValue;
    }

    this._change.emit(this._filter);

    if ( this._isDirty !== isDirty ) {
      this._isDirty = isDirty;
      this._dirty.emit(isDirty);
    }
  }

  protected setFilterState(value: ColumnFilterV2<string, T>) {

    if ( value == null ) {
      return;
    }

    if ( value.action == null || value.action.trim() === '' ) {
      this._filter.action = this._initialAction;
    } else {
      this._action = this._initialAction = this.checkAction(value.action);
    }

    this._initialValue = this._previousValue = this._value = this.cleanValue(value.value);
    this._isDirty = false;
  }

  protected onBlur() {
    this.blur.emit();
  }

}
