<mat-form-field
  appearance="outline"
  (click)="$event.stopPropagation()"
>
  <mat-label
    *ngIf="label"
  >{{label}}</mat-label>

  <input
    type="text"
    autocomplete="off"
    matInput
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
  />

  <!-- Clear button -->
  <button
    (click)="onClear()"
    *ngIf="value?.length > 0"
    aria-label="Clear"
    mat-icon-button
    matSuffix
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</mat-form-field>
