import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FileInfo } from 'src/app/core/core.types';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { destroySubscriptions } from '../../core/reactive/until-destroyed';
import { FileUploadService } from '../input/file-upload/file-upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { InfoService } from 'src/app/core/info/info.service';
import { isNothing } from 'src/app/core/utils';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FileRenderComponent } from '../file-render/file-render.component';
import { CoreModule } from 'src/app/core/core.module';
import { UploadFile } from 'src/app/core/files.types';

export interface FileMultiChangeEvent {
  change: 'add' | 'remove';
  file: FileInfo;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    MatIconModule,
    FileRenderComponent,
  ],
  selector: 'rag-file-multi',
  templateUrl: './file-multi.component.html',
  styleUrls: [ './file-multi.component.scss' ],
})
export class FileMultiComponent
  implements OnDestroy, OnChanges {

  @Input() accept: Array<string> = [];  // accept all kind of files
  @Output() readonly changeEvent: EventEmitter<FileMultiChangeEvent>;
  @Input() readonly = false;
  @Input() removeConfirmation = true;   // display confirmation when remove file
  @Input() fitVertical = false;         // take the whole parent's height
  @Input() autoUpload = false;
  @Input() autoDelete = true;

  readonly uploadProgress$: Observable<number>;

  private _error = new EventEmitter<string>();
  private _files: Array<FileInfo> = [];
  private _uploadProgress$ = new EventEmitter<number>();

  constructor(
    private fileService: FileUploadService,
    private infoService: InfoService
  ) {
    this.changeEvent = new EventEmitter();
    this.uploadProgress$ = this._uploadProgress$.asObservable();
  }

  get count() {
    return this._files?.length ?? 0;
  }

  get files() {
    return this._files;
  }

  @Input() set files(value: Array<FileInfo>) {
    if (value == null) {
      this._files = [];
      return;
    }
    this._files = value.map(v => ({ ...v }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.hasOwnProperty('files') ) {
      if ( changes.files.firstChange ) {
        return;
      }
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onFile(fileInput: HTMLInputElement, newFiles: FileList) {
    if ( !(newFiles?.length > 0) ) {
      return;
    }

    // clear input to allow re-adding removed files
    const fileHandles = Array.from(newFiles);
    fileInput.value = null;

    const invalidFiles = fileHandles.filter(file => this.accept.find(acceptMimeType => file.type.match(acceptMimeType) != null));
    if ( invalidFiles.length > 0 ) {
      invalidFiles.forEach(file => this._error.emit(`type '${file.type}' of '${file.name}' not supported`));
      return;
    }

    fileHandles
      .map(file => new UploadFile(file))
      // ignore duplicates by name
      .filter(file => this._files.find(f => f.fileName === file.fileName) == null)
      .forEach(this.addFile);
  }

  onFileRemove(fileInput: HTMLInputElement, file: FileInfo) {
    const index = this._files.findIndex(f => f.uuid === file.uuid || f.id === file.id);
    if ( !(index > -1) ) {
      // removed file not found?
      return;
    }

    const closure = () => {
      this._files.splice(index, 1);
      this.changeEvent.emit({
        change: 'remove',
        file,
      });
    };

    if ((this.autoUpload && this.autoDelete) || !isNothing(file['file'])) {
      if (file.uuid != null && file.uuid !== '') {
        this.fileService
          .deleteFile(file.uuid)
          .pipe(tap(_ => closure()))
          .subscribe();
      } else {
        this.infoService.showAlert(
          $localize`:@@file_cannot_be_deleted:This file can be deleted only by classic front-end.`);
      }
      return;
    }
    closure();
  }

  getCountLabel(): string {
    if (this.count === 1) {
      return `${this.count} ` + $localize`:@@global_file:file`;
    } else {
      return `${this.count} ` + $localize`:@@global_files:Files`;
    }
  }

  private addFile = (file: UploadFile): void => {

    const closure = () => {
      this.changeEvent.emit({
        change: 'add',
        file,
      });
      this._files.push(file);
    };

    if (this.autoUpload) {
      this.fileService
        .uploadAttachmentWithProgress(file.uuid, file.file)
        .pipe(map(_event => {
          if (_event.type === HttpEventType.DownloadProgress) {
            const _progress = Math.floor(100 * _event.loaded / _event.total);
            file.setProgress(_progress < 100 ? _progress : 0);
          } else if (_event instanceof HttpResponse) {
            file.setProgress(0);
          }
        }))
        .pipe(tap(_ => closure()))
        .subscribe();
      return;
    }

    closure();
  };

}
