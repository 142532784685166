import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { LinkedUserTypes } from 'src/app/core/user/link/linked-users.types';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

@Component({
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogModule,
  ],
  selector: 'rag-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: [ './delete-user-dialog.component.scss' ],
})
export class DeleteUserDialogComponent
  implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LinkedUserTypes.LinkedUser,
  ) {
  }

  ngOnInit() {
  }

  onClick(result: boolean): void {
    this.dialogRef.close(result);
  }
}
