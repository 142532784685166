<div class="filter-box">

  <div class="input-wrapper">
    <ng-container [ngSwitch]="action">

      <!--BETWEEN-->
      <ng-container *ngSwitchCase="'$between'">
        <form [formGroup]="dateFormGroup">
          <mat-form-field appearance="outline">
            <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>
            <!-- Calendar Range component for filter $between -->
            <mat-date-range-input [rangePicker]="dateRangePicker" [separator]="'-'">
              <input matStartDate formControlName="start" (blur)="onBlur()">
              <input matEndDate formControlName="end" (blur)="onBlur()">
            </mat-date-range-input>
            <mat-datepicker-toggle matPrefix [for]="dateRangePicker"></mat-datepicker-toggle>
            <button
              (click)="clearValue()"
              [class.hidden]="!hasChanged"
              mat-icon-button
              matSuffix
              matTooltip="revert filter to default"
              i18n-matTooltip="@@pivot_filter_tooltip_revert"
              [matTooltipDisabled]="!hasDefaultValue"
            >
              <mat-icon *ngIf="hasDefaultValue" svgIcon="restore"></mat-icon>
              <mat-icon *ngIf="!hasDefaultValue" svgIcon="close"></mat-icon>
            </button>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </ng-container>

      <!--Fixed Ranges-->
      <ng-container *ngSwitchCase="'$nextDays'" [ngTemplateOutlet]="numberInput"></ng-container>
      <ng-container *ngSwitchCase="'$nextMonths'" [ngTemplateOutlet]="numberInput"></ng-container>
      <ng-container *ngSwitchCase="'$previousDays'" [ngTemplateOutlet]="numberInput"></ng-container>
      <ng-container *ngSwitchCase="'$previousMonths'" [ngTemplateOutlet]="numberInput"></ng-container>

      <ng-template #numberInput>
        <mat-form-field appearance="outline">
          <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>
          <input
            matInput
            type="text"
            inputmode="numeric"
            (keydown)="onKeyDown($event)"
            [(ngModel)]="value"
            (ngModelChange)="onChange($event)"
            (blur)="onBlur()"
            [placeholder]="placeholderText"
            [name]="filter?.identifier"
            matNativeControl
            i18n-matTooltip="@@global_price_filter_tooltip"
            matTooltip='Only integer inputs are allowed'
            [matTooltipDisabled]="numberMatchRegex()"
          >

          <button
            (click)="clearValue()"
            [class.hidden]="!hasChanged"
            mat-icon-button
            matSuffix
            matTooltip="revert filter to default"
            i18n-matTooltip="@@pivot_filter_tooltip_revert"
            [matTooltipDisabled]="!hasDefaultValue"
          >
            <mat-icon *ngIf="hasDefaultValue" svgIcon="restore"></mat-icon>
            <mat-icon *ngIf="!hasDefaultValue" svgIcon="close"></mat-icon>
          </button>
        </mat-form-field>
      </ng-template>

      <!--DEFAULT-->
      <ng-container *ngSwitchDefault>
        <mat-form-field appearance="outline">
          <mat-label *ngIf="labelText as _labelText">{{_labelText}}</mat-label>

          <!-- Calendar component -->
          <mat-datepicker-toggle [for]="datePicker" matPrefix></mat-datepicker-toggle>
          <input
            (keydown)="onKeyDown($event)"
            [(ngModel)]="value"
            (blur)="onBlur()"
            (ngModelChange)="onChange($event)"
            [matDatepicker]="datePicker"
            [name]="filter?.identifier"
            matNativeControl
            [placeholder]="placeholderText"
          />

          <button
            (click)="clearValue()"
            [class.hidden]="!hasChanged"
            mat-icon-button
            matSuffix
            matTooltip="revert filter to default"
            i18n-matTooltip="@@pivot_filter_tooltip_revert"
            [matTooltipDisabled]="!hasDefaultValue"
          >
            <mat-icon *ngIf="hasDefaultValue" svgIcon="restore"></mat-icon>
            <mat-icon *ngIf="!hasDefaultValue" svgIcon="close"></mat-icon>
          </button>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>

  <button
    *ngIf="showActions"
    [matMenuTriggerFor]="menu"
    mat-icon-button
    type="button"
  >
    <mat-icon *ngIf="!hasValue" svgIcon="filter-outline"></mat-icon>
    <mat-icon *ngIf="hasValue" svgIcon="filter"></mat-icon>

    <mat-menu #menu>
      <ng-template matMenuContent>
        <mat-radio-group
          [(ngModel)]="action"
          (ngModelChange)="setAction($event)"
        >
          <div mat-menu-item *ngFor="let actionOption of actionOptions">
            <mat-radio-button
              [value]="actionOption.value"
            >{{actionOption.label}}</mat-radio-button>
          </div>
        </mat-radio-group>
      </ng-template>
    </mat-menu>
  </button>

</div>
