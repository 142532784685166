<mat-chip-grid #chipList aria-label="Tag selection">
  <span matPrefix>#</span>
  <input
    placeholder="New Tag..."
    i18n-placeholder="@@global_new_tag"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
    [matAutocomplete]="auto"
    aria-describedby=""
    #tagInput
  >
</mat-chip-grid>
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="tagSelected($event)"
  (optionActivated)="activateOption($event)"
>
  <mat-option *ngFor="let tag of allTags$ | async" [value]="tag">
    {{tag}}
  </mat-option>
</mat-autocomplete>

<mat-chip-listbox aria-label="Tag selection">
  <mat-chip-option
    *ngFor="let tag of value"
    [selectable]="true"
    [removable]="true"
    (removed)="removeTag(tag)"
    [value]="tag"
    [selected]="false"
    class="tag"
  >
    {{tag}}
    <button matChipRemove>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </mat-chip-option>
</mat-chip-listbox>