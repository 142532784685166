import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ImageableContentReference} from '../../../../core/core.types';
import {Observable} from 'rxjs';
import {CachedSubject} from '../../../../core/cached-subject';
import {DistributionTypeHelper} from '../../../../core/distribution-type-helper';
import {ViewHelper} from '../../../../core/view-helper';
import {ImageUrlHelper} from '../../../../core/image-url-helper';
import {DisplayStatusColors, DisplayStatusHelper} from '../../../../core/display-status-helper';
import {ContentService} from '../../../../core/content/content.service';
import {Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'rag-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnChanges, OnInit {

  @Input() content!: ImageableContentReference;
  @Input() editorMode: boolean = false;

  contentLink: string;
  statusColor: DisplayStatusColors;
  readonly cardBackgroundStyles$: Observable<any | null>;
  readonly DEFAULT_PICTURE_URL = "assets/images/card_event_online.jpg";
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL)

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get objSubTypeAsText(): string {
    return DistributionTypeHelper
      .asText(this.content?.objType, this.content?.objSubType ?? this.content?.courseType);
  }

  get statusText(): string {
    if (this.content != null) {
      switch(this.statusColor) {
        case DisplayStatusColors.green:
          return $localize`:@@completed:Completed`;
        case DisplayStatusColors.yellow:
          return $localize`:@@started:Started`;
        case DisplayStatusColors.red:
          return $localize`:@@incomplete:Incomplete`;
      }
    }
    return $localize`:@@incomplete:Incomplete`;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('content')) {
      this.updateImageUrl();
    }
  }

  ngOnInit(): void {
    this.statusColor = DisplayStatusHelper.toColor(this.content?.displaystatus);
    this.contentLink = ContentService.getContentHref(this.content);
  }

  onNavigateToDetails(): void {
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }
}
