<ng-container *ngIf="version === 1">
  <div class="wrapper" [ngSwitch]="actionType">

    <rag-curriculum-locked-by
      *ngSwitchCase="'locked'"
      [content]="content"
    ></rag-curriculum-locked-by>

    <a
      *ngSwitchCase="'courseError'"
      (click)="onShowCourseErrorDialog()"
      mat-mini-fab
    >
      <mat-icon [svgIcon]="icon"></mat-icon>
    </a>

    <a
      *ngSwitchDefault
      [disabled]="locked"
      [routerLink]="contentHref"
      mat-mini-fab
      color="primary"
    >
      <mat-icon svgIcon="play"></mat-icon>
    </a>

  </div>
</ng-container>

<ng-container *ngIf="version === 2">
  <div class="wrapper-2" [ngSwitch]="actionType">
    <div *ngSwitchCase="'locked'" class="curriculum-locked-wrapper">
      <rag-curriculum-locked-by
        [content]="content"
        [version]="2"
      ></rag-curriculum-locked-by>
      <span [ngClass]="{'disabled': locked || (actionType === 'locked')}"
        (click)="onExecuteItem($event)"
        mat-flat-button
        class="item-title-wrapper"
      >
        {{selectedItemTitle}}
      </span>
    </div>

    <a
      *ngSwitchCase="'courseError'"
      (click)="onShowCourseErrorDialogV2($event)"
      mat-flat-button
      class="item-title"
    >
      {{selectedItemTitle}}
    </a>

    <a [ngClass]="{'disabled': locked || (actionType === 'locked')}"
      *ngSwitchDefault
      (click)="onExecuteItem($event)"
      mat-flat-button
      class="item-title"
    >
      {{selectedItemTitle}}
    </a>
  </div>
</ng-container>
