<div class="site-wrapper" style="" (click)="onNavigateToDetails()">

  <div class="card-wrapper" [ngClass]="{'card-wrapper-mobile': !editorMode}">
    <div class="mat-card example-card">
      <div
        class="image-wrapper"
        [ngStyle]="cardBackgroundStyles$ | async">
        <img *ngIf="statusColor === 3" class="card-icon-check rounded-div" src="assets/icons/svg/Controls/correct.svg">
      </div>
      <div class="mat-card-content">
        <div class="mat-card-title-wrapper">
          <p class="mat-card-title font-medium letterspacing-min">{{content?.title | localeText}}</p>
          <span class="mat-card-subtitle font-light letterspacing-more">{{objSubTypeAsText}}</span>
        </div>
        <div class="mat-card-bottom-wrapper">
          <div class="progress-line" mode="indeterminate">
            <div
              [ngClass]="{
              'width-0': (statusColor === 1),
              'width-50': (statusColor === 2),
              'width-100': (statusColor === 3)
               }"
              class="progress-line-bg"
            ></div>
          </div>
          <p class="text-status font-regular-italic letterspacing-more">{{statusText}}</p>
        </div>
      </div>
    </div>
  </div>

</div>
