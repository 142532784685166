import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContentService } from '../content/content.service';
import { ImageableContentReference } from '../core.types';

@Injectable({
  providedIn: 'root',
})
export class AccountResolverService {

  constructor(private contentService: ContentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ImageableContentReference[]> {
    return this.contentService.fetchAccountData().pipe(take(1));
  }
}
