<rag-page-header
  backUrl="/admin/textMacros"
>
  <div class="page-header-title">
    <span>{{pageHeaderTitle$ | async}}</span>
  </div>
  <div class="page-header-buttons">
    <button
      [disabled]="saveButtonDisabled$ | async"
      type="button"
      color="primary"
      mat-flat-button
      (click)="onSave()"
    >
      {{saveButtonText$ | async}}
    </button>
  </div>
</rag-page-header>

<rag-text-macros
  [formGroup]="formGroup"
  [showType]="false"
  [data]="textMacro"
  [usage]="usages"
  [isTextMacro]="true"
  [currentMacro]="textMacro.macro"
></rag-text-macros>
