import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfileFieldTypes } from '../../../core/input/profile-field/profile-field.types';
import { destroySubscriptions } from '../../../core/reactive/until-destroyed';
import { InputHelper } from '../input.types';


@Component({ template: '' })
export class AbstractProfileFieldComponent<T>
  implements OnChanges, OnDestroy, OnInit {

  @Input() disabled: boolean;
  @Input() field: ProfileFieldTypes.ProfileField<T>;
  @Input() suffixTemplate: TemplateRef<any>;
  form: UntypedFormGroup;

  constructor(
    private controlContainer: ControlContainer,
  ) {
  }

  protected static checkFormControl<T>(
    form: UntypedFormGroup,
    fieldId: string,
    value: T,
    disabled: boolean,
    required: boolean,
  ) {

    if ( form.contains(fieldId) ) {
      return;
    }

    form.addControl(fieldId, new UntypedFormControl({
      value,
      disabled,
    }, required ? [ Validators.required ] : []));
  }

  getControl(): AbstractControl | null {
    return this.form?.get(this.field.fieldId);
  }

  getError(): string | null {
    const control = this.getControl();
    return InputHelper.getError(control);
  }

  hasError(errorCode: string): boolean {
    return this.getControl()?.hasError(errorCode);
  }

  isDisabled(): boolean {
    return this.disabled || !this.field?.editable;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.hasOwnProperty('disabled') ) {
      this.checkDisabled();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    const form = this.form = this.controlContainer.control as UntypedFormGroup;

    AbstractProfileFieldComponent
      .checkFormControl(form, this.field.fieldId, this.cleanValue(), this.isDisabled(), this.field.required);

    this.checkDisabled();
  }

  protected cleanValue(): T {
    return this.field?.value;
  }

  protected checkDisabled(): void {
    const control = this.getControl();
    if ( control == null ) {
      // ignore for now
      return;
    }

    if ( this.isDisabled() && control.enabled ) {
      control.disable();
    } else if ( !this.isDisabled() && control.disabled ) {
      control.enable();
    }
  }

}
