import { mergeWith } from 'lodash';

const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const zeroFormat = (value: number, leadingZero: number = 2) => {
  const pad = '0'.repeat(leadingZero);
  return (pad + value).slice(-pad.length);
};

/**
 * @see https://stackoverflow.com/a/7261048
 */
export const dataUrlToBlog = (dataURI: string): Blob => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for ( let i = 0; i < byteString.length; i++ ) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ ab ], { type: mimeString });
};

export class FileHelper {

  static sizeFormatted(size: number): string {
    const KB = 1024;
    if ( size < KB ) {
      return `${size} B`;
    }
    const MB = 1024 * KB;
    if ( size < MB ) {
      const sizeKB = Math.ceil(size / KB);
      return `${sizeKB} KB`;
    }
    const GB = 1024 * MB;
    if ( size < GB ) {
      const sizeMB = Math.ceil(size / MB);
      return `${sizeMB} MB`;
    }
    const sizeGB = Math.ceil(size / GB);
    return `${sizeGB} GB`;
  }
}

export const updateResultObject = <T>(target: T, source: T, mergeAttributeKeys: string[]): void => {
  mergeWith(target, source, (value, srcValue, key: string) => {
    if ( mergeAttributeKeys.includes(key) ) {
      return srcValue;
    } else {
      return undefined;
    }
  });
};

export const isTimeCrossing = (begin1: number, end1: number, begin2: number, end2: number) => {
  const signA1 = Math.sign(begin1 - begin2);
  const signA2 = Math.sign(begin1 - end2);
  if (signA1 !== signA2) {
    return true;
  }
  const signB1 = Math.sign(end1 - begin2);
  const signB2 = Math.sign(end1 - end2);
  return (signB1 !== signB2) || (signB1 !== signA1);
};

export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];

export const MapToArray = <T>(obj: Map<any, any>): Array<T> => Object.values(obj) as T[];

export const isUUID = (value: string) => {
  if (typeof value === 'string') {
    return value.match(UUID_REGEX);
  }
  return false;
};

export const isNothing = (value: any) => value === null || value === undefined;

export const arrayToMap = <T, K>(data: Array<T>, keyExt: (item: T) => K): Map<K, T> => {
  const result = new Map<K, T>();
  if (data !== undefined) {
    data.forEach(item => {
      result.set(keyExt(item), item);
    });
  }
  return result;
}

export const arrayTraverseObjectsToMap = <T, K>(data: Array<T>, keyExt: (item: T) => K, childExtractor: (item: T) => T[]): Map<K, T> => {
  const result = new Map<K, T>();
  const closure = (items: Array<T>) => items.forEach(_item => {
    result.set(keyExt(_item), _item);
    const childItems = childExtractor(_item);
    if (childItems != undefined && childItems.length > 0) {
      closure(childItems);
    }
  });
  if (data !== undefined) {
    closure(data);
  }
  return result;
}
