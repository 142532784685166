<div class="container">
  <div *ngIf="data.message" [innerHTML]="data.message | safe:'html'"></div>

  <div *ngIf="!data.message" i18n="@@maintenance_snackbar_server_down">
  At the moment the system is undergoing a short maintenance.<br/>
  During this maintenance period, certain site features may be temporarily unavailable.<br/>
  Thank you for your understanding.
  </div>

  <button *ngIf="data.action" (click)="snackBarRef.dismissWithAction()"
          mat-flat-button
          type="button">
    <span i18n="@@maintenance_snackbar_dismiss">Close</span>
  </button>
</div>
