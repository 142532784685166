import { TableColumnMenu } from './table-column-menu/table-column-menu.types';
import {
  ColumnFilterDataAccessor,
  ColumnFilterDropdownOption,
  ColumnFilterMethod,
  ColumnFilterMethodAsync,
  filterDateV2,
  filterDropDownV2,
  filterHtmlV2,
  filterNumberV2,
  FilterOperator,
  filterTagsV2,
  filterTextV2,
} from '../../core/column-settings/column-filter.types';
import { CompareMethod, TransformerMethod } from '../../core/primitives/primitives.types';
import { AnyObject } from '../../core/core.types';
import { MergeHelper } from '../../core/primitives/merge.helper';
import { TableColumnDataType, TableColumnOptions } from './table-column.types';


/**
 * Starts from {@link TableColumnBuilder.start}.
 * The final result can be retrieved from {@link TableColumnBuilder.build}.
 */
export class TableColumnBuilder<DATA_TYPE = any,
  OPTIONS_TYPE extends TableColumnOptions<DATA_TYPE> = TableColumnOptions<DATA_TYPE>> {

  static readonly COLOR_GREEN = 'rgb(2, 161, 2)';
  static readonly COLOR_ORANGE = '#E8B028';
  static readonly COLOR_RED = '#970000';
  static readonly COLOR_GRAY = 'rgb(151, 151, 151)';

  private readonly _state: TableColumnMenu
    .MenuItem<OPTIONS_TYPE>;

  private constructor(
    state: TableColumnMenu.MenuItem<OPTIONS_TYPE>,
  ) {
    this._state = state;
  }

  /**
   * This is the start point of the builder. When you are done building, use {@link TableColumnBuilder.build} to
   * retrieve the final result.
   */
  static start<DATA_TYPE = any, OPTIONS_TYPE extends TableColumnOptions<DATA_TYPE> =
    TableColumnOptions<DATA_TYPE, string, FilterOperator>>(
    initialState?: TableColumnMenu.MenuItem<OPTIONS_TYPE>,
    cloneInitialState = true,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    return new TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE>((
      cloneInitialState ? MergeHelper.cloneDeep(initialState) : initialState
    ) ?? {
      id: null,
      selected: false,
      options: {
        dataType: null,
        filter: {
          action: FilterOperator.EQ,
          identifier: null,
          value: null,
          defaultValue: null,
        },
      } as OPTIONS_TYPE,
    });
  }

  build(): TableColumnMenu.MenuItem<OPTIONS_TYPE> {

    const state = this._state;
    if ( !state.id ) {
      throw new Error('Please use withColumnId to set the columnId!');
    }

    const options = state.options;
    if ( !options.dataType ) {
      throw new Error('Please use withType to set the data type of the column!');
    }

    return this._state;
  }

  clone(): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {
    return new TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE>(MergeHelper.cloneDeep(this._state));
  }

  /**
   * Set the identifier (which should be identical to the value of ng-container[matColumnDef]).
   */
  withColumnId(
    columnId: string,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;
    const filter = state.options.filter;

    state.id = filter.identifier = columnId;

    return this;
  }

  /**
   * Find a value from the object according to which column this is.
   */
  withDataAccessor(
    dataAccessor: TransformerMethod<DATA_TYPE, any, OPTIONS_TYPE> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    this._state.options.dataAccessor = dataAccessor;

    return this;
  }

  /**
   * Set the column as disabled (i.e. cannot be enabled or disabled by the user).
   * This will always place the column as last however.
   */
  withDisabled(
    disabled = true,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    this._state.disabled = disabled;

    return this;
  }

  /**
   * Find a value from the object according to which column this is.
   */
  withDisplayValueAccessor(
    displayValueAccessor: TransformerMethod<DATA_TYPE, string | number | null,
      TableColumnOptions<DATA_TYPE, string, FilterOperator>> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    this._state.options.displayValueAccessor = displayValueAccessor;

    return this;
  }

  /**
   * These are the possible options that the column might contain. All current options will be replaced.
   * <br/>
   * This will also populate the {@link dropDownOptionsOriginal} with a clone of the given options (which is used
   * when filtering, and rendering values).
   */
  withDropDownOptions(
    dropDownOptions: AnyObject<string>,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownOptions = MergeHelper.cloneDeep(dropDownOptions);
    options.dropDownOptionsOriginal = MergeHelper.cloneDeep(dropDownOptions);

    return this;
  }

  withDropDownSortMethod(
    dropDownSortMethod: CompareMethod<ColumnFilterDropdownOption> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownSortMethod = dropDownSortMethod;

    return this;
  }

  /**
   * Here you can set color from the icons for dropdown options.<br/>
   * Valid color options:<br/>
   * <ul>
   * <li>{@link TableColumnBuilder.COLOR_GREEN}</li>
   * <li>{@link TableColumnBuilder.COLOR_ORANGE}</li>
   * <li>{@link TableColumnBuilder.COLOR_RED}</li>
   * </ul>
   */
  withDropDownOptionsColor(
    dropDownOptionsColor: AnyObject<string>,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownOptionsColor = MergeHelper.cloneDeep(dropDownOptionsColor);

    return this;
  }

  /**
   * Here you can set icons for dropdown options
   */
  withDropDownOptionsIcons(
    dropDownOptionsIcons: AnyObject<string>,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownOptionsIcons = MergeHelper.cloneDeep(dropDownOptionsIcons);

    return this;
  }

  /**
   * Select which options will not be hidden (if so used), if there is no matching row.
   */
  withDropDownOptionsStatic(
    dropDownOptionsStatic: string[] = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownOptionsStatic = [ ...dropDownOptionsStatic ];

    return this;
  }

  /**
   * Select which options will not be hidden (if so used), if there is no matching row.
   * Defaults to true.
   */
  withDropDownWithoutAll(
    dropDownWithoutAll: boolean = true,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.dropDownWithoutAll = dropDownWithoutAll;

    return this;
  }

  /**
   * Update individual attributes of the filter object.
   */
  withFilterAction(
    action: FilterOperator,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const filter = this._state.options.filter;

    filter.action = action;

    return this;
  }

  /**
   * Function to get attributes from a row.
   */
  withFilterDataAccessor(
    filterDataAccessor: ColumnFilterDataAccessor<DATA_TYPE> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterDataAccessor = filterDataAccessor;

    return this;
  }

  /**
   * Define a customized debounce timeout for this column (in milliseconds).
   */
  withFilterDebounceTimeout(
    filterDebounceTimeout: number = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterDebounceTimeout = filterDebounceTimeout;

    return this;
  }

  /**
   * Set a default value for this column.
   */
  withFilterDefaultValue(
    defaultValue: any = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;
    const options = state.options;
    const filter = options.filter;

    // if we have a default value, set hasFilter to true
    state.hasFilter = defaultValue != null && defaultValue !== '';
    filter.value = filter.defaultValue = defaultValue;

    return this;
  }

  /**
   * Hide this filter in {@link ContentFilterComponent}.
   */
  withFilterHidden(
    filterHidden: boolean = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterHidden = filterHidden;

    return this;
  }

  /**
   * Define the filterMethod (if none is given, the default behaviour is used). filterMethodAsync will be removed.
   * <br/>
   * Usually the data argument passed to the filter function will be the column value. To access other attributes
   * or if you need to do some additional steps, you should combine this with
   * {@link TableColumnBuilder.withFilterDataAccessor}.
   */
  withFilterMethod(
    filterMethod: ColumnFilterMethod<string, DATA_TYPE> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterMethod = filterMethod;
    options.filterMethodAsync = null;

    return this;
  }

  /**
   * Define a filterMethodAsync. This will clear the regular filter method and mark this entry as
   * "needs to be handled asynchronously" in {@link ContentFilterComponent}.
   */
  withFilterMethodAsync(
    filterMethodAsync: ColumnFilterMethodAsync<string, DATA_TYPE> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterMethodAsync = filterMethodAsync;
    options.filterMethod = null;

    return this;
  }

  /**
   * The name of the attribute to write in the URL state (used with {@link QueryParamsService}).
   */
  withFilterStateAttribute(
    filterStateAttribute: string = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.filterStateAttribute = filterStateAttribute;

    return this;
  }

  /**
   * For use with filter or sorting columns (do not show up in column menus).
   */
  withHiddenDeselected(): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;

    state.hidden = true;
    state.selected = false;

    return this;
  }

  /**
   * Enable by default and prevent de-selecting.
   */
  withHiddenSelected(): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;

    state.hidden = true;
    state.selected = true;

    return this;
  }

  /**
   * Set an explicit label for the input. This overrides any label from the active filter action.
   */
  withLabel(
    label: string = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.label = label;

    return this;
  }

  /**
   * Define a placeholder. This will be shown in text or number filters for example.
   * <br/>
   * This was (is) historically used as label.
   */
  withPlaceholder(
    placeholder: string = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.placeholder = placeholder;

    return this;
  }

  /**
   Do not remove column when merging with server columns in {@link TableColumnMenuService.createFromDefaults}
   */
  withPreserve(
    preserve = true,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    this._state.preserve = preserve;

    return this;
  }

  /**
   * Set the initial selected state.
   */
  withSelected(
    selected: boolean = true,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;

    state.selected = selected;

    return this;
  }

  /**
   * Set order index (useful when ordering matters)
   */
  withOrderIndex(
    orderIndex: number
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;

    state.orderIndex = orderIndex;

    return this;
  }

  /**
   * Fetch, and transform a column value into sortable form.
   */
  withSortingAccessor(
    sortingAccessor: TransformerMethod<DATA_TYPE, any,
      TableColumnOptions<DATA_TYPE, string, FilterOperator>> = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;

    options.sortingAccessor = sortingAccessor;

    return this;
  }

  /**
   * Define the column title.
   */
  withTitle(
    title: string = null,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const state = this._state;

    state.title = title;

    return this;
  }

  /**
   * Define which type of content this column contains. You can skip this for completely customized columns.
   * Set {@link TableColumnOptions.filterMethod}, and {@link WithAction.action}.
   * Type {@link TableColumnDataType.other} sets the filterMethod to null.
   */
  withType(
    dataType: TableColumnDataType,
  ): TableColumnBuilder<DATA_TYPE, OPTIONS_TYPE> {

    const options = this._state.options;
    const filter = options.filter;

    switch ( dataType ?? '' ) {

      case TableColumnDataType.date:
      case TableColumnDataType.dateTime:
        options.filterMethod = filterDateV2;
        filter.action = FilterOperator.GTE;
        break;

      case TableColumnDataType.number:
        options.filterMethod = filterNumberV2;
        filter.action = FilterOperator.EQ;
        break;

      case TableColumnDataType.price:
        options.filterMethod = filterNumberV2;
        filter.action = FilterOperator.EQ;
        break;

      case TableColumnDataType.email:
      case TableColumnDataType.text:
        options.filterMethod = filterTextV2;
        filter.action = FilterOperator.LIKE;
        break;

      case TableColumnDataType.dropdown:
      case TableColumnDataType.radio:
        options.filterMethod = filterDropDownV2;
        filter.action = FilterOperator.EQ;
        // set the defaults to empty strings to match filter component
        this.withFilterDefaultValue('');
        break;

      case TableColumnDataType.html:
        options.filterMethod = filterHtmlV2;
        filter.action = FilterOperator.LIKE;
        break;

      case TableColumnDataType.other:
        options.filterMethod = null;
        filter.action = FilterOperator.EQ;
        break;

      case TableColumnDataType.multiselect:
      case TableColumnDataType.tags:
        options.filterMethod = filterTagsV2;
        options.filterDataAccessor = entry => entry?.[filter.identifier];
        filter.action = FilterOperator.LIKE;
        break;

      case TableColumnDataType.image:
      case TableColumnDataType.password:
        options.filterMethod = null;
        filter.action = FilterOperator.EQ;
        break;

      default:
        throw new Error('Invalid data type given "' + dataType + '"!');
    }

    options.dataType = dataType;

    return this;
  }



}

export const STRUCTURAL_COLUMN = TableColumnBuilder.start()
  .withColumnId('actions')
  .withType(TableColumnDataType.other)
  .withHiddenSelected()
  .withPreserve()
  .build();
