<div
  cdkDropListGroup
  class="list-wrapper"
>

  <div
    class="list target"
  >
    <div class="list-header list-append">
        <span class="list-title">
          <span>{{i18n.selected}}</span>
          <span
            *ngIf="maxSelections > 0"
            [ngClass]="{'invalid': isInvalid}"
            class="count"
          > ({{selectionCount || 0}} / {{maxSelections}})</span>
        </span>
    </div>

    <div class="list-search list-append">
      <mat-form-field appearance="outline" class="search-field">
        <mat-label>{{i18n.search}}</mat-label>
        <input
          [(ngModel)]="viewDataTarget.filter"
          (ngModelChange)="onFilter(viewDataTarget)"
          autocomplete="off"
          matInput
          type="text"
        />
        <button
          (click)="onFilterReset(viewDataTarget)"
          *ngIf="viewDataTarget.filter?.length > 0"
          mat-icon-button
          matSuffix
        >
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="list-scroll">
      <cdk-virtual-scroll-viewport
        #viewportTarget
        [appendOnly]="allowSortingInContainers"
        itemSize="48"
      >
        <mat-list
          (cdkDropListDropped)="onDrop(viewDataSource, viewDataTarget, viewportTarget.getRenderedRange().start, $event)"
          [cdkDropListData]="viewDataTarget.filteredData"
          [cdkDropListDisabled]="isDragDropDisabled"
          [cdkDropListSortingDisabled]="!allowSortingInContainers"
          cdkDropList
        >
          <ng-container
            *cdkVirtualFor="let entry of viewDataTarget.filteredData; templateCacheSize: 0"
          >
            <mat-list-item
              (cdkDragStarted)="onDragStarted(viewDataTarget, entry)"
              [cdkDragData]="entry"
              [cdkDragDisabled]="isDragDropDisabled"
              cdkDrag
              [ngClass]="{changed: (entry.changed === true)}"
              class="item"
            >
              <rag-assignment-dialog-entry
                (entryChange)="onSelectionChanged(viewDataTarget)"
                (touchEnd)="isDragDropDisabled = false"
                (touchStart)="isDragDropDisabled = true"
                [entry]="entry"
                [disabled]="entry.disabled ?? false"
                ragTouch
              ></rag-assignment-dialog-entry>
              <span *cdkDragPreview class="placeholder"></span>
              <span *cdkDragPlaceholder class="placeholder"></span>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </cdk-virtual-scroll-viewport>
    </div>

    <div class="select-all list-append">
      <mat-checkbox
        (change)="onToggleAll(viewDataTarget, $event)"
        [checked]="viewDataTarget.selectAllState === 'checked'"
        [disabled]="viewDataTarget.selectAllState === 'disabled'"
        [indeterminate]="viewDataTarget.selectAllState === 'indeterminate'"
        labelPosition="before"
      >
        <span i18n="@@global_select_all">Select all</span>
      </mat-checkbox>
    </div>

  </div>

  <div
    class="list-actions"
  >
    <button
      (click)="onMoveSelectedEntries(viewDataSource, viewDataTarget)"
      [disabled]="isMaxSelected || isButtonMoveDisabled(viewDataSource)"
      mat-stroked-button
    >
      <mat-icon svgIcon="chevron-left"></mat-icon>
      <span i18n="@@global_add">Add</span>
    </button>

    <button
      (click)="onMoveSelectedEntries(viewDataTarget, viewDataSource)"
      [disabled]="isButtonMoveDisabled(viewDataTarget)"
      mat-stroked-button
    >
      <span i18n="@@global_remove">Remove</span>
      <mat-icon svgIcon="chevron-right"></mat-icon>
    </button>
  </div>

  <div
    class="list source"
  >
    <div class="list-header list-append">
      <span class="list-title">{{i18n.available}}</span>
    </div>

    <div class="list-search list-append">
      <mat-form-field appearance="outline" class="search-field">
        <mat-label>{{i18n.search}}</mat-label>
        <input
          [(ngModel)]="viewDataSource.filter"
          (ngModelChange)="onFilter(viewDataSource)"
          autocomplete="off"
          matInput
          type="text"
        />
        <button
          (click)="onFilterReset(viewDataSource)"
          *ngIf="viewDataSource.filter?.length > 0"
          mat-icon-button
          matSuffix
        >
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="list-scroll">
      <cdk-virtual-scroll-viewport
        #viewportSource
        itemSize="48"
      >
        <mat-list
          (cdkDropListDropped)="onDrop(viewDataTarget, viewDataSource, viewportSource.getRenderedRange().start, $event)"
          [cdkDropListData]="viewDataSource.filteredData"
          [cdkDropListDisabled]="isDragDropDisabled"
          [cdkDropListSortingDisabled]="!allowSortingInContainers"
          [disabled]="isMaxSelected"
          cdkDropList
        >
          <ng-container
            *cdkVirtualFor="let entry of viewDataSource.filteredData; templateCacheSize: 0"
          >
            <mat-list-item
              (cdkDragStarted)="onDragStarted(viewDataSource, entry)"
              [cdkDragData]="entry"
              [cdkDragDisabled]="isDragDropDisabled"
              cdkDrag
              [ngClass]="{changed: (entry.changed === true)}"
              class="item"
            >
              <rag-assignment-dialog-entry
                (entryChange)="onSelectionChanged(viewDataSource)"
                (touchEnd)="isDragDropDisabled = false"
                (touchStart)="isDragDropDisabled = true"
                [disabled]="isMaxSelected"
                [entry]="entry"
                ragTouch
              ></rag-assignment-dialog-entry>
              <span *cdkDragPreview class="placeholder"></span>
              <span *cdkDragPlaceholder class="placeholder"></span>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </cdk-virtual-scroll-viewport>
    </div>

    <div class="select-all list-append">
      <mat-checkbox
        (change)="onToggleAll(viewDataSource, $event)"
        [checked]="viewDataSource.selectAllState === 'checked'"
        [disabled]="viewDataSource.selectAllState === 'disabled'"
        [indeterminate]="viewDataSource.selectAllState === 'indeterminate'"
        labelPosition="before"
      >
        <span i18n="@@global_select_all">Select all</span>
      </mat-checkbox>
    </div>

  </div>

</div>
