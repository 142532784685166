import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import tinycolor from 'tinycolor2';
import { AccountDesignService } from '../route/admin/account-design/account-design.service';
import { PrincipalService } from './principal/principal.service';
import { RuntimeEnvironmentService } from './runtime-environment.service';
import { PermissionStates } from './principal/permission.states';
import { MergeHelper } from './primitives/merge.helper';


window['cssVars'] = cssVars;

interface Rgb {
  b: number;
  g: number;
  r: number;
}

interface CSSVars {
  [p: string]: string;
}

export interface ThemeColor {
  darkContrast: boolean;
  hex: string;
  name: string;
}

interface ThemeColors {
  accentColor?: string;
  mainColor?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  activeColors: ThemeColors;
  allVariables = {};
  isIe: boolean;
  private DEFAULT_COLOR_ACCENT: string;
  private DEFAULT_COLOR_MAIN: string;
  private _initialized = false;
  private currentAccentColor: string;
  private currentMainColor: string;

  constructor(
    private accountDesignService: AccountDesignService,
    deviceDetector: DeviceDetectorService,
    private principalService: PrincipalService,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private router: Router,
  ) {
    this.isIe = deviceDetector.browser === 'IE';

    this.onInit();
  }

  private static getColorObject(value: tinycolor.ColorInput, name: string): ThemeColor {
    const c = tinycolor(value);
    return {
      name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }

  private static multiply(rgb1: Rgb, rgb2: Rgb): tinycolor.Instance {
    rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
    rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
    rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
    return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  }

  private static shouldShowCustomColors(
    permissions: PermissionStates,
    publicCustomColors?: boolean
  ): boolean {

    if (permissions.ngCustomColors) {
      // user has permission for custom colors -> return true
      return true;
    }

    if (!permissions.isLogged) {
      // principal is guest -> check if custom colors are enabled for guests
      return publicCustomColors;
    }

    // check custom color permission
    return false;
  }

  calculateTheme(hex: tinycolor.ColorInput) {
    const baseLight = tinycolor('#ffffff');
    const baseDark = ThemeService.multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
    const baseTriad = tinycolor(hex).tetrad();
    return [
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
      ThemeService.getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(65), 'A100'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(55), 'A200'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(45), 'A400'),
      ThemeService.getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(40), 'A700'),
    ];
  }

  getThemeColors(applyAccountColors: boolean): ThemeColors {
    if ( applyAccountColors ) {
      return this.applyDefaultColors(this.activeColors);
    } else {
      return this.applyDefaultColors();
    }
  }

  onInit() {
    if ( this._initialized ) {
      return;
    }
    this._initialized = true;

    this.runtimeEnvironmentService.environment$
      .pipe(tap(env => {
        this.DEFAULT_COLOR_MAIN = env.mainColor;
        this.DEFAULT_COLOR_ACCENT = env.accentColor;
      }))
      .subscribe();

    combineLatest([
      this.principalService.isLogged$,
      this.principalService.permissionStates$,
      this.accountDesignService.getStartPage(),
      this.accountDesignService.getStyleSettings(),
    ])
      .pipe(map((
        [ , permissionStates, startPage, styleSettings ],
      ) => {
        const colors: ThemeColors = {};
        if ( ThemeService.shouldShowCustomColors(permissionStates, startPage.acc.applyCustomColors) ) {
          colors.accentColor = styleSettings?.acc?.accentColor;
          colors.mainColor = styleSettings?.acc?.mainColor;
        }
        return this.applyDefaultColors(colors);
      }))
      .pipe(map(colors => {
        this.activeColors = colors;
        let changed = false;
        const vars = {};
        // update theme colors
        const mainColor = colors.mainColor;
        if ( this.currentMainColor !== mainColor ) {
          changed = true;
          this.currentMainColor = mainColor;
          const primaryTheme = this.calculateTheme(mainColor);
          this.toCssVariables(vars, primaryTheme, 'primary');
        }

        const accentColor = colors.accentColor;
        if ( this.currentAccentColor !== accentColor ) {
          changed = true;
          this.currentAccentColor = accentColor;
          const accentTheme = this.calculateTheme(accentColor);
          this.toCssVariables(vars, accentTheme, 'accent');
        }

        if ( changed ) {
          this.replaceCSSAttributes(vars);
        }
      }))
      .subscribe();

    if ( this.isIe ) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .pipe(debounceTime(150))
        .pipe(tap(() => cssVars()))
        .subscribe();
    }
  }

  /** use this for a complete theme (targetPalette options: primary, accent or warn) */
  replaceThemePalette(array: any, targetPalette: string): void {
    const vars = {};
    this.toCssVariables(vars, array, targetPalette);
    this.replaceCSSAttributes(vars);
  }

  setCssVariables(element: HTMLElement, newVars: object & CSSVars) {
    if ( !this.isIe ) {
      const cssText = Object.entries(newVars)
        .map(([ key, value ]) => key + ': ' + value)
        .join('; ');
      element.setAttribute('style', cssText);
    }
  }

  toCssVariables(result: any, colors: any, targetPalette: string) {
    // fill result to execute cssVars only once
    colors.forEach(item => {
      // normal colors
      let property = '--' + targetPalette + '-' + item.name;
      let value = item.hex;
      result[property] = value;

      // contrasts
      property = '--' + targetPalette + '-contrast-' + item.name;
      value = item.darkContrast ? '#000' : '#fff';
      result[property] = value;
    });
  }

  private applyDefaultColors(colors?: ThemeColors): ThemeColors {
    colors = colors || {};

    if ( !(/[#](?:[\da-f]{3})|(?:[\da-f]{6})|(?:[\da-f]{8})/.test(colors.accentColor)) ) {
      colors.accentColor = this.DEFAULT_COLOR_ACCENT;
    }

    if ( !(/[#](?:[\da-f]{3})|(?:[\da-f]{6})|(?:[\da-f]{8})/.test(colors.mainColor)) ) {
      colors.mainColor = this.DEFAULT_COLOR_MAIN;
    }

    return colors;
  }

  private replaceCSSAttributes(vars: CSSVars): void {
    const newVars = MergeHelper.mergeDeep(this.allVariables, vars);
    if ( this.isIe ) {
      cssVars({
        onlyLegacy: false,
        variables: newVars,
      });
    } else {
      this.setCssVariables(document.documentElement, newVars);
    }
  }

}
