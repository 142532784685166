<rag-page-header
  backUrl="/admin/notifications/signatures"
  >
  <div class="page-header-title">
    <span *ngIf="signature.id === 0" i18n="@@global_new_signature">New signature</span>
    <div *ngIf="signature.id > 0">
      <span>{{signature.name}}</span>
    </div>
  </div>
  <div class="page-header-buttons">
    <button
      [disabled]="formGroup.invalid || formGroup.pristine"
      type="button"
      color="primary"
      mat-flat-button
      (click)="onSave()"
    >
      {{saveOrCreateText}}
    </button>
  </div>
</rag-page-header>

<rag-text-macros
  [formGroup]="formGroup"
  [usage]="usages"
  [data]="signature"
  [isTextMacro]="false"
  [currentMacro]="signature.macro"
></rag-text-macros>
