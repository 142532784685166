import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Attachment } from 'src/app/component/attachments/attachments.types';
import { AdminCategoriesService } from 'src/app/route/admin/admin-categories/admin-categories.service';
import { Categories } from '../categories/categories.types';
import { ContentService } from '../content/content.service';
import { Core, ImageableContentReference } from '../core.types';
import { FieldsAdminService } from '../fields-admin.service';
import { Fields } from '../fields.types';
import { InfoService } from '../info/info.service';

export interface ContentData {
  account: ImageableContentReference[];
  content: ImageableContentReference,
  attachments: Array<Attachment>;
}

export const ContentResolverService: ResolveFn<ContentData> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  contentService: ContentService = inject(ContentService),
  infoService: InfoService = inject(InfoService),
  router: Router = inject(Router)
) => {
  let contentId = parseInt(route.params['id'], 10);
  if (isNaN(contentId)) {
    contentId = route.data?.curId;
  }

  let task: Observable<ImageableContentReference[]> = contentService.fetchAccountData();
  const _account = route.data['account'];
  if (_account !== undefined) {
    task = of(_account);
    delete route.data['account'];
  }

  return task.pipe(map(account => {

      const content = ContentService.getContentById(account, contentId, false,
        [ Core.DistributableType.lms_course, Core.DistributableType.lms_curriculum ]);
      if ( !content ) {
        infoService
        .showAlert($localize`:@@error_content_not_found:This content does not exist or you do not have access.`)
        .pipe(switchMap(() => router.navigateByUrl('/')))
        .pipe(take(1))
        .subscribe();
        return null;
      }

      return {
        account,
        content,
        attachments: []
      };
    }))
    .pipe(switchMap(result => {
      if (result === null) {
        return EMPTY;
      }
      return contentService.fetchAttachmentsForContent(result.content.objType, result.content.id)
        .pipe(map(attachments => {
          if (attachments.length > 0) {
            result.attachments = attachments;
          }
          return result;
        }))
    }))
    .pipe(take(1));
}

export const ContentFieldsResolver: ResolveFn<Fields.ContentFields> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  fieldsAdminService: FieldsAdminService = inject(FieldsAdminService)
) => {
  const { objType, objIdParam } = route.data;
  const objId = parseInt(route.params[objIdParam], 10);
  if (objType !== undefined && objId !== undefined) {
    return fieldsAdminService.getContentFields( objType, objId );
  }
  return EMPTY;
}

export const CategoriesResolver: ResolveFn<Categories.Selection> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  categoriesService: AdminCategoriesService = inject(AdminCategoriesService)
) => {
  const { objType, objIdParam } = route.data;
  const objId = parseInt(route.params[objIdParam], 10);
  if (objType !== undefined && objId !== undefined) {
    return categoriesService.getCategories( objType, objId );
  }
  return EMPTY;
}