import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ObjectUsageTypes } from '../../../../../core/object-usage.types';

export interface UsageDialogData {
  action: 'edit' | 'remove';
  canDeleteWithInactive?: boolean;
  usages: ObjectUsageTypes.Usage[];
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
    PipesModule
  ],
  selector: 'rag-usage-dialog',
  templateUrl: './usage-dialog.component.html',
  styleUrls: [ './usage-dialog.component.scss' ],
})
export class UsageDialogComponent
  implements OnInit {

  actionEdit = false;
  actionRemove = false;
  canDelete: boolean;
  usages: ObjectUsageTypes.Usage[];

  constructor(
    public dialogRef: MatDialogRef<UsageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsageDialogData,
  ) {
    this.usages = data.usages;
    this.canDelete = this.checkDelete();
    this.actionRemove = data.action === 'remove';
    this.actionEdit = data.action === 'edit';
  }

  ngOnInit() {
  }

  private checkDelete(): boolean {
    if ( !(this.usages?.length > 0) ) {
      // can be deleted when no references exist
      return true;
    }

    if ( this.data.canDeleteWithInactive === false ) {
      // any link prevents deletion
      return false;
    }

    // can "delete" if no active events are linked
    return this.usages.findIndex(item => item.active) === -1;
  }

}
