<div
  *ngIf="validSince || availableSince || confirmedOn || dueBy || validUntil || courseEnd "
  class="pseudo-table"
>
  <div *ngIf="validSince" class="pseudo-tr date-valid-since">
    <div class="pseudo-td date-label">
      <span i18n="@@elearning_curriculum_info_valid_since">Valid since:</span>
    </div>
    <div class="pseudo-td date-value">{{validSince | formatDate:'date'}}</div>
  </div>

  <div *ngIf="courseEnd" class="pseudo-tr date-valid-since">
    <div class="pseudo-td date-label">
      <span i18n="@@elearning_curriculum_info_Completed_on">Completed on:</span>
    </div>
    <div class="pseudo-td date-value">{{courseEnd | formatDate:'date'}}</div>
  </div>

  <div *ngIf="availableSince" class="pseudo-tr date-available-since">
    <div class="pseudo-td date-label">
      <span i18n="@@important_content_info_available_since">Available since:</span>
    </div>
    <div class="pseudo-td date-value">{{availableSince |  formatDate:'date'}}</div>
  </div>

  <div *ngIf="confirmedOn" class="pseudo-tr date-confirmed-on">
    <div class="pseudo-td date-label">
      <span i18n="@@important_content_info_confirmed_on">Confirmed on:</span>
    </div>
    <div class="pseudo-td date-value">{{confirmedOn |  formatDate:'date'}}</div>
  </div>

  <div *ngIf="dueBy" class="pseudo-tr date-due-by">
    <div class="pseudo-td date-label">
      <span i18n="@@content_card_overview_due_by">Expiration of validity:</span>
    </div>
    <div class="pseudo-td date-value">{{dueBy |  formatDate:'date'}}</div>
  </div>

  <div *ngIf="validUntil" class="pseudo-tr date-valid-until">
    <div class="pseudo-td date-label">
      <span i18n="@@important_content_info_valid_until">Valid until:</span>
    </div>
    <div class="pseudo-td date-value">{{validUntil |  formatDate:'date'}}</div>
  </div>
</div>

<div *ngIf="needsConfirm" class="needs-confirm">
  <button
    [disabled]="isConfirmDisabled"
    mat-flat-button
    color="warn"
    (click)="onReadDocumentAcknowledge()"
    >
    <span i18n="@@important_content_read_confirm">Confirm read</span>
  </button>

  <!-- <mat-checkbox (change)="onReadDocumentAcknowledge()" [disabled]="needsConfirmSuccess || !!needsConfirmError">
    <span i18n="@@important_content_info_needs_confirm">Needs confirmation!</span>
  </mat-checkbox>
  <div *ngIf="needsConfirmError">
    <mat-error>{{needsConfirmError}}</mat-error>
  </div> -->
</div>

<div *ngIf="needsReading" class="needs-confirm">
  <span i18n="@@important_content_info_needs_confirm">Needs confirmation!</span>
</div>

<div *ngIf="hasCurriculumLock" class="wrapper curriculum-lock">
  <rag-curriculum-locked-by
    [content]="content"
  ></rag-curriculum-locked-by>
</div>

<div *ngIf="lockReason" class="wrapper course-lock">
  <span>{{lockReason}}</span>
</div>
