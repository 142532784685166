import { OfflineContent } from './admin-offline.types';

export namespace UserHelper {
  export const reportRowToParticipant = (extUserRow: any): OfflineContent.Participant => {
    if (extUserRow ===  null) {
      return null;
    }
    return {
      userId: extUserRow.userId,
      assignmentMode: null,
      email: extUserRow.userEmail,
      employeeID: extUserRow.userEmployeeID,
      examinationStatus: null,
      firstname: extUserRow.userFirstname,
      invitationStatus: null,
      invitationStatusDate: null,
      lastname: extUserRow.userLastname,
      locationDesc: extUserRow.userLocationDesc,
      login: null,
      paymentStatus: null,
      merchantStatus: null,
      merchantTransactionType: null,
      participationStatus: null,
      registrationStatus: null,
      transactionStatus: null
    };
  };
}
