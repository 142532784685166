import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { Core, Translateable } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageConstants, YesButton, YesNoButtons } from 'src/app/core/info/info.types';
import * as uuid from 'uuid';
import { ProcessService } from '../../route/main/process/process.service';
import { CatalogBookingDialogComponent } from '../../route/user/catalog/catalog-booking-dialog/catalog-booking-dialog.component';
import {
  CatalogBookingDialogParams,
  ContentEvent,
} from '../../route/user/catalog/catalog-booking-dialog/catalog-booking-dialog.types';
import { OfflineContent } from '../admin-offline.types';
import { PrincipalService } from '../principal/principal.service';
import { CatalogService } from './catalog.service';
import { naturalCompare } from '../natural-sort';
import { Catalogs } from './catalog.types';
import * as moment from 'moment';
import { AccountInterceptor } from '../interceptors/account.interceptor';
import { RedirectHelper } from '../redirect.helper';


@Injectable({
  providedIn: 'root',
})
export class CatalogBookingHelper {

  constructor(
    private catalogService: CatalogService,
    private infoService: InfoService,
    private processService: ProcessService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  static getColorOfFreePlaces(
    availablePlaces: number,
    maxUserCount: number,
    closedStatus: OfflineContent.ClosedStatus,
  ) {

    if ( OfflineContent.isSuccessfulyClosed(closedStatus) ) {
      return 'green';
    }

    if ( OfflineContent.isComplete(closedStatus) ) {
      return 'red';
    }

    if ( !(maxUserCount > 0) ) {
      return '';
    }

    if ( !(maxUserCount > 0) || (availablePlaces > 10)) {
      return '';
    }

    if (availablePlaces >= 1) {
      return 'orange';
    }

    if (availablePlaces === 0) {
      return 'red';
    }
    return '';
  }

  static getDSBRedirect(): string {

    const href = `${window.location.href}`
      .replace(/\?.+/, '')
      .replace('catalog', 'run');

    const accountKey = AccountInterceptor.getAccountKey();
    if ( accountKey ) {
      return `${href}?key=${accountKey}`;
    } else {
      return href;
    }
  }

  static getFreePlacesPlaceholder(
    availablePlaces: number,
    maxUserCount: number,
    closedStatus: OfflineContent.ClosedStatus,
  ): string {

    switch ( closedStatus ?? '' ) {
      case 'canceled':
        return $localize`:@@global_canceled:Canceled`;
      case 'done':
        // done should not be listed in catalog!
        return $localize`:@@done:Done`;
      case 'aborted':
        return $localize`:@@global_aborted:Aborted`;
    }

    if ( !(maxUserCount > 0) || (availablePlaces > 10)) {
      return $localize`:@@global_many_free_places:many free places`;
    }

    if (availablePlaces > 1) {
      return $localize`:@@global_n_free_places:${availablePlaces} free places`;
    }

    if (availablePlaces === 1) {
      return $localize`:@@global_one_free_place:1 free place`;
    }

    return $localize`:@@booked_up:booked up`;
  }

  static mayBookEventSchedule(
    closedStatus: OfflineContent.ClosedStatus,
    eventDate: number,
    eventDateUntil: number,
    bookingUntilEnd: boolean,
    priceForUser: number,
  ): boolean {

    if ( OfflineContent.isComplete(closedStatus) ) {
      // cannot book or cancel completed event schedules
      return false;
    }

    const now = moment();
    if ( moment(eventDateUntil).isBefore(now) ) {
      // cannot book passed event schedules -> should not be listed in catalog anyway
      return false;
    }

    if (moment(eventDate).isAfter(now)) {
      // event has not started yet -> allow booking
      return true;
    }

    if (priceForUser > 0) {
      // event is running, but has a price -> no booking allowed
      return false;
    }

    // only allow booking started events, if bookingUntilEnd is set
    return (bookingUntilEnd !== false);
  }

  static sortComparatorEventSchedule(
    a: { eventDate: number; eventDateUntil: number; title?: Translateable },
    b: { eventDate: number; eventDateUntil: number; title?: Translateable },
  ): number {

    let result = a.eventDate - b.eventDate;
    if ( (result !== 0) && !isNaN(result) ) {
      // different start dates
      return result;
    }

    result = a.eventDateUntil - b.eventDateUntil;
    if ( (result !== 0) && !isNaN(result) ) {
      // different end dates
      return result;
    }

    // as a last resort, compare titles
    return naturalCompare(a.title, b.title);
  }

  bookWithEvents(
    isReservation: boolean,
    catalogEntry: Catalogs.CatalogEntry,
    assignmentMode: OfflineContent.EventAssignmentMode | null,
    offlineContents?: OfflineContent.EventCatalogView[],
    selectedEvents: Map<number, Set<number>> = new Map<number, Set<number>>(),
    process?: Core.UserProcess,
    bookingUUID?: string): Observable<Catalogs.V2.HandlingBookingData> {

    this.catalogService.bookingInProgress(catalogEntry, selectedEvents.size > 0);

    const bookedContents: ContentEvent[] = Array.from(selectedEvents.entries())
      .map(([ offlineContentId, events ]) => {

        const offlineContent = offlineContents?.find(content => content.id === offlineContentId);
        if ( offlineContent == null ) {
          // did not find the matching offlineContent!
          return null;
        }

        // none block events must have selected events schedules
        if ( !offlineContent.blockEvent && !(events.size > 0) ) {
          // no events selected for this content
          return null;
        }

        const offlineEvents = offlineContent.events ?? [];
        const eventsEntries = Array.from(events)
          .map(offlineEventId =>
            offlineEvents.find(entry => entry?.id === offlineEventId) ??
            // the paranoia fallback :D
            { id: offlineEventId });

        return {
          offlineContentId,
          offlineContentTitle: offlineContent.title,
          events: eventsEntries,
          blockEvent: offlineContent.blockEvent
        };
      })
      .filter(entry => entry != null);

    return this.principalService.isLogged$
      // prevent login from opening additional dialogs
      .pipe(take(1))
      .pipe(switchMap(isLogged => {

        if ( !isLogged ) {
          // the user is not logged in -> store current booking process
          this.processService.setContext({
            id: catalogEntry.id,
            objType: catalogEntry.objType,
            catalogUUID: this.catalogService.catalogUUID,
          });
        }

        return this.infoService.showDialog<CatalogBookingDialogComponent, CatalogBookingDialogParams,
          Catalogs.CatalogBooking>(CatalogBookingDialogComponent, {
          offlineContents: bookedContents,
          contentId: catalogEntry.id,
          contentType: catalogEntry.objType,
          isLogged,
          title: catalogEntry.title,
          bookingUUID,
          isReservation,
          contentIsNotABlockEvent: catalogEntry.objType !== Core.DistributableType.lms_offlineCnt,
          courseType: catalogEntry?.objSubType,
        })
        .pipe(switchMap(booking => {
          if (booking === undefined) {
            this.catalogService.abortBooking();
            return of({
              bookingUUID: null,
              blockEvent: false
            });
          }
          if (catalogEntry.blockEvent) {
            // this is a block event booking process
            return of({
              bookingUUID,
              blockEvent: true
            });
          }
          // this is NOT a block event booking process
          return this.handleBookingResult(catalogEntry, true, bookingUUID, assignmentMode, isReservation, booking)
            .pipe(map(_ => ({
              bookingUUID,
              blockEvent: false
            })))
        }));
      }));
  }

  // bookWithoutEvents(catalogEntry: Catalogs.CatalogEntry): Observable<Catalogs.V2.HandlingBookingData> {
  //   // disable the button until networking is in progress
  //   this.catalogService.bookingInProgress(catalogEntry, false);

  //   const messageWithoutEvents = $localize`:@@catalog_bookmark_without_events:
  //     Do you want to bookmark this event?
  //     It will be added to your personal profile.
  //     You can book concrete dates at a later date.
  //   `;
  //   const messageContentNotHavingEvents = $localize`:@@catalog_booking_confirm:Would you like to book this content?`;

  //   if ( DistributionTypeHelper.isCurriculum(catalogEntry.objType) ) {
  //     return this.catalogService
  //       .searchForEvents(catalogEntry.id, catalogEntry.objType, this.catalogService.catalogUUID)
  //       .pipe(switchMap(offlineContents => {
  //         if ( offlineContents?.length === 0 ) {
  //           return this.handleBooking(catalogEntry, messageContentNotHavingEvents, false);
  //         } else {
  //           return this.handleBooking(catalogEntry, messageWithoutEvents, false);
  //         }
  //       }));
  //   } else if ( DistributionTypeHelper.isOfflineContent(catalogEntry.objType) ) {
  //     if (catalogEntry.blockEvent) {
  //       return this.handleBooking(catalogEntry, messageContentNotHavingEvents, catalogEntry.priceForUserInCent > 0);
  //     }
  //     return this.handleBooking(catalogEntry, messageWithoutEvents, false);
  //   } else {
  //     return this.handleBooking(catalogEntry, messageContentNotHavingEvents, false);
  //   }
  // }

  cancel(catalogEntry: Catalogs.Bookable): Observable<void> {
    if ( catalogEntry.catalogBooking == null ) {
      return EMPTY;
    }

    return this.infoService
  .showMessage($localize`:@@catalog_booking_cancel_event_confirm:Your event will be removed, would you like to continue?`, { buttons: YesNoButtons, title: MessageConstants.DIALOG.TITLE.CONFIRM })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => {
        this.catalogService.bookingInProgress(catalogEntry, false);
        return this.catalogService.cancelBooking(catalogEntry.catalogBooking.id);
      }))
      .pipe(switchMap(booking => this.handleBookingResult(catalogEntry, false, booking.bookingUUID, null, false, booking)))
      .pipe(finalize(() => this.catalogService.completeBooking()))
      .pipe(catchError(this.handleError));
  }

  private handleBooking(catalogEntry: Catalogs.CatalogEntry, message: string, isReservation: boolean): Observable<Catalogs.V2.HandlingBookingData> {

    return this.infoService.showMessage(message, {
      buttons: YesNoButtons,
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
    })
      .pipe(switchMap(button => {
        if ( button !== YesButton ) {

          this.catalogService.abortBooking();
          RedirectHelper.clearRedirect();
          return of();
        }

        const bookingUUID = uuid.v4();

        return this.bookWithEvents(
          isReservation,
          catalogEntry,
          null,
          null,
          new Map<number, Set<number>>(),
          null,
          bookingUUID
        );
      }));
  }

  private handleBookingResult(
    catalogEntry: Catalogs.Bookable,
    distributed: boolean,
    bookingUUID: string,
    assignmentMode: OfflineContent.EventAssignmentMode | null,
    isReservation: boolean,
    catalogBooking?: Catalogs.CatalogBooking,
  ): Observable<void> {

    if ( catalogBooking == null ) {
      // the user has closed the dialog without selecting anything
      this.catalogService.abortBooking();
      return EMPTY;
    }

    // the user has booked the content without or having selected events
    catalogEntry.distributed = distributed;
    catalogEntry.catalogBooking = catalogBooking;
    this.catalogService.completeBooking();

    if ( distributed ) {

      let message = $localize`:@@catalog_booking_forward_after_bookmarked:
            Process successfully completed.
            You will now be redirected to the booking overview.`;

      if (assignmentMode != null) {
        if ( assignmentMode === 'wish' ) {
          message = $localize`:@@catalog_booking_forward_after_request:
            You have requested this content successfully.
            You will now be redirected to the booking overview.`;
        }
      }
      return this.catalogService.checkRequiredUserFields(catalogEntry.objType, catalogEntry.id)
        .pipe(switchMap(() => isReservation ? of(0) : this.infoService.showMessage(message, { infoType: InfoType.Success }) ))
        .pipe(switchMap(() => isReservation ? of(0) : from(this.router.navigateByUrl(`/bookings/${bookingUUID}`))))
        .pipe(map(_ => void (0)));

    } else {

      // show success for cancelled booking
      return this.infoService
        .showMessage($localize`:@@catalog_booking_canceled:Process performed successfully.`, {
          infoType: InfoType.Success,
        })
        .pipe(switchMap(_ => this.reloadLocation()));
    }
  }

  private handleError = (): Observable<void> => {
    this.catalogService.failBooking();
    return EMPTY;
  };

  private reloadLocation(): Observable<void> {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { _: (new Date()).getTime() },
      queryParamsHandling: 'merge',
    });

    return from(this.router.navigateByUrl(urlTree, {
      replaceUrl: true,
      onSameUrlNavigation: 'reload'
    }))
      .pipe(map(_ => void (0)));
  }

}
