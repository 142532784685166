import { Component } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import { AdminGamificationListComponent } from './admin-gamification-list/admin-gamification-list.component';
import { PageHeaderComponent } from '../../../component/page-header/page-header.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { AdminGamificationSettingsComponent } from './admin-gamification-settings/admin-gamification-settings.component';

@Component({
  standalone: true,
  selector: 'rag-admin-gamification',
  templateUrl: './admin-gamification.component.html',
  imports: [
    MatTabsModule,
    AdminGamificationListComponent,
    AdminGamificationSettingsComponent,
  ],
  styleUrls: ['./admin-gamification.component.scss']
})
export class AdminGamificationComponent {

}
