import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep, isEqual, remove } from 'lodash';
import { filter, map, take, tap } from 'rxjs/operators';
import { State } from '../../../../app.state';
import { CachedSubject } from '../../../../core/cached-subject';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { ModalDialog } from '../../../../core/modal-dialog';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../core/principal/permission.states';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ThemeService } from '../../../../core/theme.service';
import { LocaleTranslation } from '../../../../core/translation/translation.types';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { AccountDesignService } from '../account-design.service';
import { EditLoginPageDialogComponent } from '../edit-login-page-dialog/edit-login-page-dialog.component';
import { LanguageHelper, LanguageInfo } from '../../../../core/language.helper';
import { PreloadService } from '../../../../core/preload.service';


@Component({
  selector: 'rag-login-page-conf',
  templateUrl: './login-page-conf.component.html',
  styleUrls: [ './login-page-conf.component.scss' ],
})
export class LoginPageConfComponent
  implements OnInit, OnDestroy {

  get languages(): LanguageInfo[] {
    return LanguageHelper.LANGUAGES;
  }

  applyCustomColors = false;
  hidePassword = false;
  applyCustomColorsIsDirty = false;

  public config = {
    language: State.language,
    allowedContent: true,
    removePlugins: [
      'elementspath', 'a11yhelp', 'about', 'copyformatting', 'div', 'selectall', 'language', 'find', 'flash', 'forms',
      'image', 'magicline', 'pagebreak', 'pastefromword', 'preview', 'print', 'showblocks', 'table', 'tableselection',
      'tabletools', 'templates', 'anchor',
    ],
    resize_enabled: false,
  };
  editedItems: string [] = [];
  isDirty$ = this.dirtyCheckService.isDirty$;
  loginError: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  loginErrorIsDirty = false;
  loginFormTexts: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  loginFormTextsIsDirty = false;
  loginPlaceholder: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  loginPlaceholderIsDirty = false;
  pendingApplyCustomColors: boolean;
  pendingLoginError: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  pendingLoginFormTexts: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  pendingLoginPlaceholder: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  pendingRegistrationButtonActive: boolean;
  pendingWelcomePictureId: number;
  pendingWelcomeTexts: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  permissionStates: PermissionStates;
  progress: number;
  registrationButtonActive = false;
  registrationButtonIsDirty = false;
  selectedLanguage = State.language;
  welcomePictureId: number;
  welcomePictureIdIsDirty = false;
  welcomePictureURL: string;
  welcomeTexts: LocaleTranslation = LanguageHelper.getEmptyTranslation();
  welcomeTextsIsDirty = false;
  displayedLoginPlaceholder: string;
  displayedLoginErrorElement: string;
  displayedWelcomeText: string;
  displayedLoginFormText: string;
  anonymousRegistrationEnabled = false;

  constructor(
    private dirtyCheckService: DirtyCheckService,
    private hostElementRef: ElementRef,
    private infoService: InfoService,
    private service: AccountDesignService,
    private principalService: PrincipalService,
    private themeService: ThemeService,
    public dialog: ModalDialog,
    private preloadService: PreloadService,
  ) {
    subscribeUntilDestroyed(this.principalService.permissionStates$
      .pipe(filter(CachedSubject.isNotEmpty))
      .pipe(map(permissions => this.permissionStates = permissions)), this);
    this.updateDisplayedTexts();

  }

  changeApplyCustomColors() {
    this.toggleCustomColorPreview(this.pendingApplyCustomColors);
    this.checkIfSomethingIsDirty();
  }

  editItem(item: string, value: any) {
    this.dialog.openModal(EditLoginPageDialogComponent, {
      data: { item, value, language: this.selectedLanguage },
      disableClose: true,
      minWidth: '70vw',
    }).afterClosed().subscribe(result => {
      if ( result?.confirmed ) {
        // change value back without referencing
        if ( item === 'loginFormTexts' ) {
          this.pendingLoginFormTexts = result.data;
          this.updateDisplayedTexts();
        } else if ( item === 'welcomeTexts' ) {
          this.pendingWelcomeTexts = result.data;
          this.updateDisplayedTexts();
        } else if ( item === 'loginError' ) {
          this.pendingLoginError = result.data;
          this.updateDisplayedTexts();
        } else if ( item === 'loginPlaceholder' ) {
          this.pendingLoginPlaceholder = result.data;
          this.updateDisplayedTexts();
        } else if ( item === 'welcomePictureId' ) {
          this.pendingWelcomePictureId = result.data;
          this.reloadImages();
        }
      }

      // check if something is dirty to enable save button
      this.checkIfSomethingIsDirty();
    });
  }

  getLoginPageInformation() {
    this.service.getStartPage().pipe(take(1))
      .subscribe((designSettings) => {
        const acc = designSettings.acc;

        this.applyCustomColors = cloneDeep(acc.applyCustomColors);
        this.pendingApplyCustomColors = cloneDeep(acc.applyCustomColors);
        this.loginError = cloneDeep(acc.loginError);
        this.pendingLoginError = cloneDeep(acc.loginError);
        this.loginFormTexts = cloneDeep(acc.loginFormTexts);
        this.pendingLoginFormTexts = cloneDeep(acc.loginFormTexts);
        this.loginPlaceholder = cloneDeep(acc.loginPlaceholder);
        this.pendingLoginPlaceholder = cloneDeep(acc.loginPlaceholder);
        this.welcomeTexts = cloneDeep(acc.welcomeTexts);
        this.pendingWelcomeTexts = cloneDeep(acc.welcomeTexts);
        this.pendingWelcomePictureId = cloneDeep(acc.welcomePictureId);
        this.welcomePictureId = cloneDeep(acc.welcomePictureId);
        this.registrationButtonActive = cloneDeep(acc.registrationButtonActive);
        this.pendingRegistrationButtonActive = this.registrationButtonActive;
        this.updateDisplayedTexts();
        // check if something is dirty
        this.checkIfSomethingIsDirty();
      });
  }

  isApplyCustomColorsDirty(): void {
    this.applyCustomColorsIsDirty = !(this.pendingApplyCustomColors === this.applyCustomColors);

    if ( this.applyCustomColorsIsDirty && !this.editedItems.includes('applyCustomColors') ) {
      this.editedItems.push('applyCustomColors');
    } else if ( !this.applyCustomColorsIsDirty && this.editedItems.includes('applyCustomColors') ) {
      remove(this.editedItems, (value) => value === 'applyCustomColors');
    }
  }

  isLoginErrorDirty(): void {
    this.loginErrorIsDirty = !isEqual(this.loginError, this.pendingLoginError);
    if ( this.loginErrorIsDirty && !this.editedItems.includes('loginError') ) {
      this.editedItems.push('loginError');
    } else if ( !this.loginErrorIsDirty && this.editedItems.includes('loginError') ) {
      remove(this.editedItems, (value) => value === 'loginError');
    }
  }

  isLoginFormTextDirty(): void {
    this.loginFormTextsIsDirty = !isEqual(this.loginFormTexts, this.pendingLoginFormTexts);
    if ( this.loginFormTextsIsDirty && !this.editedItems.includes('loginFormTexts') ) {
      this.editedItems.push('loginFormTexts');
    } else if ( !this.loginFormTextsIsDirty && this.editedItems.includes('loginFormTexts') ) {
      remove(this.editedItems, (value) => value === 'loginFormTexts');
    }
  }

  isLoginPlaceholderDirty(): void {
    this.loginPlaceholderIsDirty = !isEqual(this.loginPlaceholder, this.pendingLoginPlaceholder);
    if ( this.loginPlaceholderIsDirty && !this.editedItems.includes('loginPlaceholder') ) {
      this.editedItems.push('loginPlaceholder');
    } else if ( !this.loginPlaceholderIsDirty && this.editedItems.includes('loginPlaceholder') ) {
      remove(this.editedItems, (value) => value === 'loginPlaceholder');
    }
  }

  isRegistrationButtonDirty(): void {
    this.registrationButtonIsDirty = !(this.pendingRegistrationButtonActive === this.registrationButtonActive);

    if ( this.registrationButtonIsDirty && !this.editedItems.includes('registrationButton') ) {
      this.editedItems.push('registrationButton');
    } else if ( !this.registrationButtonIsDirty && this.editedItems.includes('registrationButton') ) {
      remove(this.editedItems, (value) => value === 'registrationButton');
    }
  }

  checkIfSomethingIsDirty(): void {
    this.isApplyCustomColorsDirty();
    this.isLoginErrorDirty();
    this.isLoginFormTextDirty();
    this.isLoginPlaceholderDirty();
    this.isRegistrationButtonDirty();
    this.isWelcomePictureIdDirty();
    this.isWelcomeTextDirty();

    // check if something is dirty and push the new state to dirtyCheckService
    const isDirty = this.editedItems.length > 0;
    this.dirtyCheckService.submitNextState('AccountDesignComponent', isDirty);
  }

  isWelcomePictureIdDirty(): void {
    this.welcomePictureIdIsDirty = ((this.welcomePictureId !== this.pendingWelcomePictureId));
    if ( this.welcomePictureIdIsDirty ) {
      this.editedItems.push('welcomePictureId');
    } else if ( this.editedItems.includes('welcomePictureId') ) {
      remove(this.editedItems, (value) => value === 'welcomePictureId');
    }
  }

  isWelcomeTextDirty(): void {
    this.welcomeTextsIsDirty = !isEqual(this.welcomeTexts, this.pendingWelcomeTexts);
    if ( this.welcomeTextsIsDirty ) {
      this.editedItems.push('welcomeTexts');
    } else if ( this.editedItems.includes('welcomeTexts') ) {
      remove(this.editedItems, (value) => value === 'welcomeTexts');
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    subscribeUntilDestroyed(this.service.getImages()
      .pipe(map(images => {
        images.forEach(image => {
          if ( image.fileId === this.pendingWelcomePictureId ) {
            this.welcomePictureURL = image.link;
          }
        });
      })), this);

    this.preloadService.getAnonymousRegistration()
      .pipe(tap(anonymousRegistration => this.anonymousRegistrationEnabled = anonymousRegistration))
      .subscribe();

    // get startpage
    this.getLoginPageInformation();
  }

  onSave() {
    const loginPageInformation = {
      applyCustomColors: this.pendingApplyCustomColors,
      welcomePictureId: this.pendingWelcomePictureId,
      loginError: this.pendingLoginError,
      loginFormTexts: this.pendingLoginFormTexts,
      loginPlaceholder: this.pendingLoginPlaceholder,
      welcomeTexts: this.pendingWelcomeTexts,
      registrationButtonActive: this.pendingRegistrationButtonActive,
    };

    this.service.uploadLoginPageInformation(loginPageInformation).subscribe(
      () => {

        this.reloadImages(this.pendingWelcomePictureId);
        // cleanup of is dirty
        this.applyCustomColors = cloneDeep(this.pendingApplyCustomColors);
        this.welcomePictureId = cloneDeep(this.pendingWelcomePictureId);
        this.loginError = cloneDeep(this.pendingLoginError);
        this.loginFormTexts = cloneDeep(this.pendingLoginFormTexts);
        this.loginPlaceholder = cloneDeep(this.pendingLoginPlaceholder);
        this.welcomeTexts = cloneDeep(this.pendingWelcomeTexts);
        this.registrationButtonActive = cloneDeep(this.pendingRegistrationButtonActive);

        this.checkIfSomethingIsDirty();

        this.infoService.showSnackbar(MessageKey.ACC_LOGIN_PAGE_DATA_SAFED, InfoType.Success);
      }, () => {
        this.infoService.showSnackbar(MessageKey.ACC_LOGIN_PAGE_DATA_NOT_SAFED, InfoType.Error);
      });
  }

  reloadImages(pictureId?: number) {
    this.service.getImages().pipe(take(1)).subscribe(images => {
      images.forEach(image => {
        if ( image.fileId === (pictureId || this.pendingWelcomePictureId) ) {
          this.welcomePictureURL = image.link;
        }
      });
    });
  }

  reset() {
    // reset pending values without referencing
    this.pendingApplyCustomColors = cloneDeep(this.applyCustomColors);
    this.pendingRegistrationButtonActive = cloneDeep(this.registrationButtonActive);
    this.pendingLoginPlaceholder = cloneDeep(this.loginPlaceholder);
    this.pendingLoginError = cloneDeep(this.loginError);
    this.pendingWelcomeTexts = cloneDeep(this.welcomeTexts);
    this.pendingLoginFormTexts = cloneDeep(this.loginFormTexts);
    this.pendingWelcomePictureId = cloneDeep(this.welcomePictureId);

    this.reloadImages();
    this.toggleCustomColorPreview(this.pendingApplyCustomColors);

    // cleanup of is dirty
    this.checkIfSomethingIsDirty();
  }

  // test
  updateDisplayedTexts() {
    this.displayedLoginPlaceholder = this.pendingLoginPlaceholder[this.selectedLanguage];
    this.displayedLoginErrorElement = this.pendingLoginError[this.selectedLanguage];
    this.displayedWelcomeText = this.pendingWelcomeTexts[this.selectedLanguage];
    this.displayedLoginFormText = this.pendingLoginFormTexts[this.selectedLanguage];
  }

  private toggleCustomColorPreview(applyColors: boolean) {
    const hostElement = this.hostElementRef && this.hostElementRef.nativeElement;
    if ( !hostElement ) {
      return;
    }

    const variables = {};
    const themeColors = this.themeService.getThemeColors(applyColors);
    const accentColors = this.themeService.calculateTheme(themeColors.accentColor);
    this.themeService.toCssVariables(variables, accentColors, 'accent');
    const mainColors = this.themeService.calculateTheme(themeColors.mainColor);
    this.themeService.toCssVariables(variables, mainColors, 'primary');
    this.themeService.setCssVariables(hostElement, variables);
  }
}
