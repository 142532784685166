<rag-content-card
  [accessoryTemplate]="accessoryTemplate"
  [content]="catalogEntry"
  [contentLink]="contentLink"
>
  <ng-container
    *ngTemplateOutlet="getTemplate(
      tplOfflineContent,
      tplCourse,
      tplCurriculum
    ); context: { catalogEntry: catalogEntry }"
  ></ng-container>
</rag-content-card>

<ng-template #tplOfflineContent let-catalogEntry="catalogEntry">
  <rag-catalog-card-schedules-pager
    [blockEvent]="catalogEntry.blockEvent"
    [schedulesViews]="catalogEntry.offlineEventsViews"
  ></rag-catalog-card-schedules-pager>
</ng-template>

<ng-template #tplCourse let-catalogEntry="catalogEntry">
  <rag-catalog-card-course
    [catalogEntry]="catalogEntry"
  ></rag-catalog-card-course>
</ng-template>

<ng-template #tplCurriculum let-catalogEntry="catalogEntry">
  <rag-catalog-card-curriculum
    [catalogEntry]="catalogEntry"
  ></rag-catalog-card-curriculum>
</ng-template>
