import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalLogin, ExternalSystemType } from 'src/app/core/global.types';
import * as uuid from 'uuid';
import { DataProcessing, URL_PATTERN } from 'src/app/core/core.types';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { map, switchMap } from 'rxjs/operators';
import { AdobeConnectService } from 'src/app/core/adobe-connect/adobe-connect.service';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { combineLatest, Observable, of } from 'rxjs';
import { PermissionStates } from 'src/app/core/principal/permission.states';

export const ExtLoginDialogComponentSettings = {
  width: '500px',
  maxWidth: '80%',
  disableClose: true,
};

@Component({
  selector: 'rag-ext-login-dialog',
  templateUrl: './ext-login-dialog.component.html',
  styleUrls: [ './ext-login-dialog.component.scss' ],
})
export class ExtLoginDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {

  dummy: ExternalLogin;
  form: UntypedFormGroup;
  isNew: boolean;
  message: DataProcessing.Message = null;
  mode: DataProcessing.Mode = 'edit';
  userPermissionStates$: Observable<PermissionStates>;

  constructor(
    private adobeConnectService: AdobeConnectService,
    private formBuilder: UntypedFormBuilder,
    private principalService: PrincipalService,
    public dialogRef: MatDialogRef<ExtLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExternalLogin) {

    this.isNew = data == null;

    this.dummy = !this.isNew ? { ...data } : {
      uuid: uuid.v4(),
      serverName: '',
      serverType: 'any',
      login: '',
      password: '',
      url: '',
    };

    this.userPermissionStates$ = this.principalService.permissionStates$;
  }

  get isAdobeConnectServer() {
    return this.form.get('serverType').value === 'adobe_connect';
  }

  hasError(componentName: string, errorCode: string): boolean {
    return this.form.get(componentName).hasError(errorCode);
  }

  ngAfterViewInit() {
    this.form.get('password').valueChanges.pipe(map(changes => {
      const password2Control = this.form.get('password2');
      password2Control.setErrors(this.validatePasswords(this.form.get('password2') as UntypedFormControl));
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    // reset error message when empty
    this.form.get('url').valueChanges.pipe(map(changes => {
      if ( changes.length === 0 ) {
        this.form.get('url').setErrors({
          required: true,
        });
      }
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.handleDisabledState(this.dummy.serverType);
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      serverName: [ this.dummy.serverName ],
      serverType: [ this.dummy.serverType ],
      url: [ this.dummy.url, [ Validators.pattern(URL_PATTERN) ] ],
      login: [ { value: this.dummy.login, disabled: true } ],
      password: [ { value: this.dummy.password, disabled: true } ],
      password2: [ { value: this.dummy.password, disabled: true }, [ this.validatePasswords ] ],
    });

    this.form.get('serverType').valueChanges.pipe(map(value => {
      this.handleDisabledState(value);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onFocusPassword(formControlName: string): void {
    const control = this.form.get(formControlName);
    if ( control == null ) {
      return;
    }

    if ( control.pristine ) {
      control.setValue(null, { emitEvent: false });
    }
  }

  onSubmit(): void {

    if ( this.form.invalid ) {
      // prevent saving an invalid form
      return;
    }

    const password = this.getPassword();

    const closure = () => {
      const value = { ...this.dummy, ...this.form.value };
      delete value.password2;

      if ( !password ) {
        // password has not changed -> prevent saving ***
        delete value.password;
      }
      this.dialogRef.close(value);
    };

    this.message = null;

    if ( this.form.get('serverType').value === 'adobe_connect' ) {

      // validate provided values
      this.mode = 'validating';

      const url = this.form.value['url'];
      const login = this.form.value['login'];

      combineLatest([
        this.adobeConnectService.commomInfo(url),
        this.validateCredentials(url, login, password),
      ])
      .pipe(switchMap(([ commonInfo ]) => {

        this.message = {
          text: 'Adobe Connect Ver: ' + commonInfo.common.version,
          isError: false,
        };

        const fakeExt: ExternalLogin = {
          serverName: 'dummy',
          serverType: 'adobe_connect',
          uuid: '',
          url,
          login,
          password
        };

        fakeExt['session'] = commonInfo.common.cookie;
        fakeExt['limit'] = 1;

        return this.adobeConnectService.reportBulkObjects(fakeExt);

      })).subscribe(_ => {

        this.mode = 'validated';

        setTimeout(() => {
          closure();
        }, 1000);

      }, error => {

        this.mode = 'edit';
        // display error message

        let message: string;
        if (error.hasOwnProperty('errors')) {

          console.log(JSON.stringify(error));

          const acError = error.errors[0];

          if (acError.errorCode === 'ERR_AC_LOGIN_FAILED') {
            message = $localize`:@@ac_error_invalid_credentials:Incorrect login credentials`;
          } else if (acError.errorCode === 'ERR_AC_NO_ACCESS') {
            message = $localize`:@@ac_error_not_admin:A login with administrative privileges is required.`;
          }
        }

        if (message == null) {
          message = $localize`:@@general_error:The last operation failed. Please try again later.`;
        }

        // stop any animations
        this.message = {
          text: message,
          isError: true,
        };
      });

      return;
    }

    closure();
  }

  validatePasswords = (formControl: UntypedFormControl) => {
    if ( this.form == null ) {
      return;
    }
    const pswdValue = this.form.get('password').value;
    if ( formControl.value && formControl.value !== pswdValue ) {
      return {
        match: true,
      };
    } else if ( formControl.value == null ) {
      return {
        invalid: true,
      };
    } else {
      return null;
    }
  };

  private getPassword(): string | null {
    const control = this.form.get('password');

    if ( control.pristine || control.invalid ) {
      return null;
    }

    return control.value;
  }

  private handleDisabledState(value: ExternalSystemType) {
    if ( value === 'any' ) {
      const closure = (control: AbstractControl) => {
        control.reset();
        control.disable();
      };
      closure(this.form.get('login'));
      closure(this.form.get('password'));
      closure(this.form.get('password2'));
    } else {
      this.form.get('login').enable();
      this.form.get('password').enable();
      this.form.get('password2').enable();
    }
  }

  private validateCredentials(url: string, login: string, password?: string): Observable<string> {

    if ( !password ) {
      // skip checking credentials, if the password has not changed
      return of(void (0));
    }

    return this.adobeConnectService.login(url, login, password);
  }

}
