<div class="dialog-header">
  <div mat-dialog-title>
    <ng-content></ng-content>
  </div>
  <button
      class="close"
      type="button"
      *ngIf="closingEnabled"
      mat-icon-button
      (click)="onCloseClick($event)"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
