import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { SelfRegisterInfoResponse } from './self-register-form.types';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../../core/api.urls';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AccountDesignService } from '../../../admin/account-design/account-design.service';
import { ImageUrlHelper } from '../../../../core/image-url-helper';
import { LanguageHelper } from '../../../../core/language.helper';


@Injectable({ providedIn: 'root' })
export class SelfRegisterFormService {

  private _cachedInfo: SelfRegisterInfoResponse | null = null;

  constructor(
    private accountDesignService: AccountDesignService,
    private http: HttpClient,
  ) {
  }

  getInfo(
    processHash: string | null,
  ): Observable<SelfRegisterInfoResponse> {

    if ( this._cachedInfo != null ) {
      return of(this._cachedInfo);
    }

    let url = ApiUrls.getKey('SelfRegistrationV2');
    if ( processHash ) {
      url += `?process=${processHash}`;
    }
    const language = LanguageHelper.getCurrentLanguage()?.trainLanguage ?? 'de_DE';
    if ( language ) {
      url += (( processHash ) ? '&':'?') + `lang=${language}`;
    }

    return this.http.get<SelfRegisterInfoResponse>(url)
      .pipe(catchError(_ => EMPTY))
      .pipe(switchMap(response => {

        if ( response.agreementFileUUID ) {
          response.agreementUrl = ImageUrlHelper.urlForPictureUUID(response.agreementFileUUID);
        }
        if ( response.privacyFileUUID ) {
          response.privacyUrl = ImageUrlHelper.urlForPictureUUID(response.privacyFileUUID);
        }

        if ( response.agreementUrl && response.privacyUrl ) {
          return of(response);
        }

        return this.accountDesignService.getStyleSettings()
          .pipe(take(1))
          .pipe(map(o => o?.acc ?? {}))
          .pipe(map(settings => {
            response.privacyUrl ??= settings.policies;
            response.agreementUrl ??= settings.terms;
            return response;
          }));
      }))
      .pipe(tap(response => this.updateCachedInfo(response)));
  }

  /**
   * store last response in service. clear cache after 1 second.
   * @private
   */
  private updateCachedInfo(
    response: SelfRegisterInfoResponse | null,
  ): void {
    this._cachedInfo = response;
    setTimeout(() => this._cachedInfo = null, 1000);
  }

}
