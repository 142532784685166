import { Component, OnDestroy } from '@angular/core';
import { ControllingSingleUserService } from '../ctrl-single-user-util/ctrl-single-user.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { ApiUrls } from '../../../../core/api.urls';
import { ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'rag-ctrl-single-user-curriculum-item-details',
  templateUrl: './ctrl-single-user-curriculum-item-details.component.html',
  styleUrls: [ './ctrl-single-user-curriculum-item-details.component.scss' ],
})
export class CtrlSingleUserCurriculumItemDetailsComponent
  implements OnDestroy {

  url: string;

  constructor(
    private controllingSingleUserService: ControllingSingleUserService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap
      .pipe(tap(params => this.updateRouteParams(params)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  private updateRouteParams(params: ParamMap): void {

    const userId = parseInt(params.get('userId'), 10);
    const curriculumId = parseInt(params.get('curriculumId'), 10);
    const curriculumItemId = parseInt(params.get('itemId'), 10);

    if ( (userId == null) || (curriculumId == null) || (curriculumItemId == null) ) {
      return;
    }

    this.url = ApiUrls.getKey('CtrlSingleUserClassicItemCurriculum')
      .replace(/{userId}/gi, String(userId))
      .replace(/{targetId}/gi, String(curriculumItemId))
      .replace(/{curriculumId}/gi, String(curriculumId))
      .replace(/{iteration}/gi, String(-1));
  }

}
