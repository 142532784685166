<div class="container" id="todo-container">

  <table *ngIf="resultsState === 'data'" [dataSource]="dataSource$" class="table-container"
         id="todo-content-widget-table" mat-table
         matSort matSortActive="name" matSortDirection="desc">

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef>
        <span i18n="@@global_status" mat-header-cell>Status</span>
      </th>
      <td *matCellDef="let content" mat-cell>
        <mat-icon [ngClass]="{
          'display-status-green': content.isGreen,
          'display-status-red': content.isRed,
          'display-status-gray': content.isGray
        }" svgIcon="circle"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th *matHeaderCellDef>
        <span i18n="@@global_course" mat-header-cell>Course</span>
      </th>
      <td *matCellDef="let content" mat-cell> {{content.name}} </td>
    </ng-container>

    <!-- <ng-container *ngFor="let column of columnsDefs" [matColumnDef]="column.columnId"> -->
    <ng-container *ngFor="let column of widget.settings.columnsDefs" [matColumnDef]="column">
      <th *matHeaderCellDef mat-header-cell>
        <ng-container [ngSwitch]="column">
          <span *ngSwitchCase="'locked'" i18n="@@locked">Locked</span>
          <span *ngSwitchCase="'description'" i18n="@@global_description">Description</span>
          <span *ngSwitchCase="'assignmentType'" i18n="@@assignment">Assignment</span>
          <span *ngSwitchCase="'courseType'" i18n="@@course_type">Subtype</span>
          <span *ngSwitchDefault i18n="@@unknown">Unknown</span>
        </ng-container>
      </th>
      <td *matCellDef="let content" [innerHTML]="content[column]" mat-cell></td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr (click)="onClick(row)" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
  </table>

  <div *ngIf="resultsState === 'unknown'">
    <span i18n="@@result_state_loading">Loading ...</span>
  </div>
  <div *ngIf="resultsState === 'empty'">
    <span i18n="@@result_state_empty">You have no learning contents to do right now.</span>
  </div>
</div>
