import { Component, EventEmitter, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatMenuTrigger, MenuPositionX } from '@angular/material/menu';
import { TargetGroup, User } from '../../core/core.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { catchError, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AdminTargetGroupsService } from '../../core/target-group/admin-target-groups.service';
import { TargetGroupsAndUsersResponse } from '../../core/target-group/admin-target-groups.types';
import { InfoService } from '../../core/info/info.service';
import { InfoType, MessageConstants } from '../../core/info/info.types';
import { OfflineContent } from '../../core/admin-offline.types';
import { GenericTextInputDialogComponent } from '../generic-text-input-dialog/generic-text-input-dialog.component';
import { GenericTextInputDialogData } from '../generic-text-input-dialog/generic-text-input-dialog.types';
import { PrincipalService } from '../../core/principal/principal.service';
import { PermissionStates } from '../../core/principal/permission.states';
import { EMPTY, Observable } from 'rxjs';


@Component({
  selector: 'rag-select-target-groups-menu',
  templateUrl: './select-target-groups-menu.component.html',
  styleUrls: [ './select-target-groups-menu.component.scss' ],
})
export class SelectTargetGroupsMenuComponent
  implements OnChanges, OnDestroy {

  @Input() disabled = false;
  isLoading = false;
  @Input() participants: OfflineContent.Participant[];
  permissions: PermissionStates;
  @Input() showButtonNew: boolean;
  @Input() targetGroupData: TargetGroupsAndUsersResponse | null;
  targetGroupsToAdd: TargetGroup[] | null;
  targetGroupsToRemove: TargetGroup[] | null;
  @Input() users: User[];
  @Input() xPosition: MenuPositionX;

  private _triggerReload = new EventEmitter<void>();
  private _userIds: number[];

  constructor(
    private adminTargetGroupsService: AdminTargetGroupsService,
    private infoService: InfoService,
    private principalService: PrincipalService,
  ) {
    this._triggerReload.asObservable()
      .pipe(tap(() => this.reloadData()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  getFilteredTargetGroups(
    targetGroups: TargetGroup[] | null,
    filter?: string,
  ): TargetGroup[] {

    if ( !filter ) {
      return targetGroups ?? [];
    }

    filter = filter.toLocaleLowerCase();
    return targetGroups?.filter(tg => tg.name?.toLocaleLowerCase().includes(filter)) ?? [];
  }

  hasNoUsers(): boolean {
    return !(this._userIds?.length > 0);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ( changes.hasOwnProperty('participants') ) {
      this._userIds = this.participants?.map(u => u.userId) ?? [];
    }

    if ( changes.hasOwnProperty('users') ) {
      this._userIds = this.users?.map(u => u.userId) ?? [];
    }

    this.updateData(this.targetGroupData);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onAddToGroup(
    targetGroup: TargetGroup,
  ): void {

    const targetGroupId = targetGroup?.id;
    const tgUsers = (this.targetGroupData?.targetGroupToUsers?.[targetGroupId] ?? []);
    const userIds = this._userIds
      // only modify those who are not yet members of the target group
      ?.filter(userId => !tgUsers.includes(userId));
    if ( !(userIds?.length > 0) ) {
      return;
    }

    this.adminTargetGroupsService.addUsersToTargetGroup(targetGroupId, userIds)
      .pipe(take(1))
      .pipe(tap(() => this.handleSuccess(MessageConstants.API.SUCCESS)))
      .pipe(catchError(() => this.handleError(MessageConstants.API.ERROR)))
      .subscribe();
  }

  onMenuOpened(): void {
    this._triggerReload.emit();
  }

  onMouseEnter(matMenuTrigger: MatMenuTrigger): void {

    if ( matMenuTrigger.menuOpen || !(this._userIds?.length > 0) ) {
      return;
    }

    if (this.disabled) {
      return;
    }

    matMenuTrigger.openMenu();
  }

  onNewTargetGroup(): void {

    if ( !(this._userIds?.length > 0) ) {
      return;
    }

    this.infoService
      .showDialog<GenericTextInputDialogComponent, GenericTextInputDialogData, string>(GenericTextInputDialogComponent, {
        title: $localize`:@@select_target_groups_menu_add_new:Create new group`,
        message: $localize`:@@select_target_groups_menu_add_new_message:Enter the title for the new target group`,
        buttons: null,
        data: { text: '' },
      })
      .pipe(take(1))
      .pipe(takeWhile(name => !!name))
      .pipe(switchMap(name => this.adminTargetGroupsService.save({
        name, description: '', id: null,
      }, {
        criteriaExpression: 'K1', id: null,
        criteria: [ { name: 'K1', field: 'firstname', condition: '=', value: null, id: null } ],
      })))
      .pipe(catchError(() => this.handleError(MessageConstants.API.ERROR)))
      .pipe(tap(result => this.onAddToGroup(result.targetGroup)))
      .subscribe();
  }

  onRemoveFromGroup(
    targetGroup: TargetGroup,
  ): void {

    const targetGroupId = targetGroup?.id;
    const tgManualUsers = (this.targetGroupData?.targetGroupToManualUsers?.[targetGroupId] ?? []);
    const userIds = this._userIds
      // only include those that have been manually assigned to the target group
      ?.filter(userId => tgManualUsers.includes(userId));
    if ( !(userIds?.length > 0) ) {
      return;
    }

    this.adminTargetGroupsService.removeUsersFromTargetGroup(targetGroupId, userIds)
      .pipe(take(1))
      .pipe(tap(() => this.handleSuccess(MessageConstants.API.SUCCESS)))
      .pipe(catchError(() => this.handleError(MessageConstants.API.ERROR)))
      .subscribe();
  }

  private handleError(message: string): Observable<never> {
    this.infoService.showMessage(message, { infoType: InfoType.Error });
    return EMPTY;
  }

  private handleSuccess(message: string): void {
    this.infoService.showMessage(message, { infoType: InfoType.Success });
    this.targetGroupData = this.targetGroupsToAdd = this.targetGroupsToRemove = null;
    this._triggerReload.emit();
  }

  private reloadData(): void {

    if ( this.isLoading ) {
      return;
    }

    if ( this.targetGroupData != null ) {
      return this.updateData(this.targetGroupData);
    }

    this.isLoading = true;
    this.adminTargetGroupsService.fetchTargetGroupsAndUsers()
      .pipe(tap(response => this.updateData(response)))
      .pipe(take(1))
      .subscribe();
  }

  private updateData(
    targetGroupData: TargetGroupsAndUsersResponse | null,
  ): void {

    if ( this._userIds == null || targetGroupData == null ) {
      this.targetGroupsToAdd = this.targetGroupsToRemove = null;
      return;
    }

    this.targetGroupData = targetGroupData;
    const userIds = this._userIds;
    const targetGroupUserIds = targetGroupData.targetGroupToUsers ?? {};
    const targetGroupManualUserIds = targetGroupData.targetGroupToManualUsers ?? {};

    const data = Object.values(targetGroupData.targetGroups ?? {})
      // check if modification is allowed
      .filter(tg => tg.rbacActions?.mayCreateFilter !== false)
      .reduce((pV, tg) => {

        const tgUsers = (targetGroupUserIds[tg.id] ?? [])
          .filter(userId => userIds.includes(userId));
        const canAdd = tgUsers.length !== userIds.length;
        if ( canAdd ) {
          // at least one user does not yet have this target group -> can be added
          pV.add.push(tg);
        }

        const tgManualUsers = (targetGroupManualUserIds[tg.id] ?? [])
          .filter(userId => userIds.includes(userId));
        const canRemove = tgManualUsers.length > 0;
        if ( canRemove ) {
          // at least one user was manually added to the target group -> can be removed
          pV.remove.push(tg);
        }
        return pV;
      }, { add: [], remove: [] });
    this.targetGroupsToAdd = data.add;
    this.targetGroupsToRemove = data.remove;

    this.isLoading = false;
  }

}
