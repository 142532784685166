import { Component } from '@angular/core';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import {
  ColumnFilterDropdownOption,
  dropdownOptionsFactory,
  FilterOperator,
} from '../../../core/column-settings/column-filter.types';


@Component({
  selector: 'rag-pivot-filter-number',
  templateUrl: './pivot-filter-number.component.html',
  styleUrls: [ './pivot-filter-number.component.scss' ],
})
export class PivotFilterNumberComponent
  extends AbstractPivotFilterComponent {

  static ACTIONS = {
    $eq: $localize`:@@pivot_filter_number_eq:equals...`,
    $gt: $localize`:@@pivot_filter_number_gt:greater than...`,
    $in: $localize`:@@pivot_filter_number_in:one of...`,
    $lt: $localize`:@@pivot_filter_number_lt:less than...`,
    $notIn: $localize`:@@pivot_filter_number_notIn:not one of...`,
    $between: $localize`:@@pivot_filter_number_between:between (from - to)...`,
  };
  static RE_COLLECTION_OF_NUMBERS = /^[0-9, ;"]*$/i;
  static RE_RANGE = '^\\d+\\s*-\\s*\\d+$';
  static RE_SINGLE_NUMBER = '^\\d*$';
  actionOptions: ColumnFilterDropdownOption[];
  pattern: string | RegExp = null;
  regex: RegExp;

  constructor() {
    super();

    this.defaultAction = FilterOperator.EQ;
    this.actions = Object.keys(PivotFilterNumberComponent.ACTIONS);
    this.actionOptions = dropdownOptionsFactory(PivotFilterNumberComponent.ACTIONS);
  }

  afterFilterSet(): void {
    super.afterFilterSet();
    this.updatePattern(this.action);
  }

  cleanValue(value: string): string {
    if ( value && (typeof (value) === 'string') ) {
      value = value.trim()
        // remove whitespace and percentage
        .replace(/[%]/g, '');
      if ( this.pattern && !(new RegExp(this.pattern)).test(value) ) {
        // if the value does not match our pattern
        value = null;
      }
    }
    if ( !value && (Number(value) !== 0) ) {
      // for example set false to null
      value = null;
    }
    return value;
  }

  setAction(action: FilterOperator): void {
    super.setAction(action);
    this.updatePattern(action);
  }

  getActionText(): string {
    if (!this.showActions) {
      // hide action label when not showing selection button
      return null;
    }

    return PivotFilterNumberComponent.ACTIONS[this.action];
  }

  numberMatchRegex(): boolean {
    const value = this.formControl.value;

    if ( value === '' || value == null ) {
      return true;
    }
    if (this.regex == null) {
      return true;
    }
    return this.regex.test(value);
  }

  private updatePattern(action: string) {
    if ( action === '$between' ) {
      this.pattern = PivotFilterNumberComponent.RE_RANGE;
    } else if ( [ '$in', '$notIn' ].indexOf(action) > -1 ) {
      this.pattern = PivotFilterNumberComponent.RE_COLLECTION_OF_NUMBERS;
    } else {
      this.pattern = PivotFilterNumberComponent.RE_SINGLE_NUMBER;
    }
    this.regex = new RegExp(this.pattern);
  }

}
