import { _isNumberValue } from '@angular/cdk/coercion';
import { LanguageHelper } from '../../core/language.helper';
import { WithTags } from '../../core/primitives/primitives.types';
import { AnyObject } from '../../core/core.types';
import { naturalCompare } from '../../core/natural-sort';
import { Categories } from 'src/app/core/categories/categories.types';

export class TableHelper {

  /**
   * @see https://github.com/angular/material2/issues/9966#issuecomment-365942460
   */
  static sortingDataAccessor<T>(data: T, sortHeaderId: string) {
    const value: any = data[sortHeaderId];
    return TableHelper.toString(value);
  }

  static splitTags(
    tags: string | string[] | null,
  ): string[] {

    if ( !tags ) {
      return [];
    }

    let result: string[];
    if ( Array.isArray(tags) ) {

      result = tags;

    } else if ( typeof (tags) === 'string' ) {

      result = tags.split(/[,;]/);
    } else {

      // not something we can reasonably split into an array
      console?.warn?.apply(console, ['splitTags - call with invalid parameters!', tags]);
      return [];
    }

    return result
      .map(tag => tag?.trim())
      .filter(tag => !!tag);
  }

  static tagsAsDropDownOptions(
    contents: WithTags[] | null,
  ): AnyObject<string> {

    return Array.from(new Set<string>(contents
      .map(entry => entry.tags)
      .filter(tags => !!tags)
      .flatMap(tags => tags.split(','))))
      .sort(naturalCompare)
      .reduce((pV, tag) => {
        pV[tag] = tag;
        return pV;
      }, {});
  }

  static categoriesAsDropDownOptions(
    contents: Array<Categories.Categorizable>,
    categories: Map<number, Categories.Category>
  ): AnyObject<string> {
    return Array.from(new Set<number>(contents
      .map(entry => entry.categories)
      .filter(categories => !!categories)
      .flatMap(categories => categories)))
    .reduce((pV, catId) => {
      const category = categories.get(catId);
      if (category === undefined) {
        // inconsistent data
        console.warn(catId, categories)
        return pV;
      }
      let title = LanguageHelper.objectToText(category.title);
      if (category.categoryId !== undefined) {
        title = '⌙▶︎ ' + title;
      }
      pV[catId] = title;
      return pV;
    }, {});
  }

  static toString(value: any) {
    if ( _isNumberValue(value) ) {
      return Number(value);
    } else if ( typeof (value) === 'string' ) {
      return value.toLocaleLowerCase();
    } else if ( typeof (value) === 'object' ) {
      const textValue: string = LanguageHelper.objectToText(value) ?? '';
      return textValue.toLocaleLowerCase();
    }
    return value ?? '';
  }

}
