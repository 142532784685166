<ng-container *ngFor="let navItem of navItems">

  <ng-container *ngIf="headerNavHelper.getLogin(navItem) as login">
     <div class="login-container">
       <span class="item-title login">{{login}}</span>
     </div>
  </ng-container>

  <a
    *ngIf="headerNavHelper.asLink(navItem) as link"
    class="stacked" mat-button
    [routerLink]="link.routerLink"
    [queryParams]="link.queryParams"
    routerLinkActive="active"
    [routerLinkActiveOptions]="link.routerLinkActiveOptions"
    [target]="link.external ? '_blank' : null"
  >
    <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">
      <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>
    </div>
    <span class="title-wrapper">
        <span class="link-title">{{link.title}}</span>
        <mat-icon *ngIf="link.external" class="size-16" svgIcon="open-in-new"></mat-icon>
      </span>
  </a>

  <a
    *ngIf="headerNavHelper.asLinkHref(navItem) as link"
    class="stacked" mat-button
    [href]="link.url"
    [target]="link.external ? '_blank' : null"
  >
      <span class="title-wrapper">
        <span class="link-title">{{link.title}}</span>
        <mat-icon *ngIf="link.external" class="size-16" svgIcon="open-in-new"></mat-icon>
      </span>
  </a>

  <button
    *ngIf="headerNavHelper.asMenu(navItem) as menu"
    class="stacked" mat-button type="button"
    routerLinkActive="active"
    [routerLinkActiveOptions]="menu.routerLinkActiveOptions"
    [matMenuTriggerFor]="matIconMenu"
    #matMenuTrigger="matMenuTrigger"
    [ngClass]="{expanded: matMenuTrigger.menuOpen}"
  >
    <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">
      <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>
    </div>
    <span class="title-wrapper">
        <span class="link-title">{{menu.title}}</span>
        <mat-icon svgIcon="chevron-down"></mat-icon>
      </span>

    <mat-menu #matIconMenu class="header-navigation-menu">
      <ng-container *ngFor="let childItem of menu.children">

        <button
          *ngIf="headerNavHelper.asButton(childItem) as childButton"
          type="button" mat-menu-item
          (click)="headerNavHelper.onNavItemClick(childButton)"
        >
          <span class="link-title">{{childButton.title}}</span>
          <mat-icon *ngIf="childButton.external" class="size-16" svgIcon="open-in-new"></mat-icon>
        </button>

        <a
          *ngIf="headerNavHelper.asLink(childItem) as childLink"
          mat-menu-item
          [routerLink]="childLink.routerLink"
          [queryParams]="childLink.queryParams"
          routerLinkActive="active"
          [routerLinkActiveOptions]="childLink.routerLinkActiveOptions"
          [target]="childLink.external ? '_blank' : null"
        >
          <div class="additional-routes" *ngIf="childLink.additionalRoutes?.length > 0">
            <div *ngFor="let route of childLink.additionalRoutes" [routerLink]="route"></div>
          </div>
          <span class="link-title">{{childLink.title}}</span>
          <mat-icon *ngIf="childLink.external" class="size-16" svgIcon="open-in-new"></mat-icon>
        </a>

        <a
          *ngIf="headerNavHelper.asLinkHref(childItem) as childLink"
          mat-menu-item
          [href]="childLink.url | safe:'url'"
          [target]="childLink.external ? '_blank' : null"
        >
          <span class="link-title">{{childLink.title}}</span>
          <mat-icon *ngIf="childLink.external" class="size-16" svgIcon="open-in-new"></mat-icon>
        </a>

        <mat-divider *ngIf="childItem.type === 'divider'"></mat-divider>

        <ng-container
          *ngIf="headerNavHelper.asMenu(childItem) as childMenu"
        >

          <button
            type="button" mat-menu-item
            routerLinkActive="active"
            [routerLinkActiveOptions]="childMenu.routerLinkActiveOptions"
            [matMenuTriggerFor]="matMenuChild"
          >
            <div class="additional-routes" *ngIf="childMenu.additionalRoutes?.length > 0">
              <div *ngFor="let route of childMenu.additionalRoutes" [routerLink]="route"></div>
            </div>
            <span class="link-title">{{childMenu.title}}</span>

            <mat-menu #matMenuChild class="header-navigation-menu layer-2">
              <ng-container *ngFor="let childItem2 of childMenu.children">

                <button
                  *ngIf="headerNavHelper.asButton(childItem2) as childButton2"
                  type="button" mat-menu-item
                  (click)="headerNavHelper.onNavItemClick(childButton2)"
                >
                  <span class="link-title">{{childButton2.title}}</span>
                  <mat-icon *ngIf="childButton2.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                </button>

                <a
                  *ngIf="headerNavHelper.asLink(childItem2) as childLink2"
                  mat-menu-item
                  [routerLink]="childLink2.routerLink"
                  [queryParams]="childLink2.queryParams"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="childLink2.routerLinkActiveOptions"
                  [target]="childLink2.external ? '_blank' : null"
                >
                  <div class="additional-routes" *ngIf="childLink2.additionalRoutes?.length > 0">
                    <div *ngFor="let route of childLink2.additionalRoutes" [routerLink]="route"></div>
                  </div>
                  <span class="link-title">{{childLink2.title}}</span>
                  <mat-icon *ngIf="childLink2.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                </a>

                <a
                  *ngIf="headerNavHelper.asLinkHref(childItem2) as childLink2"
                  mat-menu-item
                  [href]="childLink2.url | safe:'url'"
                  [target]="childLink2.external ? '_blank' : null"
                >
                  <span class="link-title">{{childLink2.title}}</span>
                  <mat-icon *ngIf="childLink2.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                </a>

                <mat-divider *ngIf="childItem2.type === 'divider'"></mat-divider>

                <ng-container
                  *ngIf="headerNavHelper.asMenu(childItem2) as childMenu2"
                >

                  <button
                    type="button" mat-menu-item
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="childMenu2.routerLinkActiveOptions"
                    [matMenuTriggerFor]="matMenuChild"
                  >
                    <div class="additional-routes" *ngIf="childMenu2.additionalRoutes?.length > 0">
                      <div *ngFor="let route of childMenu2.additionalRoutes" [routerLink]="route"></div>
                    </div>
                    <span class="link-title">{{childMenu2.title}}</span>

                    <mat-menu #matMenuChild class="header-navigation-menu layer-3">
                      <ng-container *ngFor="let childItem3 of childMenu2.children">

                        <button
                          *ngIf="headerNavHelper.asButton(childItem3) as childButton3"
                          type="button" mat-menu-item
                          (click)="headerNavHelper.onNavItemClick(childButton3)"
                        >
                          <span class="link-title">{{childButton3.title}}</span>
                          <mat-icon *ngIf="childButton3.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                        </button>

                        <a
                          *ngIf="headerNavHelper.asLink(childItem3) as childLink3"
                          mat-menu-item
                          [routerLink]="childLink3.routerLink"
                          [queryParams]="childLink3.queryParams"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="childLink3.routerLinkActiveOptions"
                          [target]="childLink3.external ? '_blank' : null"
                        >
                          <div class="additional-routes" *ngIf="childLink3.additionalRoutes?.length > 0">
                            <div *ngFor="let route of childLink3.additionalRoutes" [routerLink]="route"></div>
                          </div>
                          <span class="link-title">{{childLink3.title}}</span>
                          <mat-icon *ngIf="childLink3.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                        </a>

                        <a
                          *ngIf="headerNavHelper.asLinkHref(childItem3) as childLink3"
                          mat-menu-item
                          [href]="childLink3.url | safe:'url'"
                          [target]="childLink3.external ? '_blank' : null"
                        >
                          <span class="link-title">{{childLink3.title}}</span>
                          <mat-icon *ngIf="childLink3.external" class="size-16" svgIcon="open-in-new"></mat-icon>
                        </a>

                        <mat-divider *ngIf="childItem3.type === 'divider'"></mat-divider>

                      </ng-container>
                    </mat-menu>
                  </button>

                </ng-container>

              </ng-container>
            </mat-menu>
          </button>

        </ng-container>

      </ng-container>
    </mat-menu>
  </button>

</ng-container>
