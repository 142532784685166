import { OfflineContent } from 'src/app/core/admin-offline.types';
import { ViewData } from '../view-helper';
import { ControllingSingleUserTypes } from '../ctrl-single-user.types';
import { Assignable, Core, Identifiable, Statusable, Titleable } from '../core.types';
import { Catalogs } from '../catalog/catalog.types';


export interface AbstractLearnerAccountView {
  $view?: ViewData;

  additionalData: { [key: string]: any };
}

export interface AbstractLearnerAccountViewParent
  extends AbstractLearnerAccountView, Identifiable, Titleable, Statusable, Assignable {
  locked: boolean;
  repetitions: number;
}

export interface LearnerCurriculumAccountView
  extends AbstractLearnerAccountViewParent {
  code: string;
  currentStartDate: number;
  curriculumId: number;
  description: string;
  executionEndDate: number;
  firstValidSince: number;
  itemTitles: { [key: string]: string };
  lastEndDate: number;
  lastValidUntil: number;
  topic: string;
  variationCounter: number;
}

/**
 * c.f. com.reflact.sphere.ltr.bck.offline.core.OfflineInvitationConstants
 */
export enum OfflineInvitationConstants {
  INV_STATE_INVITED = 'inv',
  INV_STATE_REMINDED = 'rem',
  INV_STATE_CANCELLED = 'can',
  INV_STATE_ACK = 'ack',
  INV_STATE_DEC = 'dec',
  VIA_LINK = 'link',
  VIA_FRONTEND = 'frontend',
}

/**
 * c.f. com.reflact.sphere.meta.offline.IEventAccountStatus
 */
export enum EventAccountStatusEnum {
  ACCEPTED = 0,
  INVITED = 1,
  PARTICIPATED = 2,
  DESIRED = 3,
  DECLINED = 4,
}

export interface IEventAccountStatus {
  status: EventAccountStatusEnum;
}

export interface LearnerEventAccountView
  extends AbstractLearnerAccountViewParent, IEventAccountStatus {
  invitationStatus?: OfflineInvitationConstants;
  statusExamination: number;
  offlineEventId: number;
  merchantStatus: string;
  merchantTransactionType: string;
  bookingUUID?: string;
  reservation: boolean;
}

export interface LearnerOfflineAccountView extends Identifiable, Statusable, Assignable {
  offlineContent: OfflineContent.Event;
}

export interface LearnerCurriculumItemAccountView
  extends AbstractLearnerAccountView, Titleable, Statusable, IEventAccountStatus {
  curriculumId: number;
  curriculumItemId: number;
  description: string;
  orderIndex: number;
  rootCurriculumId: number;
  targetId: number;
  targetType: string;
  variationCounter: number;
}

export interface LearnerAccountView {
  contextItems: { [key: string]: LearnerCurriculumItemAccountView };
  curricula: { [key: string]: LearnerCurriculumAccountView };
}

export interface LearnerAccountContextItemView
  extends LearnerAccountView {
  curriculum: LearnerCurriculumAccountView;
  curriculumItem: LearnerCurriculumItemAccountView;
}

export interface LearnerAccountOfflineContentView
  extends LearnerAccountView {
  curriculum?: LearnerCurriculumAccountView;
  curriculumItem?: LearnerCurriculumItemAccountView;
  offlineContent: OfflineContent.Event;
  offlineEvent: OfflineContent.EventSchedule;
  offlineEventAccount?: LearnerEventAccountView;
}

export interface LearnerAccountOfflineContentViewV2
  extends LearnerAccountView, Assignable {
  booked?: boolean;
  curriculum?: LearnerCurriculumAccountView;
  curriculumItem?: LearnerCurriculumItemAccountView;
  offlineContent: OfflineContent.Event;
  offlineContentAccount: LearnerOfflineAccountView;
  offlineEvents: Array<OfflineContent.EventSchedule>;
  offlineEventAccountViews?: Array<LearnerEventAccountView>;
  catalogBooking?: Catalogs.CatalogBooking;
}

export namespace LearnerAccountTypes {

  export class Util {

    static hasRelevantScormDetailData(course: ControllingSingleUserTypes.CourseAccount): boolean {
      switch ( course?.courseType ) {
        case Core.CourseType.Certification:
        case Core.CourseType.Test:
        case Core.CourseType.Learning:
        case Core.CourseType.ScoDocument:
        case Core.CourseType.Link:
          return true;
        default:
          return false;
      }
    }

  }

  export type CoursePublishStatus = 'ARCHIVED' | 'DELETED' | 'DISABLED' | 'PUBLISHED';

  export type CoursePublishStatusLowerCase = 'archived' | 'deleted' | 'disabled' | 'published';

  // TODO: remove once there are no wrong values in the database
  export const coursePublishStatusFactory = (value: string | number): CoursePublishStatus => {
    if ( value == null ) {
      return null;
    }
    switch ( value ) {
      case 0:
      case '0':
      case 'archived':
      case 'ARCHIVED':
        return 'ARCHIVED';
      case 1:
      case '1':
      case 'deleted':
      case 'DELETED':
        return 'DELETED';
      case 2:
      case '2':
      case 'disabled':
      case 'DISABLED':
        return 'DISABLED';
      case 3:
      case '3':
      case 'published':
      case 'PUBLISHED':
        return 'PUBLISHED';
    }
    return null;
  };

  /**
   * @ see com.reflact.sphere.meta.elea.CourseType
   */


  export const courseTypeFactory = (value: string | number): Core.CourseType => {
    if ( value == null ) {
      return null;
    }
    switch ( value ) {
      case 'Certification':
      case 1:
        return Core.CourseType.Certification;
      case 'Test':
      case 2:
        return Core.CourseType.Test;
      case 'Learning':
      case 3:
        return Core.CourseType.Learning;
      case 'ScoDocument':
      case 8:
        return Core.CourseType.ScoDocument;
      case 'Link':
      case 10:
        return Core.CourseType.Link;
      case 'SimpleConnect':
      case 12:
        return Core.CourseType.SimpleConnect;
      case 'ToDo':
      case 13:
        return Core.CourseType.ToDo;
      case 16:
        return Core.CourseType.Recording;
    }
    return null;
  };

  export const CourseTodoAcceptanceTextInputRequired = 0b001;
  export const CourseTodoAcceptanceFileUploadRequired = 0b010;
  export const CourseTodoAcceptanceCntrlRequired = 0b100;

  export interface CourseWithAcceptanceMask {
    /**
     * @see CourseTodoAcceptanceTextInputRequired
     * @see CourseTodoAcceptanceFileUploadRequired
     * @see CourseTodoAcceptanceCntrlRequired
     */
    acceptanceMask: number;
  }

}
