export class ApiUrls {

  public static lmsApi: string;
  public static classicApi: string;
  private static ragUrls = {};

  static getKey(key: string): string {

    if ( ApiUrls.ragUrls.hasOwnProperty(key) ) {
      return ApiUrls.ragUrls[key];
    }

    const message = `trying to access unknown API key '${key}'`;
    console?.error(message);
    return null;
  }

  /**
   * Generate RESTful API call with dynamic list of parameters
   * @param key       api key
   * @param version   verion of api call
   * @param theParams query string parameters
   */
  static getKeyWithParams(key: string, version = '1', ...theParams: any[]) {
    return ApiUrls.ragUrls[key] + theParams.reduce((a, p) => {
      if ( p != null ) {
        a += '/' + p;
      }
      return a;
    }, '') + '/v' + version;
  }

  static setBaseUrls(apiBase: string, classicApiBase: string): void {
    ApiUrls.lmsApi = apiBase;
    ApiUrls.classicApi = classicApiBase;
    ApiUrls.ragUrls = {
      GetId: apiBase + '/api/server/v1/id',
      PreloadData: apiBase + '/api/public/v1/accountSettings?_navLocation={navLocation}',
      NavigationData: apiBase + '/api/public/v1/navigation',
      AccountV4: apiBase + '/api/learner/v4/account',
      AccountCurriculumEvents: apiBase + '/api/learner/curriculum/{curriculumId}/v1/events',
      AccountCurriculumLockedBy: apiBase + '/api/learner/curriculum/{curriculumId}/v1/lockedBy',
      AccountContextItem: apiBase + '/api/learner/v1/curriculum/{rootCurriculumId}/item/{curriculumItemId}/context',
      AccountOfflineContentItem: apiBase + '/api/learner/v1/curriculum/{rootCurriculumId}/item/{curriculumItemId}/offlineCnt',
      AccountOfflineContentItem_v2: apiBase + '/api/learner/v2/curriculum/{rootCurriculumId}/item/{curriculumItemId}/offlineCnt',
      AccountOfflineContentDirect: apiBase + '/api/learner/v1/offlineCnt/{offlineContentId}',
      AccountOfflineContentDirect_v2: apiBase + '/api/learner/v2/offlineCnt/{offlineContentId}',
      AccountOfflineEventSchedulesState: apiBase + '/api/learner/v1/offlineCnt/{offlineContentId}/event/schedules/state',
      AccountOfflineContentBook: apiBase + '/api/learner/v1/offline/{offlineContentId}/event/{offlineEventId}/book',
      AccountOfflineContentBook_v2: apiBase + '/api/learner/v2/offline/{offlineContentId}/event/{offlineEventId}/book/{bookingUUID}/{isReservation}',
      AccountOfflineContentConfirm: apiBase + '/api/learner/v1/offline/{offlineContentId}/event/{offlineEventId}/confirm',
      AccountOfflineContentDecline: apiBase + '/api/learner/v1/offline/{offlineContentId}/event/{offlineEventId}/decline',
      AccountOfflineContentCancel: apiBase + '/api/learner/v1/offline/{offlineContentId}/event/{offlineEventId}/cancel',
      ClassicAdministration: classicApiBase + '/navigationAction.do?dispatch=navigateTo&navigationTarget=administration',
      FetchDatesOfEvent: classicApiBase + '/API/f2f/contents/{id}/events/v1',
      ClassicControlling: classicApiBase + '/navigationAction.do?dispatch=navigateTo&navigationTarget=controlling',
      ClassicFrontEnd: classicApiBase + '/navigationAction.do?dispatch=navigateTo&navigationTarget=home&useClassic=true&headless=false',
      ClassicFrontEndDocLib: classicApiBase + '/navigationAction.do?dispatch=navigateTo&navigationTarget=showDocLib&headless=true',
      FilterResults: apiBase + '/api/filter/{context}/v1/results',
      FinishUnfinished: classicApiBase + '/DetailsAction.do?dispatch=finishUnfinished&itemID={itemId}',
      ExecutableState: classicApiBase + '/API/courseaccount/{courseId}/executablestate/v1',
      GlobalError500: classicApiBase + '/global_errors/prodError.jsp',
      GlobalMaintenance: classicApiBase + '/global_includes/scripts/maintenanceInfoJson.jsp',
      History: classicApiBase + '/global_includes/styles/print.css?url={url}&_={cacheBusting}',
      LmsJavascriptAdapter: classicApiBase + '/lmsJavascriptAdapter',
      Login: apiBase + '/api/public/principal/v1/login?login={login}',
      WindowsLoginJTA: classicApiBase + '/custom/jta/jtaLdapLogin.jsp',
      LoginSwitchUser: classicApiBase + '/API/whoami/v1',
      Logout: apiBase + '/api/user/v1/logout',
      ScoExecBase: classicApiBase + '/',
      UserInfo: classicApiBase + '/API/whoami/v2?_navLocation={navLocation}',
      ObjectPermissions: apiBase + '/api/rbac/permissions/{objectType}/{objectKey}',
      ObjectPermissionsPost: apiBase + '/api/rbac/permissions/{objectType}',
      PasswordReset: classicApiBase + '/API/user/requestnewpass/v1',
      PasswordUpdate: classicApiBase + '/API/user/resetpw',
      ContactRequest: classicApiBase + '/API/contact/v1',
      /**
       * @deprecated
       */
      Profile: classicApiBase + '/API/users/own/v2',
      LicenseCode: classicApiBase + '/API/actioncodes/code/{code}/exec/v2',
      /**
       * @deprecated
       */
      AccountDataPutLanguage: classicApiBase + '/API/users/own/language',
      /**
       * @deprecated
       */
      AccountDataGetRequest: apiBase + '/api/user/v1/own',
      /**
       * @deprecated
       */
      AccountDataPutRequest: apiBase + '/api/user/v1/own',
      AccountFrontendActions: apiBase + '/api/learner/v1/postLogin',
      /**
       * @deprecated
       */
      PasswordPutRequest: classicApiBase + '/API/users/own/logindata/v1 ',
      GeneratePassword: classicApiBase + '/API/users/password/v1',
      // user profile v2
      UserProfileDeactivate: classicApiBase + '/API/users/own/v1',
      UserProfileGet: classicApiBase + '/API/users/own/v3',
      UserProfileUpdate: classicApiBase + '/API/users/own/v4',
      UserProfileUpdatePassword: classicApiBase + '/API/users/own/logindata/v1',
      UserProfileUpdateCredentials: classicApiBase + '/API/users/own/logindata/v2',
      UserProfileUpdateLanguage: classicApiBase + '/API/users/own/language/v2',
      AdminUserProfileExport: apiBase + '/api/admin/user/profile/v1/export',

      MailboxCount: apiBase + '/api/message/v1/unread',
      Mailbox: classicApiBase + '/API/msg',
      Mailbox_NewApi: apiBase + '/api/message/v1',
      Mailbox_NewApi_SaveAsDraft: apiBase + '/api/message/saveDraft/v1',
      Mailbox_NewApi_SaveOnly: apiBase + '/api/message/saveOnly/v1',
      Mailbox_NewApi_SetRead: apiBase + '/api/message/read/v1',
      Mailbox_NewApi_Delete_Message: apiBase + '/api/message/delete/v1',
      Mailbox_NewApi_Delete_Attachment: apiBase + '/api/message/attachment/v2',
      Mailbox_NewApi_GetAccounts: apiBase + '/api/message/accounts/v1',
      Mailbox_NewApi_GetAccountSenders: apiBase + '/api/message/accounts/v1/sender',
      Mailbox_NewApi_GetAccountReceivers: apiBase + '/api/message/accounts/v1/receiver',
      Mailbox_NewApi_Reply: apiBase + '/api/message/reply/v1',
      Mailbox_NewApi_Convert: apiBase + '/api/message/v1/convert',
      CertificatesGetRequest: classicApiBase + '/API/myCerts/v1',
      // TODO RestApi calls missing
      CertIterationDetails: classicApiBase +
        '/curriculumCtrlAction.do?dispatch=ajaxFetch&action=curriculumDetails&userId={userId}&curId={curId}&iteration={iteration}',
      CurriculumCtrlAction: classicApiBase + '/curriculumCtrlAction.do?dispatch={action}&curriculumId={curriculumId}&userId={userId}',
      CurriculumCtrlActionAjax: classicApiBase + '/curriculumCtrlAction.do?dispatch=ajaxFetch&action={action}&curId={curriculumId}',
      UserContentAction: apiBase + '/api/learner/content/v1/action',
      DownloadPdfCertificatesPostRequest: classicApiBase + '/GenerateCertificateAction.do',
      GenerateCertificateJSON: classicApiBase +
        '/GenerateCertificateAction.do?dispatch=curriculumCertificate&oi={curId}&uid={userId}&it={iteration}&_u={date}&jspResponse=false',
      GenerateCertificate: classicApiBase +
        '/GenerateCertificateAction.do?dispatch=curriculumCertificate&oi={curId}&uid={userId}&it={iteration}&_u={date}',
      // TODO end
      TestSphereDateSpan: classicApiBase + '/API/util/testspheredatespan/{pattern}/v2',
      GetReportsForMailComposer: classicApiBase + '/API/reports/admin/v1',
      // Administration
      // Theme styling
      StartPage: classicApiBase + '/API/account/startpage',
      Languages: classicApiBase + '/API/account/languages',
      /**
       * Caution! This is an indicator of a corrupted http session
       * @see TF-5035
       */
      GetAccountStyleInfo: classicApiBase + '/API/account/settings/styleinfo/{accountid}/v1',
      PostAccountStyleInfo: classicApiBase + '/API/account/settings/styleinfo/v1',
      GetAccountImages: classicApiBase + '/API/account/settings/images/v1',
      PostAccountImage: classicApiBase + '/API/account/settings/image/v1',
      DeleteAccountImage: classicApiBase + '/API/account/settings/image/{id}/v1',
      News: apiBase + '/api/news/v1/list',
      Special: apiBase + '/api/news/v1/special',
      AdminNews: apiBase + '/api/admin/news/v1/list',
      PostNews: apiBase + '/api/admin/news/v1',
      ShowNewsDetails: apiBase + '/api/admin/news/v1/news/{newsId}',
      DeleteNews: apiBase + '/api/admin/news/v1/_ID_',
      CopyNews: apiBase + '/api/admin/news/v1/copy/{newsId}',
      ShowFile: classicApiBase + '/showFile',
      ShowFileById: classicApiBase + '/showFile/{fileId}',
      DownloadFile: classicApiBase + '/downloadFile',
      // Attributes
      GetUserAttribute: apiBase + '/api/admin/user/attribute/{attributeName}',
      PostUserAttribute: apiBase + '/api/admin/user/attribute/{attributeName}',
      PostUserAttributes: apiBase + '/api/admin/user/attributes',
      PostUserAttributeGroup: apiBase + '/api/admin/user/attribute/group',
      DeleteUserAttributeGroup: apiBase + '/api/admin/user/attribute/group/{groupId}',
      GetAllUserAttributes: apiBase + '/api/admin/user/attribute',
      GetAllUserAttributeGroups: apiBase + '/api/admin/user/attribute/group',
      GetUserAttributeContext: apiBase + '/api/admin/user/attribute/group/context/{context}',
      GetUserAttributesForMessaging: apiBase + '/api/admin/user/attribute/messaging',
      PostUserAttributeContext: apiBase + '/api/admin/user/attribute/group/context',
      UpdateUserAttributeGroupSort: apiBase + '/api/admin/user/attribute/group/sort',
      GetReducedAttributes: apiBase + '/api/admin/user/reduced/attribute',
      // Learning
      // 'LearnerCourseInfo': classicApiBase + '/elearning/service_courseLearnerdataV1.jsp?courseId={courseId}'
      LearnerCourseInfo: classicApiBase + '/API/learner/course/{courseId}',
      LearnerScoHref: classicApiBase + '/{fullHref}',
      LearnerOfflineEvent: apiBase + '/api/learner/offlineContent/{contentId}/event/v1/{eventId}',
      LearnerCompleteCurriculum: apiBase + '/api/learner/curriculum/{curriculumId}/v1/complete',
      // Self registration
      SelfRegistration: apiBase + '/api/selfreg/v1',
      SelfRegistrationV2: apiBase + '/api/selfreg/v2',
      SelfRegistrationActivation: classicApiBase + '/API/selfreg/activation/v1',
      SelfRegistrationAdmin: apiBase + '/api/admin/v1/selfReg',
      // Self registration - Anonymous
      SelfRegistrationAnonymous: apiBase + '/api/anon/v1/vtp/register',
      // Reports
      MyReports: apiBase + '/api/v1/report',
      ReportStatistics: apiBase + '/api/report/v1/statistics',
      ReportStatisticsByUUID: apiBase + '/api/report/statistics/v1/uuid',
      ReportPreview: apiBase + '/api/report/v1/preview',
      ReportGetFull: apiBase + '/api/report/v1/full',
      ReportGetCurriculum: apiBase + '/api/report/v1/curriculum/{curriculumId}',
      ReportComplete: apiBase + '/api/report/v1/statistics/withData',
      ReportFavorite: apiBase + '/api/report/uuid/{uuid}/v1/favorite',
      ReportTimelineChart: apiBase + '/api/report/widget/v1/learningTime',
      ReportTimelineChartXLSX: apiBase + '/api/report/widget/v1/learningTime/{notBeforeCount}/{notBeforeType:MONTH|QUARTER}/export.xlsx',
      ReportClassic: classicApiBase +
        '/reportGenerationAction.do?dispatch=show&tid={reportTemplateId}&headless=true',
      ReportClassicDashboard: classicApiBase +
        '/reportGenerationAction.do?dispatch=showDashboard&tid={reportTemplateId}&headless=true',
      SaveReport: apiBase + '/api/v1/report',
      DeleteReport: apiBase + '/api/report/v1',
      Export: apiBase + '/api/report/v1',
      ReportColumnSettings: apiBase + '/api/table/report/column/v2/{targetType}/forUser',
      ReportColumnSettingsUpdateGet: apiBase + '/api/table/report/column/v2/{targetType}/forUpdate',
      CtrlCurriculumLastToCurrent: classicApiBase + '/API/curriculum/{curId}/{userId}/lastascurrent/v1',
      CtrlCurriculumValidityDates: classicApiBase + '/API/curCtrl/{curriculumId}/{userId}/{iteration}/validityDates/v1',
      // Controlling - Curriculum Paths
      ReportColumnSettingsUpdatePost: apiBase + '/api/table/report/column/config/{targetType}',
      // Controlling - Curriculum Paths
      CtrlCurriculumPaths: apiBase + '/api/ctrl/curriculum/v1/paths',
      AminCurriculumPathInfos: apiBase + '/api/admin/curriculum/v1/{curriculumId}/pathInfos',
      AminCurriculumPaths: apiBase + '/api/admin/curriculum/v2/{curriculumId}/paths',
      AdminCurriculumPathPhases: apiBase + '/api/admin/curriculum/v2/{curriculumId}/path/{pathId}/phases',
      AdminSaveCurriculumPathPhases: classicApiBase + '/API/curriculum/{curriculumId}/path/{pathId}/phases/v1',
      AminCurriculumPaths_LegacySave: classicApiBase + '/API/curriculumPaths/v1/{curriculumId}',
      AdminCurriculumPath: apiBase + '/api/admin/curriculum/v1/{curriculumId}/path/{pathId}/{status}',
      AdminCurriculumPathPerTg: classicApiBase + '/API/curricula/{curriculumId, NUMBER}/paths/config/v1',
      AdminCurriculumAdvSettings: apiBase + '/api/admin/curriculum/v1/{curriculumId}/adv/settings',
      AdminCurriculumAdvSettingsClassic: classicApiBase + '/API/curricula/{curriculumId}/behaviour/{behaviourId}/level/{level}/v1',
      AdminCurriculumBehaviours: classicApiBase + '/API/curricula/{curriculumId}/behaviours/bulk/v1',
      AdminCurriculumGetItems: apiBase + '/api/admin/curriculum/v2/{curriculumId}/items',
      CtrlCurriculumPathSet: apiBase + '/api/ctrl/curriculum/{curriculumId}/path/{variationCounter}/user/v1/{userId}',
      AdminCurriculumContext: apiBase + '/api/admin/curriculum/v1/{curriculumId}/context/{curriculumItemId}',
      ContentInCurriculaUsage: apiBase + '/api/admin/curriculum/v1/content/{objType}/{objId}/usage',
      // Controlling - SingleEmployee
      CtrlUser: apiBase + '/api/ctrl/user/v1/{userId}/course/{courseId}',
      CtrlUserCourseInCur: apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/course/{courseId}',
      CtrlSingleUser: classicApiBase +
        '/navigationAction.do?dispatch=navigateTo&navigationTarget=singleUserCtrl&headless=true',
      CtrlSingleUsers: apiBase + '/api/ctrl/v1/learner',
      CtrlSingleUserDetails: apiBase + '/api/ctrl/learner/v1/{userId}',
      CtrlSingleUserDetailsClassic:
        classicApiBase + '/UsersInfoAction.do?dispatch=singleUserCtrlOverview&v1=true&headless=true&userId={userId}',
      CtrlSingleUserClassicItemCourse:
        classicApiBase + '/DetailsAction.do?dispatch=showDetails&view=ui&UID={userId}&detailsID={targetId}&showConfirmation=false&headless=true',
      CtrlSingleUserClassicItemCurriculum:
        classicApiBase + '/curriculumContextItemAction.do?dispatch=controlContextItemAccount&id={targetId}&userId={userId}&currId={curriculumId}&iteration={iteration}&headless=true',
      CtrlSingleUserClassicItemOffline:
        classicApiBase + '/EacademyLearnAccountAction.do?dispatch=showDetailsStatusOfflineCourse&uid={userId}&courseId={targetId}&navigation=controlling&headless=true',
      CtrlSingleUserClassicItemVRoom:
        classicApiBase + '/ControllingAction.do?dispatch=showControllingVRoomsDetails&userID={userId}&contentID={targetId}&headless=true',
      CtrlSingleUserSetStatus: apiBase + '/api/ctrl/courses/v1/user/{userId}',
      CtrlSingleUserCurrEdit: apiBase + '/api/ctrl/curriculum/v1/user/{userId}/itemAccounts',
      CtrlSingleUserCurrEvents: apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/events',
      CtrlSingleUserCurrRecalculateEvents:
        apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/recalculateEvents',
      CtrlSingleUserCurrNotifications: apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/notifications',
      CtrlSingleUserCurrRecalculateNotifications:
        apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/recalculateNotis',
      CtrlSingleUserCurrRecalculateLocks:
        apiBase + '/api/ctrl/curriculum/{curriculumId}/user/v1/{userId}/resetLocksToPrerequsitesState',
      CtrlSingleUserCertificatesSteering: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/steering',
      CtrlSingleUserIterationValidity: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/iteration/{iteration}/validity',
      CtrlSingleUserCurrIterationAction: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/iteration/{iteration}/action/{action}',
      CtrlSingleUserCurrIterationSave: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/iteration',
      CtrlSingleUserCurrIterationCertify: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/certify/{certifyDate}',
      CtrlSingleUserCourseReset: apiBase + '/api/ctrl/user/v1/{userId}/course/{courseId}/reset',
      CtrlSingleUserCourseDetailsClassic:
        classicApiBase + '/DetailsAction.do?dispatch=showDetails&view=ui&headless=true&UID={userId}&detailsID={courseId}&showConfirmation=false',
      CtrlSingleUserValiditySave: apiBase + '/api/ctrl/certificates/v1/curriculum/{curriculumId}/user/{userId}/validity',
      // Controlling - Scorm Log
      UserScormLog: apiBase + '/api/scormlog/v1/user/{userId}?dateFormat=YYYY-MM-dd%20HH:mm:ss',
      UserAndScoScormLog: apiBase + '/api/scormlog/v1/user/{userId}/sco/{scoId}',
      // Controlling - interaction pdf-export
      UserInteractionsReport: classicApiBase + '/interactionsReportPdf.jsp?courseId={courseId}&userId={userId}',
      CtrlSingleUserInteractions: apiBase + '/api/user/{userId}/sco/v1/{scoId}/interactions',
      PdfFromHTML: classicApiBase + '/pdfFromHTMLAction.do?dispatch=getPDF&orientation=landscape&windowstatus=pdfGenContinue&filename={filename}&url={fullURL}',
      // Controlling - Zuep
      CtrlSingleLists: classicApiBase +
        '/navigationAction.do?dispatch=navigateTo&navigationTarget=reportingService&headless=true',
      // Controlling - Zuep
      CtrlZuep: classicApiBase +
        '/navigationAction.do?dispatch=navigateTo&navigationTarget=zuep&headless=true',
      // Controlling - Zuep
      CtrlFurtherBoards: classicApiBase +
        '/customViewAction.do?show=zuepList&headless=true',
      // Controlling - Officials Dashboards
      CtrlFad: classicApiBase +
        '/navigationAction.do?dispatch=navigateTo&navigationTarget=fad&headless=true',
      // Transactions
      GetTransactions: apiBase + '/api/learner/v1/purchases',
      // LinkedUser
      MyLinkedUsers: classicApiBase + '/API/mylinked/v1',
      // Switch User
      GetAuthTokenFromUserId: classicApiBase + '/API/user/authtoken/{uid}/v1',
      GetOwnUUID: classicApiBase + '/API/users/own/personUUID/v1',
      DeleteLinkToUser: classicApiBase + '/API/users/{userId}/personUUID/v1',
      AddLinkToUser: classicApiBase + '/API/users/personUUID/v1',
      // User
      AdminGetUser: apiBase + '/api/admin/v2/user/{userId}',
      AdminGetUserList: apiBase + '/api/admin/v2/user',
      GetUser: apiBase + '/api/user/v1/{userId}',
      UserSettings: apiBase + '/api/user/v1/settings',
      UserColumnSettings: apiBase + '/api/user/v1/settings/columns',
      AccountColumnSettings: apiBase + '/api/user/v1/settings/columns/default',
      UserCourse: apiBase + '/api/user/{userId}/course/v2/{courseId}',
      UserCourseClassic: classicApiBase + '/DetailsAction.do?dispatch=showDetails&view=ui&UID={userId}' +
        '&detailsID={courseId}&showConfirmation=false&headless=true',
      UserCurriculum: apiBase + '/api/user/{userId}/curriculum/v2/{curriculumId}',
      UserCurriculumClassic: classicApiBase + '/curriculumCtrlAction.do?dispatch=showCurriculumDetails' +
        '&userId={userId}&curriculumId={curriculumId}&headless=true',
      AdminUserClassic: classicApiBase + '/NaviAction.do?navi=add_edit_user&action=Edit&oid={userId}&headless=true',
      AdminUserClassic_Reactivate: classicApiBase + '/userAdministrationAction.do?dispatch=reactivateUser&uid={userId}&headless=true"',
      AdminUserClassic_New: classicApiBase + '/NaviAction.do?navi=add_edit_user&action=Add&gid={groupId}&from=null&headless=true',
      AdminUserClassic_UserGroups: classicApiBase + '/API/usergroups/tree/v1',
      AdminClassicGetUser: classicApiBase + '/API/users/{userid}/v1?timeformat=HH:mm',
      GetUsersForDisplay: apiBase + '/api/admin/v1/user/view',
      AdminEditUser: apiBase + '/api/admin/v3/user/{userId}/admin',
      // 'AdminClassicCreateUser': classicApiBase + '/API/users/v1?gid={groupId}',
      AdminCreateUser: apiBase + '/api/admin/v3/user/group/{groupId}/admin',
      AdminClassicGetUserMessaging: classicApiBase + '/API/users/{userid}/messaging/v1',
      AdminClassicGetUserLinkedUsers: classicApiBase + '/API/users/{userid}/linked/v1',
      AdminGetUserFields: apiBase + '/api/admin/user/v2/attribute',
      AdminGetUserV2: apiBase + '/api/admin/v3/user/{userId}',
      AdminGetUserForAdministration: apiBase + '/api/admin/v3/user/{userId}/admin',
      AdminActivateUser: classicApiBase + '/API/users/{userid}/reactivate/v2',
      AdminDeactivateUser: classicApiBase + '/API/users/{userid}/v1',
      AdminUserNotifications: classicApiBase + '/API/users/{userid}/notifications/v2',
      UserExists: apiBase + '/api/user/v1/usernameexists/{username}',
      UserNameExistance: apiBase + '/api/user/v2/login/{username}',
      // User Imports
      AdminGetAllUserImports: apiBase + '/api/admin/v1/user/imports',
      AdminGetCSVHeader: apiBase + '/api/admin/v1/user/imports/csv/{fileUUID}/{encoding}',
      AdminPersistUserImport: apiBase + '/api/admin/v1/user/imports',
      AdminValidateUserImport: apiBase + '/api/admin/v1/user/imports/{importUUID}',
      AdminArchiveUserImport: apiBase + '/api/admin/v1/user/imports/{importUUID}/archive',
      AdminDeleteUserImport: apiBase + '/api/admin/v1/user/imports/{importUUID}/delete',
      AdminGetUserImport: apiBase + '/api/admin/v1/user/imports/{importUUID}',
      StartUserImport: apiBase + '/api/admin/v1/user/imports/{importUUID}/run',
      AdminGetUserDiagnosis: apiBase + '/api/admin/user/diagnose/v1/{diagnosisType}',
      AdminGetUserImportReport: apiBase + '/api/admin/v1/user/imports/{importUUID}/report',
      AdminGetUserImportJournal: apiBase + '/api/admin/v1/user/imports/{importUUID}/journal',
      AdminGetUserImportOddUsers: apiBase + '/api/admin/v1/user/imports/{importUUID}/oddUsers',
      AdminGetUserImportCounts: apiBase + '/api/admin/v1/user/imports/{importUUID}/counts',
      AdminGetUserImportDeactivateUsers: classicApiBase + '/API/users/imports/deactivate/v1',
      AdminUserReduced: classicApiBase + '/userAdministrationAction.do?dispatch=reducedList&&headless=true',
      // User LMS IMport
      UserLMSImport: apiBase + '/api/admin/v1/user/lms/import',
      // TargetGroups
      AdminCopyTargetGroups: classicApiBase + '/API/targetgroups/{tgid}/copy/v1',
      AdminDeleteTargetGroups: classicApiBase + '/API/targetgroups/{tgid}/v1',
      AdminTargetGroups: apiBase + '/api/admin/targetgroup/v1',
      AdminTargetGroup: apiBase + '/api/admin/targetgroup/v1/{targetGroupId}',
      AdminTargetGroupsClassic: classicApiBase + '/targetGroupAction.do?dispatch=showDetails&TGID={targetGroupId}&headless=true',
      AdminTargetGroupsClassic_New: classicApiBase + '/targetGroupAction.do?dispatch=add&headless=true',
      AdminGetUserAssignments: apiBase + '/api/admin/dist/v1/user/{userId}/dist',
      AdminGetTargetGroupAssignments: apiBase + '/api/admin/dist/v1/targetgroup/{targetGroupId}/dist',
      AdminGetUserTargteGroups: apiBase + '/api/admin/targetgroup/v1/user/{userId}',
      // User groups
      Admin_UserGroups: apiBase + '/api/admin/group',
      // Roles
      GetAdminUserRoles: apiBase + '/api/admin/roles/v1/user/{userId}',
      GetTargetGrouprRoles: apiBase + '/api/admin/roles/v1/targetgroup/{targetGroupId}',
      GetTargetGroupsAndUsers: apiBase + '/api/admin/targetgroup/v1/users',
      UpdateTargetGroupUsers: apiBase + '/api/admin/targetgroup/v1/{targetGroupId}/users',
      GetTargetGroupUsers: apiBase + '/api/admin/targetgroup/v1/{targetGroupId}/users',
      GetTargetGroupUserPreview: apiBase + '/api/admin/targetgroup/v2/results',
      FetchAllRoles: apiBase + '/api/admin/roles/v1',
      AdminUserRolesEditClassic: classicApiBase + '/roleAdminAction.do?dispatch=showDetails&roleId={roleId}&headless=true',
      AdminUserRolesCreateClassic: classicApiBase + '/roleAdminAction.do?dispatch=show&headless=true',
      AdminUserRolesAssignClassic: classicApiBase + '/roleAdminAction.do?dispatch=assignSave&action=assign&changeAction=Role&roleId={roleId}&headless=true',
      // Files
      Files: apiBase + '/api/files/v1',
      Files_Upload: apiBase + '/api/files/{authType}/v1',
      Files_Upload_V2: apiBase + '/api/files/{authType}/{authRefType}/v2/{fileUUID}',
      Files_Upload_V2_mark_used: apiBase + '/api/files/{fileAuthRefType}/v2/{fileUUID}/use',
      AttachmentsForContent: apiBase + '/api/learner/content/v1/{objType}/{objId}/attachments',
      // Rbac
      GetPermissions: apiBase + '/api/rbac/permissions',
      // Distribution
      DistributionAssignments: apiBase + '/api/admin/dist/content/type/{objectType}/id/{objectId}/v1/assignments',
      DistributionAssignmentObjects: apiBase + '/api/admin/dist/assignmentObjects',
      // Limited Distribution
      DistLimitedAssignments: apiBase + '/api/admin/dist/limited/v1/assignments',
      CurriculumLicenseInfo: apiBase + '/api/admin/dist/limited/info/v1/user/{userId}/curriculum/{curriculumId}',
      // Offline content
      Offline: apiBase + '/api/admin/offline',
      OfflineColumns: apiBase + '/api/admin/offline/v1/columns',
      OfflineColumns_V2: apiBase + '/api/admin/offline/v2/columns',
      OfflineContent: apiBase + '/api/admin/offline/content/{contentId}',
      OfflineContents: apiBase + '/api/admin/offline/content',
      OfflineContentDelete: apiBase + '/api/admin/offline/content/{eventId}/event/{eventScheduleId}',
      OfflineContentsList: apiBase + '/api/admin/offline/v1/list',
      CopyOfflineContent: apiBase + '/api/admin/offline/content/{offlineContentId}/copy',
      OfflineEvent: apiBase + '/api/admin/offline/content/{contentId}/event/{eventId}',
      OfflineAssign: classicApiBase + '/distributionAdminAction.do?dispatch=show&action=assign&changeAction=saveChanges&targetType=lms_offlineCnt&targetId={contentId}&headless=true&name=',
      Providers: apiBase + '/api/admin/offline/provider',
      Location: apiBase + '/api/admin/offline/location',
      Trainer: apiBase + '/api/admin/offline/trainer',
      VirtualRoomAttendance: apiBase + '/api/admin/offline/content/{offlineContentId}/event/{offlineEventId}/attendance',
      AdminCurriculum: classicApiBase + '/navigationAction.do?dispatch=navigateTo&navigationTarget=administrationCurricula&headless=true',
      AdminCurriculumDetails: classicApiBase + '/curriculumAdminAction.do?dispatch=showDetails&id={curriculumId}&headless=true',
      AdminCurriculumEdit: classicApiBase + '/curriculumAdminAction.do?dispatch=edit&id={curriculumId}&headless=true',
      AdminGetCurriculumCertificates: apiBase + '/api/admin/curriculum/v1/{curriculumId}/certificates',
      AdminGetCurriculumCertificateDetails: apiBase + '/api/admin/curriculum/v1/{curriculumId}/certificate/{certificateId}',
      AdminSaveCertificateTemplate: classicApiBase + '/API/curriculumCertificates/v1/{curriculumId}',
      AdminDeleteCertificateTemplate: classicApiBase + '/API/curriculumCertificates/v1/{certificateId}',
      AdminExportCertificateTemplate: classicApiBase + '/downloadFile/{certificateId}',
      AdminTestCertificateTemplate: classicApiBase + '/GenerateCertificateAction.do?modus=test&tid={certificateId}&ot={curriculumTitle}',
      AdminSaveCertificateTemplateField: classicApiBase + '/API/curriculumCertificateFields/v1/{templateId}',
      AdminGetCurriculumPaths: apiBase + '/api/admin/curriculum/v1/{curriculumId}/paths',
      AdminCurriculumBilling: apiBase + '/api/admin/curriculum/v1/{curriculumId}/billing',
      AdminCurriculumCopy: apiBase + '/api/admin/curriculum/v1/copy/{curriculumId}',
      AdminCurriculumCopyPath: classicApiBase + '/API/curricula/{curriculumId}/paths/{pathIndex}/copy/v2',
      AdminCurriculumRemoveContent: apiBase + '/api/admin/curriculum/v1/{curriculumId}/{objType}/{objId}/remove',
      CtrlOfflineAssign: classicApiBase + '/offlineContentControllingAction.do?dispatch=show&headless=true&eventID={eventId}&contentID={contentId}',
      CtrlOfflineParticipants: apiBase + '/api/ctrl/offline/content/{contentId}/event/{eventId}/participant',
      CtrlOfflineParticipantInvitationStatus: apiBase +
        '/api/ctrl/offline/content/{contentId}/event/{eventId}/invitationStatus',
      CtrlOfflineParticipantParticipationStatus: apiBase +
        '/api/ctrl/offline/content/{contentId}/event/{eventId}/participationStatus/{action}',
      CtrlOfflineParticipantParticipationRequest: apiBase +
        '/api/ctrl/offline/content/{contentId}/event/{eventId}/participationRequest/{action}',
      CtrlOfflineParticipantRegistrationStatus: apiBase +
        '/api/ctrl/offline/content/{contentId}/event/{eventId}/registrationStatus/{registrationStatus}',
      CtrlOfflineParticipantExaminationStatus: apiBase +
        '/api/ctrl/offline/content/{contentId}/event/{eventId}/examinationStatus/{action}',
      CtrlOfflineParticipantListExport: classicApiBase + '/API/pdf/generate/v2',
      PDFGenerateAttendeesList: apiBase + '/api/admin/pdf/generate/attendeeslist',
      PDFGenerateBadge: apiBase + '/api/pdf/generate/badge/{badgeUUID}',
      PDFGenerateUserProfileExport: apiBase + '/api/pdf/generate/user/v1/profile',
      CtrlOfflineTargetGroups: apiBase + '/api/admin/offline/targetGroups',
      // Cockpit
      GetCockpitItems: classicApiBase + '/curriculumAdminAction.do?dispatch=getElementOptions&targetType=lms_offlineCnt',
      CockpitCurriculum: classicApiBase + '/API/curricula/v1',
      GetCurriculumInfo: classicApiBase + '/API/learner/curricula/{curriculumId}/info/v1',
      CustomViewURL: classicApiBase + '/{customViewURL}',
      // Online Agenda
      GetCurricula: classicApiBase + '/API/curricula/v2',
      GetCurriculaV3: classicApiBase + '/API/curricula/v3?includeTags=true&filter=ALL',
      GetCurriculaMeta: apiBase + '/api/admin/curriculum/v1/meta',
      GetCurriculaByStatus: apiBase + '/api/admin/curriculum/status/v1',
      DeleteCurriculum: classicApiBase + '/API/curricula/{currId}/v1',
      GetCurriculum: classicApiBase + '/API/curricula/{currId}/v1',
      // Target Groups
      TargetGroups: apiBase + '/api/targetgroup/v1',
      // Principal
      ResolvePrincipalIds: apiBase + '/api/public/principal/v1',
      // External Login
      ExtLogin: apiBase + '/api/admin/ext/login',
      ExtOAuth2ClientRegistration: apiBase + '/api/admin/oauth/v1/clientRegistration',
      ExtOAuth2ClientRegistrationUpdate: apiBase + '/api/admin/oauth/v1/clientRegistration/{registrationId}',
      ExtOAuth2ClientRegistrationDelete: apiBase + '/api/admin/oauth/v1/clientRegistration/{registrationId}',
      ExtOAuth2ClientRegistrationUsers: apiBase + '/api/admin/oauth/v1/clientRegistration/{registrationId}/user',
      ExtOAuth2WellKnown: apiBase + '/api/admin/oauth/v1/wellKnown?issuerUri={issuerUri}',
      // External Payment
      GetExtDSB: apiBase + '/api/admin/ext/dsb',
      PostExtDSB: apiBase + '/api/admin/ext/dsb',
      DeleteExtDSB: apiBase + '/api/admin/ext/dsb',
      // Adobe Connect
      AC: apiBase + '/api/ac',
      // lms_course
      LearnerCourse: apiBase + '/api/user/course/v1/{courseId}',
      LearnerCourseV2: apiBase + '/api/user/course/v2/{courseId}',
      LearnerCourseInCurriculum: apiBase + '/api/user/curriculum/{curriculumId}/item/{curriculumItemId}/v1/course',
      LearnerCourseStatus: apiBase + '/api/user/course/{courseId}/displayStatus/v1/{displayStatus}',
      LearnerCourseTodo: apiBase + '/api/user/course/v1/{courseId}/todo',
      LearnerCourseTodoCurriculum: apiBase +
        '/api/user/curriculum/{curriculumId}/item/{curriculumItemId}/v1/todo',
      LearnerCourseSaveExtension: apiBase + '/api/user/course/v1/{courseId}/ext/{extensionType}',
      AdminCourse: apiBase + '/api/admin/courses/v1',
      AdminCourseV2: apiBase + '/api/admin/courses/v2',
      AdminCourseDetails: apiBase + '/api/admin/courses/details',
      AdminCourseDeploy: classicApiBase + '/API/courses/v1',
      AdminCourseCreateDetails: classicApiBase + '/API/courses/v2',
      AdminCourseSaveDetails: classicApiBase + '/API/courses/{courseId}/v1',
      AdminCourseSaveDetailsNG: apiBase + '/api/admin/courses/v1',
      AdminCourseRedeploy: classicApiBase + '/API/courses/{courseId}/redeploy/v1',
      AdminCourseRedeploy_ng: apiBase + '/api/admin/courses/{courseId}/redeploy/v1',
      AdminCourseGetDetails: apiBase + '/api/admin/courses/v3/{courseId}',
      AdminCourseGetCurricula: classicApiBase + '/API/courses/{courseId}/curricula/v1',
      AdminCoursesGetTagsLegacy: classicApiBase + '/API/tags/tag/course/{courseId}/v1',
      AdminCourseGetAllAvailableTags: classicApiBase + '/API/courses/tags/v1',
      AdminCourseGetNotifications: classicApiBase + '/API/events/{moduleId}/{itemsModuleId}/{courseId}/v1',
      AdminCourseDeleteTag: classicApiBase + '/API/tags/tag/course/{courseId}/{tag}/v1',
      AdminCourseRemoveSuspendData: classicApiBase + '/API/ctrl/course/{courseId}/sessiondata/v1',
      AdminCourseRemoveAssignments: classicApiBase + '/distributionAdminAction.do?dipatch=ajaxUnassignContent&targetId={targetId}&targetType={targetTyoe}',
      AdminCourseGetRedeploy: apiBase + '/api/admin/courses/{courseId}/redeploy/v1',
      AdminCourseGetSettings: classicApiBase + '/API/courses/{courseId}/settings/v1',
      AdminCourseGetOther: classicApiBase + '/API/API/courses/{courseId}/misc/v1',
      AdminCourseNotificationCreatePage: classicApiBase + '/eventsAction.do?dispatch=show&headless=true',
      AdminCourseNotificationEditPage: classicApiBase + '/eventsAction.do?dispatch=edit&goToOverview=true&eactid={notificationId}&activeTab=courseNotis&headless=true',
      AdminCourseNotificationInfoPage: classicApiBase + '/eventsAction.do?dispatch=showDetails&actionID={notificationId}&activeTab=courseNotis&headless=true',
      AdminCourseList: apiBase + '/api/admin/v1/courses',
      AdminCourseList_v2: apiBase + '/api/admin/v2/courses',
      AdminCourseArchive: apiBase + '/api/admin/courses/v1/archive',
      AdminCourseDelete: apiBase + '/api/admin/courses/v1/delete',
      AdminCourseUsage: apiBase + '/api/admin/courses/v1/usage',
      AdminCourseSetSupervisor: apiBase + '/api/admin/content/v1/user/relation',
      AdminCourseRemoveSupervisor: apiBase + '/api/admin/content/v1/user/relation/{relationUUID}',
      AdminCourseGetPreviewUrl: classicApiBase + '/elearning/course/coursePreview/{href}',
      AdminDownloadScoZip: classicApiBase + '/DeployCoursesAction.do?dispatch=singleScoZipDownload&deployable=true&id={courseId}',
      // handbook
      UserHandbookSettings: apiBase + '/api/v1/handbook',
      AdminHandbookSettings: apiBase + '/api/admin/v1/handbook',
      // Admin Signatures V2
      GetAllSignaturesV2: classicApiBase + '/API/signatures/v2',
      GetSignatureDetailsV2: classicApiBase + '/API/signatures/{signatureId}/v2',
      DeleteSignatureV2: classicApiBase + '/API/signatures/{signatureId}/v2',
      SaveSignatureV2: classicApiBase + '/API/signatures/{signatureId}/v2',
      CreateSignatureV2: classicApiBase + '/API/signatures/v2',
      CheckMacroExistsV2: classicApiBase + '/API/signatures/macro/{macro}/exist/v2',
      CopySignature: apiBase + '/api/admin/text/macros/v1/{signatureId}/copy',
      // Admin Text Macros
      AdminGetAllTextMacros: apiBase + '/api/admin/text/macros/v1/type/all',
      AdminGetTextMacroDetails: apiBase + '/api/admin/text/macros/v1/details/{textMacroId}',
      AdminSaveTextMacro: apiBase + '/api/admin/text/macros/v1',
      AdminDeleteTextMacro: apiBase + '/api/admin/text/macros/v1/delete/{textMacroId}',
      AdminCheckTextMacroExists: apiBase + '/api/admin/text/macros/v1/exists/{textMacro}',
      // Admin Notifications
      AdminNotificationsList: classicApiBase + '/eventsAction.do?dispatch=getEventsJSON',
      AdminNotificationsDeliveryInformation: classicApiBase + '/eventsAction.do?dispatch={dispatchString}&type=all&id={notificationId}',
      AdminNotificationsCopy: classicApiBase + '/eventsAction.do?dispatch=copyEventAction&actionID={eventActionId}&titlePrefix={copyOf}',
      AdminNotificationsChangeEventAction: classicApiBase + '/eventsAction.do?dispatch=editEventActionJSON&actionID={eventActionId}&eventModuleID={eventModuleId}&eventID={eventId}',
      // 'AdminNotificationsDetailsPage': classicApiBase + '/eventsActions.do?dispatch=edit&goToOverview=true&eactid={notificationId}&headless=true',
      AdminEventActionsSubTargets: classicApiBase + '/eventsAction.do?dispatch=getEventActionOptionsJSON&moduleId={moduleId}&targetId={targetId}',
      // Content
      AdminGetContentsByType: apiBase + '/api/admin/dist/content/type/{distType}',
      ImageContentReference: apiBase + '/api/admin/content/v1/{objType}/{objId}/imageref',
      // account-wide javascript
      AccountScriptAdmin: apiBase + '/api/admin/v1/accountScript',
      AccountScriptUser: apiBase + '/api/config/v1/accountScript',
      // contributions
      Contribution: apiBase + '/api/contr/v1/{contrId}',
      // Text modules macros
      // 'TextModulMacros': classicApiBase + '/API/signatures/textmodules/v1',
      TextMacroSettings: apiBase + '/api/macro/v1/{macroContext}',
      CourseMacroTexts: apiBase + '/api/macro/v1/courses/texts',
      // User certificate upload
      UploadPdfCertificateActionV1: classicApiBase + '/curCtrlAction.do',
      // SetF2FStatus
      LogAttendance: apiBase + '/api/ctrl/offline/content/{contentId}/event/{eventId}/attending?by={principalId}',
      // Short Urls
      GetShortUrlExists: apiBase + '/api/admin/url/exists/{shortUrl}',
      GetRedirectByShortUrl: apiBase + '/api/public/catalog/url/v1/{shortUrl}',
      // Catalog
      CatalogCleanUp: apiBase + '/api/catalog/v2/admin/{catalogUUID}/clean' ,
      CatalogList: apiBase + '/api/public/catalog/v1/list',
      CatalogList_V2: apiBase + '/api/catalog/v2/admin',
      CatalogSetItemVisibility: apiBase + '/api/catalog/v2/admin/{uuid}/item/{objectType}/{objectId}/visibility',
      CatalogPublicBook: apiBase + '/api/public/catalog/v1/book',
      CatalogPublicBook_v2: apiBase + '/api/public/catalog/v2/book',
      CatalogBook: apiBase + '/api/catalog/v1/book',
      CatalogBook_v2: apiBase + '/api/catalog/v2/book',
      CatalogDetails: apiBase + '/api/public/catalog/v1/details/{targetType}/{targetId}',
      CatalogFinishBooking: classicApiBase + '/API/catalog/book/event/v1',
      CatalogEvents: apiBase + '/api/public/catalog/v1/events/{objectType}/{objectId}',
      CatalogAdd: apiBase + '/api/public/catalog/v1/add',
      CatalogState: apiBase + '/api/public/catalog/v1/state',
      CatalogEntry: apiBase + '/api/public/catalog/v1/entry/{objectType}/{objectId}',
      CatalogEntryPublished: apiBase + '/api/catalog/v1/entry/{targetType}/{targetId}/published',
      CatalogEntryLicensed: apiBase + '/api/public/catalog/v2/entry/{targetType}/{targetId}/licensed',
      CatalogPurchase: apiBase + '/api/catalog/purchase',
      CatalogPurchaseProcess: apiBase + '/api/catalog/purchase/{hashId}/{referenceId}/process',
      CatalogRequiredUserFields: apiBase + '/api/catalog/v1/{objectType}/{objectId}/requiredUserFields',
      CatalogBookingForContentAndUser: apiBase + '/api/catalog/v1/user/{userId}/type/{objType}/object/{objId}/booking',
      // Version Info
      VersionInfoApi: apiBase + '/api/server/v1/info',
      VersionInfoBackEnd: classicApiBase + '/version.json',
      // Process
      Process: apiBase + '/api/public/process/v1',
      // event handling system
      EhsNotification: apiBase + '/api/admin/ehs/module/{eventModule}/content/{targetObjectId}',
      EhsNotificationAdd: classicApiBase + '/eventsAction.do?useClassic=true&headless=false',
      EhsNotificationDefault: apiBase + '/api/admin/ehs/module/OfflineContents/default',
      EhsNotificationDelete: classicApiBase + '/eventsAction.do?dispatch=remove&iactid={actionId}',
      EhsNotificationHistory: classicApiBase + '/API/events/{actionId}/history/v1',
      EhsNotificationHistoryMessage: classicApiBase + '/API/events/{actionId}/user/{userContextUserId}/date/{sendDate}/v1',
      EhsLinkDetails: classicApiBase +
        '/eventsAction.do?dispatch=showDetails&actionID={actionId}&activeTab=offlineNotis&useClassic=true&headless=false',
      EhsLinkEdit: classicApiBase +
        '/eventsAction.do?dispatch=edit&goToOverview=true&eactid={actionId}&activeTab=offlineNotis&useClassic=true&headless=false',
      EhsNotificationStatus: classicApiBase +
        '/eventsAction.do?dispatch=editEventActionJSON&actionID={actionId}&enabled={enabled}',
      EhsSignatures: apiBase + '/api/admin/ehs/module/signatures',
      EhsNotificationTemplates: classicApiBase + '/eventsAction.do?dispatch=getEventActionTemplateJSON',
      EhsNotificationTemplateDetails: classicApiBase + '/eventsAction.do?dispatch=getEventActionTemplateJSON&templateId={ID}',
      // Bookings
      BookingDetails: apiBase + '/api/learner/bookings/{bookingUUID}',
      // Landing Page Settings
      LandingPageSettings: apiBase + '/api/account/settings/landing_page',
      UserEmailAccounts: classicApiBase +
        '/addUsersExtJsonAction.do?dispatch=getJSON&all=true&type=messaging&assignedIDs=',
      CreateNewNotification: classicApiBase + '/API/events/v1',
      NotificationList: classicApiBase + '/API/events/{notificationId}/v1',
      AdminFetchUrlCalls: classicApiBase + '/API/events/{urlCallId}/v1',
      NotificationDeleteFile: classicApiBase + '/API/events/{notificationId}/attachments/{attachmentId}/v1',
      CreateNotificationTemplate: classicApiBase + '/eventsAction.do?dispatch=saveEventActionTemplate',
      DeleteNotificationTemplate: classicApiBase + '/eventsAction.do?dispatch=removeEventActionTemplateJSON&templateId={ID}',
      // Tags
      Tags: apiBase + '/api/tags',
      // Roles
      GetAllRoles:  apiBase + '/api/admin/offline/trainer/roles/v1',
      // Financials
      Financials: apiBase + '/api/admin/financials',
      // Journal
      Journal: apiBase + '/api/journal/type/{entityType}/v2/entityId/{entityId}',
      // Admin cirriculum
      CurriculumAdmin: apiBase + '/api/admin/curriculum/v1/{curriculumId}',
      CurriculumSettings: apiBase + '/api/admin/curriculum/v1/{curriculumId}/settings',
      CurriculumPhases: apiBase + '/api/admin/curriculum/v1/{curriculumId}/phases',
      CurriculumSavePhases: classicApiBase + '/API/curriculum/{curriculumId}/phases/v1',
      FetchPrincipalsForInput: apiBase + '/api/admin/v1/principals/find/{input}',
      // QA
      GetPubQAs: apiBase + '/api/contr/questions/v1/pub',
      GetAllQAs: apiBase + '/api/contr/questions/v1/all',
      GetAllQAsForUser: apiBase + '/api/contr/questions/user/v1',
      GetQAForUUID: apiBase + '/api/contr/question/v1/{uuid}',
      AdminSaveQuestion: apiBase + '/api/contr/question/v1/{uuid}',
      AdminChangeQuestionStatus: apiBase + '/api/contr/question/v1/{questionUUID}/status',
      AdminResetQuestion: apiBase + '/api/contr/question/v1/{questionUUID}/reset',
      SendNewQA: apiBase + '/api/contr/questions/v1',
      AdminExportQAReport: apiBase + '',
      GetAllTagsForType: apiBase + '/api/tags/type/{type}',
      GetCurriculumInvoiceGroups: classicApiBase + '/API/tags/curriculumGroup/v1',
      // Widget
      GamificationWidget: apiBase + '/api/learner/gamification/widget/v1',
      GamificationOverview: apiBase + '/api/learner/gamification/v1',
      AdminGamificationBadgeTemplates: apiBase + '/api/admin/gamification/badge/templates',
      AdminGamificationBadgeTemplateDetails: apiBase + '/api/admin/gamification/badge/template/{badgeTemplateUUID}',
      AdminGamificationDeleteBadgeTemplate: apiBase + '/api/admin/gamification/badge/template/{badgeTemplateUUID}',
      AdminGamificationBadgeTemplateUsages: apiBase + '/api/admin/gamification/badge/template/{badgeTemplateUUID}/usages',
      AdminGamificationSettings: apiBase + '/api/admin/gamification/settings',
      AdminGamificationGetContentConfig: apiBase + '/api/admin/gamification/badge/content/config/{objectType}/{objectId}',
      AdminGamificationContentConfig: apiBase + '/api/admin/gamification/save/badge/content/config',
      AdminGamificationDeleteContentConfig: apiBase + '/api/admin/gamification/delete/badge/content/config/{badgeConfigUUID}',
      AdminGamificationSaveBadgeTemplate: apiBase + '/api/admin/gamification/badge/template',
      // Quest
      AdminQuestContentSettings: apiBase + '/api/admin/quest/content/{objType}/{objId}/v1/settings',
      QuestContentSettings: apiBase + '/api/quest/content/{objType}/{objId}/v1/settings',
      Quests: apiBase + '/api/ctrl/quest/v1',
      // Fields
      AdminContentFields: apiBase + '/api/admin/fields/v1/content/{objType}/{objId}',
      ContentFields: apiBase + '/api/fields/v1/content/{objType}/{objId}',
      // Categories
      AdminCategories: apiBase + '/api/admin/categories/v1',
      Categories: apiBase + '/api/categories/v1',
      // Action Codes
      AddCharge: classicApiBase + '/API/actioncodes/charges/v1',
      UpdateCharge: classicApiBase + '/API/actioncodes/charges/{id}/v1',
      CopyCharge: classicApiBase + '/API/actioncodes/charges/{id}/copy/v1',
      CopyChargeReduced: classicApiBase + '/API/actioncodes/charges/addNewBasedOn/{id}/v1',
      GetAllCharges: classicApiBase + '/API/actioncodes/charges/v1',
      GetCharge: classicApiBase + '/API/actioncodes/charges/{id}/v1',
      EnableCharge: classicApiBase + '/API/actioncodes/charges/{id}/enable/v1',
      DisableCharge: classicApiBase + '/API/actioncodes/charges/{id}/disable/v1',
      EnableActionCode: classicApiBase + '/API/actioncodes/charges/{id}/code/{code}/enable/v2',
      DisableActionCode: classicApiBase + '/API/actioncodes/charges/{id}/code/{code}/disable/v2',
      MarkAsSentActionCode: classicApiBase + '/API/actioncodes/charge/{id}/code/{code}/send/true/v2',
      MarkAsUnsentActionCode: classicApiBase + '/API/actioncodes/charge/{id}/code/{code}/send/false/v2',
      SearchGeneral: classicApiBase + '/API/actioncodes/search/{param}/v1',
      SearchByCode: classicApiBase + '/API/actioncodes/charges/search/code/{param}/v1',
      SearchByCompany: classicApiBase + '/API/actioncodes/charges/search/company/{param}/v1',
      SearchByDeliveryMail: classicApiBase + '/API/actioncodes/charges/search/deliveryMail/{param}/v1',
      SearchByName: classicApiBase + '/API/actioncodes/charges/search/name/{param}/v1',
      // Recordings
      AdminSaveRecording: apiBase + '/api/admin/recording/{recordingId}',
      AdminGetAllRecordings: apiBase + '/api/admin/recording',
      AdminGetRecordingById: apiBase + '/api/admin/recording/{recordingId}',
      AdminRecordingOfflineContents: apiBase + '/api/admin/recording/offlineContent',
      AdminRecordingParticipants: apiBase + '/api/admin/recording/{uuid}/participant',
      AdminRecordingPrices: apiBase + '/api/admin/recording/{uuid}/price',
      AdminDeleteRecording: apiBase + '/api/admin/recording/{recordingId}',
    };
  }
}
