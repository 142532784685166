import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { ContentOverviewComponent } from './content-overview.component';
import { AccountResolverService } from '../../../core/resolvers/account-resolver.service';
import { UserSettingsResolverService } from 'src/app/core/user-settings-resolver.service';

export const ContentOverviewRoutes: Routes = [ {
  path: 'content-overview',
  component: ContentOverviewComponent,
  canActivate: [ AuthGuard ],
  data: {
    checkPermissions: 'navUserLearn',
    settingsContext: 'Content_Overview_UserSettings'
  },
  resolve: {
    content: AccountResolverService,
    userSettings: UserSettingsResolverService,
  },
}, {
  path: 'content-overview/:assignment',
  component: ContentOverviewComponent,
  canActivate: [ AuthGuard ],
  resolve: { content: AccountResolverService },
  data: {
    checkPermissions: 'navUserLearn',
  },
} ];

@NgModule({
  imports: [
    RouterModule.forChild(ContentOverviewRoutes),
  ],
  exports: [
    RouterModule,
  ],
})
export class ContentOverviewRoutingModule {
}
