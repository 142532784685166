import { Core, Distributable } from './core.types';
import { Router, UrlTree } from '@angular/router';
import { AccountInterceptor } from './interceptors/account.interceptor';


export class UrlHelper {

  static defaultContentUrl = (id: number) => '/content/' + id;

  static execUrlFor(distObject: {
    objType: Core.DistributableType,
    objSubType?: Core.CourseType,
    id: number
  }): string | null {
    if (distObject == null) {
      return null;
    }
    switch ( distObject.objType ) {

      case Core.DistributableType.lms_offlineCnt:
        return '/run/offline/' + distObject.id;

      case Core.DistributableType.lms_course:

        switch ( distObject.objSubType ) {
          case Core.CourseType.ToDo:
            return '/run/todo/' + distObject.id;
          default:
            return this.defaultContentUrl(distObject.id);
        }

      default:
        return this.defaultContentUrl(distObject.id);
    }
  }

  static getBaseUrl(): string {
    let context = '';
    if ( window.location.href.includes('/frontend/') ) {
      context = '/frontend/';
      // todo make context dynamic
    } else {
      context = '/';
    }
    return `${window.location.origin}${context}`;
  }

  /**
   * @param redirectUrl The redirectUrl will be encoded as URL parameter. You should not include the # at the start.
   */
  static getPublicRedirect(
    redirectUrl: string,
  ): string {
    const accountKey = AccountInterceptor.getAccountKey();
    const url = encodeURIComponent(redirectUrl);
    return `${UrlHelper.getBaseUrl()}#/login/redirect?key=${accountKey}&url=${url}`;
  }

  static getQueryParams(urlTree: UrlTree | null): any | null {

    if ( urlTree == null ) {
      return null;
    }

    return urlTree.queryParams;
  }

  static getRouterLink(urlTree: UrlTree | null): string | null {

    if ( urlTree == null ) {
      return null;
    }

    const url = urlTree.toString();
    return url.split('?', 1)[0];
  }

  static getUrlTree(router: Router, value: string | string[] | null): UrlTree | null {

    if ( !value ) {
      return null;
    }

    if ( typeof (value) === 'string' ) {
      return router.parseUrl(value);
    }
    if ( Array.isArray(value) ) {
      return router.createUrlTree(value);
    }

    return null;
  }

}
