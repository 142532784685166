import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LearnerContentComponent } from '../../learner-content-util/learner-content.controller';
import { LearnerContentTypes } from '../../learner-content-util/learner-content.types';
import { AccountDesignService } from '../../../../admin/account-design/account-design.service';
import { delay, map, startWith, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { InfoType, MessageKey, NextButton, YesButton, YesNoButtons } from '../../../../../core/info/info.types';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LearnerCourseTodoService } from './learner-course-todo.service';
import { LearnerCourseService } from '../learner-course-util/learner-course.service';
import { Core, FileInfo } from '../../../../../core/core.types';
import { EMPTY, Observable } from 'rxjs';
import { DisplayStatus } from '../../../../../core/display-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoService } from '../../../../../core/info/info.service';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { LearnerAccountTypes } from '../../../../../core/learner-account/learner-account.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { ViewHelper } from '../../../../../core/view-helper';
import { ContentService } from '../../../../../core/content/content.service';
import { DirtyCheckService } from '../../../../../core/dirty-check.service';
import { AdminCoursesTypes } from '../../../../../core/admin-courses.types';
import { LanguageHelper } from '../../../../../core/language.helper';
import { CKEditorDefaults } from '../../../../../core/ckeditor.types';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';
import { FileMultiChangeEvent } from '../../../../../component/file-multi/file-multi.component';
import * as deepEqual from 'fast-deep-equal';
import { LearnerCourseSelfEstimationService } from '../learner-course-self-estimation/learner-course-self-estimation.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { RagCKEditorHelper } from '../../../../../core/input/rag-ckeditor/ragCkeditorHelper';
import { MergeHelper } from '../../../../../core/primitives/merge.helper';

@Component({
  selector: 'rag-learner-course-todo',
  templateUrl: './learner-course-todo.component.html',
  styleUrls: [ './learner-course-todo.component.scss' ],
})
export class LearnerCourseTodoComponent
  extends LearnerContentComponent<LearnerContentTypes.CourseAccountToDo>
  implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('ckeditor') ckeditor: CKEditorComponent;
    @ViewChildren('slider', { read: ElementRef }) slider: QueryList<ElementRef>;

    isReadonly: boolean;
  isSelfAssessmentReadonly: boolean;
  isSaveDisabled = true;
  isSelfAssessmentSaveDisabled = true;
  todoFormGroup: UntypedFormGroup;
  selfEstimationFormGroup?: UntypedFormGroup;
  solutionFiles: Array<FileInfo>;
  extensions: AdminCoursesTypes.CourseExtension[];
  sampleSolution?: AdminCoursesTypes.CourseExtension;
  languageKey: string;
  step = 0;
  extensionsStepsCount = 0;
  observer = new ResizeObserver(entries => {
    entries.forEach(entry => {
      this.flexWidth = entry.contentRect.width;
    });
  });
  flexWidth = 630;
  scala = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];
  selfEstimation?: AdminCoursesTypes.CourseExtension;
  previousSliderElement: Element = null;
  hasSubmitted = false;
  courseId = 0;
  readonly ckOptions = MergeHelper.cloneDeep(CKEditorDefaults.CKEDITOR_OPTIONS);
  public Editor = Editor;

  constructor(
    protected accountDesignService: AccountDesignService,
    private dirtyCheckService: DirtyCheckService,
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private learnerCourseSelfEstimationService: LearnerCourseSelfEstimationService,
    private learnerCourseTodoService: LearnerCourseTodoService,
    private learnerCourseService: LearnerCourseService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(accountDesignService);
    this.languageKey = LanguageHelper.getCurrentLanguage().key;

    this.route.params
      .subscribe(params => {
        const courseId = parseInt(params['itemId'], 10);
        if ( (this.courseId > 0) && (this.courseId !== courseId) ) {
          // if the user navigate to another course todo page -> force reload
          this.forceReload();
        }
        this.courseId = courseId;
      });
  }

  get fileUploadRequired(): boolean {
    if ( this.learningUnit?.contribution?.files?.length > 0 ) {
      return false;
    }
    return (this.learningUnit?.acceptanceMask & LearnerAccountTypes.CourseTodoAcceptanceFileUploadRequired) > 0;
  }

  get showFileUpload(): boolean {
    return (this.learningUnit?.acceptanceMask & LearnerAccountTypes.CourseTodoAcceptanceFileUploadRequired) > 0;
  }

  get hasSelfEstimationText() {
    const selfEstimationComment = this.selfEstimation?.commitment?.commitment?.comment;
    return selfEstimationComment != null && selfEstimationComment !== '';
  }

  get hasTask(): boolean {
    return this.hasTaskAttachments || this.hasTaskDescription;
  }

  get hasTaskAttachments(): boolean {
    return this.learningUnit?.attachments?.length > 0;
  }

  get hasTaskDescription(): boolean {
    return this.taskDescription?.length > 0;
  }

  get isPublished(): boolean {
    return DisplayStatusHelper.isStatusGreen(this.learningUnit?.displayStatus) || this.learningUnit?.contribution?.status === LearnerContentTypes.PublishStatus.published;
  }

  get isSubmitDisabled(): boolean {
    return this.isReadonly ||
      this.isPublished ||
      this.todoFormGroup?.dirty ||
      this.todoFormGroup?.invalid ||
      (this.textInputRequired && (this.learningUnit?.contribution == null));
  }

  get taskDescription(): string {
    return LanguageHelper.objectToText(this.learningUnit?.taskDescription) || '';
  }

  get sampleSolutionText() {
    return LanguageHelper.objectToText(this.sampleSolution?.text, this.languageKey);
  }

  get textInputRequired(): boolean {
    if ( !!this.learningUnit?.contribution?.contribution ) {
      return false;
    }
    return (this.learningUnit?.acceptanceMask & LearnerAccountTypes.CourseTodoAcceptanceTextInputRequired) > 0;
  }

  private static getAssignmentType(assignmentMandatory: boolean): string {
    if ( assignmentMandatory === true ) {
      return 'mandatory';
    } else if ( assignmentMandatory === false ) {
      return 'voluntary';
    } else {
      return null;
    }
  }

  private static toggleFormControlRequired(control: AbstractControl, required?: boolean): void {
    if ( (required === true) ) {
      control.setValidators([ Validators.required ]);
    } else if ( required === false ) {
      control.setValidators([]);
    }
  }

  ngOnInit(): void {
    this.learnerCourseService.learningUnit$
      .pipe(tap(data => this.setLearningUnit(data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.observer.disconnect();
    destroySubscriptions(this);
  }

  ngAfterViewInit() {
    if ( this.slider != null ) {
      this.slider.changes
        .pipe(startWith(null))
        .pipe(tap(() => {
          this.slider
            .map(items => items?.nativeElement)
            .map(nativeElement => {
              if ( this.previousSliderElement != null ) {
                this.observer.unobserve(this.previousSliderElement);
              }
              this.previousSliderElement = nativeElement;
              this.observer.observe(nativeElement);
            });
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }
    this.ckeditor.disabled = this.isReadonly;
  }

  getCompletedStatus(stepName: LearnerContentTypes.ExtensionType): boolean {

    if ( stepName === LearnerContentTypes.ExtensionType.Estimation ) {
      if (this.getEstimationStep() === 2) {
        return this.hasSubmitted && this.isStatusGreen();
      }
      return this.isSelfAssessmentReadonly && this.isStatusGreen();
    }

    if ( stepName === LearnerContentTypes.ExtensionType.SampleSolution ) {
      if ( this.getSampleSolutionStep() === 1 ) {
        return this.hasSubmitted && this.isStatusGreen();
      }
      if ( this.hasSubmitted ) {
        return this.isSelfAssessmentReadonly && this.isStatusGreen();
      }
      return false;
    }
    return false;
  }

  getEstimationStep(): number {
    if ( this.learningUnit.extensions[0] === LearnerContentTypes.ExtensionType.SampleSolution ) {
      if ( this.learningUnit.extensions[1] === LearnerContentTypes.ExtensionType.Estimation ) {
        return 2;
      }
    } else if ( this.learningUnit.extensions[0] === LearnerContentTypes.ExtensionType.Estimation ) {
      return 1;
    }
    return -1;
  }

  getFiles(): FileInfo[] {
    const files = this.sampleSolution?.files;
    if ( !(files?.length > 0) ) {
      return null;
    }
    return files;
  }

  getLabelText(step: LearnerContentTypes.ExtensionType): string {
    switch ( step ) {
      case LearnerContentTypes.ExtensionType.SampleSolution:
        return $localize`:@@global_sample_solution:Sample solution`;
      case LearnerContentTypes.ExtensionType.Estimation:
        return $localize`:@@global_self_assessment:Self-Assessment`;
    }
  }

  getMarginForSlider(margin: string) {
    if ( this.flexWidth <= 560 ) {
      switch ( margin ) {
        case 'bottom':
          return '16px';
        case 'top':
          return '16px';
      }
    } else {
      switch ( margin ) {
        case 'bottom':
          return '-10px';
        case 'top':
          return '16px';
      }
    }
  }

  getSampleSolutionStep(): number {
    if ( this.learningUnit.extensions[0] === LearnerContentTypes.ExtensionType.Estimation ) {
      if ( this.learningUnit.extensions[1] === LearnerContentTypes.ExtensionType.SampleSolution ) {
        return 2;
      }
    } else if ( this.learningUnit.extensions[0] === LearnerContentTypes.ExtensionType.SampleSolution ) {
      return 1;
    }
    return -1;
  }

  getSaveButtonDisabledState(): boolean {
    if ( this.step === 0 ) {
      return this.isSaveDisabled;
    } else {
      return this.isSelfAssessmentSaveDisabled || (
        this.selfEstimationFormGroup?.get('slider').pristine &&
        this.selfEstimationFormGroup.get('comment').pristine
      );
    }
  }

  getSliderStyles(): any {
    return {
      'margin-bottom': this.getMarginForSlider('bottom'),
      'margin-top': this.getMarginForSlider('top'),
      width: this.getWidthAndHeightForSlider('width'),
      height: this.getWidthAndHeightForSlider('height'),
    };
  }

  getStepControl(stepName: LearnerContentTypes.ExtensionType): UntypedFormGroup {
    if ( stepName === LearnerContentTypes.ExtensionType.Estimation ) {
      if ( this.isSelfAssessmentReadonly && this.isStatusGreen() ) {
        return this.selfEstimationFormGroup;
      } else {
        return null;
      }
    }
    return null;
  }

  getStyles(value: number): any {
    return {
      'font-weight': this.isBold(value) ? 'bold' : null,
      'font-size': this.isBold(value) ? '16px' : null,
      transform: this.isBold(value) ? 'translate(0,-3px)' : null,
      'text-decoration': this.isBold(value) ? 'underline' : null,
    };
  }

  getWidthAndHeightForSlider(value: string) {
    if ( this.flexWidth <= 560 ) {
      switch ( value ) {
        case 'width':
          return '50px';
        case 'height':
          return '150px';
      }
    } else {
      switch ( value ) {
        case 'width':
          return '550px';
        case 'height':
          return '50px';
      }
    }
  }

  getSampleSolutionInfoTooltip() {
    return $localize`:@@learner_course_todo_not_graded_sample_solution_text:The sample solution can be viewed only after the task has been successfully solved.`;
  }

  getStepControlForTodo(): UntypedFormGroup | null {
    if (this.step === this.getSampleSolutionStep() - 1) {
      return (this.hasSubmitted && this.isStatusGreen()) ? this.todoFormGroup : null;
    } else {
      return this.hasSubmitted ? this.todoFormGroup : null;
    }
  }

  getTodoControlCompletedStatus() {
    if (!this.hasSubmitted) {
      return false;
    }
    if (this.getEstimationStep() === 1) {
      return true;
    } else if (this.getSampleSolutionStep() === 1) {
      return this.isStatusGreen();
    }
    return false;
  }

  hasSampleSolution(): boolean {
    if ( (this.sampleSolution?.files.length === 0) &&
      (!LanguageHelper.LANGUAGES
        .find(language => LanguageHelper.objectToText(this.sampleSolution?.text, language.key) !== '')) ) {
      return false;
    }
    return true;
  }

  isBold(value: number): boolean {
    return value === this.selfEstimationFormGroup?.controls?.slider?.value;
  }

  isCKEditorDisabled(): boolean {
    return this.isReadonly;
  }

  isOnSampleSolutionPage() {
    return this.step === this.getSampleSolutionStep();
  }

  isOnSelfAssessmentPage() {
    return this.step === this.getEstimationStep();
  }

  isStatusGreen() {
    return DisplayStatusHelper.isStatusGreen(this.learningUnit?.displayStatus);
  }

  nextButtonDisabled(step: number): boolean {

    if ( this.getSampleSolutionStep() === step ) {
      return false;
    } else if ( this.getEstimationStep() === step ) {
      return !this.isStatusGreen() || !this.isSelfAssessmentReadonly;
    } else if ( step === 0 ) {
      if ( this.nextStepIsSampleSolution() ) {
        return !this.hasSubmitted || !this.isStatusGreen();
      } else {
        return !this.hasSubmitted;
      }
    }
    return true;
  }

  nextStepIsSampleSolution(): boolean {
    return this.step === this.getSampleSolutionStep() - 1;
  }

  onFileChange($event: FileMultiChangeEvent): void {
    const control = this.todoFormGroup?.get('files');
    const uuid = $event.file?.uuid;
    if ( (uuid == null) || (control == null) || (this.learningUnit == null) ) {
      return;
    }

    const solutionFiles = this.solutionFiles || [];
    const index = solutionFiles.findIndex(file => file.uuid === uuid);
    const hasFile = (index > -1);
    if ( hasFile && ($event.change === 'remove') ) {
      solutionFiles.splice(index, 1);
    } else if ( !hasFile && ($event.change === 'add') ) {
      solutionFiles.push($event.file);
    }

    const changed = !deepEqual(
      (this.learningUnit?.contribution?.files || []).map(file => file.uuid),
      (this.solutionFiles || []).map(file => file.uuid),
    );
    if (changed) {
      control.markAsDirty();
    } else {
      control.markAsPristine();
    }

    control.markAsTouched();

    control.setValue(solutionFiles.length > 0 ? solutionFiles : null);
  }

  onBackStep(stepper: MatStepper) {
    stepper.previous();
  }

  onNextStep(stepper: MatStepper) {
    stepper.next();
  }

  onSave() {
    RagCKEditorHelper.disableSourceEditing(this.ckeditor);
    if ( this.step === 0 ) {
      return this.onSaveContribution();
    } else {
      return this.onSaveEstimation();
    }
  }

  onSaveContribution(): void {
    if ( this.isReadonly || this.isSaveDisabled || !this.todoFormGroup?.dirty ) {
      // nothing to see here
      return;
    }

    const context = this.updateContext;
    if ( context == null ) {
      // nothing to see here
      return;
    }

    const contribution: LearnerContentTypes.Contribution = {
      comment: false,
      contribution: this.todoFormGroup.get('contribution').value,
      creationDate: null,
      id: null,
      lastModified: null,
      status: null,
      targetIteration: null,
      uuid: null
    };

    this.learnerCourseTodoService.saveContribution(context, contribution, this.solutionFiles)
      .pipe(tap(learningUnit => this.learnerCourseService.setLearningUnit(learningUnit)))
      .pipe(take(1))
      .pipe(switchMap(this.updateDisplayStatus))
      .subscribe();
  }

  onSaveEstimation(): void {
    if ( this.isSelfAssessmentSaveDisabled ) {
      // nothing to see here
      return;
    }
    this.infoService
      .showMessage($localize`:@@self_estimation_save_confirm:
        If you save your self-assessment,
        you will not be able to edit it.<br>
        Do you still want to save ?
      `,
        {
          buttons: YesNoButtons,
          title: $localize`:@@global_confirm:Confirm`,
        })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => {
        const commitment = {
          value: this.selfEstimationFormGroup.controls.slider.value,
          comment: this.selfEstimationFormGroup.controls.comment.value,
        };
        return this.learnerCourseSelfEstimationService.saveExtension(commitment, this.learningUnit.id, AdminCoursesTypes.Extensions.Estimation);
      }))
      .pipe(tap(response => {

        const estimationIndex = this.extensions.map(ext => ext?.type)
          .indexOf(AdminCoursesTypes.Extensions.Estimation);
        this.extensions.splice(estimationIndex, 1, {
          type: AdminCoursesTypes.Extensions.Estimation,
          commitment: {
            commitment: {
              comment: response.commit.commitment.comment,
              value: response.commit.commitment.value,
            },
            courseId: this.learningUnit.id,
            type: AdminCoursesTypes.Extensions.Estimation,
            uuid: response.commit.uuid,
          },
        });

        this.learnerCourseService.calculateSampleSolutionDisabledState(this.learningUnit, this.extensions);
        this.learnerCourseService.setLearningUnit(this.learningUnit);
        this.isSelfAssessmentReadonly = true;
        this.isSelfAssessmentSaveDisabled = true;
        this.infoService.showMessage(
          $localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }))
      .subscribe();
  }

  onSubmit(): void {
    const context = this.updateContext;
    if ( context == null ) {
      // nothing to see here
      return;
    }

    this.infoService.showConfirm(MessageKey.LERN_CNT.TODO.SUBMIT, YesNoButtons)
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(_ => this.learnerCourseTodoService.submitContributionForCourse(context)))
      .pipe(tap(displayStatus => {
        if (this.learningUnit.contribution) {
          this.learningUnit.contribution.status = LearnerContentTypes.PublishStatus.published;
        }
        this.learningUnit.displayStatus = DisplayStatusHelper.toDisplayStatus(displayStatus);
        this.hasSubmitted = !DisplayStatusHelper.isStatusRed(this.learningUnit.displayStatus);
        this.isReadonly = true;
        this.ckeditor.disabled = true;
        this.isSaveDisabled = true;
        this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success);
        this.learnerCourseService.setLearningUnit(this.learningUnit);
        if ( this.extensionsStepsCount > 0 ) {
          this.openNextPageDialog();
        }
      }))
      .pipe(take(1))
      .subscribe();
  }
  openNextPageDialog() {
    const nextStepIsSelfEstimation = this.getEstimationStep() === 1;
    if (nextStepIsSelfEstimation || this.isStatusGreen()) {
      this.infoService
        .showMessage($localize`:@@learner_course_todo_next_page_dialog:
              You have successfully submitted your task.<br>
              Clicking "Next" will take you to the next step.`, {
          buttons: NextButton,
          title: $localize`:@@global_next_step:Next Step`,
        })
        .pipe(takeWhile(button => button === NextButton))
        .pipe(tap(() => {
          this.step = this.step + 1;
        }))
        .subscribe();
    }
  }

  selectionChanged($event: StepperSelectionEvent) {
    this.step = $event.selectedIndex;
  }

  private setLearningUnit(learningUnit: LearnerContentTypes.CourseAccountToDo): void {
    this.learningUnit = learningUnit;
    if ( ViewHelper.getViewData(learningUnit)?.extensions != null ) {
      this.extensions = ViewHelper.getViewData(learningUnit)?.extensions;
    } else {
      this.extensions = [];
    }

    this.sampleSolution = this.extensions
      .find(extension => extension.type === AdminCoursesTypes.Extensions.SampleSolution);

    if (this.sampleSolution?.onlyForControlling) {
      this.extensions = this.extensions
        .filter(ext => ext.type !== AdminCoursesTypes.Extensions.SampleSolution);

      this.learningUnit.extensions = this.learningUnit.extensions
        .filter(ext => ext.valueOf() !== AdminCoursesTypes.Extensions.SampleSolution);

      this.sampleSolution = null;
    }

    this.selfEstimation = this.extensions
      .find(extension => extension.type === AdminCoursesTypes.Extensions.Estimation);

    this.buildForms();
    this.hasSubmitted = !DisplayStatusHelper.isStatusRed(this.learningUnit.displayStatus);
    if ( this.learningUnit.extensions[0] != null ) {
      this.extensionsStepsCount = 1;
    }
    if ( this.learningUnit.extensions[1] != null ) {
      this.extensionsStepsCount = 2;
    }

    const viewData = ViewHelper.getViewData(learningUnit);
    this.curriculum = viewData.curriculumAccount;
    this.curriculumItem = viewData.curriculumItem;
    if ( this.curriculumItem ) {
      this.curriculumItemTitle = this.curriculumItem.title || learningUnit?.title;
    }

    this.assignmentType = LearnerCourseTodoComponent.getAssignmentType(this.learningUnit?.assignmentMandatory);
    this.setBackUrl(this.curriculum, this.assignmentType);

    if ( learningUnit == null ) {
      this.isReadonly = true;
      this.isSelfAssessmentReadonly = true;
    } else {
      const textInputRequired = learningUnit.acceptanceMask & LearnerAccountTypes.CourseTodoAcceptanceTextInputRequired;
      const contributionSetAndMoreThanDraft = learningUnit.contribution != null &&
        (learningUnit.contribution?.status !== LearnerContentTypes.PublishStatus.draft);
      this.isReadonly = contributionSetAndMoreThanDraft ||
        (
          // we have required fields -> text is not a comment
          !textInputRequired &&
          // AND content is locked
          ContentService.isCourseLocked({
            type: Core.DistributableType.lms_course,
            locked: this.learningUnit.locked,
            displaystatus: this.learningUnit.displayStatus,
            repetitions: this.learningUnit.repetitions,
            archived: undefined,
            assignmentType: undefined,
            description: undefined,
            hasDirectAssignment: undefined,
            hideInLearnerAccount: undefined,
            id: undefined,
            items: undefined,
            lastModified: undefined,
            objType: undefined,
            sequentialCur: undefined,
            title: undefined
          })
        );

      this.isSelfAssessmentReadonly = this.hasSelfEstimationText;
    }

    this.solutionFiles = [ ...(learningUnit?.contribution?.files || []) ];

    if ( learningUnit?.contribution?.contribution ) {
      // this line get executed twice which enforce change event propagation which finally
      // enables the save button
      this.getFormControlForTodo('contribution', this.textInputRequired)
        .setValue(learningUnit?.contribution?.contribution, { emitEvent: false });
    } else {
      this.getFormControlForTodo('contribution', this.textInputRequired)
        .setValue('', { emitEvent: false });
    }
    this.getFormControlForTodo('files', this.fileUploadRequired)
      .setValue(this.solutionFiles, { emitEvent: false });

    this.isSaveDisabled = true;
    this.isSelfAssessmentSaveDisabled = true;
    this.todoFormGroup.markAsPristine();
    this.todoFormGroup.markAsUntouched();
    this.dirtyCheckService.submitNextState('LearnerCourseTodoComponent', false);
  }

  private buildForms(): void {
    this.todoFormGroup = this.formBuilder.group({
      contribution: [ '', this.textInputRequired ? [ Validators.required ] : [] ],
      files: [ [], this.fileUploadRequired ? [ Validators.minLength(1) ] : [] ],
    });

    if (this.selfEstimation != null) {
      this.selfEstimationFormGroup = this.formBuilder.group({
        slider: [ this.selfEstimation?.commitment?.commitment.value ?? 5 ],
        comment: [ this.selfEstimation?.commitment?.commitment.comment ?? '', [ Validators.required ] ],
      });

      this.selfEstimationFormGroup.valueChanges
        .pipe(map(() => {
          if ( this.isSelfAssessmentReadonly ) {
            this.isSelfAssessmentSaveDisabled = true;
          } else {
            this.isSelfAssessmentSaveDisabled = !this.selfEstimationFormGroup.controls?.comment?.value.trim();
          }
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }

    this.todoFormGroup.get('contribution').valueChanges
      .pipe(tap(this.emitChangesForTodo))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.todoFormGroup.get('files').valueChanges
      .pipe(tap(this.emitChangesForTodo))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.dirtyCheckService.isDirty$
      .pipe(map(isDirty => {
        this.isSaveDisabled = !isDirty;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateDisplayStatus = (): Observable<unknown> => {
    const courseId = this.learningUnit?.id;

    // todo TF-1356 decide if status should change to DisplayStatus.COMPLETE or DisplayStatus.INCOMPLETE
    const displayStatus = DisplayStatus.COMPLETE;

    // todo TF-1356 implement me!
    return EMPTY
      .pipe(switchMap(() => this.learnerCourseTodoService.updateCourseStatus(courseId, displayStatus)))
      .pipe(take(1))
      // reload page and data after 1s
      .pipe(delay(1000))
      .pipe(tap(() => this.router.navigate(this.route.snapshot.url, {
        queryParams: { _: new Date().getTime() },
        queryParamsHandling: 'merge',
      })));
  };

  private emitChangesForTodo = (): void => {
    if ( (this.todoFormGroup == null) || (this.learningUnit == null) ) {
      return;
    }

    this.dirtyCheckService.submitNextState('LearnerCourseTodoComponent', this.todoFormGroup.dirty);
  };

  private getFormControlForTodo(key: string, required?: boolean): AbstractControl {
    const control = this.todoFormGroup.get(key);
    if ( required != null ) {
      LearnerCourseTodoComponent.toggleFormControlRequired(control, required);
    }
    return control;
  }

  private forceReload() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const onSameUrlNavigation = this.router.onSameUrlNavigation;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([ currentUrl ]).then(
      () => this.router.onSameUrlNavigation = onSameUrlNavigation,
      () => this.router.onSameUrlNavigation = onSameUrlNavigation,
    );
  }
}
