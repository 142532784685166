import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { CachedSubject } from '../../../core/cached-subject';
import { ColumnFilterV2 } from '../../../core/column-settings/column-filter.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { TableColumnMenuService } from '../table-column-menu/table-column-menu.service';
import { TableAccessors } from '../table.accessors';
import { TableControllerTypes } from './table-controller.types';
import { ContentFilterHelper } from '../../content-filter/content-filter.helper';
import { SubscriptionHolder } from '../../../core/reactive/subscription-holder';
import { PivotFilterService } from '../../pivot-filter/pivot-filter.service';


type FilterPredicateCheckCallback = (filter: ColumnFilterV2<string, string>) => boolean;

@Component({
  template: '',
})
export class TableControllerComponent<T,
  C extends TableControllerTypes.ColumnMenuData<T> = TableControllerTypes.ColumnMenuData<T>>
  implements OnDestroy {

  @Output() readonly afterPaginatorSet: Observable<MatTableDataSourcePaginator>;
  readonly columnMenuData$: Observable<C>;
  columns: string[];
  columnsFilter: string[];
  dataSource: MatTableDataSource<T>;
  readonly dataSourceSortChange$: Observable<Sort>;
  /**
   * disable default sorting by setting defaultSort to '' in constructor
   */
  defaultSort: string;
  defaultSortDirection: SortDirection = 'asc';
  inputDisabled = true;
  isColumnContextLoaded = false;
  isFilterActive = false;
  isFilterChanged = false;
  isPaginatorSet = false;
  multiActionsDisabled = true;
  readonly recalculateSticky$: Observable<void>;
  renderColumns: TableControllerTypes.ColumnMenuItem<T>[];
  selection = new SelectionModel<T>(true, []);
  protected _recalculateSticky = new EventEmitter<void>();
  protected readonly _dataSourceSortChange$ = new SubscriptionHolder<Sort>(this);
  private _afterPaginatorSet = new EventEmitter<MatTableDataSourcePaginator>(true);
  private _columnMenuData = new CachedSubject<C>(null);
  private _loading = true;

  constructor(
    protected tableColumnMenuService: TableColumnMenuService,
    dataSource: MatTableDataSource<T> = new MatTableDataSource<T>(),
) {
    this.afterPaginatorSet = this._afterPaginatorSet.asObservable();
    this.columnMenuData$ = this._columnMenuData.withoutEmptyValues();
    this.dataSourceSortChange$ = this._dataSourceSortChange$.value$;
    this.recalculateSticky$ = this._recalculateSticky.asObservable();

    this.dataSource = dataSource;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;
    this.dataSource.filterPredicate = this.filterPredicateDefault;

    this.afterPaginatorSet
      .pipe(tap(value => this.isPaginatorSet = (value != null)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.dataSourceSortChange$
      .pipe(debounceTime(50))
      .pipe(tap(sort => this.tableColumnMenuService.triggerSortChanged(sort)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    tableColumnMenuService.resetToDefaultSettings$
      .pipe(tap(() => this.onResetFilter()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get columnMenuData(): C {
    return this._columnMenuData.value;
  }

  set columnMenuData(value: C) {
    this._columnMenuData.next(value);

    this.setSortFromMenuData(value);
  }

  get isFilteredEmpty() {
    return this.dataSource.filter?.length > 0 &&
      this.dataSource.filteredData?.length === 0;
  }

  get filters(): TableControllerTypes.ColumnOptions<T>[] {
    const menuItems = this.columnMenuData?.menuItems;
    if ( menuItems == null ) {
      return null;
    }

    return Object.values(menuItems)
      .map(column => column?.options)
      .filter(filter => filter != null);
  }

  get hasData() {
    return this.dataSource.data?.length > 0;
  }

  get isDataEmpty(): boolean {
    return !(this.dataSource.data?.length > 0);
  }

  get isLoading(): boolean {
    return this._loading;
  }

  get isDataLoaded(): boolean {
    return !this._loading && (this.dataSource.data != null);
  }

  get isPaginatorInvisible(): boolean {
    return this.isLoading || this.dataSource.data.length === 0 || this.isFilteredDataEmpty;
  }

  get isFilteredDataEmpty(): boolean {
    return this.dataSource.data?.length > 0 && this.dataSource.filteredData.length === 0;
  }

  get paginator(): MatTableDataSourcePaginator {
    return this.dataSource.paginator;
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatTableDataSourcePaginator) {
    this.dataSource.paginator = value;
    this._afterPaginatorSet.emit(value);
  }

  get sort(): MatSort {
    return this.dataSource.sort;
  }

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {

    if ( (value == null) || (value === this.dataSource.sort) ) {
      // terminate without change
      return;
    }

    this.dataSource.sort = value;
    this._dataSourceSortChange$.observable = value?.sortChange?.asObservable();
    this.checkDefaultSort();
  }

  get tableColumns(): TableControllerTypes.ColumnMenuItemMap<T> {
    return this._columnMenuData.value?.menuItems;
  }

  get hasSelected() {
    return !this.selection.isEmpty();
  }

  asRow(row: unknown): T {
    return row as T;
  }

  cellValueFor<RESULT = any>(row: T, column: TableControllerTypes.ColumnMenuItem<T>): RESULT {
    return TableAccessors.getColumnValue(row, column?.options, column?.id);
  }

  clearData(): void {
    this._loading = true;
    if ( this.dataSource.data != null ) {
      // prevent NPE - clear only if data have already been set
      this.dataSource.data = [];
    }
  }

  /**
   * You can override this method to filter the rows to only those that may have checkboxes active.
   */
  filterForSelection(
    rows: T[] | null,
  ): T[] {

    if ( !(rows?.length > 0) ) {
      return [];
    }

    return rows;
  }

  getSelectableRows(): T[] {
    return this.filterForSelection(this.dataSource.filteredData);
  }

  getTableColumn = (key: string): TableControllerTypes.ColumnMenuItem<T> => {
    const tableColumns = this.tableColumns;
    if ( tableColumns == null ) {
      return null;
    }

    return tableColumns[key];
  };

  getValidSort(sortColumn: string): string {
    const menuItems = this.columnMenuData?.menuItems;
    if ( !(menuItems?.hasOwnProperty(sortColumn)) ) {
      // the mapping for this column cannot be found
      return this.defaultSort;
    }

    return sortColumn;
  }

  isSelectAllChecked(): boolean {
    if ( this.isDataEmpty || !this.selection.hasValue() ) {
      return false;
    }

    return this.filterForSelection(this.selection.selected)
      // check if there are any reasonable selections
      .length > 0;
  }

  isSelectAllIndeterminate(): boolean {

    if ( !this.selection.hasValue() ) {
      return false;
    }

    const selectedCount = this.filterForSelection(this.selection.selected).length;
    if ( selectedCount === 0 ) {
      return false;
    }

    const availableCount = this.getSelectableRows().length;
    return (selectedCount !== availableCount);
  }

  isSelected(value: T): boolean {
    return this.selection.isSelected(value) ?? false;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onFilterChange<K>(f: ColumnFilterV2<string, K | T>, column: TableControllerTypes.ColumnMenuItem<K | T>): void {
    const filter: ColumnFilterV2<string, K | T> = column.options.filter;
    filter.action = f.action;
    filter.value = f.value;
    this.checkFilter();
  }

  onResetFilter() {
    const menuItems = this.columnMenuData?.menuItems;
    if ( menuItems == null ) {
      return;
    }
    const hasChanged = Object.keys(menuItems).reduce((pV, columnId) => {

      const menuItem = menuItems[columnId];
      if ( menuItem.options?.filter === undefined ) {
        // no filter, no change
        return pV;
      } else {
        return this.resetMenuItem(menuItem) || pV;
      }
    }, false);

    if ( hasChanged ) {
      PivotFilterService.emitFilterChange();
      this.checkFilter();
    }
  }

  onToggleAll(callback?: (data: T) => boolean): void {
    if ( this.isSelectAllChecked() ) {
      this.selection.clear();
    } else {
      // todo check if this should use filtered or paged data
      this.selection.clear();
      if (callback) {
        this.selection.select(...this.dataSource.filteredData.filter(callback));
      } else {
        this.selection.select(...this.dataSource.filteredData);
      }
    }
    this.postOnToggleAll();
    this.checkMultiActionsDisabled();
  }

  onToggleSelection($event: MatCheckboxChange, entry: T) {
    const checked = $event.checked;
    if ( checked !== this.selection.isSelected(entry) ) {
      if ( checked ) {
        this.selection.select(entry);
      } else {
        this.selection.deselect(entry);
      }
    }
    this.checkMultiActionsDisabled();
  }

  resetMenuItem(menuItem: TableControllerTypes.ColumnMenuItem<T>, resetValue: string = null): boolean {
    const options = menuItem.options;
    const filter = options.filter;
    if ( typeof (filter) === 'string' && (filter !== resetValue) ) {
      (options.filter as unknown as string) = resetValue;
      return true;
    } else if ( filter.value !== resetValue ) {
      filter.value = resetValue;
      return true;
    }
  }

  setColumns(columns: string[]): void {
    this.columns = TableColumnMenuService.getCheckedColumns(this.columns, columns);
    this.columnsFilter = this.columns.map(id => id + 'Filter');
    this._recalculateSticky.emit();
    this.checkDefaultSort();
  }

  setMenuData(menuData: C): void {
    this.renderColumns = Object.values(menuData.menuItems)
      .filter(menuItem => !menuItem.hidden);
    this.setColumns(TableColumnMenuService.menuDataToColumns(menuData));
    this.columnMenuData = menuData;
  }

  public setTableData(data: T[]): void {
    this.dataSource.data = data;
    this._loading = false;
  }

  protected checkFilter = (): void => {
    const menuItems = this.columnMenuData?.menuItems;
    if ( menuItems == null ) {
      return;
    }

    this.isFilterChanged = false;
    this.isFilterActive = Object.values(menuItems)
      .map(column => {

        const filter = column?.options?.filter;
        if ( filter == null ) {
          // skip anything without filter
          return false;
        }

        this.onCheckFilter(filter);

        const isFilterActive = !!(filter.action && filter.value);
        if ( filter.defaultValue === undefined ) {

          // no default filter value set -> check if value has something
          if ( filter.value != null ) {
            this.isFilterChanged = true;
          }
        } else if ( filter.value !== filter.defaultValue ) {

          // filter value was changed from default -> set flag
          this.isFilterChanged = true;
        }

        // update marker-flag in column
        column.hasFilter = isFilterActive;

        return isFilterActive;
      })
      // filter is active if any column is filtering
      .includes(true);

    this.nextDataSourceFilter(this.isFilterActive ? String(this.dataSource.filter !== 'true') : '');
  };

  protected onCheckFilter(filter: ColumnFilterV2): void { }

  protected checkMultiActionsDisabled() {
    this.multiActionsDisabled = this.inputDisabled || this.selection.isEmpty();
  }

  protected defaultSortingDataAccessor = (data: T, sortHeaderId: string): string => {
    const column = this.getTableColumn(sortHeaderId);
    return TableAccessors.getSortValue(data, column?.options, sortHeaderId);
  };

  protected filterPredicateDefault = (data: T): boolean => this.filterPredicateForColumns(data,
      (filter) => this.filterPredicateDefaultCheck(data, filter));

  protected filterPredicateDefaultCheck = (data: T, filter: ColumnFilterV2<string, string>) => {
    const columnId = filter.identifier;
    const column = this.getTableColumn(columnId);
    return ContentFilterHelper.filterMatch(data, column?.options);
  };

  protected filterPredicateForColumns = (data: T, filterPredicateCheckAttribute: FilterPredicateCheckCallback): boolean => {
    const menuItems = this.columnMenuData?.menuItems;
    if ( menuItems == null ) {
      return true;
    }

    return Object.values(menuItems)
      .map(column => column?.options?.filter)
      .filter(filter => filter != null)
      .reduce((pV, filter) => {
        if ( (pV !== false) && filter.action && filter.value ) {
          const result = filterPredicateCheckAttribute(filter);
          if ( result != null ) {
            pV = result;
          }
        }
        return pV;
      }, null as boolean) !== false;
  };

  /**
   * reset filter on every column by default
   */
  protected getFilterResetValue(columnId: string): string {
    const column = this.getTableColumn(columnId);
    const options = column?.options;
    const filter = options?.filter;
    return filter?.defaultValue ?? null;
  }

  protected nextDataSourceFilter = (filter): void => {
    this.dataSource.filter = filter;
  };

  protected postOnToggleAll() {
  }

  protected checkDefaultSort(): void {
    if ( this.defaultSort === '' ) {
      return;
    }

    const sort = this.dataSource.sort;
    if ( (sort == null) || (this.columns == null) || (this._columnMenuData.value == null) ) {
      // skip without sort headers or columns
      return;
    } else if ( (sort.active != null) && (this.columns.indexOf(sort.active) !== -1) ) {
      // skip with active - and valid - sort
      return;
    }

    if ( this.setSortFromMenuData(this.columnMenuData) ) {
      // sorting applied from menu data
      return;
    }

    if ( this.defaultSort ) {
      sort.direction = this.defaultSortDirection || 'asc';
      return this.setSort(sort, this.defaultSort);
    }

    const firstMenuItem = Object.values(this._columnMenuData.value.menuItems)
      .find(menuItem => !menuItem.hidden);
    if ( firstMenuItem != null ) {
      // sort by first reasonable menu item
      this.setSort(sort, firstMenuItem.id);
    }
  }

  protected setSort(
    sort: MatSort,
    column: string,
    callback?: () => void,
  ): void {
    setTimeout(() => {
      const sortDirection = sort.direction || 'asc';
      const disableClear = sort.disableClear;
      sort.sort({ id: null, start: 'asc', disableClear });

      // dirty hack :D
      // @see https://stackoverflow.com/a/59033664
      sort.sort({ id: column, start: sortDirection, disableClear });
      this.dataSource.sort = sort;

      // @see https://github.com/angular/components/issues/10242#issuecomment-421490991
      const matSortable = sort.sortables.get(column) as MatSortHeader;
      if ( matSortable != null ) {
        matSortable._setAnimationTransitionState({ fromState: sortDirection, toState: 'active' });
      }

      this._dataSourceSortChange$.value = { active: column, direction: sortDirection };

      if ( callback != null ) {
        callback.call(this);
      }
    });
  }

  protected setSortFromMenuData(menuData: C): boolean {
    const menuItems = menuData?.menuItems;
    const sort = this.sort;
    if ( (menuItems == null) || (sort == null) ) {
      return false;
    }

    const sortItem = Object.values(menuItems)
      .find(item => item.sortActive === true);
    const sortColumnId = sortItem?.id;
    if ( sortColumnId == null || sort.active === sortColumnId ) {
      return false;
    }

    sort.direction = sortItem.sortDirection;
    this.setSort(sort, sortColumnId);
    return true;
  }

}
