import { merge } from 'lodash';
import { WIDGET_DEFAULTS } from 'src/app/rag-layout/widgets/widgets.types';
import { ApiUrls } from '../../../core/api.urls';
import { ColumnDefaultConfig } from '../../../core/column-settings/column-settings.types';
import { LocaleTranslation } from '../../../core/translation/translation.types';
import { ViewHelper } from '../../../core/view-helper';

export class TrainImage {
  fileId;
  fileName;
  fileType;
  id;
  link;
}

const fnHasOwnProperty = {}.hasOwnProperty;
const fnHasOwn = (obj, key: string) => fnHasOwnProperty.apply(obj, [ key ]);

export interface DesignSettings {
  applyCustomColors?: boolean;
  loginError?: LocaleTranslation;
  loginFormTexts?: LocaleTranslation;
  loginPlaceholder?: LocaleTranslation;
  registrationButtonActive?: boolean;
  useNextGen?: boolean;
  welcomePictureId?: number;
  welcomeTexts?: LocaleTranslation;
}

export interface StartPageSettings {
  acc: DesignSettings;
  accountId?: number;
  accountKey?: string;
  base?: { [key: string]: any };
  welcomePictureUrl?: string;
}

const addMissingText = (target: LocaleTranslation, source: LocaleTranslation): LocaleTranslation => {
  if ( !target ) {
    return source;
  }
  if ( !target.de ) {
    target.de = source.de;
  }
  if ( !target.en ) {
    target.en = source.en;
  }
  return target;
};

export class StartPageSettingsImpl
  implements StartPageSettings {

  acc: DesignSettings;
  accountId?: number;
  accountKey?: string;
  base?: { [key: string]: any };
  welcomePictureUrl?: string;

  constructor(response?: any) {
    response = response || {};
    const responseAccount = response.acc || {};
    this.accountId = response.accountId;
    this.accountKey = response.accountKey;
    this.acc = {
      applyCustomColors: responseAccount.applyCustomColors || false,
      welcomePictureId: responseAccount.welcomePictureId,
      // todo switch default to false
      useNextGen: fnHasOwn(responseAccount, 'useNextGen') ? (
        responseAccount.useNextGen === true || responseAccount.useNextGen === 'true'
      ) : true,
      welcomeTexts: addMissingText(responseAccount.welcomeTexts, {
        de: '',
        en: '',
      }),
      loginError: addMissingText(responseAccount.loginError, {
        de: 'Bitte geben Sie Ihren Benutzernamen (z.B. Ihre E-Mail-Adresse) ein.',
        en: 'Please enter you user name (e.g. your e-mail address).',
      }),
      loginFormTexts: addMissingText(responseAccount.loginFormTexts, {
        de: 'Willkommen',
        en: 'Welcome',
      }),
      loginPlaceholder: addMissingText(responseAccount.loginPlaceholder, {
        de: 'Benutzername (z.B. E-Mail-Adresse)',
        en: 'Username (e.g. e-mail address)',
      }),
      registrationButtonActive: responseAccount.registrationButtonActive || false,
    };
    if ( this.acc.welcomePictureId > 0 ) {
      this.welcomePictureUrl = ApiUrls.getKey('ShowFile') + '/' + this.acc.welcomePictureId;
    } else {
      this.welcomePictureUrl = 'assets/images/default_image.png';
    }
  }
}

export interface StyleSettingsData {
  accentColor?: string;
  faviconId?: number | null;
  legalNotice?: string;
  logoId?: number;
  mainColor?: string;
  myContentsName?: LocaleTranslation;
  pageTitle?: string;
  policies?: string;
  poweredBy?: string;
  reportGenConf?: any;
  terms?: string;
  widgets?: any;
  widgetsEditableByUser?: boolean;
}

export interface StyleSettings {
  acc: StyleSettingsData;
  base: StyleSettingsData;
  logoUrl: string;
}

export class StyleSettingsImpl
  implements StyleSettings {
  acc: StyleSettingsData = {};
  base: StyleSettingsData = {};
  logoUrl: string;

  constructor(response?: any) {
    if ( !response ) {
      response = {};
    } else if ( response.data ) {
      response = response.data;
    }
    response.acc = response.acc ?? {};
    response.base = response.base ?? {};

    if ( response.acc.acc ) {
      // clean buggy code
      delete response.acc.acc;
    }

    this.base.accentColor = response.base.accentColor || '#767676';
    this.base.logoId = response.base.logoId || 0;
    this.base.mainColor = response.base.mainColor || '#1D4994';
    this.base.pageTitle = response.base.pageTitle || 'iLearn24';
    this.base.poweredBy = response.base.poweredBy || 'Reflact AG & Aviatics';
    this.acc.accentColor = response.acc.accentColor || this.base.accentColor;
    this.acc.logoId = response.acc.logoId || this.base.logoId;
    this.acc.faviconId = response.acc.faviconId || 0;
    this.acc.mainColor = response.acc.mainColor || this.base.mainColor;
    this.acc.pageTitle = response.acc.pageTitle || this.base.pageTitle;
    this.acc.widgets = Object.keys(WIDGET_DEFAULTS).reduce((pV, widgetUUID) => {
      const existingWidgetConf = response.acc.widgets && response.acc.widgets[widgetUUID];
      if ( existingWidgetConf != null ) {
        pV[existingWidgetConf.uuid] = merge({}, WIDGET_DEFAULTS[widgetUUID], existingWidgetConf);
      } else {
        pV[widgetUUID] = ViewHelper.cloneDeep(WIDGET_DEFAULTS[widgetUUID]);
      }
      return pV;
    }, {});
    this.acc.widgetsEditableByUser = response.acc?.widgetsEditableByUser ?? true;
    // todo move to ColumnSettingsService
    this.acc.reportGenConf = merge({}, ColumnDefaultConfig, response.acc.reportGenConf);
    this.acc.poweredBy = response.acc.poweredBy || this.base.poweredBy;
    this.acc.legalNotice = response.acc.legalNotice || this.base.legalNotice;
    this.acc.terms = response.acc.terms || this.base.terms;
    this.acc.policies = response.acc.policies || this.base.policies;
    this.acc.myContentsName = addMissingText(response.acc.myContentsName, {
      de: 'Meine Inhalte',
      en: 'My Contents',
      fr: 'Mon contenu'     // I did not find any possability to force i18n to localize this text to specific language disregarding the language for the build
    });

    if ( this.acc.logoId > 0 ) {
      this.logoUrl = ApiUrls.getKey('ShowFile') + '/' + this.acc.logoId;
    } else {
      this.logoUrl = 'assets/logo/design_logo.png';
    }
  }
}
