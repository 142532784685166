import { AnyObject, NumberedAnyObject } from '../../../../core/core.types';
import { ControllingSingleUserTypes } from '../../../../core/ctrl-single-user.types';
import { InputTypes } from '../../../../component/input/input.types';
import { LicenseGroupToTgColumns } from './license-group-to-tg.columns';
import { ColumnSettings } from '../../../../core/report/report.types';
import { TableControllerTypes } from '../../../../component/table/table-controller/table-controller.types';


export namespace LicenseGroupToTgTypes {

  export class Util {

    static calculateAssignmentCounts(tableRows: TableRow[], menuItems: TableControllerTypes.ColumnMenuItem[],
      statistics?: AnyObject<DistAssignmentTotals>):
      AnyObject<DistAssignmentTotals> {
      // todo fix calculation of statistics
      // todo fix update of changed state
      const totalCount = tableRows.length;

      if ( statistics == null ) {
        statistics = {};
      } else {
        // reset checked counts
        Object.values(statistics)
          .forEach(entry => {
            entry.mandatory.checkedCount = entry.voluntary.checkedCount =
              entry.mandatory.disabledCount = entry.voluntary.disabledCount = 0;
            entry.changed = entry.mandatory.input.changed = entry.voluntary.input.changed = false;
          });
      }

      // calculate number of checked entries
      const result = tableRows.reduce((pV, row) => {
        menuItems.forEach(column => {
          const assignment = Util.getAssignmentEntry(row, column.id);
          if ( assignment == null ) {
            return;
          }

          const statistic = Util.getStatistic(pV, column.id, totalCount);
          // calculates checkedCount and input.changed
          Util.calculateAssignmentCountAdd(statistic.mandatory, assignment.mandatory);
          Util.calculateAssignmentCountAdd(statistic.voluntary, assignment.voluntary);
        });
        return pV;
      }, statistics);

      // update checked states for global checkboxes
      Object.values(result)
        .forEach(column => {
          const mandatoryChanged = column.mandatory.input.changed;
          const voluntaryChanged = column.voluntary.input.changed;
          column.changed = mandatoryChanged || voluntaryChanged;
          // calculates input.currentValue and input.indeterminate
          Util.updateInputState(column.mandatory);
          Util.updateInputState(column.voluntary);
          column.mandatory.input.changed = mandatoryChanged;
          column.voluntary.input.changed = voluntaryChanged;
        });

      return result;
    }

    static createAssignmentEntry(curriculumId: number, userId: number,
      mandatory: boolean, mandatoryDisabled: boolean,
      voluntary: boolean, voluntaryDisabled: boolean,
    ): DistAssignmentEntry {
      return {
        $entryId: `${curriculumId}.${userId}`,
        $isAssignmentEntry: true,
        curriculumId,
        userId,
        mandatory: {
          originalValue: mandatory,
          currentValue: mandatory,
          changed: false,
          disabled: mandatoryDisabled,
        },
        voluntary: {
          originalValue: voluntary,
          currentValue: voluntary,
          changed: false,
          disabled: voluntaryDisabled,
        },
      };
    }

    static getAssignmentEntry(row: TableRow, columnId: string): DistAssignmentEntry {
      const result = row[columnId];
      if ( result?.$isAssignmentEntry === true ) {
        return result;
      }

      const curriculumId = parseInt(columnId, 10);
      if ( !(curriculumId > 0) ) {
        // this is not an assignment column!
        return null;
      }

      return row[columnId] = LicenseGroupToTgTypes.Util.createAssignmentEntry(
        curriculumId, row.userId, false, false, false, false,
      );
    }

    static getStatistic(statistics: AnyObject<DistAssignmentTotals>, columnId: string,
      totalCount: number): DistAssignmentTotals {
      if ( statistics.hasOwnProperty(columnId) ) {
        return statistics[columnId];
      }
      const result: DistAssignmentTotals = {
        changed: false,
        mandatory: {
          input: {
            currentValue: undefined,
          },
          checkedCount: 0,
          disabledCount: 0,
          totalCount,
        },
        voluntary: {
          input: {
            currentValue: undefined,
          },
          checkedCount: 0,
          disabledCount: 0,
          totalCount,
        },
      };
      statistics[columnId] = result;
      return result;
    }

    static toDistAssignmentEntry(assignment: DistResponseAssignment): DistAssignmentEntry {
      return LicenseGroupToTgTypes.Util.createAssignmentEntry(
        assignment.curriculumId,
        assignment.userId,
        assignment.assignedMandatory ?? false,
        assignment.assignedMandatoryDisabled ?? false,
        assignment.assignedVoluntary ?? false,
        assignment.assignedVoluntaryDisabled ?? false,
      );
    }

    static toTableRows(users: ControllingSingleUserTypes.ControllingUser[],
      assignments: LicenseGroupToTgTypes.DistCurriculum, curriculumColumnIds: string[]):
      LicenseGroupToTgTypes.TableRow[] {
      // map users as table rows
      const userRowMap = users.reduce((pV, user) => {
        pV[user.userId] = user;
        return pV;
      }, {} as NumberedAnyObject<LicenseGroupToTgTypes.TableRow>);

      // copy assignments to table rows
      Object.values(assignments)
        .flatMap(curriculum => Object.values(curriculum))
        .map(Util.toDistAssignmentEntry)
        .forEach(assignment => userRowMap[assignment.userId][assignment.curriculumId] = assignment);

      const tableRows = Object.values(userRowMap);

      // ensure we have an assignment object for each curriculum
      tableRows.forEach(row => {
        curriculumColumnIds.forEach(columnId => Util.getAssignmentEntry(row, columnId));
      });

      return tableRows;
    }

    static updateTotalAssignment(tableRows: TableRow[], totals: DistAssignmentTotals, columnId: string,
      isTypeMandatory: boolean): void {
      const total = isTypeMandatory ? totals.mandatory.input : totals.voluntary.input;
      tableRows.forEach(row => {
        const assignment = Util.getAssignmentEntry(row, columnId);
        const rowInput = isTypeMandatory ? assignment.mandatory : assignment.voluntary;
        if ( !rowInput.disabled ) {
          InputTypes.Util.updateValueStateChange(rowInput, total.currentValue);
          if ( rowInput.changed ) {
            total.changed = true;
          }
        }
      });
    }

    private static calculateAssignmentCountAdd(statistic: DistAssignmentTotalField,
      input: InputTypes.ValueState<boolean>): void {
      if ( input.disabled === true ) {
        statistic.disabledCount++;
      } else if ( input.currentValue === true ) {
        statistic.checkedCount++;
      }
      if ( input.changed ) {
        statistic.input.changed = true;
      }
    }

    private static updateInputState(field: DistAssignmentTotalField): void {
      const checked = ((field.checkedCount + field.disabledCount) === field.totalCount);
      InputTypes.Util.updateValueStateChange(field.input, checked, !checked && (field.checkedCount > 0));
    }

  }

  export interface DistAssignmentTotalField {
    checkedCount: number;
    disabledCount: number;
    input: InputTypes.ValueState<boolean>;
    totalCount: number;
  }

  export interface DistAssignmentTotals {
    changed: boolean;
    mandatory: DistAssignmentTotalField;
    voluntary: DistAssignmentTotalField;
  }

  export interface DistResponseAssignment {
    assignedMandatory?: boolean;
    assignedMandatoryDisabled?: boolean;
    assignedVoluntary?: boolean;
    assignedVoluntaryDisabled?: boolean;
    curriculumId: number;
    userId: number;
  }

  export type DistUser = NumberedAnyObject<DistResponseAssignment>;
  export type DistCurriculum = NumberedAnyObject<DistUser>;

  export interface DistResponse {
    assignments: DistCurriculum;
    curriculumColumnSettings: LicenseGroupToTgColumns.DistColumnSettings[];
    userColumnSettings: ColumnSettings[];
    users: ControllingSingleUserTypes.ControllingUser[];
  }

  export interface DistAssignmentEntry {
    $entryId: string;
    $isAssignmentEntry: boolean;
    curriculumId: number;
    mandatory: InputTypes.ValueState<boolean>;
    userId: number;
    voluntary: InputTypes.ValueState<boolean>;
  }

  export interface DistAssignmentUpdate {
    assignedMandatory: boolean;
    assignedVoluntary: boolean;
    curriculumId: number;
    userId: number;
  }

  export interface TableRow
    extends ControllingSingleUserTypes.ControllingUser {
    // curricula are mapped by curriculumId as string
    [key: string]: any | DistAssignmentEntry;
  }

}
