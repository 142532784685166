import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TableColumnDataType, TableControllerTypes } from '../table-controller/table-controller.types';
import { ColumnFilterV2 } from '../../../core/column-settings/column-filter.types';
import { Observable } from 'rxjs';
import { TableColumnOptions } from '../table-column.types';


interface TableHeaderFilterChange {
  column: TableControllerTypes.ColumnMenuItem;
  filter: ColumnFilterV2;
}

@Component({
  selector: 'rag-table-header-filter-default',
  templateUrl: './table-header-filter-default.component.html',
  styleUrls: [ './table-header-filter-default.component.scss' ],
})
export class TableHeaderFilterDefaultComponent {

  @Input() column: TableControllerTypes.ColumnMenuItem | null;
  @Output() readonly filterChange: Observable<TableHeaderFilterChange>;
  @Output() readonly blur: Observable<TableControllerTypes.ColumnMenuItem>;
  private _filterChange = new EventEmitter<TableHeaderFilterChange>(true);
  private _blur = new EventEmitter<TableControllerTypes.ColumnMenuItem>(true);

  constructor() {
    this.filterChange = this._filterChange.asObservable();
    this.blur = this._blur.asObservable();
  }

  get options(): TableColumnOptions | null {
    return this.column?.options;
  }

  getTemplate(
    tplHidden: TemplateRef<any>,
    tplDate: TemplateRef<any>,
    tplNumber: TemplateRef<any>,
    tplText: TemplateRef<any>,
    tplDropDown: TemplateRef<any>,
    tplTags: TemplateRef<any>,
  ): TemplateRef<any> {

    switch ( this.options?.dataType ) {

      case TableColumnDataType.date:
      case TableColumnDataType.dateTime:
        return tplDate;

      case TableColumnDataType.number:
      case TableColumnDataType.price:
        return tplNumber;

      case TableColumnDataType.email:
      case TableColumnDataType.text:
      case TableColumnDataType.html:
        return tplText;

      case TableColumnDataType.dropdown:
      case TableColumnDataType.radio:
        return tplDropDown;

      case TableColumnDataType.multiselect:
      case TableColumnDataType.tags:
        return tplTags;

      case TableColumnDataType.image:
      case TableColumnDataType.other:
      case TableColumnDataType.password:
      default:
        return tplHidden;
    }
  }

  onFilterChange(
    filter: ColumnFilterV2,
  ): void {
    this._filterChange.next({ filter, column: this.column });
  }

  onBlur() {
    this._blur.next(this.column);
  }

}
