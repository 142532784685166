<div class="filter-wrapper">
  <mat-form-field appearance="outline" class="filter">
    <mat-label i18n="@@global_search">Search</mat-label>
    <input
      [value]="searchFilter"
      matInput
      type="text"
      (keyup)="applySearchFilter($event.target['value'])"
      placeholder="Search"
      i18n-placeholder="@@global_search"
    >
  </mat-form-field>

  <mat-form-field class="filter" appearance="outline">
    <mat-label i18n="@@global_filter_by_tags">Filter by Tags</mat-label>
    <mat-chip-grid #chipList class="chip-list">
      <mat-chip-row
        *ngFor="let tags of tagsForFilter"
        [removable]="true"
        (removed)="removeTagFromFilter(tags)">
        {{tags}}
        <button matChipRemove>
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="Tag"
        #tagInput
        #trigger="matAutocompleteTrigger"
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matAutocompletePosition]="'above'"
      >
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event, trigger)">
      <mat-optgroup i18n-label="@@global_all_tags" label="All Tags"></mat-optgroup>
      <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="outline" class="filter" *ngIf="withFilterStatus">
    <mat-label i18n="@@filter_by_status">Filter by Status</mat-label>
    <mat-select [formControl]="filterByStatusControl">
      <mat-option [value]="'NEW'">
        <span i18n="@@admin_user_import_status_new">New</span>
      </mat-option>
      <mat-option [value]="'DRAFT'">
        <span i18n="@@admin_user_import_status_processing">In processing</span>
      </mat-option>
      <mat-option [value]="'PUBLISHED'">
        <span i18n="@@admin_courses_status_published">Published</span>
      </mat-option>
      <mat-option [value]="'NOT_PUBLISHED'">
        <span i18n="@@status_not_published">Not Published</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="filter">
    <mat-label i18n="@@overview_placeholder_select_sort">Sort by</mat-label>
    <mat-select [formControl]="sortBy">
      <mat-option [value]="'dateQuestion'">
        <span i18n="@@global_date_contribution">Date of contribution</span>
      </mat-option>
      <mat-option [value]="'dateAnswer'">
        <span i18n="@@global_date_answer">Date of answer</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button (click)="toggleViewMode()" mat-button class="filter view">
    <mat-icon [svgIcon]="cardView ? 'view-list' : 'view-module'" color="primary"></mat-icon>
    <span *ngIf="!cardView" i18n="@@overview_cards_view">Cards view</span>
    <span *ngIf="cardView" i18n="@@global_list_view">List view</span>
  </button>
</div>

<div class="main" *ngIf="filteredQuestions.length > 0 else noFilteredQuestions">
  <div class="left" *ngIf="cardView">
    <ng-container *ngFor="let question of filteredQuestions">
      <div
        class="question-list-entry"
        (click)="onChangeQuestion(question)"
        [ngClass]="{'active': selectedQuestion === question}"
      >
        <div class="abstract-width">
          <div
            class="question-abstract"
            [matTooltip]="question.questionAbstract"
            [matTooltipShowDelay]="1000"
          >
            <span>{{question.questionAbstract}}</span>
          </div>
          <div class="question-date">
            <div class="icon-wrapper">
              <mat-icon svgIcon="chat-question-outline" class="icon"></mat-icon>
              <span>{{question.creationDate | formatDate:'date-time'}}</span>
            </div>
            <div class="icon-wrapper" *ngIf="getAnswerDate(question) as date">
              <mat-icon svgIcon="chat-alert-outline" class="icon"></mat-icon>
              <span>
                {{date | formatDate:'date-time'}}
              </span>
            </div>
          </div>
          <div class="tags-wrapper justify-content-end">
            <mat-icon
              *ngIf="question.tags !== ''"
              svgIcon="tag-outline"
              class="tags-icon"
            ></mat-icon>
            <div class="tag" *ngFor="let tag of this.splitTags(question.tags)">{{tag}}</div>
          </div>
        </div>


      </div>
    </ng-container>
  </div>

  <div class="right" *ngIf="cardView">
    <div *ngIf="filteredQuestions.length > 0">
      <rag-qa-entry
        [question]="selectedQuestion"
      ></rag-qa-entry>
    </div>
  </div>
</div>

<mat-accordion class="width-100 accordion" *ngIf="!cardView">
  <mat-expansion-panel
    *ngFor="let question of filteredQuestions"
    [expanded]="question === selectedQuestion"
    (opened)="onChangeQuestion(question)"
  >
    <mat-expansion-panel-header class="expansion-panel">
      <mat-panel-title>
        {{question.questionAbstract}}
      </mat-panel-title>
      <mat-panel-description class="panel-description">
        <div class="question-date">
          <div class="icon-wrapper">
            <mat-icon svgIcon="chat-question-outline" class="icon"></mat-icon>
            <span>{{question.creationDate | formatDate:'date-time'}}</span>
          </div>
          <div class="icon-wrapper" *ngIf="getAnswerDate(question) as date">
            <mat-icon svgIcon="chat-alert-outline" class="icon"></mat-icon>
            <span>
                {{date | formatDate:'date-time'}}
              </span>
          </div>
        </div>
        <div class="tags-wrapper">
          <mat-icon
            *ngIf="question.tags !== ''"
            svgIcon="tag-outline"
            class="tags-icon"
          ></mat-icon>
          <div class="tag" *ngFor="let tag of this.splitTags(question.tags)">{{tag}}</div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <rag-qa-entry
      [question]="question"
      [isOpenedInCardView]="false"
    ></rag-qa-entry>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #noFilteredQuestions>
  <div class="no-question">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>
</ng-template>
