<rag-page-header>
  <div class="page-header-title">
    <span>{{pageTitle}}</span>
  </div>

  <div
    class="page-header-buttons"
  >
    <rag-split-button [showSecondaryButton]="filterType !== 'urlCall'">

      <button
        type="button"
        mat-flat-button
        color="primary"
        class="primary-button"
        (click)="onAddNewEventAction(filterType !== 'urlCall')"
      >
        <span i18n="@@general_create">Create</span>
      </button>

      <button
        type="button"
        mat-flat-button
        color="primary"
        [matMenuTriggerFor]="headerMenu"
        class="secondary-button icon-button"
      >
        <mat-icon svgIcon="chevron-down"></mat-icon>
        <mat-menu #headerMenu xPosition="before">

          <button
            *ngIf="filterType === null || filterType === undefined"
            (click)="onAddNewEventAction(true)"
            mat-menu-item
            type="button"
          >
            <span i18n="@@global_notification">Notification</span>
          </button>

          <button
            mat-menu-item
            type="button"
            (click)="onAddNewDefaultTemplate()"
          >
            <span i18n="@@global_create_notification_template">Create notification template</span>
          </button>

          <button
            *ngIf="filterType === null || filterType === undefined"
            (click)="onAddNewEventAction(false)"
            mat-menu-item
            type="button"
          >
            <span i18n="@@global_url_call">URL-Call</span>
          </button>

        </mat-menu>
      </button>

    </rag-split-button>

  </div>
</rag-page-header>


<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="admin-notifications-list-columns">
</rag-table-column-menu>

<mat-menu #notificationsActionsMenu xPosition="before">
  <ng-template let-notification="notification" matMenuContent>
    <a
      mat-menu-item
      type="button"
      [disabled]="isDetailsButtonDisabled(notification)"
      [routerLink]="getUrlDetails(notification)"
    >
      <mat-icon svgIcon="pencil-outline"></mat-icon>
      <span i18n="@@global_edit">Edit</span>
    </a>

    <button
      mat-menu-item
      type="button"
      (click)="onChangeEvent(notification)"
      [disabled]="isEventActionInvalid(notification)"
    >
      <mat-icon svgIcon="note-edit-outline"></mat-icon>
      <span i18n="@@global_change_event">Change event</span>
    </button>

    <a
      mat-menu-item
      type="button"
      [routerLink]="getUrlInfo(notification)"
      [disabled]="isEventActionInvalid(notification) || notification.defaultTemplate === 'true'"
    >
      <mat-icon svgIcon="magnify"></mat-icon>
      <span i18n="@@ehs_notification_history_delivery_status">Delivery status</span>
    </a>

    <button
      mat-menu-item
      (click)="onJournal(notification)"
      [disabled]="historyButtonDisabled$ | async"
      type="button">
      <mat-icon svgIcon="history"></mat-icon>
      <span i18n="@@global_history">History</span>
  </button>

    <mat-divider></mat-divider>

    <button
      mat-menu-item
      type="button"
      (click)="onCopyEventAction(notification)"
      [disabled]="isEventActionInvalid(notification)"
    >
      <mat-icon svgIcon="content-copy"></mat-icon>
      <span i18n="@@global_copy">Copy</span>
    </button>

    <button
      mat-menu-item
      type="button"
      (click)="onChangeEventActionStatus(notification)"
      [disabled]="isEventActionInvalid(notification)"
    >
      <mat-icon [svgIcon]="getStatusIcon(notification)"></mat-icon>
      <span>{{getStatusText(notification)}}</span></button>
    <button
      mat-menu-item
      type="button"
      (click)="onDeleteEventAction(notification)"
      [disabled]="isEventActionInvalid(notification)"
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>

  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">
  <rag-sticky-scroll *ngIf="!isDataEmpty; else tplDataEmpty" [recalculate]="recalculateSticky$" [ngClass]="{'sticky-scroll-height': !isDataEmpty}">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded && (renderColumns?.length > 0); else tplDataLoading">
      <table
        [dataSource]="dataSource"
        mat-table
        matSort
      >
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              *ngIf="column.id !== 'enabled'"
              [row]="row"
              [column]="column"
            ></rag-tables-column-render-default>
            <ng-container *ngIf="column.id === 'enabled'">
              <div class="flex" [matTooltip]="row?.activeHint">
                <span>{{getDisplayValue(row, column)}}</span>
                <mat-icon
                  *ngIf="showInfoIcon(row)"
                  class="info-icon"
                  svgIcon="alert-outline"
                ></mat-icon>
              </div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let notification" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled || isEventActionInvalid(notification)"
              [matMenuTriggerData]="{notification: notification}"
              [matMenuTriggerFor]="notificationsActionsMenu"
              mat-icon-button>
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
