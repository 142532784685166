import {
  NavigationData,
  NavItemIcon,
  NavItemMenu,
  NavItemMenuIcon,
  NavItemSpecial,
  NavItemType,
} from './navigation.types';


export const DEFAULT_NAVIGATION_GUEST: NavigationData = {
  isGuest: true,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.menuIcon,
      special: NavItemSpecial.language,
    } as NavItemMenuIcon,
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_contact:Contact`,
      icon: 'face-agent',
      routerLink: '/contact',
    } as NavItemIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@global_login:Login`,
      icon: 'login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
    },
  ],
  main: [
    {
      type: NavItemType.link,
      title: $localize`:@@global_login:Login`,
      routerLink: '/login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
    },
    {
      type: NavItemType.link,
      title: $localize`:@@global_catalog:Catalog`,
      routerLink: '/catalog',
      special: NavItemSpecial.catalog,
    },
  ],
};

export const DEFAULT_NAVIGATION_USER: NavigationData = {
  isGuest: false,
  showContentSearch: true,
  top: [
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_messages:Messages`,
      icon: 'email',
      checkPermissions: 'navUserMessages',
      routerLink: '/mailbox/inbox',
      additionalRoutes: [ '/mailbox' ],
      special: NavItemSpecial.mailbox,
    },
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_contact:Contact`,
      icon: 'face-agent',
      routerLink: '/contact',
    },
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_system_check:System check`,
      icon: 'shield-check-outline',
      routerLink: '/tech-check',
    },
    {
      type: NavItemType.menuIcon,
      title: $localize`:@@header_top_profile:Profile`,
      icon: 'account-circle',
      children: [
        {
          type: NavItemType.text,
          title: '{userFullName}',
          special: NavItemSpecial.profileUserName,
        },
        {
          type: NavItemType.divider,
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_profile:Profile`,
          routerLink: '/my-profile',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_transactions:Transactions`,
          routerLink: '/transactions',
          checkPermissions: 'navUserTransactions',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_top_gamification:Awards`,
          routerLink: '/awards',
          special: NavItemSpecial.gamification
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_top_linked_users:Linked users`,
          checkPermissions: 'navUserLink',
          special: NavItemSpecial.profileLinkedUser,
          additionalRoutes: [ '/linked-users' ],
        },
      ],
    } as NavItemMenuIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@logout_button:Logout`,
      icon: 'logout',
      special: NavItemSpecial.logout,
    } as NavItemIcon,
  ],
  main: [

    {
      type: NavItemType.menu,
      title: $localize`:@@header_nav_my_contents:My Contents`,
      special: NavItemSpecial.myTrainings,
      additionalRoutes: [ '/content-overview' ],
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@assignment_mandatory:Mandatory`,
          routerLink: '/content-overview',
          queryParams: { at: 'mandatory$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnMandatory',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@assignment_voluntary:Voluntary`,
          routerLink: '/content-overview',
          queryParams: { at: 'voluntary$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnVoluntary',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_virtual_conference:Virtual event`,
          routerLink: '/content-overview',
          queryParams: { ct: 'Virtual Classroom$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnOfflineContent',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_seminar:On-site event`,
          routerLink: '/content-overview',
          queryParams: { ct: 'Face-to-Face event$eq', es: 'title$eq' },
          checkPermissions: 'navUserLearnOfflineContent',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_student_certificates:My certificates`,
          routerLink: '/my-certificates',
          checkPermissions: 'navUserCertificates',
        },
      ],
    },

    {
      type: NavItemType.menu,
      title: $localize`:@@header_nav_administrator:Administration`,
      children: [

        {
          type: NavItemType.menu,
          title: $localize`:@@admin_offline_content_header:Events`,
          checkPermissions: 'navAdminOfflineContent',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/offline/content',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_calendar_view:Calendar View`,
              routerLink: '/admin/offline/content/cal/{date}/month',
              special: NavItemSpecial.adminOfflineCalendar,
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_recordings:Recordings`,
              routerLink: '/admin/offline/recordings',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_location:Locations`,
              routerLink: '/admin/offline/location',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_trainer:Trainer`,
              routerLink: '/admin/offline/trainer',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_provider:Provider`,
              routerLink: '/admin/offline/provider',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_notification_templates:Notification templates`,
              routerLink: '/admin/offline/notifications',
              checkPermissions: 'navAdminOfflineNotification',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@categories:Categories`,
          routerLink: '/admin/categories',
          checkPermissions: 'navAdminCategories',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_catalogs:Catalogs`,
          routerLink: '/admin/catalog',
          checkPermissions: 'navAdminCatalog',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_lu_header:Learning content`,
          routerLink: '/admin/courses',
          checkPermissions: 'navAdminCourse',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@ctrl_single_user_details_nav_curricula:Curricula`,
          checkPermissions: 'navAdminCurriculum',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '' },
              routerLinkActiveOptions: {exact: true},
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_cockpit_column_type_title:Online Agenda`,
              routerLink: '/admin/curriculum',
              queryParams: { type: 'a294578e-ab2f-4576-a106-0177600b67ad$eq' },
              routerLinkActiveOptions: {exact: true},
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_learner_cockpit:Learner Cockpit`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '174b90c1-5781-4c1e-a5dd-989a3618af4e$eq' },
              routerLinkActiveOptions: {exact: true},
            }
          ]
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_user_administration:User Administration`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_users:Users`,
              routerLink: '/admin/user',
              checkPermissions: 'navAdminUsers',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_user_imports:User imports`,
              routerLink: '/admin/user-import',
              checkPermissions: 'navAdminUsersCSV',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_groups:User groups`,
              routerLink: '/admin/user-groups',
              checkPermissions: 'navAdminUserGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_target_groups:Target groups`,
              routerLink: '/admin/target-groups',
              checkPermissions: 'navAdminTargetGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_self_registration:Self registration`,
              routerLink: '/admin/self-registration',
              checkPermissions: 'navAdminSelfRegistration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@limited_user_administration:Limited user administration`,
              routerLink: '/admin/reduced-attributes',
              checkPermissions: 'navAdminReducedUserAdministration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_data_fields:User data fields`,
              routerLink: '/admin/user-attribute',
              checkPermissions: 'navAdminUserAttribute',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_learner_state:Learner state import`,
              routerLink: '/admin/learner-state',
              checkPermissions: 'navAdminLearnerStateImport',
              hideForIlearn: 'apply'
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_text_macros:Text macros`,
          routerLink: '/admin/textMacros',
          checkPermissions: 'navAdminTextMacros',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_news:News`,
          routerLink: '/admin/news',
          checkPermissions: 'navAdminNews',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_content_content_notifications:Notifications`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_top_messages:Messages`,
              routerLink: '/admin/notifications/message',
              checkPermissions: 'navAdminNotifications',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@general_signatures:Signatures`,
              routerLink: '/admin/notifications/signatures',
              checkPermissions: 'navAdminSignatures',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_url_call:URL-Call`,
              routerLink: '/admin/notifications/urlCall',
              checkPermissions: 'navAdminNotifications',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_qa:Q&A`,
          routerLink: '/admin/qa',
          checkPermissions: 'navAdminQA',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@global_integration:Integration`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_login:Virtual platforms`,
              routerLink: '/admin/ext/login',
              checkPermissions: 'navAdminExternalLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_oidc:OpenID Connect`,
              routerLink: '/admin/ext/oauth2',
              checkPermissions: 'navAdminExternalOAuth2',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_dsb_gateway_settings:DSB-Gateway Settings`,
              routerLink: '/admin/ext/dsbGateway',
              checkPermissions: 'navAdminExternalDSBGateway',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_script_account_script:Account-wide JavaScript`,
              routerLink: '/admin/script',
              checkPermissions: 'navAdminJavascript',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_nav_development-goals:Development goals`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_development_goals_distribution:Distribution`,
              routerLink: '/admin/development-goals/licence-group/assignment',
              checkPermissions: 'navAdminDistributionLimited',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_permissions:Permissions`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_roles:Roles`,
              routerLink: '/admin/roles',
              checkPermissions: 'adminRoles',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@account_design:Account design`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_general:General`,
              routerLink: '/admin/account-design/general',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_login:Login`,
              routerLink: '/admin/account-design/login',
              checkPermissions: 'navAdminAccountDesignLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_footer:Footer`,
              routerLink: '/admin/account-design/footer',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@global_configurations:Configurations`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_widgets:Widgets`,
              routerLink: '/admin/configuration/widgets',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: 'Gamification',
              routerLink: '/admin/configuration/gamification',
              checkPermissions: 'navAdminGamification'
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_report_generator:Report generator`,
              routerLink: '/admin/configuration/report-gen-admin',
              checkPermissions: 'navAdminReportGenerator',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@landing_page:Landing page`,
              routerLink: '/admin/configuration/start-page',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@profile_export:Profile export`,
              routerLink: '/admin/configuration/profile',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_action_codes:Action Codes`,
          routerLink: '/admin/ac',
          checkPermissions: 'adminActionCodesReduced',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_handbook:Handbook`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook:Handbook`,
              routerLink: '/admin/handbook',
              routerLinkActiveOptions: { exact: true },
              checkPermissions: 'navAdminHandbook',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook_interactive:Interactive (experimental)`,
              routerLink: '/admin/handbook/interactive',
              checkPermissions: 'navAdminHandbook',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_handbook_settings:Settings`,
              routerLink: '/admin/handbook/settings',
              checkPermissions: 'navAdminHandbookSettings',
            },
          ],
        },
      ],
    } as NavItemMenu,

    {
      type: NavItemType.menu,
      title: $localize`:@@header_nav_controlling:Controlling`,
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_controlling_dashboard:Dashboard`,
          routerLink: '/report/dashboard',
          checkPermissions: 'navCtrlReportDashboard',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@header_nav_report_generator:Report generator`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_curriculum:Curriculum`,
              routerLink: '/report/v2/generator/Curriculum',
              checkPermissions: 'navCtrlReportCurriculum',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_curriculum_selected:Selected curricula`,
              routerLink: '/report/v2/generator/Curriculum/select',
              checkPermissions: 'navCtrlReportCurriculum',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_course:Course`,
              routerLink: '/report/v2/generator/Course',
              checkPermissions: 'navCtrlReportCourse',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_event:Event`,
              routerLink: '/report/v2/generator/Offline',
              checkPermissions: 'navCtrlReportOffline',
            },
          ],
        } as NavItemMenu,
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_controlling_report_mine:My reports`,
          routerLink: '/report/v2/saved',
          checkPermissions: 'navCtrlReportSaved',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_single_user:Single user`,
          routerLink: '/ctrl/user',
          checkPermissions: 'navCtrlSingleUser',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_feedback_dashboard:Feedback dashboard`,
          routerLink: '/ctrl/quest',
          checkPermissions: 'navSuperiorFeedback',
        },
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_lists:List view`,
          routerLink: '/report/single-lists',
          checkPermissions: 'navCtrlSingleLists',
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_zuep:Background check`,
          routerLink: '/report/zuep',
          checkPermissions: 'navCtrlZuep',
          external: true,
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_fad:Officials' dashboards`,
          routerLink: '/report/fad',
          checkPermissions: 'navCtrlFad',
          external: true,
        },*/
        /*<NavItem>{
          type: NavItemType.link,
          title: $localize`:@@header_nav_ctrl_furtherboards:Further boards`,
          routerLink: '/report/furtherboards',
          checkPermissions: 'navCtrlFurtherBoards',
          external: true,
        },*/
      ],
    },

    {
      type: NavItemType.link,
      title: $localize`:@@global_catalog:Catalog`,
      routerLink: '/catalog',
      checkPermissions: 'navCatalog',
    },

    {
      type: NavItemType.link,
      title: $localize`:@@global_qa:Q&A`,
      routerLink: '/qa',
      checkPermissions: 'navQA',
    },

    {
      type: NavItemType.link,
      title: $localize`:@@switch_to_classic_frontend:Switch to classic view`,
      url: '{classicApi}/navigationAction.do?dispatch=navigateTo&navigationTarget=home&useClassic=true&headless=false',
      checkPermissions: 'navClassic',
      external: true,
    },
    {
      type: NavItemType.link,
      title: $localize`:@@global_doclib_link:Media library`,
      routerLink: '/library',
      checkPermissions: 'navDocLib',
      external: true,
    },
    {
      type: NavItemType.text,
      title: '{login}',
      special: NavItemSpecial.loginName,
    },

  ],
};
