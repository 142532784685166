import { Translateable } from 'src/app/core/core.types';
import { JournalEntityView, JournalResponse } from 'src/app/core/journal/journal.types';
import { TableColumnBuilder } from '../../table/table-column.builder';
import { TableColumnDataType, TableControllerTypes } from '../../table/table-controller/table-controller.types';

export interface JournalDialogData extends JournalResponse {
  title: Translateable;
}

export namespace JournalDialog {

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface ColumnMenuItemMap
    extends TableControllerTypes.ColumnMenuItemMap {
    date: TableControllerTypes.ColumnMenuItem;
    user: TableControllerTypes.ColumnMenuItem;
    actionType: TableControllerTypes.ColumnMenuItem;
    message: TableControllerTypes.ColumnMenuItem;
    // configuration: TableControllerTypes.ColumnMenuItem;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'date' ],
    endWith: [ 'message' ],
    menuItems: {

      date: TableColumnBuilder.start<JournalEntityView>()
        .withColumnId('date')
        .withType(TableColumnDataType.dateTime)
        .withTitle($localize`:@@global_date:Date`)
        .withSelected()
        .build(),


      user: TableColumnBuilder.start<JournalEntityView>()
        .withColumnId('user')
        .withType(TableColumnDataType.text)
        .withTitle($localize`:@@global_users:Users`)
        .withDataAccessor(entry => `${entry.firstName??''} ${entry.lastName??''}`)
        .withSelected()
        .build(),

      actionType: TableColumnBuilder.start<JournalEntityView>()
        .withColumnId('actionType')
        .withType(TableColumnDataType.dropdown)
        .withDropDownOptions({
          NONE: $localize`:@@journal_entity_action_type_none:None`,
          CHANGE: $localize`:@@journal_entity_action_type_change:Change`,
          CREATE: $localize`:@@journal_entity_action_type_create:Create`,
          DELETE: $localize`:@@journal_entity_action_type_delete:Delete`,
        })
        .withTitle($localize`:@@global_type:Type`)
        .withSelected()
        .build(),

      message: TableColumnBuilder.start<JournalEntityView>()
        .withColumnId('message')
        .withType(TableColumnDataType.html)
        .withTitle($localize`:@@global_description:Description`)
        .withSelected()
        .build(),
    },
  };
}
