import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdminCurriculumListService } from './admin-curriculum-list.service';
import { AdminCurriculum, AdminCurriculumLegacyResolver } from '../admin-curriculum.types';
import CurriculumMeta = AdminCurriculum.CurriculumMeta;

@Injectable({ providedIn: 'root' })
export class AdminCurriculumListResolver
  implements Resolve<CurriculumMeta[]> {

  constructor(
    private adminCurriculumListService: AdminCurriculumListService,
  ) {
  }

  resolve(): Observable<CurriculumMeta[]> {
    return this.adminCurriculumListService.getCurricula();
  }
}
