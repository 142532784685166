<div class="design-wrapper">
  <rag-page-header>
    <div class="page-header-title" i18n="@@global_config">Configuration</div>

    <div class="page-header-buttons">

      <!-- Save button -->
      <button
        *ngIf="saveButtonVisible$ | async"
        (click)="onSave()"
        [disabled]="saveButtonDisabled$ | async"
        color="primary"
        mat-flat-button
        type="button"
      >
        <span i18n="@@global_save">Save</span>
      </button>

    </div>
  </rag-page-header>

  <nav class="tab-group-container" color="primary" mat-tab-nav-bar>
    <a #rlaWidgets="routerLinkActive"
       [active]="rlaWidgets.isActive"
       mat-tab-link
       routerLink="widgets"
       routerLinkActive>
      <span i18n="@@account_design_widgets">Widgets</span>
    </a>
    <a #rlaGamification="routerLinkActive"
       *ngIf="permissions?.navAdminGamification"
       [active]="rlaGamification.isActive"
       mat-tab-link
       routerLink="gamification"
       routerLinkActive>
      <span>Gamification</span>
    </a>
    <a #rlaRepGen="routerLinkActive"
       [active]="rlaRepGen.isActive"
       mat-tab-link
       routerLink="report-gen-admin"
       routerLinkActive>
      <span i18n="@@header_nav_report_generator">Report generator</span>
    </a>
    <a #rlaLandingPage="routerLinkActive"
       [active]="rlaLandingPage.isActive"
       mat-tab-link
       routerLink="start-page"
       routerLinkActive>
      <span i18n="@@landing_page">Landing page</span>
    </a>
    <a #rlaProfile="routerLinkActive"
       [active]="rlaProfile.isActive"
       mat-tab-link
       routerLink="profile"
       routerLinkActive>
      <span i18n="@@profile_export">Profile export</span>
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
