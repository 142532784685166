<button
  type="button"
  [disabled]="multiActionsDisabled()"
  [matMenuTriggerFor]="batchActionsMenu"
  mat-flat-button
>
  <mat-icon svgIcon="menu"></mat-icon>

  <mat-menu #batchActionsMenu>

    <ng-template matMenuContent>
      <a
        *ngIf="maySaveUser"
        mat-menu-item
        [routerLink]="editUserUrl"
      >
        <mat-icon svgIcon="account-outline"></mat-icon>
        <span i18n="@@header_nav_administrator">Administration</span>
      </a>

      <ng-container *ngIf="controllingComponent">
        <button
          mat-menu-item
          type="button"
          (click)="controllingComponent.onEditAccount()"
          [disabled]="!controllingComponent.hasSelectionChangeableChild()"
        >
          <mat-icon svgIcon="list-status"></mat-icon>
          <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
        </button>

        <button
          mat-menu-item
          type="button"
          (click)="controllingComponent.onSwitchPaths()"
          [disabled]="!controllingComponent.hasSelectionParent()"
        >
          <mat-icon svgIcon="call-split"></mat-icon>
          <span i18n="@@curriculum_switch_path">Switch path</span>
        </button>

        <button
          mat-menu-item
          type="button"
          (click)="controllingComponent.onResetLocks()"
          [disabled]="!controllingComponent.hasSelectionParent()"
        >
          <mat-icon svgIcon="lock-check-outline"></mat-icon>
          <span i18n="@@curriculum_reset_locks">Reset locks</span>
        </button>

        <button
          [disabled]="!controllingComponent.hasSelectionChangeableCourse()"
          mat-menu-item
          type="button"
          [matMenuTriggerFor]="courseMultiActionsMenu"
        >
          <mat-icon svgIcon="school-outline"></mat-icon>
          <span i18n="@@ctrl_single_user_curriculum_actions_course">Course actions</span>

          <mat-menu #courseMultiActionsMenu>

            <button
              mat-menu-item
              type="button"
              (click)="controllingComponent.onResetCourses()"
              [disabled]="!controllingComponent.permissions.ctrlCourseReset"
            >
              <mat-icon svgIcon="undo-variant"></mat-icon>
              <span i18n="@@global_reset">Reset</span>
            </button>

          </mat-menu>
        </button>
      </ng-container>

    </ng-template>
  </mat-menu>
</button>
