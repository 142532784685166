import { Observable, of } from 'rxjs';
import { StorageHelper } from './storage/storage.helper';


export class RedirectHelper {

  /**
   * Beware the prefix!
   */
  private static IGNORED_TARGETS = [
    '/home',
    '/login',
  ];

  public static clearRedirect(): Observable<void> {
    return StorageHelper.remove('loginRedirect');
  }

  public static getRedirect(): Observable<string> {
    return StorageHelper.get<string>('loginRedirect');
  }

  public static isValidRedirect(url: string): boolean {

    if (url == null || !url) {
      // not a valid url
      return false;
    }

    if (url === '/') {
      // do not redirect to root
      return false;
    }

    // check if any explicit ignore matches
    return RedirectHelper.IGNORED_TARGETS
      .find(prefix => url.startsWith(prefix)) == null;
  }

  /**
   * @return Observable of the updated value or null if filtered
   */
  public static setRedirect(url: string): Observable<string> {

    if ( !RedirectHelper.isValidRedirect(url) ) {

      return of(null);

    } else {

      return StorageHelper.save<string>('loginRedirect', url);
    }
  }

}
