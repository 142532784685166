import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterContext, FilterResult } from './filter-api.types';
import { Observable } from 'rxjs';
import { AnyObject } from '../core.types';
import { ApiUrls } from '../api.urls';
import { ApiResponse } from '../global.types';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class FilterApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getFilterResults(context: FilterContext): Observable<AnyObject<FilterResult[]>> {
    const url = ApiUrls.getKey('FilterResults')
      .replace(/{context}/gi, String(context));
    return this.http.get<ApiResponse<AnyObject<FilterResult[]>>>(url)
      .pipe(map(response => response.filterResults));
  }

}
