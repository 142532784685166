import { IsActiveMatchOptions, Params } from '@angular/router';
import { Translation } from '../translation/translation.types';


export const ROUTE_MATCH_OPTIONS_EXACT: IsActiveMatchOptions = Object.freeze({
  paths: 'exact',
  queryParams: 'exact',
  fragment: 'ignored',
  matrixParams: 'ignored',
});

export const ROUTE_MATCH_OPTIONS_NOT_EXACT: IsActiveMatchOptions = Object.freeze({
  paths: 'subset',
  queryParams: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored',
});

export enum NavItemSpecial {
  adminOfflineCalendar = 'adminOfflineCalendar',
  catalog = 'catalog',
  gamification = 'gamification',
  language = 'language',
  login = 'login',
  logout = 'logout',
  mailbox = 'mailbox',
  myTrainings = 'myTrainings',
  profileUserName = 'profileUserName',
  profileLinkedUser = 'profileLinkedUser',
  loginName = 'loginName'
}

export enum NavItemType {
  button = 'button',
  buttonIcon = 'buttonIcon',
  divider = 'divider',
  link = 'link',
  linkIcon = 'linkIcon',
  menu = 'menu',
  menuIcon = 'menuIcon',
  text = 'text',
}

export enum NavItemHideForIlearn {
  default = 'default',
  apply = 'apply'
}

export interface NavItem {
  /**
   * additional routes that mark this item as active
   */
  additionalRoutes?: string[];
  checkPermissions?: string;
  external?: boolean;
  queryParams?: Params;
  routerLink?: string;
  routerLinkActiveOptions?: { exact: boolean } | IsActiveMatchOptions | null;
  /**
   * used to identify an item which needs some special handling
   */
  special?: NavItemSpecial;
  title: Translation;
  type: NavItemType;
  url?: string;
  hideForIlearn?: NavItemHideForIlearn;
}

interface WithIcon {
  icon: string;
}

export interface NavItemIcon
  extends NavItem, WithIcon {
}

export interface NavItemMenu
  extends NavItem {
  children: NavItem[];
}

export interface NavItemMenuIcon
  extends NavItemMenu, WithIcon {
}

export interface NavigationData {
  isGuest: boolean;
  main: NavItem[];
  showContentSearch: boolean;
  top: NavItem[];
}
