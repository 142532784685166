<div class="container">
  <rag-slideshow
    (onclick)="onClick($event)"
    [autoplay]="autoplay"
    [slides]="imagesData"
  ></rag-slideshow>
  <p *ngIf="hasResponse && imagesData.length === 0">
      <span i18n="@@widget_news_no_available_data">There are currently no news
      to be displayed.</span>
  </p>
</div>
