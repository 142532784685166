import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrls } from './api.urls';
import { ApiResponse } from './global.types';
import { MacroContextSettings, MacroContext } from './macros.types';

@Injectable({
  providedIn: 'root',
})
export class MacrosService {

  constructor(
    private http: HttpClient
  ) {

  }

  getMacros(macrosContext: MacroContext): Observable<MacroContextSettings> {
    const url = ApiUrls.getKey('TextMacroSettings').replace('{macroContext}', macrosContext);
    return this.http.get<ApiResponse<MacroContextSettings>>(url).pipe(map(response => response.data));
  }

  getCourseTexts(): Observable<MacroContextSettings> {
    const url = ApiUrls.getKey('CourseMacroTexts');
    return this.http.get<ApiResponse<MacroContextSettings>>(url)
      .pipe(map(response => response.data));
  }
}
