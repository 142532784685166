import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PrincipalService } from '../../../core/principal/principal.service';
import { InfoService } from '../../../core/info/info.service';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { ContentService } from '../../../core/content/content.service';
import { ActivatedRoute } from '@angular/router';
import { LearnerAccountService } from '../../../core/learner-account/learner-account.service';
import { Observable, timer } from 'rxjs';
import { StyleSettings } from '../../admin/account-design/account-design.types';
import { takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { RouteSnapshotHelper } from '../../../core/route-snapshot.helper';
import { Content } from '../../../core/content/content.types';
import { CachedSubject } from '../../../core/cached-subject';
import { ApplicationStateService, ViewMode } from '../../../core/application-state.service';
import { AgendaOfflineCnt, LiveAgendaDetails } from './live-agenda.types';
import { Core, ImageableContentReference, Translateable } from '../../../core/core.types';
import { OfflineContent } from '../../../core/admin-offline.types';
import { ModalDialog } from '../../../core/modal-dialog';
import * as moment from 'moment';
import { ApiUrls } from '../../../core/api.urls';
import { LiveAgendaDetailsDialogComponent } from './live-agenda-details-dialog/live-agenda-details-dialog.component';
import { LiveAgendaDetailsService } from './live-agenda-details-dialog/live-agenda-details.service';
import { ExtLoginsService } from '../../../core/ext-logins.service';
import { GenericMessageDialogComponent } from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { ImageUrlHelper } from '../../../core/image-url-helper';
import { OnlineAgendaColor } from '../../admin/admin-cockpits/admin-cockpits-details/admin-cockpits-details.types';
import { isNothing } from 'src/app/core/utils';

@Component({
  selector: 'rag-live-agenda',
  templateUrl: './live-agenda.component.html',
  styleUrls: [ './live-agenda.component.scss' ],
})
export class LiveAgendaComponent
  implements OnInit, OnDestroy {
    @Output() readonly fullscreen: Observable<boolean>;
    @Input() isPreview: boolean;
    @Input() previewCurriculum: ImageableContentReference;
    @Input() previewEvents: OfflineContent.Event[];
    @Input() previewPictureId: string | number;
    @Input() previewColors: OnlineAgendaColor;

    content: ImageableContentReference;
  contentId: number;
  lengthOfColumns = 0;
  loading = true;
  styleSettings$: Observable<StyleSettings>;
  tiles: [ AgendaOfflineCnt[] ] = [ [] ];
  colors: OnlineAgendaColor;
  pictureId: string | number;
  timeframes: string[] = [];
  isNothing = isNothing;
  private _fullscreen = new CachedSubject<boolean>(null);

  constructor(
    private infoService: InfoService,
    private accountDesignService: AccountDesignService,
    private applicationStateService: ApplicationStateService,
    private principalService: PrincipalService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private extLoginsService: ExtLoginsService,
    private learnerAccountService: LearnerAccountService,
    private liveAgendaDetailsService: LiveAgendaDetailsService,
    private dialog: ModalDialog,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();
    this.fullscreen = this._fullscreen.withoutEmptyValues();
  }

  private static createPreviewTile(event: any): AgendaOfflineCnt {
    return {
      title: event.title,
      active: event.active,
      description: event.description,
      parentId: event.contentId,
      id: event.id,
      eventDate: event.eventDate,
      eventDateUntil: event.eventDateUntil,
      eventClosed: event.eventClosed,
      placeholder: false,
      isLive: false,
      isInPast: false,
      pictureUUID: event.pictureUUID,
    };
  }

  private static createTile(
    event: OfflineContent.EventSchedule,
    curriculumItemId: number,
  ): AgendaOfflineCnt {

    return {
      curriculumItemId,
      title: event.title,
      active: event.active,
      description: event.description,
      parentId: event.contentId,
      id: event.id,
      eventDate: event.eventDate,
      eventDateUntil: event.eventDateUntil,
      eventClosed: event.eventClosed,
      placeholder: false,
      isLive: false,
      isInPast: false,
      pictureUUID: event.pictureUUID,
    };
  }

  private static generatePlaceholder(eventDate?: number, title?: Translateable): AgendaOfflineCnt {
    const placeholder: AgendaOfflineCnt = {
      placeholder: true,
      eventDate: 99999999999999,
      title: 'placeholderAtEnd',
    };
    if ( eventDate ) {
      placeholder.eventDate = eventDate;
    }
    if ( title ) {
      placeholder.title = title;
    }
    return placeholder;
  }

  private static insertPlaceholderIntoArray(
    array: AgendaOfflineCnt[], index: number, eventDate: number, segment: string,
  ): void {
    array.splice(index, 0, LiveAgendaComponent.generatePlaceholder(eventDate, segment));
  }

  ngOnDestroy(): void {
  }

  getTimeSpanOfEventColumn(first: AgendaOfflineCnt, last: AgendaOfflineCnt): void {
    let timeframe = moment(first.eventDate).hours() + ':';
    if ( moment(first.eventDate).minutes() < 10 ) {
      timeframe += 0;
    }
    timeframe += moment(first.eventDate).minutes();
    timeframe += ' - ' + moment(last.eventDateUntil).hours() + ':';
    if ( moment(last.eventDateUntil).minutes() < 10 ) {
      timeframe += 0;
    }
    timeframe += moment(last.eventDateUntil).minutes();

    this.timeframes.push(timeframe);
  }

  getTimeframes(): void {
    for ( let item = 0; item < this.lengthOfColumns; item++ ) {
      // get all items of column
      const column: AgendaOfflineCnt[] = [];
      for (const col of this.tiles) {
        column.push(col[item]);
      }

      // compare times of column
      const firstAppointmentOfColumn = this.getFirstAppointmentOfColumn(column);
      const lastAppointmentOfColumn = this.getLastAppointmentOfColumn(column);

      this.getTimeSpanOfEventColumn(firstAppointmentOfColumn, lastAppointmentOfColumn);
    }
  }

  toggleFullscreen(fullscreen: boolean): void {
    this.applicationStateService.setViewMode(fullscreen ? ViewMode.FULLSCREEN : ViewMode.DEFAULT);
    this._fullscreen.next(fullscreen);
  }

  fetchEvents(): void {
    this.tiles.pop();
    this._fullscreen.next(true);
    this.learnerAccountService.fetchCurriculumEvents(this.content.id)
      .pipe(take(1))
      .pipe(tap((response) => {
        // console.log('fetchCurriculumEvents', response);
        this.content.items.map((item: Content) => {
            const array = [];
            // only get event items
            if ( item.objType !== Core.DistributableType.lms_offlineCnt ) {
              return;
            }
            const child = response[item.id];
            child?.map((event) => {
              array.push(LiveAgendaComponent.createTile(event, item.curItemId));
            });
            this.tiles.push(array);
          },
        );

        this.tiles.map(row => {
          row.map(tile => {
            this.updateStatusOfTile(tile);
          });
        });

        this.loading = false;

        // prepare array
        // make sure that each array is sorted in time
        this.sortArraysInTime();

        // fill in placeholders so times are matching
        this.fillMatrixWithPlaceholdersToMatchTime();

        // get timeframes
        this.getTimeframes();
      }))
      .subscribe();
  }

  fetchEventsForPreview(events: OfflineContent.Event[]): void {
    this.tiles.pop();
    this._fullscreen.next(false);

    events.map((event) => {
      const array = [];
      event.offlineEvents?.map((date) => {
        array.push(LiveAgendaComponent.createPreviewTile(date));
      });
      this.tiles.push(array);
    });

    this.tiles.map(row => {
      row.map(tile => {
        this.updateStatusOfTile(tile);
      });
    });

    this.loading = false;

    // prepare array
    // make sure that each array is sorted in time
    this.sortArraysInTime();

    // fill in placeholders so times are matching
    this.fillMatrixWithPlaceholdersToMatchTime();

    // get timeframes
    this.getTimeframes();
  }

  getImageUrl(pictureId): string {
    return ImageUrlHelper.urlForPictureId(pictureId);
  }

  openEvent(offlineEvent: AgendaOfflineCnt): void {
    this.liveAgendaDetailsService.getLiveAgendaDetails(offlineEvent.parentId, offlineEvent.id)
      .pipe(map((event: LiveAgendaDetails) => {
        let isRoom = false;
        let isLink = false;

        if ( event.offlineEvent.extLoginPath != null && event.offlineEvent.extLoginPath.length > 0 ) {
          isRoom = true;
        }
        if ( event.offlineEvent.extLogin?.url != null ) {
          isLink = true;
        }
        if ( isLink || isRoom ) {
          const path = event.offlineEvent.extLoginPath || event.offlineEvent.extLogin.url;
          this.extLoginsService.urlAsGuest(event.offlineEvent.extLogin, path)
            .pipe(take(1))
            .subscribe(url => {
              window.open(url, '_blank');
            });
        } else {
          // show dialog that no direct link to event is defined
          this.dialog.closeAll();
          this.dialog.open(GenericMessageDialogComponent, {
            data: {
              title: $localize`:@@live_agenda_no_link_title:No room or link available`,
              message: $localize`:@@live_agenda_no_link_content:Unfortunately there is no linked room or link that can be accessed`,
            },
            panelClass: 'liveAgenda',
          }).afterClosed();
        }
      }))
      .pipe(take(1))
      .subscribe();
  }

  openEventInfo(offlineEvent: AgendaOfflineCnt): void {
    this.liveAgendaDetailsService.getLiveAgendaDetails(offlineEvent.parentId, offlineEvent.id)
      // open dialog with data - other dialogs will be closed before
      .pipe(switchMap(data => {
        this.dialog.closeAll();
        return this.dialog.open(LiveAgendaDetailsDialogComponent, {
          data: {
            panelClass: 'none',
            ...data,
            isPreview: this.isPreview,
            mainColor: !!this.colors?.main[0]?.hex,
          },
        }).afterClosed();
      }))

      .pipe(take(1))
      .subscribe();
  }

  showPicture(tile: AgendaOfflineCnt): string {
    let picture = '';
    if ( tile.pictureUUID !== '0' ) {
      picture = ApiUrls.getKey('Files');
      picture += '/' + tile.pictureUUID;
    } else {
      return '';
    }

    return picture;
  }

  updateLiveStatus(): void {
    timer(0, 10000).subscribe(() => {
      this.tiles.map(row => {
        row.map(tile => {
          this.updateStatusOfTile(tile);
        });
      });
    });
  }

  updateStatusOfTile(tile: AgendaOfflineCnt) {
    if ( tile.placeholder ) {
      return;
    }

    tile.isLive = false;
    tile.isInPast = false;

    const start = moment(tile.eventDate);
    const end = moment(tile.eventDateUntil);
    const now = moment();

    if ( start < now && now < end ) {
      tile.isLive = true;
    }
    if ( end < now ) {
      tile.isInPast = true;
    }
  }

  ngOnInit(): void {

    if ( this.isPreview ) {
      this._fullscreen.next(false);
      this.content = this.previewCurriculum;
      this.fetchEventsForPreview(this.previewEvents);

      if ( this.previewColors ) {
          this.colors = this.previewColors;
          const root = document.documentElement;

        if ( this.previewColors.main?.length > 0 ) {
          root.style.setProperty('--live-main-300', this.colors.main[0]?.hex);
          root.style.setProperty('--live-main-300-contrast', this.colors.main[0]?.black ? '#000' : '#fff');
          root.style.setProperty('--live-main-500', this.colors.main[1]?.hex);
          root.style.setProperty('--live-main-500-contrast', this.colors.main[1]?.black ? '#000' : '#fff');
        }

        if ( this.previewColors.accent?.length > 0 ) {
          root.style.setProperty('--live-accent-200', this.colors.accent[0]?.hex);
          root.style.setProperty('--live-accent-200-contrast', this.colors.accent[0]?.black ? '#000' : '#fff');
          root.style.setProperty('--live-accent-500', this.colors.accent[1]?.hex);
          root.style.setProperty('--live-accent-500-contrast', this.colors.accent[1]?.black ? '#000' : '#fff');
          root.style.setProperty('--live-accent-800', this.colors.accent[2]?.hex);
          root.style.setProperty('--live-accent-800-contrast', this.colors.accent[2]?.black ? '#000' : '#fff');
        }
      }
      if ( this.previewPictureId ) {
        this.pictureId = this.previewPictureId;
      }
      // check live status of tiles
      this.updateLiveStatus();

    } else {

      this.route.data
        .pipe(map(data => {
          this.content = data.content.content;
          this.contentId = this.content.id;
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();

      this.contentService.accountData$
        .pipe(tap(account => {
          const contentId = RouteSnapshotHelper.getParamNumber(this.route.snapshot, 'id');
          const content = ContentService
            .getContentById(account, contentId, false, [ Core.DistributableType.lms_curriculum ]);
          if ( content ) {
            if ( content.color ) {
              this.colors = JSON.parse(content.color);
              const root = document.documentElement;
              if ( this.colors.main?.length > 0 ) {
                root.style.setProperty('--live-main-300', this.colors.main[0]?.hex);
                root.style.setProperty('--live-main-300-contrast', this.colors.main[0]?.black ? '#000' : '#fff');
                root.style.setProperty('--live-main-500', this.colors.main[1]?.hex);
                root.style.setProperty('--live-main-500-contrast', this.colors.main[1]?.black ? '#000' : '#fff');
              }
              if ( this.colors.accent?.length > 0 ) {
                root.style.setProperty('--live-accent-200', this.colors.accent[0]?.hex);
                root.style.setProperty('--live-accent-200-contrast', this.colors.accent[0]?.black ? '#000' : '#fff');
                root.style.setProperty('--live-accent-500', this.colors.accent[1]?.hex);
                root.style.setProperty('--live-accent-500-contrast', this.colors.accent[1]?.black ? '#000' : '#fff');
                root.style.setProperty('--live-accent-800', this.colors.accent[2]?.hex);
                root.style.setProperty('--live-accent-800-contrast', this.colors.accent[2]?.black ? '#000' : '#fff');
              }
            }
            if ( content.pictureId ) {
              this.pictureId = content.pictureId;
            }
            this.content = content;
            this.fetchEvents();

            // check live status of tiles
            this.updateLiveStatus();
          }
        }))
        .pipe(takeUntilDestroyed(this))
        .subscribe();
    }
  }

  private fillArraysToSameLength(): void {
    this.lengthOfColumns = this.getLengthNeededForColumns();
    this.tiles.map(row => {
      if ( row.length < this.lengthOfColumns ) {
        let placeholderToAdd = this.lengthOfColumns - row.length;
        while ( placeholderToAdd > 0 ) {
          row.push(LiveAgendaComponent.generatePlaceholder());
          placeholderToAdd--;
        }
      }
    });
  }

  private fillMatrixWithPlaceholdersToMatchTime() {
    // fill the arrays to make them all the same length
    this.fillArraysToSameLength();

    for ( let item = 0; item < this.lengthOfColumns; item++ ) {
      // get all items of column
      const column: AgendaOfflineCnt[] = [];
      for (const col of this.tiles) {
        column.push(col[item]);
      }

      for ( let o = 0; o < column.length; o++ ) {
        // check if item in column is undefined and generate placeholder
        // this can occur at the end of an array
        if ( column[o] === undefined ) {
          this.tiles[o][item] = LiveAgendaComponent.generatePlaceholder();
          column[o] = LiveAgendaComponent.generatePlaceholder();
        }
      }

      // compare times of column
      const firstAppointmentOfColumn = this.getFirstAppointmentOfColumn(column);

      // insert placeholders if the times do not match
      for ( let n = 0; n < this.tiles.length; n++ ) {
        if ( this.tiles[n][item].eventDate > firstAppointmentOfColumn.eventDate ) {
          LiveAgendaComponent.insertPlaceholderIntoArray(this.tiles[n], item, firstAppointmentOfColumn.eventDate, 'Platzhalter Segment ' + (n + 1) + '.' + (item + 1));
        }
      }
      this.removeUnnecessaryPlaceholderAtTheEnd();
      // update length of columns when necessary
      this.lengthOfColumns = this.getLengthNeededForColumns();
    }
  }

  private getLengthNeededForColumns(): number {
    let maxLength = 0;
    this.tiles.map((row: []) => {
      if ( row.length > maxLength ) {
        maxLength = row.length;
      }
    });

    return maxLength;
  }

  private getFirstAppointmentOfColumn(column: AgendaOfflineCnt[]): AgendaOfflineCnt {
    let firstAppointment: AgendaOfflineCnt = { placeholder: true, eventDate: Number.MAX_VALUE };
    column.map(tile => {
      if ( firstAppointment.eventDate > tile.eventDate ) {
        firstAppointment = tile;
      }
    });
    return firstAppointment;
  }

  private getLastAppointmentOfColumn(column: AgendaOfflineCnt[]): AgendaOfflineCnt {
    let lastAppointment: AgendaOfflineCnt = { placeholder: true, eventDateUntil: 0 };
    column.map(tile => {
      // dont compare placeholder
      if ( !tile.placeholder && lastAppointment.eventDateUntil < tile.eventDateUntil ) {
        lastAppointment = tile;
      }
    });
    return lastAppointment;
  }

  private removeUnnecessaryPlaceholderAtTheEnd(): void {
    for ( let i = 0; i < this.lengthOfColumns; i++ ) {
      // get all items of column
      const column: AgendaOfflineCnt[] = [];
      for (const col of this.tiles) {
        column.push(col[i]);
      }

      let allItemsOfColumnArePlaceholder = true;
      // check if there are only placeholders in the column
      column.map(item => {
          if ( item && !item.placeholder ) {
            allItemsOfColumnArePlaceholder = false;
          }
        },
      );

      // remove column
      if ( allItemsOfColumnArePlaceholder ) {
        for (const col of this.tiles) {
          col.splice(i, 1);
        }
      }

      // update length of columns when necessary
      this.lengthOfColumns = this.getLengthNeededForColumns();
    }
  }

  private sortArraysInTime(): void {
    this.tiles.map(row => {
      row.sort((a, b) => a.eventDate - b.eventDate);
    });
  }
}
