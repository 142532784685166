<rag-dialog-header>
  <span i18n="@@global_ext_login">Virtual platform</span>
</rag-dialog-header>

<div mat-dialog-content>
  <form [formGroup]="form">

    <mat-form-field appearance="outline">
      <mat-label>
        <span i18n="@@global_name">Name</span>
      </mat-label>
      <input
        [formControlName]="'serverName'"
        i18n-placeholder="@@global_name"
        matInput
        placeholder="Name"
        required>
      <mat-error>
        <span
          *ngIf="hasError('serverName', 'required')"
          i18n="@@validation_required">This field is required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="(userPermissionStates$ | async).hasAdobeConnectAdmin" appearance="outline">
      <mat-label>
        <span i18n="@@global_type">Type</span>
      </mat-label>
      <mat-select
        [formControlName]="'serverType'"
        name="type"
        required>
        <mat-option [value]="'any'">
          <span i18n="@@global_any">Any</span>
        </mat-option>
        <mat-option [value]="'adobe_connect'">
          <span i18n="@@ext_login_adobe_connect">Adobe Connect</span>
        </mat-option>
      </mat-select>
      <mat-error>
        <span
          *ngIf="hasError('serverType', 'required')"
          i18n="@@validation_required">This field is required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <span i18n="@@global_url">Url</span>
      </mat-label>
      <input
        [formControlName]="'url'"
        i18n-placeholder="@@global_url"
        matInput
        placeholder="Url"
        required>

      <mat-hint>
        <span i18n="@@ext_login_url_http_required">HTTP scheme is required</span>:
        <span class="gap">http://</span>
        <span class="gap" i18n="@@global_or">or</span>
        <span class="gap">https://</span>
      </mat-hint>

      <div *ngIf="isAdobeConnectServer" [ngSwitch]="mode" matSuffix>
        <mat-icon *ngSwitchCase="'validating'" id="sync" svgIcon="sync"></mat-icon>
        <mat-icon *ngSwitchCase="'validated'" class="validated" svgIcon="checkbox-marked-circle-outline"></mat-icon>
      </div>

      <mat-error>
        <span
          *ngIf="hasError('url', 'required')"
          i18n="@@validation_required">This field is required</span>
        <span
          *ngIf="hasError('url', 'pattern')"
          i18n="@@error_url_pattern">Invalid URL pattern</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="(userPermissionStates$ | async).hasAdobeConnectAdmin" appearance="outline">
      <mat-label>
        <span i18n="@@global_login">Login</span>
      </mat-label>
      <input
        [formControlName]="'login'"
        [required]="isAdobeConnectServer"
        i18n-placeholder="@@global_login"
        matInput
        placeholder="Login">
      <mat-error>
        <span
          *ngIf="hasError('login', 'required')"
          i18n="@@validation_required">This field is required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="(userPermissionStates$ | async).hasAdobeConnectAdmin" appearance="outline">
      <mat-label i18n="@@global_password">Password</mat-label>
      <input
        [formControlName]="'password'"
        [required]="isAdobeConnectServer"
        i18n-placeholder="@@global_password"
        matInput
        placeholder="Password"
        type="password"
        (focus)="onFocusPassword('password')"
      >
      <mat-error>
        <span
          *ngIf="hasError('password', 'required')"
          i18n="@@validation_required">This field is required</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="(userPermissionStates$ | async).hasAdobeConnectAdmin" appearance="outline">
      <mat-label>
        <span i18n="@@pswd_repeat_password">Confirm password</span>
      </mat-label>
      <input
        [formControlName]="'password2'"
        [required]="isAdobeConnectServer"
        i18n-placeholder="@@global_password"
        matInput
        placeholder="Password"
        type="password"
        (focus)="onFocusPassword('password2')"
      >
      <mat-error>
        <span
          *ngIf="hasError('password2', 'required')"
          i18n="@@validation_required">This field is required</span>
        <span
          *ngIf="hasError('password2', 'match')"
          i18n="@@validation_match">The password do not match</span>
      </mat-error>
    </mat-form-field>

  </form>

  <div class="messages">
    <span *ngIf="message != null" [class.error]="message.isError">{{message.text}}</span>
  </div>
</div>

<div mat-dialog-actions>

  <div
    *ngIf="mode === 'validating'"
    class="wait">
    <span i18n="@@global_please_wait">please wait ...</span>
  </div>

  <button (click)="onCancel()" class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    (click)="onSubmit()"
    [disabled]="form.invalid || form.pristine || mode !== 'edit'"
    cdkFocusInitial
    color="primary"
    mat-flat-button
    type="button">

    <span *ngIf="isNew" i18n="@@global_add">Add</span>
    <span *ngIf="!isNew" i18n="@@global_save">Save</span>
  </button>
</div>
