import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../component/table/table-controller/table-controller.types';
import { Core } from '../../../core/core.types';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { ContentService } from '../../../core/content/content.service';
import { Content } from '../../../core/content/content.types';
import { ColumnFilterV2, filterDropDownV2Results } from '../../../core/column-settings/column-filter.types';
import { FilterResultDropDown } from '../../../core/column-settings/filter-api.types';
import { TableColumnBuilder } from '../../../component/table/table-column.builder';
import { TableHelper } from '../../../component/table/table-helper';


export interface ContentOverviewColumnMenuData
  extends TableControllerTypes.ColumnMenuData {
  menuItems: ContentOverviewColumnMenuItemMap;
}

export interface ContentOverviewColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  actions: TableControllerTypes.ColumnMenuItem;
  /**
   * only used for filtering
   */
  assignmentType: TableControllerTypes.ColumnMenuItem;
  /**
   * used for sorting
   */
  eventDate: TableControllerTypes.ColumnMenuItem;
  /**
   * used for filtering
   */
  eventInPast: TableControllerTypes.ColumnMenuItem;
  /**
   * only used for filtering
   */
  eventLocation: TableControllerTypes.ColumnMenuItem;
  information: TableControllerTypes.ColumnMenuItem;
  lastValidSince: TableControllerTypes.ColumnMenuItem;
  lastValidUntil: TableControllerTypes.ColumnMenuItem;
  progress: TableControllerTypes.ColumnMenuItem;
  settings: TableControllerTypes.ColumnMenuItem;
  tags: TableControllerTypes.ColumnMenuItem;
  /**
   * render dropdown to change sorting in card view
   */
  sortAttribute: TableControllerTypes.ColumnMenuItem;
  startdate: TableControllerTypes.ColumnMenuItem;
  displaystatus: TableControllerTypes.ColumnMenuItem;
  title: TableControllerTypes.ColumnMenuItem;
  /**
   * only used for filtering
   */
  objType: TableControllerTypes.ColumnMenuItem;
}

const filterColumnBuilder = TableColumnBuilder.start<Content>()
  .withType(TableColumnDataType.dropdown)
  .withFilterDataAccessor(entry => entry)
  .withFilterDefaultValue('')
  .withFilterDebounceTimeout(0)
  .withHiddenDeselected();

export const CONTENT_OVERVIEW_MENU_COLUMNS: ContentOverviewColumnMenuData = {
  startWith: [ 'actions' ],
  endWith: [],
  menuItems: {

    actions: TableControllerTypes.Util
      .structuralColumnFactory('actions', ''),

    displaystatus: TableControllerTypes.Util.withOptions(
      TableControllerTypes.Util
        .displayStatusColumnFactory('displaystatus', $localize`:@@global_status:Status`, {
          open: $localize`:@@overview_placeholder_select_type_open:Incomplete`,
          done: $localize`:@@overview_placeholder_select_type_done:Completed`,
        }, '', null, true),
      {
        // used in ContentOverviewWidget without permission mtoffc
        dropDownOptionsStatic: ['open'],
        filterDataAccessor: entry => entry,
        filterMethod: (content, filter): boolean => {
          switch ( filter?.value ?? '' ) {
            case 'open':

              // ended accounts cannot be changed -> are not to do
              return (content.currentAccountStatus !== Core.CurriculumAccountStatus.ended) &&
                DisplayStatusHelper.isToDoContentRecursive(content);

            case 'done':

              return DisplayStatusHelper.isStatusGreen(content.displaystatus);

            default:
              return true;
          }
        },
        filterStateAttribute: 'st',
        label: $localize`:@@overview_placeholder_select_type:Select status`,
      },
    ),

    title: TableControllerTypes.Util.withOptions(
      TableControllerTypes.Util
        .textColumnFactory('title', $localize`:@@global_title:Title`, null, true),
      {
        filterDebounceTimeout: 150,
        filterStateAttribute: 'ts',
        label: $localize`:@@global_search:Search`,
      },
    ),

    lastValidSince: TableControllerTypes.Util
      .dateTimeColumnFactory('lastValidSince',
        $localize`:@@content_overview_lastValidSince:Last valid since`, null, false),
    lastValidUntil: TableControllerTypes.Util
      .dateTimeColumnFactory('lastValidUntil',
        $localize`:@@content_overview_lastValidUntil:Last valid until`, null, true),
    startdate: TableControllerTypes.Util
      .dateTimeColumnFactory('startdate',
        $localize`:@@content_overview_startdate:Start date`, null, true),

    progress: TableControllerTypes.Util
      .structuralColumnFactory('progress', $localize`:@@progress:Progress`),
    settings: TableControllerTypes.Util
      .structuralColumnFactory('settings', ''),

    information: TableColumnBuilder.start()
      .withColumnId('information')
      .withTitle($localize`:@@global_information:Information`)
      .withType(TableColumnDataType.text)
      .withSelected()
      .build(),


    objType: TableControllerTypes.Util.withOptions(
      TableControllerTypes.Util
        .dropdownColumnFactory('objType', '', {
          Curriculum: $localize`:@@select_curriculum:Curriculum`,
          Course: $localize`:@@select_course:Course`,
          'Virtual Classroom': $localize`:@@global_virtual_conference:Virtual event`,
          'Face-to-Face event': $localize`:@@select_face_to_face_event:On-site event`,
          'Hybrid event': $localize`:@@global_hybrid_event:Hybrid event`,
          'Recording': $localize`:@@global_recording:Recording`
        }, '', null, false),
      {
        // used in navigation with permission mtoffc
        dropDownOptionsStatic: ['Virtual Classroom', 'Face-to-Face event', 'Hybrid event'],
        filterDataAccessor: entry => entry,
        filterMethod: (content, filter) =>
          ContentService.isTypeMatching(content, filter?.value ?? ''),
        filterStateAttribute: 'ct',
        label: $localize`:@@placeholder_select_type:Select type`,
      },
      {
        hidden: true,
      },
    ),


    assignmentType: TableControllerTypes.Util.withOptions(
      TableControllerTypes.Util
        .dropdownColumnFactory('assignmentType', '', {
          mandatory: $localize`:@@assignment_mandatory:Mandatory`,
          voluntary: $localize`:@@assignment_voluntary:Voluntary`,
          booked: $localize`:@@catalog_booked:Booked contents`,
        }, '', null, false),
      {
        // used in navigation without permission mtoffc
        dropDownOptionsStatic: ['mandatory', 'voluntary'],
        filterDataAccessor: entry => entry,
        filterMethod: (content, filter) =>
          ContentService.isAssignmentMatching(content, filter?.value ?? ''),
        filterStateAttribute: 'at',
        label: $localize`:@@placeholder_select_assignment:Select assignment`,
      },
      {
        hidden: true,
      },
    ),


    eventLocation: TableControllerTypes.Util.withOptions(
      TableControllerTypes.Util
        .dropdownColumnFactory('eventLocation', '', {
          // these options will be dynamically generated
        }, '', null, false),
      {
        filterDataAccessor: entry => entry,
        filterMethodAsync: (filterResults: FilterResultDropDown[], content: Content, filter) =>
          filterDropDownV2Results(filterResults, content?.id, content?.objType, filter),
        label: $localize`:@@general_select_location:Select location`,
        filterStateAttribute: 'lo'
      },
      {
        hidden: true,
      },
    ),


    sortAttribute: TableColumnBuilder.start<Content>()
      .withColumnId('sortAttribute')
      .withType(TableColumnDataType.dropdown)
      .withFilterDataAccessor(() => void (0))
      .withFilterMethod(() => true)
      .withFilterStateAttribute('es')
      .withDropDownWithoutAll()
      .withDropDownOptions({
        title: $localize`:@@global_title:Title`,
        lastValidUntil: $localize`:@@due_date:Due date`,
        eventDate: $localize`:@@content_overview_sort_event_date:Event date`,
      })
      .withLabel($localize`:@@overview_placeholder_select_sort:Sort by`)
      .withHiddenDeselected()
      .build(),

    eventDate: TableColumnBuilder.start<Content>()
      .withColumnId('eventDate')
      .withType(TableColumnDataType.number)
      .withSortingAccessor(content => content?.agendaDate ?? content?.eventDate ?? Number.MIN_VALUE)
      .withHiddenDeselected()
      .build(),

    eventInPast: TableColumnBuilder.start<Content>()
      .withColumnId('eventInPast')
      .withLabel($localize`:@@overview_placeholder_select_type:Select status`)
      .withType(TableColumnDataType.dropdown)
      .withDropDownOptions({
        upcoming: $localize`:@@overview_placeholder_select_event_status_upcoming:Upcoming events`,
        passed: $localize`:@@overview_placeholder_select_event_status_passed:Passed events`,
      })
      .withDropDownOptionsStatic([ 'upcoming' ])
      .withFilterDataAccessor(entry => entry)
      .withFilterMethod((content: Content, filter): boolean => {
        switch ( filter?.value ?? '' ) {
          case 'upcoming':
            return content?.eventInPast === false;

          case 'passed':
            return content?.eventInPast === true;

          default:
            return true;
        }
      })
      .withFilterStateAttribute('st')
      .withHiddenDeselected()
      .build(),

    tags: filterColumnBuilder.clone()
      .withColumnId('tags')
      .withType(TableColumnDataType.tags)
      .withLabel($localize`:@@global_tags:Tags`)
      .withFilterDataAccessor(data => data)
      .withFilterStateAttribute('tags')
      .withFilterMethod((data: Content, filter: ColumnFilterV2) => {
        if ( data == null ) {
          return false;
        }

        const filterTags = TableHelper.splitTags(filter?.value);
        if ( !(filterTags.length > 0) ) {
          return true;
        }
        let contentTags = [];
        if ( data.tags != null ) {
          contentTags = TableHelper.splitTags(data.tags);

          return contentTags
            // check that contentTags contains all filterTags -> boolean and of all selected tags
            .filter(tag => filterTags.includes(tag)).length === filterTags.length;
        } else {
          if ( data?.objType === 'lms_curriculum' ) {

            if ( data.items != null ) {
              const tagsSet = data.items.reduce((pV, content) => {
                TableHelper.splitTags(content.tags).forEach(tag => {
                  if ( filterTags.includes(tag) ) {
                    pV.add(tag);
                  }
                });
                return pV;
              }, new Set<string>());

              // check that contentTags contains all filterTags -> boolean and of all selected tags
              return tagsSet.size === filterTags.length;

            }
          }
        }
        return false;

      })
      .build(),

  },
};
