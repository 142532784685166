<div class="container">
  <div class="hint">
    <span i18n="@@timeSettings_time_for_email">Schedule a time for this email to be sent</span>
  </div>

  <form [formGroup]="form" class="rag-time-settings">

    <mat-form-field appearance="outline">

      <mat-label>
        <span i18n="@@send_date">Date</span>
      </mat-label>

      <mat-datepicker #datePicker></mat-datepicker>

      <input
        [formControlName]="'date'"
        [matDatepickerFilter]="dateTimeFilter"
        [matDatepicker]="datePicker"
        autocomplete="off"
        matInput/>

      <mat-datepicker-toggle [for]="datePicker"
                            matPrefix></mat-datepicker-toggle>
      <button
        (click)="resetDate()"
        *ngIf="timeSettings.selectedDate"
        mat-icon-button
        matSuffix>
          <mat-icon svgIcon="close"></mat-icon>
      </button>

    </mat-form-field>

    <mat-form-field appearance="outline">

      <mat-label>
        <span i18n="@@mail_composer_field_time">Time</span>
      </mat-label>

      <input
        [formControlName]="'time'"
        autocomplete="off"
        matInput
        ragTimePicker/>

      <button
        (click)="resetTime()"
        *ngIf="hasValue('time')"
        mat-icon-button
        matSuffix>
          <mat-icon svgIcon="close"></mat-icon>
      </button>

      <mat-error *ngIf="hasError('time','timeFormat')">
        <span i18n="@@validation_invalid_time_format">Format must be 'HH:MM'</span>
      </mat-error>

    </mat-form-field>

    <mat-form-field appearance="outline">

      <mat-label>
        <span i18n="@@repetition">Repetition</span>
      </mat-label>

      <mat-hint>
        <span i18n="@@time_settings_timespan_format_short">y-m-d (years-months-days)</span>
      </mat-hint>

      <input
        [formControlName]="'repetition'"
        matInput>

      <mat-icon
        i18n-matTooltip="@@time_settings_timespan_format"
        matSuffix
        matTooltip="Format: y-m-d (years-months-days) after the first scheduled time."
        svgIcon="information"></mat-icon>

      <mat-error *ngIf="hasError('repetition','sphereDateSpan')">
        <span i18n="@@time_settings_timespan_format_short">y-m-d (years-months-days)</span>
      </mat-error>

    </mat-form-field>
  </form>
</div>