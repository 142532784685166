import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorSketchModule } from 'ngx-color/sketch';
import { InputModule } from '../../../component/input/input.module';
import { CoreModule } from '../../../core/core.module';
import { AccountDesignComponent } from './account-design.component';
import { FooterConfComponent } from './footer-conf/footer-conf.component';
import { GeneralConfComponent } from './general-conf/general-conf.component';
import { LoginPageConfComponent } from './login-page-conf/login-page-conf.component';
import { WidgetConfComponent } from './widget-conf/widget-conf.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { RagCKEditorModule } from '../../../core/input/rag-ckeditor/rag-ckeditor.module';
import { RouterModule } from '@angular/router';
import { EditLoginPageDialogComponent } from './edit-login-page-dialog/edit-login-page-dialog.component';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';
import { DialogTitleComponent } from 'src/app/component/dialog-title/dialog-title.component';
import { PageHeaderComponent } from 'src/app/component/page-header/page-header.component';

@NgModule({
  declarations: [ AccountDesignComponent, WidgetConfComponent, FooterConfComponent, GeneralConfComponent, LoginPageConfComponent, EditLoginPageDialogComponent ],
  imports: [
    RagCKEditorModule,
    ColorSketchModule,
    CommonModule,
    PageHeaderComponent,
    CoreModule,
    DragDropModule,
    FormsModule,
    InputModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    DialogTitleComponent,
    DialogHeaderComponent,
  ],
})
export class AccountDesignModule {
}
