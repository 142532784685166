import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { PreloadService } from './preload.service';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { State } from '../app.state';
import { Observable } from 'rxjs';


export interface RuntimeEnvironment {
  /**
   * c.f. tinycolor.ColorInput
   */
  accentColor: string;
  disableJiraCollectorInAccount?: string[];
  enableJiraCollector: boolean;
  envJson: boolean;
  hideForIlearn: boolean;
  iLearnSelfRegisterV2?: string[];
  lmsApi: string;

  /**
   * c.f. tinycolor.ColorInput
   */
  mainColor: string;
  momentLocaleDeLongDateFormat: moment.LongDateFormatSpec;
  // @see node_modules/moment/src/lib/locale/formats.js -> defaultLongDateFormat
  momentLocaleEnLongDateFormat: moment.LongDateFormatSpec;
  momentLocaleFrLongDateFormat: moment.LongDateFormatSpec;
  production: boolean;
  showPreAlphaNavigation: boolean;
  showBetaNavigation: boolean;
  /**
   * enable checking content for redirect to ContentLearnerCockpitComponent
   */
  enableLearnerCockpitSwitch: boolean;
  trainApi: string;
}

@Injectable({ providedIn: 'root' })
export class RuntimeEnvironmentService {

  environment: RuntimeEnvironment;
  readonly environment$: Observable<RuntimeEnvironment>;
  private _initialized = false;

  constructor(
    private preloadService: PreloadService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.environment$ = this.preloadService.getEnvJson();
  }

  private static initializeMomentJs(environment: RuntimeEnvironment) {
    switch ( State.language ) {
      case 'en':
        moment.updateLocale('en', {
          longDateFormat: environment.momentLocaleEnLongDateFormat || {
            // @see node_modules/moment/src/lib/locale/formats.js -> defaultLongDateFormat
            LTS: 'h:mm:ss A',
            LT: 'h:mm A',
            L: 'MM/DD/YYYY',
            LL: 'MMMM D, YYYY',
            LLL: 'MMMM D, YYYY h:mm A',
            LLLL: 'dddd, MMMM D, YYYY h:mm A',
          },
          calendar: {
            lastDay: '[yesterday] LT',
            sameDay: '[today], LT',
            nextDay: '[tommorow]',
            lastWeek: '[last] dddd',
            nextWeek: '[next] dddd',
            sameElse: 'L, LT',
          },
        });
        moment.locale('en');
        break;
      case 'fr':
        moment.updateLocale('fr', {
          longDateFormat: environment.momentLocaleFrLongDateFormat || {
            // @see node_modules/moment/src/lib/locale/fr.js -> defaultLongDateFormat
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd, D. MMMM YYYY HH:mm',
          },
          calendar: {
            lastDay: '[yesterday_fr] LT',
            sameDay: '[today_fr], LT',
            nextDay: '[tommorow_fr]',
            lastWeek: '[last_fr] dddd',
            nextWeek: '[next_fr] dddd',
            sameElse: 'L, LT',
          },
        });
        moment.locale('fr');
        break;
      case 'pl':
        moment.updateLocale('pl', {
          longDateFormat: environment.momentLocaleFrLongDateFormat || {
            // @see node_modules/moment/src/lib/locale/fr.js -> defaultLongDateFormat
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd, D. MMMM YYYY HH:mm',
          },
          calendar: {
            lastDay: '[yesterday_fr] LT',
            sameDay: '[today_fr], LT',
            nextDay: '[tommorow_fr]',
            lastWeek: '[last_fr] dddd',
            nextWeek: '[next_fr] dddd',
            sameElse: 'L, LT',
          },
        });
        moment.locale('pl');
        break;
      default:
        moment.updateLocale('de', {
          longDateFormat: environment.momentLocaleDeLongDateFormat || {
            // @see node_modules/moment/locale/de.js:33
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D. MMMM YYYY',
            LLL: 'D. MMMM YYYY HH:mm',
            LLLL: 'dddd, D. MMMM YYYY HH:mm',
          },
          calendar: {
            lastDay: '[Gestern], LT',
            sameDay: '[Heute], LT',
            nextDay: '[Morgen]',
            lastWeek: '[letzten] dddd',
            nextWeek: '[nächsten] dddd',
            sameElse: 'L, LT',
          },
        });
        moment.locale('de');
    }
  }

  onInit(): void {
    if ( this._initialized ) {
      return;
    }
    this._initialized = true;

    this.environment$
      .pipe(tap(this.setEnvironment))
      .subscribe();
  }

  private setEnvironment = (environment: RuntimeEnvironment): void => {
    this.environment = environment;

    RuntimeEnvironmentService.initializeMomentJs(environment);
  };

}
