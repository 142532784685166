<rag-page-header>
  <div class="page-header-title" i18n="@@admin_dev_goals_distribution_headline">Content distribution</div>

  <div class="page-header-buttons">
    <button
      type="button"
      (click)="onSave()"
      [disabled]="inputDisabled || !hasChanges"
      color="primary"
      mat-flat-button
    >
      <span i18n="@@global_save">Save</span>
    </button>

    <button
      type="button"
      [matMenuTriggerFor]="headerActionsMenu"
      class="rag-stroked-button"
      mat-stroked-button
    >
      <mat-icon svgIcon="menu"></mat-icon>

      <mat-menu #headerActionsMenu>

        <button
          type="button"
          (click)="onReset()"
          [disabled]="!hasChanges"
          mat-menu-item
        >
          <mat-icon svgIcon="cancel"></mat-icon>
          <span i18n="@@global_reset">Reset</span>
        </button>

      </mat-menu>
    </button>
  </div>
</rag-page-header>


<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@ctrl_single_user_no_users">There are no users available for controlling.</span>
  </div>
</ng-template>

<ng-template #noAssignment>
  <div class="no-data">
    <span i18n="@@ctrl_single_user_no_assignment">There is currently no assignment for controlling.</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<rag-table-column-menu
  #curriculumColumnMenu
  (evtColumnsChanged)="setCurriculumColumns($event)"
  [menuData$]="curriculumColumnMenuData"
  [forcedEnabledColumnIds]="getModifiedColumns()"
  menuContext="LicenseGroupToTg@curriculumColumns"
></rag-table-column-menu>

<rag-table-column-menu
  #userColumnMenu
  (evtColumnsChanged)="setUserColumns($event)"
  [menuData$]="userColumnMenuData"
  menuContext="LicenseGroupToTg@userColumns"
></rag-table-column-menu>

<ng-container *ngIf="isDataLoaded; else tplDataLoading">
  <ng-container *ngIf="!isDataEmpty; else noAssignment">
    <div
      *ngIf="(columns?.length > 0) else tplDataLoading"
      class="content"
    >
      <rag-sticky-scroll [recalculate]="recalculateSticky$">
        <ng-container
          *ngIf="isPaginatorSet; else tplDataLoading"
        >
          <table
            *ngIf="(dataSource.data?.length > 0); else tplDataEmpty"
            [dataSource]="dataSource"
            mat-table
            matSort
          >
            <!-- user columns -->
            <ng-container *ngFor="let column of userMenuItems" [matColumnDef]="column.id">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{column.title | localeText}}
              </th>
              <td *matCellDef="let row" mat-cell>
                <rag-tables-column-render-default
                  [column]="column"
                  [row]="row"
                ></rag-tables-column-render-default>
              </td>
            </ng-container>
            <ng-container *ngFor="let column of userMenuItems" [matColumnDef]="column.id + 'Filter'">
              <th *matHeaderCellDef mat-header-cell>
                <rag-table-header-filter-default
                  [column]="column"
                  (filterChange)="onFilterChange($event.filter, column)"
                ></rag-table-header-filter-default>
              </th>
            </ng-container>

            <!-- column menu for user -->
            <ng-container matColumnDef="userColumnMenu">
              <th *matHeaderCellDef mat-header-cell class="icon">
                <button
                  (click)="userColumnMenu.openMenu($event)"
                  mat-icon-button
                >
                  <mat-icon svgIcon="dots-vertical"></mat-icon>
                </button>
              </th>
              <td *matCellDef mat-cell class="icon"></td>
            </ng-container>
            <ng-container matColumnDef="userColumnMenuFilter">
              <th *matHeaderCellDef mat-header-cell class="icon">
                <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
                  <mat-icon svgIcon="filter-off-outline"></mat-icon>
                </button>
              </th>
            </ng-container>

            <!-- curriculum columns -->
            <ng-container *ngFor="let column of curriculumMenuItems" [matColumnDef]="column.id">
              <th *matHeaderCellDef mat-header-cell>
                <rag-licence-group-column-statistics
                  [statistics]="columnStatistics[column.id]"
                ></rag-licence-group-column-statistics>
              </th>
              <td *matCellDef="let row" mat-cell class="assignment">
                <ng-container *ngIf="asAssignment(row, column) as assignment">
                  <rag-checkbox
                    label="Mandatory"
                    i18n-label="@@global_required"
                    [ngClass]="{pointer: assignment.mandatory.disabled}"
                    [valueState]="assignment.mandatory"
                    (valueStateChanged)="onChange()"
                    (click)="onShowInfo(row, column, assignment, assignment.mandatory.disabled)"
                  ></rag-checkbox>
                  <rag-checkbox
                    label="Voluntary"
                    i18n-label="@@assignment_voluntary"
                    [ngClass]="{pointer: assignment.voluntary.disabled}"
                    [valueState]="assignment.voluntary"
                    (valueStateChanged)="onChange()"
                    (click)="onShowInfo(row, column, assignment, assignment.voluntary.disabled)"
                  ></rag-checkbox>
                </ng-container>
              </td>
            </ng-container>
            <ng-container *ngFor="let column of curriculumMenuItems" [matColumnDef]="column.id + 'Filter'">
              <th *matHeaderCellDef="let row" mat-header-cell class="assignment filter">
                <ng-container *ngIf="columnStatistics[column.id] as statistics">
                  <rag-checkbox
                    label="Mandatory"
                    i18n-label="@@global_required"
                    [valueState]="statistics.mandatory.input"
                    (valueStateChanged)="onChangeAll(statistics, column.id, true)"
                  ></rag-checkbox>
                  <rag-checkbox
                    label="Voluntary"
                    i18n-label="@@assignment_voluntary"
                    [valueState]="statistics.voluntary.input"
                    (valueStateChanged)="onChangeAll(statistics, column.id, false)"
                  ></rag-checkbox>
                </ng-container>
              </th>
            </ng-container>

            <!-- column menu for curriculum -->
            <ng-container matColumnDef="curriculumColumnMenu">
              <th *matHeaderCellDef mat-header-cell class="no-rotate icon spacing">
                <button
                  (click)="curriculumColumnMenu.openMenu($event)"
                  mat-icon-button
                >
                  <mat-icon svgIcon="dots-vertical"></mat-icon>
                </button>
              </th>
              <td *matCellDef mat-cell class="icon"></td>
            </ng-container>
            <ng-container matColumnDef="curriculumColumnMenuFilter">
              <th *matHeaderCellDef mat-header-cell class="icon"></th>
            </ng-container>

            <!-- columns for curriculum titles -->
            <ng-container *ngFor="let column of userMenuItems" [matColumnDef]="column.id + 'CurriculumTitle'">
              <th *matHeaderCellDef mat-header-cell></th>
            </ng-container>
            <ng-container [matColumnDef]="'userColumnMenuCurriculumTitle'">
              <th *matHeaderCellDef mat-header-cell class="icon"></th>
            </ng-container>
            <ng-container *ngFor="let column of curriculumMenuItems" [matColumnDef]="column.id + 'CurriculumTitle'">
              <th *matHeaderCellDef mat-header-cell class="rotate transparent">
                <div
                  *ngIf="!isIE && (curriculumColumns[0] === column.id)"
                  [ngStyle]="{'width': 'calc(100% * ' + (curriculumColumns.length || 1) + ' + 40px)'}"
                  class="cover">
                </div>
                <rag-licence-group-column-header
                  *ngIf="columnStatistics[column.id] as statistics"
                  [column]="column"
                  [menu]="curriculumColumnMenu"
                  [canNotRemove]=" statistics.changed || curriculumColumns.length <=3">
                </rag-licence-group-column-header>
              </th>
            </ng-container>
            <ng-container [matColumnDef]="'curriculumColumnMenuCurriculumTitle'">
              <th *matHeaderCellDef mat-header-cell class="icon transparent"></th>
            </ng-container>

            <tr *matHeaderRowDef="curriculumTitleColumns; sticky: true" mat-header-row></tr>
            <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
            <tr *matHeaderRowDef="columnsFilter; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: columns;" mat-row></tr>
          </table>

          <div class="no-data" *ngIf="isFilteredDataEmpty">
            <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
          </div>
        </ng-container>

      </rag-sticky-scroll>

      <mat-paginator
        [pageSize]="20"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </ng-container>
</ng-container>
