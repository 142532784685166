import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../api.urls';
import {
  EhsNotificationHistoryMessage,
  EhsNotificationHistoryResponse,
  EhsNotificationsResponse,
  EventModule,
} from './ehs.types';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { InfoService } from '../info/info.service';
import { CancelButton, InfoType, MessageConstants, YesButton, YesNoButtons } from '../info/info.types';
import { catchError, delay, map, mapTo, switchMap, takeWhile, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Core, AnyObject } from '../core.types';
import { AdminOfflineNotificationRow } from 'src/app/route/admin/admin-offline/components/content-notifications/content-notifications.columns';
import { GenericMessageDialogComponent } from '../../component/generic-message-dialog/generic-message-dialog.component';
import { ContentNotificationsHelper } from '../../route/admin/admin-offline/components/content-notifications/content-notifications.helper';
import { Signature, NotificationTemplate, NotificationTemplateDetails } from '../../route/admin/admin-offline/components/content-notifications/content-notifications.types';
import { NotificationEventChangeSaveData } from '../../route/admin/admin-event-actions/admin-notifications/admin-notifications.types';
import { UrlCallDetails } from '../../route/admin/admin-event-actions/admin-url-call/admin-url-call.types';
import { ApiResponse } from 'src/app/core/global.types';

@Injectable({ providedIn: 'root' })
export class EhsService {

  readonly needsUpdate$: Observable<void>;
  private _needsUpdate$ = new EventEmitter<void>(true);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private infoService: InfoService,
  ) {
    this.needsUpdate$ = this._needsUpdate$.asObservable();
  }

  static getUrl(action: AdminOfflineNotificationRow | null, dispatch: string): string | null {
    const actionId = action?.actionId;
    if ( !(actionId > 0) ) {
      return null;
    }

    switch ( dispatch ?? '' ) {

      case 'showDetails':
        return ApiUrls.getKey('EhsLinkDetails')
          .replace(/{actionId}/gi, String(actionId));

      case 'edit':
        return ApiUrls.getKey('EhsLinkEdit')
          .replace(/{actionId}/gi, String(actionId));

      default:
        return null;
    }
  }

  /**
   * if a whitelist of eventIds is returned, only these events should be allowed for selection
   */
  static getEventIdWhitelist(
    objectType?: Core.DistributableType | string,
    objectSubType?: Core.CourseType | number,
  ): number[] | null {
    if ((objectType == null) || (objectSubType == null)) {
      return null;
    }

    switch (objectType) {
      case Core.DistributableType.lms_course:
        switch (objectSubType) {
          case Core.CourseType.Recording:
          case Core.CourseType.Link:
          case Core.CourseType.ToDo:
          case Core.CourseType.Learning:
            return [
              110002, // WBT_COURSE_DELETED
              112002, // WBT_LEARN_ASSIGNED
              112006, // WBT_LEARN_COMPLETED
              112009, // WBT_LEARN_NOT_ATTEMPTED
              112004, // WBT_LEARN_START
              112003, // WBT_LEARN_UNASSIGNED
            ];
          case Core.CourseType.Test:
            return [
              113002, // WBT_TEST_ASSIGNED
              113003, // WBT_TEST_UNASSIGNED
              113004, // WBT_TEST_START
              113006, // WBT_TEST_PASSED
              113007, // WBT_TEST_COMPLETED
              113008, // WBT_TEST_FAILED
            ];
          case Core.CourseType.Certification:
            return null;
            // return [
            //   114002, // WBT_CERT_ASSIGNED
            //   114003, // WBT_CERT_UNASSIGNED
            //   114004, // WBT_CERT_START
            //   114006, // WBT_CERT_PASSED
            //   114007, // WBT_CERT_COMPLETED
            //   114008, // WBT_CERT_FAILED
            // ];
          case Core.CourseType.ScoDocument:
            return [
              115002, // WBT_DOC_ASSIGNED
              115003, // WBT_DOC_UNASSIGNED
              115004, // WBT_DOC_START
              115006, // WBT_DOC_COMPLETED,
              115009, // WBT_DOC_NOT_ATTEMPTED
            ]
        }
        break;
    }
    return [];
  }

  changeEventAction(data: NotificationEventChangeSaveData): Observable<ApiResponse<void>> {
    let url = ApiUrls.getKey('AdminNotificationsChangeEventAction')
      .replace(/{eventActionId}/gi, String(data.actionId))
      .replace(/{eventModuleId}/gi, String(data.eventModuleId))
      .replace(/{eventId}/gi, String(data.eventId));
      // .replace(/{targetObjectId}/gi, String(data.targetId));

    if (data.targetId != null) {
      url += `&targetObjectID=${String(data.targetId)}`;
    }

    if (data['parentTargetId'] != null) {
      url += `&parentTargetObjectID=${String(data.parentTargetId)}`;
    }

    return this.http.post<ApiResponse<void>>(url, null);
  }

  copyEventAction(eventActionId: number, targetObjectId: number): Observable<number> {
    const url = ApiUrls.getKey('AdminNotificationsCopy')
      .replace(/{eventActionId}/gi, String(eventActionId))
      .replace(/{targetObjectId}/gi, String(targetObjectId))
      .replace(/{copyOf}/gi, $localize`:@@global_copy_of:Copy of `);

    return this.http.get<ApiResponse<number>>(url)
      .pipe(map(data => data.actionID));
  }

  createUrlCall(contentId: number): UrlCallDetails {
    return {
      url: '',
      description: '',
      enabled: 'false',
      offset: '',
      eventId: '0',
      actionType: '1',
      title: '',
      targetObjectId: String(contentId),
      eventModuleId: '0',
      excludedTgs: '',
      // extraConditions: '',
      id: '0',
      parameter: '',
      requestMethod: '',
      targetGroupIds: '',
      $view: null,
      hasInterval: 'false',
      urlCallDescription: '',
      urlCallTitle: '',
      intervalSpan: '',
      contextPattern: '',
      defaultTemplate: 'false',
    };
  }

  deleteEhsAction(actionId: number): Observable<void> {
    if ( !(actionId > 0) ) {
      return EMPTY;
    }

    const url = ApiUrls.getKey('EhsNotificationDelete')
      .replace(/{actionId}/gi, String(actionId));

    return this.infoService.showMessage($localize`:@@ehs_delete_confirm:
      Do you wish to delete this event action?`, {
      buttons: YesNoButtons,
      title: MessageConstants.DIALOG.TITLE.CONFIRM,
    })

      // abort if a button, different from YesButton has been clicked
      .pipe(takeWhile(button => button === YesButton))

      // todo replace with actual API call (in classic or NG)
      .pipe(switchMap(() => this.http.post(url, null, { responseType: 'text' })))
      .pipe(catchError(this.handleError))

      .pipe(map(() => {
        this.infoService.showMessage(
          $localize`:@@evs_delete_success:The event action has been successfully deleted.`,
          { infoType: InfoType.Success });
        // notify components about changes
        this._needsUpdate$.emit();
      }));
  }

  fetchUrlCall(urlCallId: number): Observable<UrlCallDetails> {
    const url = ApiUrls.getKey('AdminFetchUrlCalls')
      .replace(/{urlCallId}/gi, String(urlCallId));

    return this.http.get<UrlCallDetails>(url);
  }

  getDefaultNotifications(
    eventModule: EventModule,
  ): Observable<EhsNotificationsResponse> {
    const url = ApiUrls.getKey('EhsNotificationDefault')
      .replace(/{eventModule}/gi, String(eventModule));
    return this.http.get<EhsNotificationsResponse>(url);
  }

  getNotificationHistory(
    actionId: number,
  ): Observable<EhsNotificationHistoryResponse> {
    const url = ApiUrls.getKey('EhsNotificationHistory')
      .replace(/{actionId}/gi, String(actionId));
    return this.http.get<EhsNotificationHistoryResponse>(url);
  }

  getNotificationHistoryMessage(
    actionId: number,
    userContextUserId: number,
    sendDate: number,
  ): Observable<EhsNotificationHistoryMessage> {
    const url = ApiUrls.getKey('EhsNotificationHistoryMessage')
      .replace(/{actionId}/gi, String(actionId))
      .replace(/{userContextUserId}/gi, String(userContextUserId))
      .replace(/{sendDate}/gi, String(sendDate));
    return this.http.get<EhsNotificationHistoryMessage>(url)
      .pipe(catchError(this.handleError));
  }

  getNotifications(eventModule: EventModule, targetObjectId: number): Observable<EhsNotificationsResponse> {
    const url = ApiUrls.getKey('EhsNotification')
      .replace(/{eventModule}/gi, String(eventModule))
      .replace(/{targetObjectId}/gi, String(targetObjectId));
    return this.http.get<EhsNotificationsResponse>(url);
  }

  getNotificationTemplates(): Observable<NotificationTemplate[]> {
    const url = ApiUrls.getKey('EhsNotificationTemplates');
    return this.http.get<ApiResponse<NotificationTemplate[]>>(url)
      .pipe(map(response => response.templates));
  }

  getNotificationTemplateDetails(id: string): Observable<NotificationTemplateDetails> {
    const url = ApiUrls.getKey('EhsNotificationTemplateDetails')
      .replace(/{ID}/gi, id);
    return this.http.get<NotificationTemplateDetails>(url);
  }

  getSignatures(): Observable<Array<Signature>> {
    const url = ApiUrls.getKey('EhsSignatures');
    return this.http.get<Array<Signature>>(url);
  }

  setNotificationStatus(actionId: number, enabled: boolean): Observable<void> {

    const url = ApiUrls.getKey('EhsNotificationStatus')
      .replace(/{actionId}/gi, String(actionId))
      .replace(/{enabled}/gi, String(enabled));

    return this.http.get<void>(url)
      .pipe(tap(_ => {
        // notify components about changes
        this._needsUpdate$.emit();
      }))
      .pipe(catchError(this.handleError))
      .pipe(map(() => {
        this.infoService.showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success });
        // notify components about changes
      }));
  }

  setNotificationStatusBatch(
    actions: AdminOfflineNotificationRow[] | null,
    enabled: boolean,
  ): Observable<void> {

    const queries = (actions ?? [])
      .filter(action => ContentNotificationsHelper.isEnabled(action.enabled) !== enabled)
      .map(action =>
        this.setNotificationStatus(action.actionId, enabled)
          .pipe(tap(() => {
            // update new enabled status
            action.enabled = ContentNotificationsHelper.getEnabledStatus(enabled, action.enabledEndDate);
          })),
      );

    if ( queries.length === 0 ) {
      return EMPTY;
    }

    const messageDescription = enabled ?
      $localize`:@@offline_cnt_event_allactivate:Do you want to activate all the notifications?` :
      $localize`:@@offline_cnt_event_alldeactivate:Do you want to deactivate all the notifications?`;
    return this.infoService
      .showDialog(GenericMessageDialogComponent, {
        title: MessageConstants.DIALOG.TITLE.CONFIRM,
        message: messageDescription,
        buttons: CancelButton | YesButton,
      })
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => forkJoin(queries)))
      .pipe(catchError(this.handleError))
      .pipe(tap(() => this.infoService
        .showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success })))
      .pipe(mapTo(void (0)));
  }

  /**
   * @deprecated this needs to replaced with a NG frontend that replaces the current classic implementation
   */
  openNewNotification(contentId: number, eventModuleId: number, eventId: number): void {
    if ( !((eventId > 0) && (eventModuleId > 0) && (contentId > 0)) ) {
      return;
    }

    const url = ApiUrls.getKey('EhsNotificationAdd');

    // initialize form in classic
    of(window.open(url + '&dispatch=show', 'EHS'))
      // delay a bit to allow train to do its thing
      .pipe(delay(500))

      .pipe(tap(() => {
        // create form with required parameters
        const form = this.createFormPost(url, {
          dispatch: 'performForm',
          newAction: '',
          eventModuleID: String(eventModuleId),
          targetObjectID: String(contentId),
          eventID: String(eventId),
          compound: 'false',
        });

        // append and submit form - opens a new tab
        const body = this.document.body;
        form.style.display = 'none';
        body.appendChild(form);
        form.submit();
        body.removeChild(form);
      }))
      .subscribe();
  }

  private createFormPost(url: string, params: AnyObject<string>): HTMLFormElement {
    const div = this.document.createElement('div');

    div.innerHTML = `<form target="EHS" method="post" enctype="multipart/form-data" `
      + `action="${url.replace('&', '&amp;')}">`
      + Object.entries(params ?? {})
        .map(([ key, value ]) =>
          `<input type="hidden" name="${encodeURIComponent(key)}" value="${encodeURIComponent(value)}">`)
        .join('\n')
      + '</form>';

    return div.querySelector('form');
  }

  private handleError = (): Observable<never> => {
    this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
    return EMPTY;
  };

}
