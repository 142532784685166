<rag-page-header>
  <div class="page-header-title" i18n="@@linked_users_page_header">Linked Users</div>

  <div class="page-header-buttons">
    <button (click)="showDialogForAddingNewLinkedUser()" class="button-wrapper" color="primary" mat-flat-button>
      <mat-icon svgIcon="plus"></mat-icon>
      <span i18n="@@linked_users_add_user_button">Add user</span>
    </button>
  </div>
</rag-page-header>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  [menuData$]="columnMenuData$"
  menuContext="LinkedUsers@columnSettings">
</rag-table-column-menu>

<!-- Context menu -->
<mat-menu #userActionsMenu>
  <ng-template matMenuContent let-user="user">
    <button (click)="switchAccount(user)" mat-menu-item>
      <mat-icon svgIcon="account-switch"></mat-icon>
      <span i18n="@@linked_users_button_switch_account">Switch Account</span>
    </button>
    <button (click)="deleteLinkedUser(user)" mat-menu-item>
      <mat-icon color="warn" svgIcon="delete"></mat-icon>
      <span i18n="@@linked_users_button_delete">Delete</span>
    </button>
  </ng-template>
</mat-menu>

<div class="content">
  <rag-sticky-scroll
    [recalculate]="recalculateSticky$"
  >
    <table *ngIf="(dataSource.data?.length > 0)"
          [dataSource]="dataSource" mat-table matSort>

      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{column.title | localeText}}
        </th>
        <td *matCellDef="let row" mat-cell>
          <ng-container *ngIf="column.options.dataType === 'date'">{{row[column.id] | formatDate}}</ng-container>
          <ng-container *ngIf="column.options.dataType === 'number'">{{row[column.id]}}</ng-container>
          <ng-container *ngIf="column.options.dataType === 'text'">{{row[column.id] | localeText}}</ng-container>
          <div *ngIf="column.options.dataType === 'html'" [innerHTML]="row[column.id] | safe:'html'"></div>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
        <th *matHeaderCellDef mat-header-cell>
          <rag-table-header-filter-default
            [column]="column"
            (filterChange)="onFilterChange($event.filter, column)">
          </rag-table-header-filter-default>
        </th>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
        </th>
        <td *matCellDef="let user" class="actions" mat-cell>
          <button
            [disabled]="inputDisabled"
            [matMenuTriggerFor]="userActionsMenu"
            [matMenuTriggerData]="{user: user}"
            mat-icon-button
          >
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsFilter" stickyEnd>
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
            <mat-icon svgIcon="filter-off-outline"></mat-icon>
          </button>
        </th>
      </ng-container>

      <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
      <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns;"  mat-row></tr>
    </table>

    <div class="no-data" *ngIf="isFilteredDataEmpty">
      <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
    </div>

  </rag-sticky-scroll>
  
  <div [ngClass]="{paginatorInVisibility: isFilteredEmpty}">
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
