<div class="container">
  <div class="contents">
    <rag-catalog-view
      [embedded]="true"
      [isCards]="isBoxViewMode"
      [sortBy]="null"
      [contents]="contents"
      [maxItems]="maxItems | async"
    ></rag-catalog-view>
  </div>
  <div class="actions">
    <a [queryParams]="{catalogState: 'not_booked', viewType: 'cards', contentType: 'lms_offlineCnt'}"
       color="primary"
       mat-flat-button
       routerLink="/catalog">
      <span i18n="@@all_events">All events</span>
    </a>
  </div>

</div>

<!-- Custom menu item -->
<ng-template #viewMode>
  <button (click)="toggleViewMode()" mat-button>
    <mat-icon [svgIcon]="isBoxViewMode ? 'view-list' : 'view-module'" color="primary"></mat-icon>
    <span *ngIf="!isBoxViewMode" i18n="@@overview_cards_view">Cards view</span>
    <span *ngIf="isBoxViewMode" i18n="@@global_list_view">List view</span>
  </button>
</ng-template>
