<rag-page-header
  backUrl="/admin/qa"
>
  <div class="page-header-title">
    <span i18n="@@global_edit_contribution">Edit contribution</span>
  </div>
  <div class="page-header-buttons">
    <button
      type="button"
      mat-flat-button
      color="primary"
      [disabled]="saveButtonDisabled$ | async"
      (click)="onSaveQuestion()"
    >
      <span i18n="@@global_save">Save</span>
    </button>
  </div>
</rag-page-header>

<form [formGroup]="form">
  <div class="main">

    <div class="flex-box">
      <h4 class="headline" i18n="@@global_contribution">Contribution</h4>
      <button
        *ngIf="hasQuestionChanges()"
        type="button"
        mat-icon-button
        (click)="onResetQuestion()"
        matTooltip="Reset"
        i18n-matTooltip="@@global_reset"
      >
        <mat-icon svgIcon="undo"></mat-icon>
      </button>

    </div>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@global_contribution_title">Contribution title</mat-label>
      <input
        required
        type="text"
        matInput
        formControlName="questionTitle"
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <textarea
        required
        formControlName="questionText"
        matInput
        [rows]="10"
        placeholder="Contribution"
        i18n-placeholder="@@global_contribution"
      >
      </textarea>
    </mat-form-field>

    <h4 class="headline" i18n="@@global_answer">Answer</h4>
    <mat-form-field appearance="outline">
      <textarea
        required
        formControlName="answerText"
        matInput
        [rows]="10"
        placeholder="Answer"
        i18n-placeholder="@@global_answer"
      >
      </textarea>
    </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label i18n="@@global_tags">Tags</mat-label>
    <rag-tags
      [allTags]="allTags"
      [formControlName]="'tags'"
    ></rag-tags>
  </mat-form-field>
  </div>
</form>
