import { TableControllerTypes } from '../../../../component/table/table-controller/table-controller.types';
import { ColumnSettings, ReportConfig } from '../../../../core/report/report.types';
import { ReportTableColumnMenuData } from './report-table/report-table.types';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { DateHelper } from '../../../../core/date.helper';


export class ReportGeneratorV2UpdateHelper {

  static asColumnSettings(
    menuItems: TableControllerTypes.ColumnMenuItemMap,
  ) {
    return Object.values(menuItems)

      // sort items by order index
      .sort((a, b) => a.orderIndex - b.orderIndex)

      .map(menuItem => {

        if ( menuItem.hidden === true ) {
          // ignore hidden columns
          return null;
        }

        const filter = menuItem.options?.filter;
        const hasFilter = !!(filter?.value && filter.action);
        const enabled = hasFilter || (menuItem.selected === true);
        return {
          enabled,
          id: menuItem.id,
          selected: enabled,
          // the title is required for the grouping component
          title: menuItem.title,
        } as ColumnSettings;
      })

      // exclude any null values
      .filter(entry => entry != null);
  }

  static fromColumnOptionsToReportConfig(
    menuItems: TableControllerTypes.ColumnMenuItemMap | null,
    reportConfig: ReportConfig | null,
  ): boolean {

    if ( reportConfig == null || menuItems == null ) {
      return false;
    }

    const columnsChanged = this.copyColumnSettingsToConfig(reportConfig, menuItems);
    const filterChanged = this.copyFiltersToConfig(reportConfig, menuItems);

    return columnsChanged || filterChanged;
  }

  static fromReportConfigToColumnOptions(
    reportConfig: ReportConfig | null,
    menuData: ReportTableColumnMenuData | null,
  ): boolean {

    if ( reportConfig == null || menuData?.menuItems == null ) {
      return false;
    }

    const filterChanged = this.copyFiltersFromConfig(reportConfig, menuData.menuItems);
    const columnsChanged = this.copyColumnSettingsFromConfig(reportConfig, menuData);

    return columnsChanged || filterChanged;
  }

  /**
   * Warning! Filters have to be merged first, to allow force-enabling columns with active filters
   * @private
   */
  private static copyColumnSettingsFromConfig(
    reportConfig: ReportConfig,
    menuData: ReportTableColumnMenuData,
  ): boolean {

    const menuItems = menuData.menuItems;
    const otherMenuItems = { ...menuItems };

    let index = 0;
    const columnsChanged = (reportConfig.columnSettings ?? [])
      .map(settings => {

        const columnId = settings.id;
        const menuItem = menuItems[columnId];
        const options = menuItem?.options;
        if ( options == null || menuItem.hidden ) {
          // ignore structural columns and such
          return false;
        }

        delete otherMenuItems[columnId];
        const orderIndex = index++;

        const selected = settings.enabled && settings.selected;
        let columnChanged = (menuItem.selected !== selected) || (menuItem.orderIndex !== orderIndex);

        if ( columnChanged ) {
          menuItem.selected = selected;
          menuItem.orderIndex = orderIndex;
        }

        const filter = options.filter;
        const filterActive = !!(filter?.value && filter.action);
        if ( filterActive && !selected ) {
          // force active filters to be selected
          menuItem.selected = true;
          columnChanged = true;
        }

        return columnChanged;
      })
      // find any changed columns
      .findIndex(changed => changed === true) !== -1;

    // place other items last and deselect them
    const selectedChanged = Object.values(otherMenuItems).map(entry => {
      if (entry.hidden) {
        // skip system columns
        return;
      }

      entry.orderIndex = index++;

      const columnChanged = (entry.hidden !== false) || (entry.selected !== true);
      if ( columnChanged ) {
        // de-select and hide unmapped columns
        entry.selected = false;
        entry.hidden = true;
      }

      return columnChanged;
    })
      // find any changed columns
      .findIndex(changed => changed === true) !== -1;

    TableColumnMenuService.sortByTitle(menuData, menuItems);

    return columnsChanged || selectedChanged;
  }

  private static copyColumnSettingsToConfig(
    reportConfig: ReportConfig,
    menuItems: TableControllerTypes.ColumnMenuItemMap,
  ): boolean {

    if ( reportConfig.columnSettings == null ) {
      reportConfig.columnSettings = [];
    }

    const columnSettings = reportConfig.columnSettings;
    const previousSettings = JSON.stringify(columnSettings);

    // clear previous settings and copy from menuItems
    columnSettings.splice(0);
    columnSettings.push(...this.asColumnSettings(menuItems));

    return previousSettings !== JSON.stringify(reportConfig.columnSettings);
  }

  private static copyFiltersFromConfig(
    reportConfig: ReportConfig,
    menuItems: TableControllerTypes.ColumnMenuItemMap,
  ): boolean {

    return Object.entries(reportConfig.settings ?? {})
      .map(([ columnId, settings ]) => {

        const menuItem = menuItems[columnId];
        const options = menuItem?.options;
        if ( options == null ) {
          // hmm... should not happen...
          return false;
        }

        const filter = options.filter;
        const isFilterActive = (filter.action) && (filter.value);
        if ( isFilterActive ) {
          // active filters should be visible as columns
          menuItem.selected = true;
        }

        if ( (filter.action === settings.filterAction) && (filter.value === settings.filter) ) {
          // filter unchanged
          return false;
        }

        // update filter
        filter.action = settings.filterAction;
        filter.value = settings.filter;

        // update default value to reset filters to previous state
        if ( filter.value != null && filter.value !== '' ) {
          filter.defaultValue = filter.value;
        } else {
          filter.defaultValue = null;
        }

        return true;
      })
      // find any changed filters
      .findIndex(changed => changed === true) !== -1;
  }

  private static copyFiltersToConfig(
    reportConfig: ReportConfig,
    menuItems: TableControllerTypes.ColumnMenuItemMap,
  ): boolean {

    if ( reportConfig.settings == null ) {
      // create missing settings object
      reportConfig.settings = {};
    }

    const settings = reportConfig.settings;
    const previousSettings = JSON.stringify(settings);

    // clear state
    Object.keys(settings).map(key => delete settings[key]);

    // generate new from menuItems
    Object.values(menuItems)
      .map(menuItem => menuItem?.options?.filter)

      // only include active filters
      .filter(filter => !!(filter?.value && filter.action))

      .map(filter => {
        settings[filter.identifier] = {
          filter: this.getFilterValueForReportConfig(filter.value),
          filterAction: filter.action,
        };
      });

    return (previousSettings !== JSON.stringify(settings));
  }

  private static getFilterValueForReportConfig(
    value: any,
  ): any {

    if ( DateHelper.isMoment(value) ) {
      return DateHelper.toUnixFromMoment(value);
    }

    return value;
  }

}
