import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlideshowTypes } from './slideshow.types';
import { Observable } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import { SlideshowAnimations } from './slideshow.animations';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

/**
 * @see https://medium.com/showpad-engineering/angular-animations-lets-create-a-carousel-with-reusable-animations-81c0dd8847e8
 */
@Component({
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
  ],
  selector: 'rag-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: [ './slideshow.component.scss' ],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [ useAnimation(SlideshowAnimations.fadeIn) ]),
      transition('* => void', [ useAnimation(SlideshowAnimations.fadeOut) ]),
    ]),
  ],
})
export class SlideshowComponent<T>
  implements OnInit {

  @Input()
  autoplay = false;
  @Input()
  autoplayInterval = 5000;
  currentSlide = 0;
  @Output()
  onclick: Observable<SlideshowTypes.Slide<T>>;
  private _onclick = new EventEmitter<SlideshowTypes.Slide<T>>();
  private _playNextSlideSchedule;
  private _slides: SlideshowTypes.Slide<T>[];

  constructor() {
    this.onclick = this._onclick.asObservable();
  }

  get slides(): SlideshowTypes.Slide<T>[] {
    return this._slides;
  }

  @Input()
  set slides(value: SlideshowTypes.Slide<T>[]) {
    const doUpdate = value && value !== this._slides;
    this._slides = value;
    if ( !doUpdate ) {
      return;
    }

    this.preloadImages();
  }

  ngOnInit(): void {
    this.playNextSlideSchedule();
  }

  onClick = (slide: SlideshowTypes.Slide<T>): void => {
    this._onclick.emit(slide);
  };

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    this.playNextSlideSchedule();
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    this.playNextSlideSchedule();
  }

  private playNextSlide = (): void => {
    if ( !this.autoplay ) {
      return;
    }
    this.onNextClick();
  };

  private playNextSlideSchedule = (): void => {
    if ( !this.autoplay ) {
      return;
    }
    if ( this._playNextSlideSchedule ) {
      clearTimeout(this._playNextSlideSchedule);
    }
    this._playNextSlideSchedule = setTimeout(this.playNextSlide, this.autoplayInterval || 5000);
  };

  private preloadImages = (): void => {
    if ( !this._slides ) {
      return;
    }

    this._slides.forEach(slide => {
      if ( slide.imageUrl ) {
        slide._backgroundStyles = {
          'background-image': 'url(' + slide.imageUrl + ')',
        };
        new Image().src = slide.imageUrl;
      }
    });
  };

}
