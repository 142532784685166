import {
  ReportTableColumnMenuData,
  ReportTableColumnMenuItemMap,
  ReportTableRow,
  ReportTableRowChild,
} from './report-table.types';
import { TableColumnBuilder } from '../../../../../component/table/table-column.builder';
import { TableColumnDataType } from '../../../../../component/table/table-controller/table-controller.types';
import { DisplayStatusHelper } from '../../../../../core/display-status-helper';
import { AdminOfflineHelper, OfflineContent } from 'src/app/core/admin-offline.types';
import { EventParticipantsHelper } from '../../../../admin/admin-offline/components/content-events/event-participants/event-participants.helper';
import { OwnershipKind } from '../../../../../core/global.types';
import { CourseStatus, CourseStatusHelper } from '../../../../../core/course-status.helper';
import { CMILessonStatus, CmiLessonStatusHelper } from '../../../../../core/cmi-lesson-status.helper';


const columnDateTime = TableColumnBuilder.start<ReportTableRow>()
  .withType(TableColumnDataType.dateTime);

const columnDropDown = TableColumnBuilder.start<ReportTableRowChild>()
  .withType(TableColumnDataType.dropdown);

const columnNumber = TableColumnBuilder.start<ReportTableRow>()
  .withType(TableColumnDataType.number);

const columnOther = TableColumnBuilder.start<ReportTableRow>()
  .withType(TableColumnDataType.other)
  .withHiddenSelected();

const columnText = TableColumnBuilder.start<ReportTableRow>()
  .withType(TableColumnDataType.text);

export const REPORT_TABLE_COLUMN_DEFAULTS: ReportTableColumnMenuItemMap = {

  /**
   * startWith #1
   */
  checkbox: columnOther.clone()
    .withColumnId('checkbox')
    .build(),

  /**
   * startWith #2
   */
  grouping: columnOther.clone()
    .withColumnId('grouping')
    .build(),

  courseTitle: columnText.clone()
    .withColumnId('courseTitle')
    .build(),

  curriculumTitle: columnText.clone()
    .withColumnId('curriculumTitle')
    .build(),

  userLastname: columnText.clone()
    .withColumnId('userLastname')
    .build(),

  userFirstname: columnText.clone()
    .withColumnId('userFirstname')
    .build(),

  courseItemStatus: columnDropDown.clone()
    .withColumnId('courseItemStatus')
    .withDropDownOptions({
      passed: $localize`:@@status_light_tooltip_passed:Passed`,
      completed: $localize`:@@status_light_tooltip_completeCourse:Completed`,
      failed: $localize`:@@status_light_tooltip_failed:Failed`,
      incomplete: $localize`:@@status_light_tooltip_incomplete:Incomplete`,
      browsed: $localize`:@@status_light_tooltip_browsed:Browsed`,
      notAttempted: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      unKnown: $localize`:@@status_light_tooltip_unknown:Unknown`,
    })
    .withDataAccessor(entry => {
      const courseStatus = CmiLessonStatusHelper.toLessonStatus(entry.courseItemStatus);
      switch ( courseStatus ) {

        case CMILessonStatus.PASSED:
          return 'passed';

        case CMILessonStatus.COMPLETED:
          return 'completed';

        case CMILessonStatus.FAILED:
          return 'failed';

        case CMILessonStatus.INCOMPLETE:
          return 'incomplete';

        case CMILessonStatus.BROWSED:
          return 'browsed';

        case CMILessonStatus.NOT_ATTEMPTED:
          return 'notAttempted';

        default:
          return 'unKnown';
      }
    })
    .build(),

  courseItemResult: columnNumber.clone()
    .withColumnId('courseItemResult')
    .build(),

  accountDisplayStatus: columnDropDown.clone()
    .withColumnId('accountDisplayStatus')
    .withDropDownOptions({
      green: $localize`:@@status_light_tooltip_certified:Certified`,
      red: $localize`:@@status_light_tooltip_notCertified:Not certified`,
      recertification: $localize`:@@status_light_tooltip_recertificationTime:Recertification`,
      not_green: $localize`:@@status_light_tooltip_not_green:Not green`,
      not_red: $localize`:@@status_light_tooltip_not_red:Not red`,
    })
    .withFilterDataAccessor(entry => entry)
    .withFilterMethod((data, filter) => {

      const filterValue = filter?.value ?? '';
      if ( filterValue === '' ) {
        return true;
      }

      if ( data?.$rowType !== 'child' ) {
        // do not match anything besides child rows
        return false;
      }

      switch ( filterValue ) {

        case 'green':
          return DisplayStatusHelper.isStatusGreen(data.accountDisplayStatus);

        case 'red':
          return DisplayStatusHelper.isStatusRed(data.accountDisplayStatus);

        case 'recertification':
          return DisplayStatusHelper.isStatusRecert(data.accountDisplayStatus);

        case 'not_green':
          return !DisplayStatusHelper.isStatusGreen(data.accountDisplayStatus);

        case 'not_red':
          return !DisplayStatusHelper.isStatusRed(data.accountDisplayStatus);

        default:
          return false;
      }
    })
    .build(),

  accountValidSince: columnDateTime.clone()
    .withColumnId('accountValidSince')
    .build(),

  accountValidUntil: columnDateTime.clone()
    .withColumnId('accountValidUntil')
    .build(),

  /**
   * endWith
   */
  actions: columnOther.clone()
    .withColumnId('actions')
    .build(),

  accountCreationDate: columnDateTime.clone()
    .withColumnId('accountCreationDate')
    .build(),

  accountEndDate: columnDateTime.clone()
    .withColumnId('accountEndDate')
    .build(),

  accountItemsDone: columnNumber.clone()
    .withColumnId('accountItemsDone')
    .build(),

  accountItemsPercentage: columnNumber.clone()
    .withColumnId('accountItemsPercentage')
    .build(),

  accountItemsTotal: columnNumber.clone()
    .withColumnId('accountItemsTotal')
    .build(),

  accountIteration: columnNumber.clone()
    .withColumnId('accountIteration')
    .build(),

  accountProcessingTimeStatus: columnDropDown.clone()
    .withColumnId('accountProcessingTimeStatus')
    .withDropDownOptions({
      locked: $localize`:@@status_account_processing_status_locked:Locked`,
      valid: $localize`:@@status_account_processing_status_valid:Executable`,
      not_attempted: $localize`:@@status_account_processing_status_not_attempted:Not started`,
      anulled: $localize`:@@status_account_processing_status_anulled:Unassigned`,
      ended: $localize`:@@status_account_processing_status_ended:Ended`,
    })
    .build(),

  accountStartDate: columnDateTime.clone()
    .withColumnId('accountStartDate')
    .build(),

  assignmentMandatory: columnDropDown.clone()
    .withColumnId('assignmentMandatory')
    .withDropDownOptions({
      true: $localize`:@@global_assignment_type_filter_mandatory:Mandatory`,
      false: $localize`:@@global_assignment_type_filter_voluntary:Voluntary`,
    })
    .build(),

  courseId: columnNumber.clone()
    .withColumnId('courseId')
    .build(),

  courseItemExecCount: columnNumber.clone()
    .withColumnId('courseItemExecCount')
    .build(),

  courseItemId: columnNumber.clone()
    .withColumnId('courseItemId')
    .build(),

  courseItemLastModified: columnDateTime.clone()
    .withColumnId('courseItemLastModified')
    .build(),

  courseItemLocked: columnDropDown.clone()
    .withColumnId('courseItemLocked')
    .withDropDownOptions({
      true: $localize`:@@report_filter_lock_true:Locked`,
      false: $localize`:@@report_filter_lock_false:Not locked`,
    })
    .build(),

  courseItemTitle: columnText.clone()
    .withColumnId('courseItemTitle')
    .build(),

  courseSessionDiagnostic: columnText.clone()
    .withColumnId('courseSessionDiagnostic')
    .build(),

  courseSessionLastError: columnNumber.clone()
    .withColumnId('courseSessionLastError')
    .build(),

  courseSessionRunning: columnDropDown.clone()
    .withColumnId('courseSessionRunning')
    .withDropDownOptions({
      true: $localize`:@@report_filter_sco_running_true:Currently running`,
      false: $localize`:@@report_filter_sco_running_false:Currently not running`,
    })
    .build(),

  courseStatus: columnDropDown.clone()
    .withColumnId('courseStatus')
    .withDropDownOptions({
      completeCourse: $localize`:@@status_light_tooltip_completeCourse:Completed`,
      incomplete: $localize`:@@status_light_tooltip_incomplete:Incomplete`,
      notAttempted: $localize`:@@status_light_tooltip_notAttempted:Not attempted`,
      unKnown: $localize`:@@status_light_tooltip_unknown:Unknown`,
    })
    .withDataAccessor(entry => {
      const courseStatus = CourseStatusHelper.toCourseStatus(entry.courseStatus);
      switch ( courseStatus ) {

        case CourseStatus.COMPLETE:
          return 'completeCourse';

        case CourseStatus.INCOMPLETE:
          return 'incomplete';

        case CourseStatus.NOT_ATTEMPTED:
          return 'notAttempted';

        default:
          return 'unKnown';
      }
    })
    .build(),

  courseContributionsCount: columnDropDown.clone()
    .withColumnId('courseContributionsCount')
    .withDropDownOptions({
      true: $localize`:@@report_filter_has_comments_true:Has comments`,
      false: $localize`:@@report_filter_has_comments_false:Without comments`,
    })
    .withFilterDataAccessor(entry => entry)
    .withFilterMethod((entry, filter) => {
      const courseContributionsCount = entry.courseContributionsCount ?? 0;
      if (filter.value === 'true') {
        return courseContributionsCount > 0;
      }
      return courseContributionsCount === 0;
    })
    .build(),

  courseLastExecutionDate: columnDateTime.clone()
    .withColumnId('courseLastExecutionDate')
    .withTitle($localize`:@@ctrl_single_user_details_lastExecutionDate:Last access`)
    .build(),

  // courseTags: undefined,

  curriculumId: columnNumber.clone()
    .withColumnId('curriculumId')
    .build(),

  // curriculumTags: undefined,

  curriculumVariationCounter: columnNumber.clone()
    .withColumnId('curriculumVariationCounter')
    .build(),

  curriculumVariationTitle: columnText.clone()
    .withColumnId('curriculumVariationTitle')
    .build(),

  userArea: columnText.clone()
    .withColumnId('userArea')
    .build(),

  userCompany: columnText.clone()
    .withColumnId('userCompany')
    .build(),

  userCostCenter: columnText.clone()
    .withColumnId('userCostCenter')
    .build(),

  userCostcenterDesc: columnText.clone()
    .withColumnId('userCostcenterDesc')
    .build(),

  userDateOfBirth: columnDateTime.clone()
    .withColumnId('userDateOfBirth')
    .build(),

  userEmail: columnText.clone()
    .withColumnId('userEmail')
    .build(),

  userEmployeeID: columnText.clone()
    .withColumnId('userEmployeeID')
    .build(),

  userExtID: columnText.clone()
    .withColumnId('userExtID')
    .build(),

  userFreeTextAttribute1: columnText.clone()
    .withColumnId('userFreeTextAttribute1')
    .build(),

  userFreeTextAttribute2: columnText.clone()
    .withColumnId('userFreeTextAttribute2')
    .build(),

  userFreeTextAttribute3: columnText.clone()
    .withColumnId('userFreeTextAttribute3')
    .build(),

  userFreeTextAttribute4: columnText.clone()
    .withColumnId('userFreeTextAttribute4')
    .build(),

  userFreeTextAttribute5: columnText.clone()
    .withColumnId('userFreeTextAttribute5')
    .build(),

  userFreeTextAttribute6: columnText.clone()
    .withColumnId('userFreeTextAttribute6')
    .build(),

  userFreeTextAttribute7: columnText.clone()
    .withColumnId('userFreeTextAttribute7')
    .build(),

  userGeID: columnText.clone()
    .withColumnId('userGeID')
    .build(),

  userHireDate: columnDateTime.clone()
    .withColumnId('userHireDate')
    .build(),

  userHostID: columnText.clone()
    .withColumnId('userHostID')
    .build(),

  userId: columnNumber.clone()
    .withColumnId('userId')
    .build(),

  userJobCode: columnText.clone()
    .withColumnId('userJobCode')
    .build(),

  userLocation: columnText.clone()
    .withColumnId('userLocation')
    .build(),

  userLocationDesc: columnText.clone()
    .withColumnId('userLocationDesc')
    .build(),

  userLogin: columnText.clone()
    .withColumnId('userLogin')
    .build(),

  userSex: columnDropDown.clone()
    .withColumnId('userSex')
    .withDropDownOptions({
      2: $localize`:@@report_filter_sex_man:Male`,
      1: $localize`:@@gender_1:Female`,
      3: $localize`:@@report_filter_sex_divers:Diverse`,
      4: $localize`:@@report_filter_sex_unknown:Unknown`,
    })
    .build(),

  userSoeID: columnText.clone()
    .withColumnId('userSoeID')
    .build(),

  userState: columnDropDown.clone()
    .withColumnId('userState')
    .withDropDownOptions({
      1: $localize`:@@global_yes:Yes`,
      2: $localize`:@@global_no:No`,
    })
    .build(),

  userSubstitute2ID: columnText.clone()
    .withColumnId('userSubstitute2ID')
    .build(),

  userSubstituteID: columnText.clone()
    .withColumnId('userSubstituteID')
    .build(),

  userSuperiorID: columnText.clone()
    .withColumnId('userSuperiorID')
    .build(),

  eventId: columnText.clone()
    .withColumnId('eventId')
    .build(),

  eventTitle: columnText.clone()
    .withColumnId('eventTitle')
    .build(),

  eventScheduleId: columnText.clone()
    .withColumnId('eventScheduleId')
    .build(),

  eventScheduleTitle: columnText.clone()
    .withColumnId('eventScheduleTitle')
    .build(),

  ownershipKind: columnDropDown.clone()
    .withColumnId('ownershipKind')
    .withDropDownOptions({
      ASSIGNED: $localize`:@@ownershipKind_assigned:assigned`,
      BOOKED: $localize`:@@ownershipKind_booked:booked`,
    })
    .withDisplayValueAccessor(entry => {
      switch ( entry.ownershipKindValue ) {

        case OwnershipKind.ASSIGNED:
          return $localize`:@@ownershipKind_assigned:assigned`;

        case OwnershipKind.BOOKED:
          return $localize`:@@ownershipKind_booked:booked`;

        default:
        case OwnershipKind.NONE:
          return '';
      }
    })
    .withFilterDataAccessor(entry => entry)
    .withFilterMethod((entry, filter) => entry.ownershipKindValue === filter.value)
    .build(),

  offlineEventStartDate: columnDateTime.clone()
      .withColumnId('offlineEventStartDate')
      .withTitle($localize`:@@admin_offline_content_table_column_event_start_date:Begin`)
      .withSelected(true)
      .build(),

  eventScheduleHasBooking: columnDropDown.clone()
    .withColumnId('eventScheduleHasBooking')
    .withDropDownOptions({
      0: $localize`:@@not_booked:Not booked`,
      1: $localize`:@@booked:Booked`,
    })
    .withDataAccessor(entry => {
      // this column is only visible if the event is in the past -> no need to check
      const booked = entry?.eventScheduleHasBooking;
      if ( booked ) {
        return $localize`:@@booked:Booked`;
      } else {
        return $localize`:@@not_booked:Not booked`;
      }
    })
    .build(),

  eventScheduleParticipationStatus: columnDropDown.clone()
    .withColumnId('eventScheduleParticipationStatus')
    .withType(TableColumnDataType.dropdown)
    .withTitle($localize`:@@global_participation_status:Participation`)
    .withFilterStateAttribute('eventScheduleParticipationStatus')
    .withDropDownOptions({
      PARTICIPATED: $localize`:@@global_participation_status_participated:Participated`,
      NOT_ATTENDED: $localize`:@@admin_offline_event_participants_not_attended:Not attended`,
    })
    .withDisplayValueAccessor(data => AdminOfflineHelper
      .participationStatusDisplayValue(data?.eventScheduleParticipationStatusValue))
    .withFilterDataAccessor(data => data)
    .withFilterMethod((data, filter) => AdminOfflineHelper
      .participationStatusFilter(data?.eventScheduleParticipationStatusValue, filter.value))
    .build(),

  eventScheduleInvitationStatus: columnDropDown.clone()
    .withColumnId('eventScheduleInvitationStatus')
    .withFilterStateAttribute('eventScheduleInvitationStatus')
    .withDataAccessor(entry => {
      switch ( entry?.eventScheduleInvitationStatus ?? '' ) {
        case 'ack':
          return $localize`:@@admin_offline_event_invitation_status_ack:Participant confirmed`;
        case 'can':
          return $localize`:@@admin_offline_event_invitation_status_can:Event cancellation sent`;
        case 'dec':
          return $localize`:@@admin_offline_event_invitation_status_dec:Participant declined`;
        case 'inv':
          return $localize`:@@admin_offline_event_invitation_status_inv:Invitation sent`;
        case 'frontend':
        case 'link':
          return '—';
        case 'rem':
          return $localize`:@@admin_offline_event_invitation_status_rem:Reminder sent`;
        default:
          const participationStatus = OfflineContent
            .offlineParticipationStatusFactory(entry?.eventScheduleInvitationStatus ?? '');
          if ( participationStatus === OfflineContent.OfflineParticipationStatus.DESIRED ) {
            return $localize`:@@admin_offline_event_invitation_status_new:New` +
              '(' + $localize`:@@admin_offline_event_participation_status_desired:Desired` + ')';
          } else {
            return $localize`:@@admin_offline_event_invitation_status_registered:Registered`;
          }
      }
    })
    .withFilterDataAccessor(entry => entry)
    .withFilterMethod((entry, filter) => {
      if ( entry.eventScheduleInvitationStatus == null && filter.value === '-' ) {
        return true;
      } else {
        return entry.eventScheduleInvitationStatus === filter.value;
      }
    })
    .withDropDownOptions({
      '-': $localize`:@@admin_offline_event_invitation_status_registered:Registered`,
      ack: $localize`:@@admin_offline_event_invitation_status_ack:Participant confirmed`,
      can: $localize`:@@admin_offline_event_invitation_status_can:Event cancellation sent`,
      dec: $localize`:@@admin_offline_event_invitation_status_dec:Participant declined`,
      inv: $localize`:@@admin_offline_event_invitation_status_inv:Invitation sent`,
      rem: $localize`:@@admin_offline_event_invitation_status_rem:Reminder sent`
    })
    .build(),

  eventSchedulePaymentStatus: columnText.clone()
    .withColumnId('eventSchedulePaymentStatus')
    .build(),

  eventAttendanceDurationMinutes: columnText.clone()
    .withColumnId('eventAttendanceDurationMinutes')
    .withType(TableColumnDataType.other)
    .withDisplayValueAccessor(data => {
      const userData = data as any;
      const attendanceDurationInMinutes = userData.eventAttendanceDurationMinutes;
      return EventParticipantsHelper.getAttendanceDurationForUserWithoutPercentage(
        attendanceDurationInMinutes,
      );
    })
    .build(),

  eventAttendanceDurationPercentage: columnText.clone()
    .withColumnId('eventAttendanceDurationPercentage')
    .withType(TableColumnDataType.number)
    .withDisplayValueAccessor(data => {
      const userData = data as any;
      return `${userData.eventAttendanceDurationPercentage}%`;
    })
    .build(),

};


export const REPORT_TABLE_MENU_DEFAULTS: ReportTableColumnMenuData = {
  startWith: [ 'checkbox' ],
  menuItems: REPORT_TABLE_COLUMN_DEFAULTS,
  endWith: [ 'actions' ],
};
