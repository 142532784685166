import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenceGroupColumnStatisticsComponent } from './licence-group-column-statistics.component';



@NgModule({
  declarations: [ LicenceGroupColumnStatisticsComponent ],
  exports: [
    LicenceGroupColumnStatisticsComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class LicenceGroupColumnStatisticsModule { }
