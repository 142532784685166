import {BreakpointObserver} from '@angular/cdk/layout';
import {EventEmitter, HostListener, Injectable} from '@angular/core';
import {EventManager} from '@angular/platform-browser';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, tap} from 'rxjs/operators';
import {CachedSubject} from './cached-subject';
import {PrincipalService} from './principal/principal.service';
import {ViewportState, ViewportStateType} from './viewport-state';

export enum ViewMode {
  FULLSCREEN,
  DEFAULT,
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {

  readonly loading$: Observable<boolean>;
  readonly sidenavVisible$: Observable<boolean>;
  readonly viewMode$: Observable<ViewMode>;
  viewportState: ViewportState = new ViewportState();
  readonly viewportState$: Observable<ViewportState>;
  private _loading$ = new CachedSubject<boolean>(false);
  private _scoRunning = new CachedSubject<boolean>(false);
  private _sidenavVisible = false;
  private _sidenavVisible$ = new CachedSubject<boolean>(false);
  private _viewMode$ = new CachedSubject<ViewMode>(ViewMode.DEFAULT);
  private _viewportState$ = new CachedSubject<ViewportState>(null);
  private counter = 0;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private eventManager: EventManager,
    private principalService: PrincipalService,
  ) {
    this.loading$ = this._loading$.asObservable();
    this.sidenavVisible$ = this._sidenavVisible$.withoutEmptyValues();
    this.viewMode$ = this._viewMode$.withoutEmptyValues();
    this.viewportState$ = this._viewportState$
      .withoutEmptyValues()
      .pipe(distinctUntilChanged((a, b) => a.state === b.state));

    this.observeViewport();
  }

  get scoRunning(): boolean {
    return this._scoRunning.value;
  }

  set scoRunning(value: boolean) {
    this._scoRunning.next(value);
  }

  get scoRunning$(): Observable<boolean> {
    return this._scoRunning
      .pipe(startWith(this._scoRunning.value))
      .pipe(distinctUntilChanged())
      .pipe(filter(CachedSubject.isNotEmpty));
  }

  observeViewport() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .pipe(tap(() => this.updateViewportState()))
      .subscribe();

    this.updateViewportState();
  }

  setLoading(state: boolean): void {
    setTimeout(() => {
      if (state === true) {
        this.counter++;
      } else {
        this.counter--;
      }
      this._loading$.next(this.counter !== 0);
    });
  }

  setSidenavVisible(visible: boolean): void {
    this._sidenavVisible = visible;
    this._sidenavVisible$.next(visible);
  }

  setViewMode(viewMode?: ViewMode) {
    this._viewMode$.next(viewMode === undefined ? ViewMode.DEFAULT : viewMode);
  }

  toggleSidenav(): void {
    this.setSidenavVisible(!this._sidenavVisible);
  }

  private updateViewportState = () => {
    const holder = new ViewportState();
    if (this.breakpointObserver.isMatched('(min-width: 1280px)')) {
      holder.state = ViewportStateType.Huge;
    } else if (this.breakpointObserver.isMatched('(min-width: 960px)')) {
      holder.state = ViewportStateType.Large;
    } else if (this.breakpointObserver.isMatched('(min-width: 600px)')) {
      holder.state = ViewportStateType.Medium;
    } else {
      holder.state = ViewportStateType.Small;
    }
    this.viewportState = holder;
    this._viewportState$.next(holder);
  };

}
