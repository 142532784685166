import { cloneDeepWith, isEqualWith } from 'lodash';
import { ImageableContentReference } from './core.types';
import {
  LearnerCurriculumAccountView,
  LearnerCurriculumItemAccountView,
} from './learner-account/learner-account.types';
import { Quests } from './quest/quest.types';


export interface RecursiveAnyObject {
  [key: string]: RecursiveAnyObject | any;
}

export interface ViewData
  extends RecursiveAnyObject {
  /**
   * the immediate parent curriculum
   */
  curriculum?: ImageableContentReference;
  curriculumAccount?: LearnerCurriculumAccountView;
  curriculumItem?: LearnerCurriculumItemAccountView;
  /**
   * the root curriculum (the one which is directly assigned)
   */
  parent?: ImageableContentReference;
  tabOpen?: boolean;
  questSettings?: Quests.ContentSettings
}

export interface AttrViewData {
  $view?: ViewData;
}

export class ViewHelper {

  static cloneDeep<T>(obj: T | null): T | null {
    if ( obj == null ) {
      return null;
    }

    return cloneDeepWith(obj, (value, key) => {
      if ( ViewHelper.shouldFilter(key) ) {
        return null;
      }
    });
  }

  static cloneRemoveRecursions(obj: any): any {
    const objects = [];
    return cloneDeepWith(obj, (value) => {
      if ( objects.indexOf(value) >= 0 ) {
        return null;
      } else if ( typeof (value) === 'object' ) {
        objects.push(value);
      }
    });
  }

  static copyData(from: any, to: any): void {
    if ( !from || !to ) {
      return;
    }
    to.$view = ViewHelper.getViewData(from);
  }

  static getViewData<T extends ViewData>(obj: any): T {
    if ( !obj ) {
      return null;
    }

    if (obj.$view == null) {
      obj.$view = {};
    }

    return obj.$view;
  }

  /**
   * deep compare ignoring any property that starts with a '$'
   */
  static isEqual(a, b): boolean {
    return isEqualWith(a, b, (a_, b_, key) => {
      if ( ViewHelper.shouldFilter(key) ) {
        return true;
      }
    });
  }

  static shouldFilter(key): boolean {
    return key === '$view';
    // filter definition contains keys with $!
    // return (typeof (key) === 'string') && /^[$]/.test(key);
  }

}
