import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoService } from 'src/app/core/info/info.service';
import {
  SelectCurriculumDialogComponent,
  SelectCurriculumDialogComponentData
} from '../select-curriculum-dialog/select-curriculum-dialog.component';
import { map, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { AdminCurriculumLegacy } from '../../../../../admin/admin-curriculum/admin-curriculum.types';

@Component({
  selector: 'rag-select-curriculum',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './select-curriculum.component.html',
  styleUrls: ['./select-curriculum.component.scss']
})
export class SelectCurriculumComponent implements OnInit, OnDestroy {

  constructor(
    private infoService: InfoService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.activeRoute.data.pipe(switchMap(data => {
        const items: AdminCurriculumLegacy[] = (data.curricula ?? [])
          .filter((o: AdminCurriculumLegacy) => o?.status === 'published');
        return this.infoService.showDialog<SelectCurriculumDialogComponent, SelectCurriculumDialogComponentData, Array<number>>(SelectCurriculumDialogComponent, {
          curricula: items
        })
          .pipe(map(selectedCurricula => {
            if (selectedCurricula === null) {
              this.router.navigate(['report', 'v2', 'generator', 'Curriculum']);
              return;
            }
            this.router.navigate(['report', 'v2', 'generator', 'Curriculum', selectedCurricula.toString()]);
          }));
      }
    ))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }
}
