import { ApiUrls } from './api.urls';

export class ImageUrlHelper {

  static urlForPicture(id: number | string, uuid: string): string | null {
    const url = ImageUrlHelper.urlForPictureUUID(uuid);
    if ( url != null ) {
      return url;
    }

    return ImageUrlHelper.urlForPictureId(id);
  }

  /**
   * @deprecated use uuid instead!
   */
  static urlForPictureId(pictureId?: number | string): string | null {
    if ( pictureId == null || pictureId === 0 || pictureId === '0' ) {
      return null;
    }
    return ApiUrls.getKey('ShowFile') + '/' + pictureId;
  }

  static urlForPictureUUID(pictureUUID: string): string | null {
    if ( pictureUUID == null ) {
      return null;
    }
    return ApiUrls.getKey('Files') + '/' + pictureUUID;
  }

}
