import { AfterViewInit, Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReceivedResponseEvent } from 'src/app/route/user/certificates/certificates.types';
import { AccountDesignService } from '../../../route/admin/account-design/account-design.service';
import { Widget } from '../../rag-layout.types';
import { TodoContentWidgetComponent } from '../todo-content-widget/todo-content-widget.component';
import { WidgetComponent } from '../widgets.module';
import { StyleSettings } from 'src/app/route/admin/account-design/account-design.types';
import { WidgetsUUID } from '../widgets.types';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'rag-widget-wrapper',
  templateUrl: './widget-wrapper.component.html',
  styleUrls: [ './widget-wrapper.component.scss' ],
})
export class WidgetWrapperComponent
  implements AfterViewInit, OnInit {

  @Input() addWidget: (widgetUUID: string) => void;
  @ViewChild('child', { static: false })
  child: WidgetComponent;
  // this wrapper displays a spinner as long the widget send notification that it has received response from server and is initialized
  hasResponse = false;
  // references to child's menus
  menuReferences: TemplateRef<any>[];
  @Input() removeItem: (widget: Widget) => void;
  @Input() resizeEvent: EventEmitter<any>;
  shouldDisplaySpinner = false;
  styleSettings$: Observable<StyleSettings>;
  title$: Observable<string>;
  @Input() widget: Widget;
  widgetSettings: Observable<any>;
  private _edit = false;
  private widgetSettingsContext: string;


  constructor(
    private accountService: AccountDesignService,
    private principalService: PrincipalService
  ) {
  }

  get edit() {
    return this._edit;
  }

  @Input()
  set edit(edit: boolean) {
    this._edit = edit;
  }

  get htmlWidgetUUID(): string {
    return WidgetsUUID.HtmlWidgetUUID;
  }

  get myCertificatesWidgetUUID(): string {
    return WidgetsUUID.MycertificatesWidgetUUID;
  }

  get newsWidgetUUID(): string {
    return WidgetsUUID.NewsWidgetUUID;
  }

  get overviewWidgetUUID(): string {
    return WidgetsUUID.OverviewWidgetUUID;
  }

  get readonly() {
    return this._edit || this.toBeRemoved;
  }

  get redeemCodeWidgetUUID(): string {
    return WidgetsUUID.CodeRedeemWidgetUUID;
  }

  get gamificationWidgetUUID(): string {
    return WidgetsUUID.GamificationWidgetUUID;
  }

  get reportBarChartWidgetUUID(): string {
    return WidgetsUUID.ReportBarChartWidgetUUID;
  }

  get reportFavoriteWidgetUUID(): string {
    return WidgetsUUID.ReportFavouritesWidgetsUUID;
  }

  get reportLinksWidgetUUID(): string {
    return WidgetsUUID.ReportLinksWidgetUUID;
  }

  get reportPieChartWidgetUUID(): string {
    return WidgetsUUID.ReportPieChartWidgetUUID;
  }

  get specialsWidgetUUID(): string {
    return WidgetsUUID.SpecialsWidgetUUID;
  }

  get toBeRemoved() {
    return this.widget.settings?.toBeRemoved;
  }

  get toDoWidgetUUID(): string {
    return TodoContentWidgetComponent.WidgetUUID;
  }

  get eventsWidgetUUID(): string {
    return WidgetsUUID.EventsWidgetUUID;
  }

  isDeletable(styleSettings: StyleSettings, widgetUUID: string): boolean {
    return styleSettings.acc.widgets[widgetUUID].deletable;
  }

  ngAfterViewInit() {
    // do not remove setTimeout() !!!
    setTimeout(() => {
      if ( this.child?.title$ ) {
        this.title$ = this.child?.title$;
      } else if ( this.widget?.title ) {
        // apply default title in case widget is disabled
        this.title$ = of(this.widget?.title);
      }

      if ( typeof this.child?.getMenuReferences === 'function' ) {
        this.menuReferences = this.child.getMenuReferences();
      }
      if ( typeof this.child?.isDataDriven === 'function' ) {
        this.shouldDisplaySpinner = this.child.isDataDriven();
      }
    });
  }

  ngOnInit(): void {
    this.widgetSettingsContext = 'Widget_' + this.widget.widgetUUID + '_Settings';
    this.styleSettings$ = this.accountService.getStyleSettings();

    this.widgetSettings = this.principalService
      .getUserSettings(this.widgetSettingsContext)
      .pipe(map(userSettings => userSettings === undefined || userSettings === '' ? '{}' : userSettings))
      .pipe(map(userSettingsStr => JSON.parse(userSettingsStr)));
  }

  setDidReceiveResponse = (receivedResponse: ReceivedResponseEvent) => {
    this.shouldDisplaySpinner = receivedResponse == null;
    this.hasResponse = receivedResponse != null;
  };

  onSettingsChange($event: any) {
    this.principalService.saveUserSettings(this.widgetSettingsContext, JSON.stringify($event)).subscribe();
  }
}
