<rag-dialog-header *ngIf="title || titleKey">
  <rag-messages [key]="titleKey" [message]="title"></rag-messages>
</rag-dialog-header>

<div class="content" mat-dialog-content>
  <rag-messages *ngIf="message || messageKey" [key]="messageKey" [message]="message"></rag-messages>

  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="text">
  </mat-form-field>
</div>

<mat-dialog-actions>

  <button [mat-dialog-close]
          class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button [mat-dialog-close]="text"
          color="primary" mat-flat-button>
    <span i18n="@@global_confirm">Confirm</span>
  </button>

</mat-dialog-actions>