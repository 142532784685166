import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResolveStart, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiUrls } from '../api.urls';
import { CachedSubject } from '../cached-subject';
import { AnyObject, Core, NumberedAnyObject, Principal, TargetGroup } from '../core.types';
import { ApiResponse, HttpRequestOptions, TrainResponse } from '../global.types';
import { NavigationService } from '../navigation/navigation.service';
import { PreloadService } from '../preload.service';
import { PermissionStates } from './permission.states';
import { PrincipalData, SearchPrincipalsResponse } from './principal.types';
import { RuntimeEnvironment, RuntimeEnvironmentService } from '../runtime-environment.service';
import { RedirectHelper } from '../redirect.helper';


interface MailCount
  extends TrainResponse {
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class PrincipalService {

  currentUser: PrincipalData;
  isLogged: boolean;
  userId: number;
  private _frontendActions = new CachedSubject<Core.FrontendAction[]>(null);
  private _mailsCount = new CachedSubject<number>(0);
  private _userSettingsObservables: AnyObject<Observable<string>> = {};
  private _userSettingsSubjects: AnyObject<CachedSubject<string>> = {};
  private accountId$_ = new CachedSubject<number>(null);
  private accountId_: number;
  private environment: RuntimeEnvironment;
  private isLogged_ = new CachedSubject<boolean>(false);
  private permissionStates_ = new CachedSubject<PermissionStates>(null);
  private principal_ = new CachedSubject<PrincipalData>(null);
  private _showBeta: boolean;

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private preloadService: PreloadService,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private router: Router,
  ) {

    this.router.events
      .pipe(filter(e => e instanceof ResolveStart))
      // result is ignored -> no active subscription to clean up
      .pipe(tap(() => this.getMailsCount(true)))
      .subscribe();

    this.principal$
      .pipe(map(principal => principal?.userId ?? 0))
      .pipe(filter(userId => (userId > 0)))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(this.getAccountScriptForUser))
      .subscribe();
    this.runtimeEnvironmentService.environment$
      .pipe(tap(env => {
        this.environment = env;

        if ( this.permissionStates_.value == null ) {
          this.permissionStates_.next(new PermissionStates(false, [], env, this._showBeta));
        }
      }))
      .subscribe();
  }

  get accountId(): number {
    return this.accountId_;
  }

  set accountId(accountId: number) {
    this.accountId_ = accountId;
    this.accountId$_.next(this.accountId);
  }

  get isLogged$(): Observable<boolean> {
    return this.isLogged_.withoutEmptyValuesWithInitial()
      .pipe(distinctUntilChanged());
  }

  get mailsCount$(): Observable<number> {
    return this._mailsCount.withoutEmptyValuesWithInitial()
      .pipe(distinctUntilChanged());
  }

  get permissionStates$(): Observable<PermissionStates> {
    return this.permissionStates_.withoutEmptyValuesWithInitial()
      .pipe(distinctUntilChanged());
  }

  get principal$(): Observable<PrincipalData> {
    return this.principal_.withoutEmptyValuesWithInitial()
      .pipe(distinctUntilChanged());
  }

  static principalEquals(previous: PrincipalData, current: PrincipalData) {
    if ( previous === current ) {
      return true;
    } else if ( !previous || !current ) {
      return false;
    }
    return previous.userId === current.userId;
  }

  checkLanguageChange = (principal: PrincipalData): Observable<PrincipalData> => {
    if ( !(principal && principal.language) ) {
      return of(principal);
    }

    let switchedLanguage;
    try {
      switchedLanguage = localStorage.getItem('switched_language');
      localStorage.removeItem('switched_language');
    } catch ( e ) {
      if ( console && console.error ) {
        console.error(e);
      }
    }
    if ( switchedLanguage && principal.language !== switchedLanguage ) {
      if ( console && console.log ) {
        console.log('checkLanguageChange', switchedLanguage);
      }
      const data = new FormData();
      data.append('json', JSON.stringify({
        language: switchedLanguage,
      }));
      return this.updateUserLanguage(data)
        .pipe(map(() => principal))
        .pipe(catchError(() => of(principal)));
    }
    return of(principal);
  };

  // API call to check logged user
  fetchUserData(allowCache = true): Observable<PrincipalData> {
    if ( !allowCache ) {
      this.isLogged_.reset();
      this.principal_.reset();
    }
    if ( this.principal_.queryStart() ) {
      const reset = allowCache === false;
      this.preloadService.getPrincipalData(reset)
        .pipe(switchMap(this.checkLanguageChange))
        .pipe(catchError(this.principal_.nextError))
        .pipe(tap(this.resetPrincipal))
        .subscribe();
    }
    return this.principal$;
  }

  getFrontendActions(resetCache = false): Observable<Core.FrontendAction[]> {
    if ( resetCache ) {
      this._frontendActions.reset();
    }

    if ( this._frontendActions.queryStart() ) {
      this.http.get<{ actions: Core.FrontendAction[] }>(ApiUrls.getKey('AccountFrontendActions'))
        .pipe(map(response => response?.actions))
        .pipe(tap(this._frontendActions.next))
        .pipe(catchError(this._frontendActions.nextError))
        .subscribe();
    }

    return this._frontendActions.withoutEmptyValuesWithInitial()

      // ignore empty frontend actions
      .pipe(map(actions => (actions ?? []).filter(action => {
        switch ( action?.action ?? '' ) {
          case 'landingPageForward':
            return (action as Core.FrontendActionForwardPage).pages?.length > 0;
          case 'recordFields':
            return (action as Core.FrontendActionRecordFields).fields?.length > 0;
          default:
            return true;
        }
      })));
  }

  getMailsCount(force = false): Observable<number> {
    if ( force ) {
      this._mailsCount.reset();
    }
    if ( this.isLogged && this._mailsCount.queryStart() ) {
      this.permissionStates$

        // de-couple from permission updates (e.g. on navigation)
        .pipe(take(1))

        .pipe(switchMap(permissions => {
          if ( !permissions.navUserMessages ) {

            // user does not have access to messaging -> no messages waiting
            return of(0);
          }

          // fetch message count
          return this.http.get<MailCount>(ApiUrls.getKey('MailboxCount'))
            .pipe(map(mailsCount => mailsCount.count));
        }))
        .pipe(map(this._mailsCount.next))
        .pipe(catchError(this._mailsCount.nextError))
        .subscribe();
    }
    return this.mailsCount$;
  }

  getObjectPermissions(objectType: string, objectKey: string): Observable<string[]> {
    const url = ApiUrls.getKey('ObjectPermissions')
      .replace(/{objectType}/gi, objectType)
      .replace(/{objectKey}/gi, objectKey);
    return this.http.get<any>(url)
      .pipe(map(response => response.permissions))
      .pipe(catchError(() => []));
  }

  getObjectsPermissions(objectType: string, objectKeys: string[]): Observable<AnyObject<string[]>> {
    const url = ApiUrls.getKey('ObjectPermissionsPost')
      .replace(/{objectType}/gi, objectType);
    return this.http.post<any>(url, objectKeys)
      .pipe(map(response => response.permissions))
      .pipe(catchError(() => []));
  }

  getUserSettings(context: string, forceReload = false): Observable<string> {
    if (context == null || context === '') {
      console?.warn('getUserSettings: invalid context');
      return EMPTY;
    }

    const subject = this.getUserSettingsSubject(context);
    if ( subject.queryStart() ) {
      const contextEncoded = encodeURIComponent(context);
      const url = `${ApiUrls.getKey('UserSettings')}/${contextEncoded}`;
      this.http
        .get<ApiResponse<string>>(url)
        .pipe(tap(() => {
          if ( forceReload ) {
            subject.reset();
          }
        }))
        .pipe(map(response => response[context]))
        .pipe(tap(subject.next))
        .pipe(catchError(subject.nextError))
        .subscribe();
    }
    return this._userSettingsObservables[context];
  }

  removeUserSettings(context: string): Observable<boolean> {
    if (context == null || context === '') {
      console?.warn('removeUserSettings: invalid context');
      return EMPTY;
    }

    const contextEncoded = encodeURIComponent(context);
    const url = `${ApiUrls.getKey('UserSettings')}/${contextEncoded}`;
    return this.http.delete<ApiResponse<void>>(url).pipe(map(response => response.success));
  }

  saveUserSettings(context: string, payload: string): Observable<boolean> {
    if (context == null || context === '') {
    console?.warn('saveUserSettings: invalid context');
    return EMPTY;
  }

    const subject = this.getUserSettingsSubject(context);
    const contextEncoded = encodeURIComponent(context);
    const url = `${ApiUrls.getKey('UserSettings')}/${contextEncoded}`;
    return this.http.post(url, payload, HttpRequestOptions).pipe(map(() => {
      subject.next(payload);
      return true;
    }));
  }

  searchForPrincipalsByText(search: string): Observable<Principal[]> {
    const url = `${ApiUrls.getKey('Mailbox_NewApi_GetAccounts')}/find`;
    return this.http.post<SearchPrincipalsResponse>(
      url, JSON.stringify({ filter: search }), HttpRequestOptions).pipe(map(response => response.data));
  }

  setShowBeta(showBeta: boolean): void {
    const permissions = this.permissionStates_.value;
    this._showBeta = showBeta;
    this.permissionStates_.next(PermissionStates.cloneWithBeta(permissions, this.environment, showBeta));
  }

  setMessagesAsReadCount(count: number) {
    this._mailsCount.next(this._mailsCount.value + count);
  }

  updateUserLanguage(requestData: any) {
    return this.http.put<TrainResponse>(
      ApiUrls.getKeyWithParams('AccountDataPutLanguage', '2'),
      requestData);
  }

  getTargetGroups(): Observable<NumberedAnyObject<TargetGroup>> {
    const url = ApiUrls.getKey('CtrlOfflineTargetGroups');
    return this.http.get<any>(url)
      .pipe(map(response => response.targetGroups));
  }

  private getAccountScriptForUser = (): Observable<unknown> => {
    const url = ApiUrls.getKey('AccountScriptUser');
    return this.http.get<any>(url)
      .pipe(catchError(() => EMPTY))
      .pipe(map(response => response?.accountScript || ''))
      // eslint-disable-next-line no-eval
      .pipe(tap(accountScript => window.eval(accountScript)))
      .pipe(catchError(e => {
        if ( console?.warn ) {
          console.warn('account-script-failed', e);
        }
        return EMPTY;
      }));
  };

  private getUserSettingsSubject(context: string): CachedSubject<string> {
    if ( this._userSettingsSubjects.hasOwnProperty(context) ) {
      return this._userSettingsSubjects[context];
    }

    const result = new CachedSubject<string>(null);
    this._userSettingsSubjects[context] = result;
    this._userSettingsObservables[context] = result.withoutEmptyValues();
    return result;
  }

  private resetPrincipal = (principal?: PrincipalData): void => {
    // todo add websocket to trigger reset from different tab
    Object.values(this._userSettingsSubjects)
      .forEach(subject => subject.reset());

    let permissionStates: PermissionStates;
    if ( !(principal?.userId > 0) ) {

      this.currentUser = principal = null;
      this.userId = null;
      this.principal_.reset();
      permissionStates = new PermissionStates(false, [], this.environment, this._showBeta);

      // clear any previously saved redirects
      RedirectHelper.clearRedirect();

    } else {

      // add user to window object for debugging
      window['loggedUser'] = principal;
      principal.fullName = principal.firstname + ' ' + principal.lastname;
      this.currentUser = principal;
      this.accountId = principal.accountid;
      this.userId = principal.userId;
      this.principal_.next(principal);

      if ( principal.permissionStates == null ) {
        // create new permissions object
        principal.permissionStates =
          new PermissionStates(true, principal.permissions, this.environment, this._showBeta);
      }
      permissionStates = principal.permissionStates;

      if ( principal.language ) {
        setTimeout(() => {
          this.navigationService.switchToLocale(principal.language);
        });
      }

    }

    this.permissionStates_.next(permissionStates);
    this.isLogged_.next(this.isLogged = !!principal);
  };

}
