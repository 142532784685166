import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, take, takeWhile, tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import {
  UserAttribute, UserAttributeContext,
  UserAttributeContextSaveData,
  UserAttributeGroup,
} from '../../../core/admin-user-attribute.types';
import { AnyObject, FileInfo, URL_PATTERN, UserGroupView } from '../../../core/core.types';
import { AdminSelfRegistrationService } from './admin-self-registration.service';
import { EMPTY, Observable } from 'rxjs';
import { CachedSubject } from '../../../core/cached-subject';
import { InfoType, MessageConstants } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import {
  FileAuthorizationRefType,
  FileAuthorizationType,
} from '../../../component/input/file-upload/file-upload.types';
import { FileUploadService } from '../../../component/input/file-upload/file-upload.service';
import { InputHelper } from '../../../component/input/input.types';
import { SelfRegAdminResponse } from './admin-self-registration.types';
import { AdminSelfRegistrationHelper } from './admin-self-registration.helper';
import { AssignmentDialogService } from '../../../component/assignment-dialog/assignment-dialog.service';
import { AssignmentDialogTypes } from '../../../component/assignment-dialog/assignment-dialog.types';
import { AssignmentDialogComponent } from '../../../component/assignment-dialog/assignment-dialog.component';
import { DirtyCheckService } from '../../../core/dirty-check.service';
import { LanguageHelper } from '../../../core/language.helper';
import { isNothing } from '../../../core/utils';


@Component({
  selector: 'rag-admin-self-registration',
  templateUrl: './admin-self-registration.component.html',
  styleUrls: [ './admin-self-registration.component.scss' ],
})
export class AdminSelfRegistrationComponent
  implements OnInit, OnDestroy {

  attributeGroups: UserAttributeGroup[];
  form: FormGroup;
  privacyPolicyFile: File;
  privacyPolicyFileInfo: FileInfo;
  privacyPolicyUrl: string;
  privacyUUID = null;
  readonly saveButtonDisabled$: Observable<boolean>;
  selectedAttributes: UserAttribute[];
  selfRegistrationEnabled: boolean;
  selfRegistrationUserGroupId: number;
  termsConditionFile: File;
  termsConditionFileInfo: FileInfo;
  termsConditionUUID = null;
  termsConditionUrl: string;
  userAttributeMap: AnyObject<UserAttribute>;
  userGroupNames: UserGroupView[];
  unsavedAttribute: UserAttribute[];
  private _saveButtonDisabled = new CachedSubject<boolean>(true);

  constructor(
    private adminSelfRegistrationService: AdminSelfRegistrationService,
    private assignmentDialogService: AssignmentDialogService,
    private dirtyCheckService: DirtyCheckService,
    private fileUploadService: FileUploadService,
    private infoService: InfoService,
    private route: ActivatedRoute,
  ) {
    this.saveButtonDisabled$ = this._saveButtonDisabled.asObservable();
  }

  checkSaveButtonDisabled(): void {

    if ( this.form == null ) {
      return;
    }

    if ( this.form.pristine ) {
      this.setDirtyState(false);
      this._saveButtonDisabled.next(true);
      return;
    }

    this.setDirtyState(true);
    if ( this.form.get('termsConditionUrl').invalid || this.form.get('privacyPolicyUrl').invalid ) {
      this._saveButtonDisabled.next(true);
      return;
    }

    this._saveButtonDisabled.next(false);
  }

  getAGBFile(): File {
    return this.termsConditionFile;
  }

  getCaption(file: string): string {
    switch ( file ) {
      case 'termsCondition':
        return $localize`:@@global_conditions:Terms and Conditions`;
      case 'privacyPolicy':
        return $localize`:@@global_privacy_policy:Privacy Statement`;
    }
  }

  getGroupName(field: UserAttribute): string {
    const attributeGroupId = field?.attributeGroupId;
    if ( !(attributeGroupId > 0) ) {
      return $localize`:@@admin_user_attribute_unused_fields_group:Unused fields`;
    }

    const group = this.attributeGroups?.find(g => g.id === attributeGroupId);
    return LanguageHelper.objectToText(group?.title);
  }

  getPlaceholder(
    formControlName: string,
  ): string | null {
    switch ( formControlName ) {

      case 'termsConditionUrl':
        return $localize`:@@global_conditions:Terms and Conditions` + ' (URL)';

      case 'privacyPolicyUrl':
        return $localize`:@@global_privacy_policy:Privacy Statement` + ' (URL)';

      default:
        return null;
    }
  }

  getPrivacyFile(): File {
    return this.privacyPolicyFile;
  }

  hasError(
    formControlName: string,
    errorCode: string,
  ): boolean {
    return this.form
      ?.get(formControlName)
      ?.hasError(errorCode) ?? false;
  }

  isAGBFileDisabled(): boolean {
    if ( this.form == null ) {
      return true;
    }
    const termsConditionUrl = this.form.get('termsConditionUrl').value;
    return termsConditionUrl != null && termsConditionUrl !== '';
  }

  isPrivacyFileDisabled(): boolean {
    if ( this.form == null ) {
      return false;
    }
    const privacyPolicyUrl = this.form.get('privacyPolicyUrl').value;
    return privacyPolicyUrl != null && privacyPolicyUrl !== '';
  }

  isUnsavedAttribute(attribute: UserAttribute) {
    return this.unsavedAttribute?.find(a => a?.attributeField === attribute?.attributeField);
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.createForm();
    this.route.data
      .pipe(tap(data => this.updateRouteData(data.data)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onChangeSelectedAttributes(): void {
    const data = AdminSelfRegistrationHelper.asSelectionData(this.userAttributeMap, this.selectedAttributes);
    this.infoService.showDialog<AssignmentDialogComponent<UserAttribute>,
      AssignmentDialogTypes.AssignmentDialogData<UserAttribute>,
      AssignmentDialogTypes.AssignmentDialogEntries<UserAttribute>>(AssignmentDialogComponent, {
      allowSortingInContainers: true,
      data, i18n: {
        available: '',
        search: '',
        selected: '',
        title: '',
        tooManySelections: '',
      },
    }).pipe(take(1))
      .pipe(takeWhile(result => (result?.selected != null)))
      .pipe(tap(result => {
        this.selectedAttributes.splice(0);
        this.selectedAttributes.push(...result.selected.map(o => o.value));
        this.setDirtyState(true);
        this.checkSaveButtonDisabled();
      }))
      .subscribe();
  }

  onRemovePicture(file: string): void {
    this.setDirtyState(true);
    switch ( file ) {
      case 'termsConditionFile':
        this.termsConditionFile = null;
        this.termsConditionFileInfo = null;
        this.termsConditionUUID = null;
        this.form.get('termsConditionUrl').enable();
        break;
      case 'privacyPolicyFile':
        this.privacyPolicyFile = null;
        this.privacyPolicyFileInfo = null;
        this.privacyUUID = null;
        this.form.get('privacyPolicyUrl').enable();
        break;
    }
    this.checkSaveButtonDisabled();
  }

  onSave(): void {
    const selfRegistrationEnabled = this.form.get('selfRegistrationEnabled').value;
    const selfRegistrationUserGroupId = this.form.get('selfRegistrationUserGroupId').value;
    const agreementUrl = this.form.get('termsConditionUrl').value;
    const privacyUrl = this.form.get('privacyPolicyUrl').value;

    const context: UserAttributeContextSaveData = {
      context: 'selfRegistration',
      userAttributes: this.selectedAttributes.map(attr => attr.attributeName),
      privacyFileUUID: this.privacyUUID,
      agreementFileUUID: this.termsConditionUUID,
    };

    if ( !!agreementUrl ) {
      context.agreementUrl = agreementUrl;
    }

    if ( !!privacyUrl ) {
      context.privacyUrl = privacyUrl;
    }

    this.adminSelfRegistrationService
      .saveSettings({ context, selfRegistrationEnabled, selfRegistrationUserGroupId })
      .pipe(take(1))
      .pipe(tap(() => {
        this.unsavedAttribute = [];
          this.infoService.showMessage(
            $localize`:@@general_save_success:The data has been saved successfully`,
            { infoType: InfoType.Success });
          this.setDirtyState(false);
          this.checkSaveButtonDisabled();
        },
      ))
      .subscribe();
  }

  onSelectedPicture($event: File, file: string): void {
    switch ( file ) {
      case 'termsConditionFile':
        this.termsConditionFile = $event;
        this.form.get('termsConditionUrl').disable();
        this.uploadTemporaryFile('termsConditionFile')
          .pipe(tap(_ => this.checkSaveButtonDisabled()))
          .subscribe();
        break;
      case 'privacyPolicyFile':
        this.privacyPolicyFile = $event;
        this.form.get('privacyPolicyUrl').disable();
        this.uploadTemporaryFile('privacyPolicyFile')
          .pipe(tap(_ => this.checkSaveButtonDisabled()))
          .subscribe();
        break;
    }
  }

  uploadTemporaryFile(file: string): Observable<boolean> {
    this.form.markAsDirty();
    this.form.markAsTouched();

    let currentFile = null;
    switch ( file ) {
      case 'termsConditionFile':
        currentFile = this.termsConditionFile;
        break;
      case 'privacyPolicyFile':
        currentFile = this.privacyPolicyFile;
    }
    this._saveButtonDisabled.next(true);
    return this.fileUploadService.uploadFileV2(uuid.v4(), currentFile,
      FileAuthorizationType.unchecked, FileAuthorizationRefType.f2fcontent_attachment)
      .pipe(catchError(() => {
        this.infoService.showMessage(MessageConstants.ERRORS.GENERAL, { infoType: InfoType.Error });
        if ( file === 'termsConditionFile' ) {
          this.form.get('termsConditionUrl').enable();
        } else if ( file === 'privacyPolicyFile' ) {
          this.form.get('privacyPolicyUrl').enable();
        }
        return EMPTY;
      }))
      .pipe(tap(fileInfo => {
        if ( file === 'termsConditionFile' ) {
          this.termsConditionFileInfo = fileInfo;
          this.termsConditionUUID = fileInfo.uuid;
        } else if ( file === 'privacyPolicyFile' ) {
          this.privacyPolicyFileInfo = fileInfo;
          this.privacyUUID = fileInfo.uuid;
        }
      }))
      .pipe(map(_ => true))
      .pipe(take(1));
  }

  private createForm(): void {

    if ( this.form != null ) {
      this.form.get('termsConditionUrl')?.setValue(this.termsConditionUrl);
      this.form.get('privacyPolicyUrl')?.setValue(this.privacyPolicyUrl);
      this.form.get('selfRegistrationEnabled')?.setValue(this.selfRegistrationEnabled);
      this.form.get('selfRegistrationUserGroupId')?.setValue(this.selfRegistrationUserGroupId);
      return;
    }

    const termsConditionUrl = new FormControl(this.termsConditionUrl, [ Validators.pattern(URL_PATTERN) ]);
    const privacyPolicyUrl = new FormControl(this.privacyPolicyUrl, [ Validators.pattern(URL_PATTERN) ]);
    const selfRegistrationEnabled = new FormControl(this.selfRegistrationEnabled, [ Validators.required ]);
    const selfRegistrationUserGroupId = new FormControl(this.selfRegistrationUserGroupId);
    this.form = new UntypedFormGroup({
      termsConditionUrl, privacyPolicyUrl,
      selfRegistrationEnabled, selfRegistrationUserGroupId,
    });

    termsConditionUrl.valueChanges.pipe(map(value => {
      this.termsConditionUrl = value;
      this.checkSaveButtonDisabled();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    privacyPolicyUrl.valueChanges.pipe(map(value => {
      this.privacyPolicyUrl = value;
      this.checkSaveButtonDisabled();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    selfRegistrationEnabled.valueChanges.pipe(map(value => {
      this.selfRegistrationEnabled = value;
      this.checkSaveButtonDisabled();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    selfRegistrationUserGroupId.valueChanges.pipe(map(value => {
      this.selfRegistrationUserGroupId = value;
      this.checkSaveButtonDisabled();
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

  }

  private setDirtyState(isDirty: boolean): void {

    this.dirtyCheckService.submitNextState('AdminSelfRegistrationComponent', isDirty);

    if ( isDirty && this.form.pristine ) {
      this.form.markAsDirty();
      this.form.markAsTouched();

    } else if ( !isDirty && !this.form.pristine ) {
      this.form.markAsPristine();
      this.form.markAsUntouched();
    }
  }

  private updateRouteData(data: SelfRegAdminResponse): void {

    const selfRegContext = data.context;
    this.userAttributeMap = (data.userAttributes ?? {});
    this.selfRegistrationEnabled = data.accountSettings?.selfRegistrationEnabled === true;
    this.selfRegistrationUserGroupId = data.accountSettings?.selfRegistrationUserGroupId;
    this.userGroupNames = data.userGroupNames;
    this.attributeGroups = data.attributeGroups;

    this.privacyPolicyUrl = selfRegContext?.privacyUrl ?? '';
    this.termsConditionUrl = selfRegContext?.agreementUrl ?? '';
    this.privacyPolicyFileInfo = selfRegContext?.privacyFile ?? null;
    this.termsConditionFileInfo = selfRegContext?.agreementFile ?? null;
    this.privacyUUID = selfRegContext?.privacyFileUUID ?? null;
    this.termsConditionUUID = selfRegContext?.agreementFileUUID ?? null;

    // disable url inputs if files have been uploaded
    InputHelper.toggleEnabled(this.form.get('privacyPolicyUrl'), !this.privacyUUID);
    InputHelper.toggleEnabled(this.form.get('termsConditionUrl'), !this.termsConditionUUID);

    const selectedAttributes = this.selectedAttributes = AdminSelfRegistrationHelper.getSelectedAttributes(data);

    this.unsavedAttribute = selectedAttributes
      .filter(attribute => isNothing(selfRegContext.userAttributes.find(a => a === attribute.attributeName)));

    if ( selectedAttributes.map(a => a.attributeName).join(',') !== selfRegContext?.userAttributes?.join(',') ) {
      this.form.markAsDirty();
      this.checkSaveButtonDisabled();
      this.infoService.showMessage($localize`:@@admin_self_register_new_changes_detected:
        There are some unsaved changes that likely come from changes to the user attributes (see marking).<br/>
        Please check the current configuration and save it once.`, {
        title: MessageConstants.DIALOG.TITLE.INFO,
      });
    }

    this.createForm();
  }

}
