import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Curriculum } from 'src/app/core/curriculum/curriculum.types';
import { UploadFile } from 'src/app/core/files.types';
import { ApiResponse, TrainResponse } from 'src/app/core/global.types';
import { Legacy } from 'src/app/core/legacy/legacy.types';
import { FileUploadService } from '../../../../component/input/file-upload/file-upload.service';
import {
  FileAuthorizationRefType,
  FileAuthorizationType
} from '../../../../component/input/file-upload/file-upload.types';
import { AdminCoursesTypes, CourseListResolverResponse, CourseListResponse } from '../../../../core/admin-courses.types';
import { ApiUrls } from '../../../../core/api.urls';
import { Core, FileInfo, JsonOmitViewReplacer } from '../../../../core/core.types';
import { InfoService } from '../../../../core/info/info.service';
import { CancelButton, DeleteButton, InfoType, YesButton } from '../../../../core/info/info.types';
import { LearnerAccountTypes } from '../../../../core/learner-account/learner-account.types';
import { destroySubscriptions } from '../../../../core/reactive/until-destroyed';
import { ViewHelper } from '../../../../core/view-helper';
import { AdminSaveSupportService } from '../../admin-includes/admin-save-support.service';
import {
  AdminCoursesValidateDeleteComponent
} from '../admin-courses-validate-delete/admin-courses-validate-delete.component';
import { AdminCoursesValidateDeleteTypes } from '../admin-courses-validate-delete/admin-courses-validate-delete.types';
import SampleSolutionExt = AdminCoursesTypes.SampleSolutionExt;
import CourseType = Core.CourseType;
import LearningDimension = AdminCoursesTypes.LearningDimension;
import CourseSaveData = AdminCoursesTypes.CourseSaveData;
import DistributableType = Core.DistributableType;

interface CourseResponse {
  course: AdminCoursesTypes.Course;
}

interface RemoveSuspendDataResponse extends TrainResponse<any> {
  success: boolean;
  failed: number;
  partly: boolean;
  succeeded: number;
}

@Injectable({ providedIn: 'root' })
export class AdminCoursesService implements OnDestroy {

  readonly reloadCourses$: Observable<number[]>;
  private _reloadCourses$ = new EventEmitter<number[]>(true);
  private _destroy$ = new Subject<void>();

  constructor(
    private adminSaveSupportService: AdminSaveSupportService<AdminCoursesTypes.Course>,
    private fileService: FileUploadService,
    private http: HttpClient,
    private infoService: InfoService,
    private router: Router,
  ) {
    this.reloadCourses$ = this._reloadCourses$.asObservable();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
    this._destroy$.next();
  }

  archiveCourses(courseIds: number[]): Observable<void> {
    let force = false;
    return this.checkUsage(courseIds)
      .pipe(takeWhile(result => result !== undefined))
      .pipe(switchMap(result =>
        {
          if (result === 'success') {
            const message = (courseIds.length === 1) ?
              $localize`:@@admin_courses_archive_single_confirm:Would you like to archive the selected course?` :
              $localize`:@@admin_courses_archive_multiple_confirm:Would you like to archive the selected courses?`;
            return this.infoService.showMessage(message, {
              title: $localize`:@@general_dialog_confirm_title:Confirm`,
              buttons: YesButton | CancelButton,
              closeable: false
            });
          }
          force = true;
          return of(YesButton);
        }))
      .pipe(takeWhile(button => button === YesButton))
      .pipe(switchMap(() => {
        let url = ApiUrls.getKey('AdminCourseArchive');
        if (force) {
          url += '?force=true'
        }
        return this.http.post<any>(url, courseIds);
      }))
      .pipe(map(() => {
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
        this._reloadCourses$.next(courseIds);
      }))
      .pipe(catchError(this.handleError));
  }

  createCourseContent(
    courseType: Core.CourseType
  ): AdminCoursesTypes.CourseContent  {

    switch ( courseType ) {
      case Core.CourseType.Learning:
        return {
          versions: [],
          typicalLearningTime: null,
          typicalLearningTimeDimension: LearningDimension.minutes,
          id: 0,
        } as AdminCoursesTypes.LearningProgram;
      case Core.CourseType.Test:
        return {
          versions: [],
          typicalLearningTime: null,
          typicalLearningTimeDimension: LearningDimension.minutes,
          id: 0,
        } as AdminCoursesTypes.Test;
      case Core.CourseType.ScoDocument:
        return {
          versions: [],
          typicalLearningTime: null,
          typicalLearningTimeDimension: LearningDimension.minutes,
          id: 0,
        } as AdminCoursesTypes.ScoDocument;
      case Core.CourseType.Link:
        return {
          context: {
            url: '',
          },
          versions: [],
          typicalLearningTime: null,
          typicalLearningTimeDimension: LearningDimension.minutes,
          id: 0,
        } as AdminCoursesTypes.Link;
    }
  }

  createCourseDetails(courseType: Core.CourseType): AdminCoursesTypes.Course {
    switch ( courseType ) {
      case Core.CourseType.ToDo:
        return {
          objType: DistributableType.lms_course,
          courseId: 0,
          courseType,
          description: {},
          id: 0,
          shortTitle: { default: null },
          status: 'published',
          taskDescription: {},
          title: { default: null },
          extensions: [],
        } as AdminCoursesTypes.CourseTodo;
      case Core.CourseType.Learning:
      case Core.CourseType.Test:
      case Core.CourseType.ScoDocument:
      case Core.CourseType.Link:
        return {
          objType: DistributableType.lms_course,
          courseId: 0,
          courseType,
          description: '',
          id: 0,
          shortTitle: '',
          status: 'published',
          title: '',
        } as AdminCoursesTypes.Course;
    }
    throw Error($localize`:@@admin_courses_type_not_implemented:This type of course cannot be created, yet!`);
  }

  createCourseResponse(courseType: Core.CourseType): AdminCoursesTypes.CourseResponse {
    switch ( courseType ) {
      case Core.CourseType.ToDo:
        const courseToDo = {
          courseId: 0,
          courseType,
          description: {},
          id: 0,
          shortTitle: { default: null },
          status: 'published',
          taskDescription: {},
          title: { default: null },
        } as AdminCoursesTypes.CourseTodo;

        return {
          id: 0,
          course: courseToDo,
          extensions: [],
        };
    }
    throw Error($localize`:@@admin_courses_type_not_implemented:This type of course cannot be created, yet!`);
  }

  createCourseSettings(courseType: Core.CourseType): AdminCoursesTypes.CourseSettings {
    // const warning = LanguageHelper.getEmptyTranslation();
    const response = {
      hasConfirmation: false,
      downloadable: false,
      resultUserVisible: false,
      viewableLoggedOut: false,
      hasWarning: false,
      texts: {},
      id: 0,
    };
    if (courseType !== CourseType.ScoDocument) {
      delete response.hasConfirmation;
    }
    return response;
  }

  deleteCourses(courseIds: number[]): Observable<void> {
    let force = false;
    return this.checkUsage(courseIds)
      .pipe(takeWhile(result => result !== undefined))
      .pipe(switchMap(result => {
        if (result === 'success') {
          const message = (courseIds.length === 1) ?
            $localize`:@@admin_courses_delete_single_confirm:Would you like to delete the selected course?` :
            $localize`:@@admin_courses_delete_multiple_confirm:Would you like to delete the selected courses?`;
          return this.infoService.showMessage(message, {
            title: $localize`:@@general_dialog_confirm_title:Confirm`,
            buttons: DeleteButton | CancelButton,
            closeable: false
          });
        }
        force = true;
        return of(DeleteButton);
      }))
      .pipe(takeWhile(button => button === DeleteButton))
      .pipe(switchMap(() => {
        let url = ApiUrls.getKey('AdminCourseDelete');
        if (force) {
          url += '?force=true';
        }
        return this.http.post<any>(url, courseIds);
      }))
      .pipe(map(() => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`,
          { infoType: InfoType.Success });
        this._reloadCourses$.next(courseIds);
      }))
      .pipe(catchError(this.handleError));
  }
/*
  deleteNotification(notificationId: number): Observable<void> {
    return;
  }*/

  getCourseAccountForUser(userId: number, courseId: number): Observable<ApiResponse<any>> {
    const url = ApiUrls.getKey('CtrlUser')
      .replace('{userId}', String(userId)).replace('{courseId}', String(courseId));
    return this.http.get<ApiResponse<any>>(url);
  }
  getCourseAccountInCurriculumForUser(userId: number, courseId: number, curriculumId: number): Observable<ApiResponse<any>> {
    const url = ApiUrls.getKey('CtrlUserCourseInCur')
      .replace('{userId}', String(userId)).replace('{courseId}', String(courseId)).replace('{curriculumId}', String(curriculumId));
    return this.http.get<ApiResponse<any>>(url);
  }

  getCourse(courseId: number): Observable<AdminCoursesTypes.Course> {
    const url = `${ApiUrls.getKey('AdminCourse')}/${courseId}`;
    return this.http.get<CourseResponse>(url)
      .pipe(map(response => response?.course));
  }

  getCourseV2(courseId: number): Observable<AdminCoursesTypes.CourseResponse> {
    const url = `${ApiUrls.getKey('AdminCourseV2')}/${courseId}`;
    return this.http.get<AdminCoursesTypes.CourseResponse>(url);
  }

  getTagsForCourse(courseId: number): Observable<Legacy.Tag[]> {
    const url = ApiUrls.getKey('AdminCoursesGetTagsLegacy')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<AdminCoursesTypes.CourseTags>(url)
      .pipe(map(response => response.data));
  }

  getAllAvailableTagsForCourses(): Observable<string[]> {
    const url = ApiUrls.getKey('AdminCourseGetAllAvailableTags');
    return this.http.get<AdminCoursesTypes.AvailableCourseTags>(url)
      .pipe(map(response => response.tags));
  }

  getCourseContent(courseId: number): Observable<AdminCoursesTypes.CourseContent> {
    const url = ApiUrls.getKey('AdminCourseRedeploy_ng')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<ApiResponse<AdminCoursesTypes.CourseContent>>(url)
      .pipe(map(response => response.data));
  }

  getCourseOther(courseId: number): Observable<AdminCoursesTypes.CourseOther> {
    const url = ApiUrls.getKey('AdminCourseGetOther')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<AdminCoursesTypes.CourseOther>(url);
  }

  getCourseDetails(courseId: number): Observable<AdminCoursesTypes.Course> {
    const url = ApiUrls.getKey('AdminCourseGetDetails')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<ApiResponse<AdminCoursesTypes.Course>>(url)
      .pipe(map(response => response.course));
  }

  getCourseCurricula(courseId: number): Observable<Curriculum.UsageDetails[]> {
    const url = ApiUrls.getKey('AdminCourseGetCurricula')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<AdminCoursesTypes.CourseCurricula>(url)
      .pipe(map(response => response.data));
  }

  /*createCourseNotifications(): Observable<AdminCoursesTypes.CourseNotification[]> {
    const response: AdminCoursesTypes.CourseNotification[] = [
      {
      id: 1,
      title: 'Test 1',
      description: 'Test Beschreibung 1',
      event: '',
      active: true,
      },
      {
      id: 2,
      title: 'Test 2',
      description: 'Test Beschreibung 2',
      event: '',
      active: false,
      },
      {
      id: 3,
      title: 'Test 3',
      description: 'Test Beschreibung 3',
      event: '',
      active: false,
      },
    ];
    return of(response);
    /!*const url = ApiUrls.getKey('AdminCourseGetCurricula')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<AdminCoursesTypes.CourseCurricula>(url)
      .pipe(map(response => response.data));*!/
  }*/

  getCourseNotifications(courseId: number, moduleId: number): Observable<AdminCoursesTypes.CourseNotification[]> {
    const url = ApiUrls.getKey('AdminCourseGetNotifications')
      .replace(/{courseId}/gi, String(courseId))
      .replace(/{moduleId}/gi, String(moduleId))
      .replace(/{itemsModuleId}/gi, '11'); // include course items events / notifications
    return this.http.get<any>(url)
      .pipe(map(response => response.events));
  }

  /**
   * @deprecated use getCourse:ist_v2 instead
   * @returns courses
   */
  getCourseList = (): Observable<CourseListResponse> => {
    const url = ApiUrls.getKey('AdminCourseList');
    return this.http.get<CourseListResponse>(url);
  };

  getCourseList_v2 = (): Observable<CourseListResolverResponse> => {
    const url = ApiUrls.getKey('AdminCourseList_v2');
    return this.http.get<CourseListResolverResponse>(url);
  };

  getCourseSettings(courseId: number): Observable<AdminCoursesTypes.CourseSettings> {
    // return of(void(0));
    const url = ApiUrls.getKey('AdminCourseGetSettings')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.get<AdminCoursesTypes.CourseSettings>(url);
  }

/*

  redeployCourse(course: AdminCoursesTypes.Course): Observable<AdminCoursesTypes.Course> {
    // todo implement me!
    return EMPTY;
  }
*/

  saveCourse(source: AdminCoursesTypes.Course): Observable<AdminCoursesTypes.Course> {
    const course = ViewHelper.cloneDeep(source);

    // remove trainers array for homework courses
    delete (course as AdminCoursesTypes.CourseTodo).trainers;

    const courseType = course.courseType = LearnerAccountTypes.courseTypeFactory(course.courseType);
    if ( courseType == null ) {
      this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
        { infoType: InfoType.Error });
      return EMPTY;
    }

    const courseId = course.courseId;
    const isNew = !(course.courseId > 0);
    const url = isNew ?
      `${ApiUrls.getKey('AdminCourse')}/${Core.CourseType[courseType]}` :
      `${ApiUrls.getKey('AdminCourse')}/${Core.CourseType[courseType]}/${courseId}`;

    const formData = new FormData();

    course.attachments = course.attachments?.map(fileInfo => {
      if ( fileInfo instanceof UploadFile ) {
        formData.append('attachments', fileInfo.file);
        return {
          uuid: fileInfo.uuid,
          fileName: fileInfo.fileName,
          fileSize: fileInfo.fileSize,
          mime: fileInfo.mime,
        };
      } else {
        return fileInfo;
      }
    });

    formData.append('course', JSON.stringify(course, JsonOmitViewReplacer));

    return this.http.post<CourseResponse>(url, formData)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(map(response => response.course))
      .pipe(tap(result => course.courseId = result.courseId))
      .pipe(tap(result => {
        this.adminSaveSupportService.resetRequiredComponents();
        if ( isNew ) {
          course.courseId = course.id = result.courseId;
          // navigate before showing message to keep message in view
          this.router.navigateByUrl(`/admin/courses/todo/${course.courseId}`).then();
        }
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  saveSampleSolution(course: AdminCoursesTypes.Course, extension: SampleSolutionExt): Observable<AdminCoursesTypes.CourseResponse> {
    const completion = (): Observable<AdminCoursesTypes.CourseResponse> => {

      const extensions: AdminCoursesTypes.CourseExtensionsRequest = {
        extensions: [
          {
            type: AdminCoursesTypes.Extensions.SampleSolution,
            text: extension.text ?? {},
            onlyForControlling: extension.onlyForControlling ?? true,
            files: extension.files ?? [],
          },
        ],
      };

      const courseWithExtensions: AdminCoursesTypes.CourseWithExtensions = {
        course,
        extensions,
      };

      return this.saveCourseV2(courseWithExtensions);
    };


    if ( !(extension?.files?.length > 0) ) {
      return completion();
    }

    const uploadTasks: Array<Observable<FileInfo>> =
      extension.files.reduce((pV, attachment) => {
        if ( attachment.id === undefined && UploadFile.instanceOfUploadFile(attachment) ) {
          pV.push(this.fileService.uploadFileV2(
            attachment.uuid, attachment.file,
            FileAuthorizationType.login_required,
            FileAuthorizationRefType.course_attachment));
        }
        return pV;
      }, []);

    if ( !(uploadTasks.length > 0) ) {
      return completion();
    }
    return combineLatest(uploadTasks)
      .pipe(takeUntil(this._destroy$))
      .pipe(switchMap(fileInfos => {
        fileInfos.forEach(fileInfo => {
          for ( let i = 0; i < extension.files.length; i++ ) {
            if ( fileInfo.uuid === extension.files[i].uuid ) {
              extension.files[i] = fileInfo;
            }
          }
        });
        return completion();
      }));
  }

  saveCourseV2(source: AdminCoursesTypes.CourseWithExtensions): Observable<AdminCoursesTypes.CourseResponse> {
    const course = ViewHelper.cloneDeep(source.course);

    // remove trainers array for homework courses
    delete (course as AdminCoursesTypes.CourseTodo).trainers;

    const courseType = course.courseType = LearnerAccountTypes.courseTypeFactory(course.courseType);
    if ( courseType == null ) {
      this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
        { infoType: InfoType.Error });
      return EMPTY;
    }

    const courseId = course.courseId;
    const isNew = !(course.courseId > 0);
    const url = isNew ?
      `${ApiUrls.getKey('AdminCourseV2')}/${Core.CourseType[courseType]}` :
      `${ApiUrls.getKey('AdminCourseV2')}/${Core.CourseType[courseType]}/${courseId}`;

    const formData = new FormData();

    course.attachments = course.attachments?.map(fileInfo => {
      if ( fileInfo instanceof UploadFile ) {
        formData.append('attachments', fileInfo.file);
        return {
          uuid: fileInfo.uuid,
          fileName: fileInfo.fileName,
          fileSize: fileInfo.fileSize,
          mime: fileInfo.mime,
        };
      } else {
        return fileInfo;
      }
    });

    formData.append('course', JSON.stringify(course, JsonOmitViewReplacer));
    formData.append('extensions', JSON.stringify(source.extensions, JsonOmitViewReplacer));

    return this.http.post<AdminCoursesTypes.CourseResponse>(url, formData)
      .pipe(catchError(() => {
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          { infoType: InfoType.Error });
        return EMPTY;
      }))
      .pipe(tap(result => course.courseId = result.course.courseId))
      .pipe(tap(result => {
        this.adminSaveSupportService.resetRequiredComponents();
        if ( isNew ) {
          course.courseId = course.id = result.course.courseId;
          // navigate before showing message to keep message in view
          this.router.navigateByUrl(`/admin/courses/todo/${course.courseId}`).then();
        }
        this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
      }));
  }

  saveNewCourseDetails(
    saveData: AdminCoursesTypes.CourseSaveData
  ): Observable<AdminCoursesTypes.Course> {
    // return;
    const url = ApiUrls.getKey('AdminCourseCreateDetails');
    return this.http.put<AdminCoursesTypes.Course>(url, saveData);
  }

  savePartialCourse(courseId: number, course: CourseSaveData): Observable<AdminCoursesTypes.Course> {
    const url = ApiUrls.getKey('AdminCourseSaveDetails')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.post<AdminCoursesTypes.Course>(url, JSON.stringify(course));
  }

  savePartialCourseNG(course: CourseSaveData): Observable<AdminCoursesTypes.Course> {
    const url = ApiUrls.getKey('AdminCourseSaveDetailsNG');
    return this.http.post<AdminCoursesTypes.Course>(url, JSON.stringify(course));
  }

  redeployCourse(courseId: number, course: FormData): Observable<AdminCoursesTypes.CourseRedeploymentResponseData> {
    const url = ApiUrls.getKey('AdminCourseRedeploy')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.post<AdminCoursesTypes.CourseRedeploymentResponse>(url, course)
      .pipe(map(data => data.changes));
  }

  addCourseTag(courseId: number, tag: string): Observable<AdminCoursesTypes.Course> {
    const url = ApiUrls.getKey('AdminCoursesGetTagsLegacy')
      .replace(/{courseId}/gi, String(courseId));
    return this.http.put<AdminCoursesTypes.Course>(url, JSON.stringify({
      tag
    }));
  }

  removeCourseTag(courseId: number, tag: string): Observable<AdminCoursesTypes.Course> {
    const url = ApiUrls.getKey('AdminCourseDeleteTag')
      .replace(/{courseId}/gi, String(courseId))
      .replace(/{tag}/gi, tag);
    return this.http.delete<AdminCoursesTypes.Course>(url);
  }

  transformStringToCourseType(courseType: string): Core.CourseType {
    switch ( courseType ) {
      case 'learning':
        return CourseType.Learning;
      case 'test':
        return CourseType.Test;
      case 'document':
        return CourseType.ScoDocument;
      case 'link':
        return CourseType.Link;
    }
  }

  deployCourse(title: string, courseType: string, uploadedContent: File): Observable<number> {
    const formData = new FormData();
    formData.append('content', uploadedContent);
    formData.append('title', title);
    formData.append('courseType', courseType);

    const url = ApiUrls.getKey('AdminCourseDeploy');
    return this.http.post<{success: boolean; courseId: number}>(url, formData)
      .pipe(map(response => response.courseId));
  }

  removeSuspendData(id: number): Observable<RemoveSuspendDataResponse> {
    const url = ApiUrls.getKey('AdminCourseRemoveSuspendData').replace('{courseId}', String(id));
    return this.http.delete<RemoveSuspendDataResponse>(url);
  }

  removeAssignments(targetId: number, targetType: Core.DistributableType): any {
    const url = ApiUrls.getKey('DistributionAssignments')
      .replace('{objectId}', String(targetId))
      .replace('{objectType}', targetType);
    return this.http.delete<RemoveSuspendDataResponse>(url);
  }

  public static getMaxFileSize(courseType: number, contentType: string): number {
    if (courseType !== Core.CourseType.ScoDocument) {
      // 1.5 GB
      return 1500 * 1024 * 1024 - 1;
    }

    if (['video/mp4', 'audio/mpeg'].includes(contentType)) {
      // 300 MB
      return 300 * 1024 * 1024 - 1;
    }
    // 80 MB
    return 80 * 1024 * 1024 - 1;
  }

  private checkUsage(courseIds: number[]): Observable<AdminCoursesValidateDeleteTypes.Result> {
    const validateUrl = ApiUrls.getKey('AdminCourseUsage');
    return this.http.post<AdminCoursesValidateDeleteTypes.ApiResponse>(validateUrl, courseIds)
      .pipe(switchMap(response => {
        if ( response.canDelete !== true ) {
          return this.infoService.showDialog<AdminCoursesValidateDeleteComponent, AdminCoursesValidateDeleteTypes.ApiResponse, AdminCoursesValidateDeleteTypes.Result>(AdminCoursesValidateDeleteComponent, response);
        } else {
          return of('success' as AdminCoursesValidateDeleteTypes.Result);
        }
      }));
  }

  private handleError = (error: any): Observable<never> => {
    this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
      { infoType: InfoType.Error });
    return EMPTY;
  };

}
