<div class="site-wrapper" (click)="onNavigateToDetails()">
  <div class="card-wrapper">
    <div class="example-card">
      <div
        class="image-wrapper"
        [ngStyle]="cardBackgroundStyles$ | async"
      >
        <div
          #matTooltip="matTooltip"
          *ngIf="isBooked(content.catalogBooking)"
          class="card-icon-booked rounded-div"
          matTooltip="Booked"
          i18n-matTooltip="@@booked"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_favourite.svg" alt="booked">
        </div>
        <div
          #matTooltip="matTooltip"
          *ngIf="isAssigned(content)"
          class="card-icon-assigned rounded-div"
          matTooltip="Assigned"
          i18n-matTooltip="@@catalog_assigned"
          (click)="matTooltip.toggle(); $event.stopPropagation();"
        >
          <img src="assets/icons/svg/Controls/icon_assigned.svg" alt="favourite">
        </div>


      </div>
      <div class="mat-card-content">
        <div class="mat-card-title">
          <p class="font-medium letterspacing-min" #title>{{ content?.title | localeText }}</p>
        </div>
        <div class="subtitle-wrapper">
          <p class="mat-card-subtitle font-light letterspacing-more">{{ objSubTypeAsText }}</p>
          <div class="price">
            <mat-icon class="price-icon" svgIcon="tag-outline"></mat-icon>
            <span class="price-value">{{ price }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
