import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import { AdminCurriculumLegacy } from 'src/app/route/admin/admin-curriculum/admin-curriculum.types';
import { MatInputModule } from '@angular/material/input';
import { CachedSubject } from 'src/app/core/cached-subject';
import { MatListModule } from '@angular/material/list';
import { DialogHeaderComponent } from 'src/app/component/dialog-header/dialog-header.component';

export interface SelectCurriculumDialogComponentData {
  curricula: Array<AdminCurriculumLegacy>;
}

@Component({
  selector: 'rag-select-curriculum-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatListModule
  ],
  templateUrl: './select-curriculum-dialog.component.html',
  styleUrls: ['./select-curriculum-dialog.component.scss']
})
export class SelectCurriculumDialogComponent implements OnInit {

  form: FormGroup;
  filteredCurriculaOptions$: Observable<Array<AdminCurriculumLegacy>>;
  selectBUttonDisabled$: Observable<boolean>;
  selectedCurricula$: Observable<Array<AdminCurriculumLegacy>>;

  private _selectedCurricula$ = new CachedSubject<Array<AdminCurriculumLegacy>>([]);
  private curricula: Array<AdminCurriculumLegacy> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectCurriculumDialogComponentData,
    private dialogRef: MatDialogRef<SelectCurriculumDialogComponent>,
    private formBuilder: FormBuilder
  ) {
    this.selectedCurricula$ = this._selectedCurricula$.asObservable();
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('60vh');
    this.buildForm();

    this.curricula = this.data.curricula;

    this.filteredCurriculaOptions$ = this.form.get('curriculum').valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      map(value => this._searchForCurriculum(value)));
  }

  onClearSelectedCurriculum() {
    this.form.get('curriculum').setValue(null);
  }

  onDelete(curriculum: AdminCurriculumLegacy) {
    let _selectedCurricula = this._selectedCurricula$.value;
    _selectedCurricula = _selectedCurricula.filter(c => c.id !== curriculum.id);
    this._selectedCurricula$.next(_selectedCurricula);

    const _selectedCUrriculaId = _selectedCurricula.map(c => c.id);

    this.curricula = this.data.curricula.filter( c => !_selectedCUrriculaId.includes(c.id) );

    this.form.get('curriculum').setValue(null);
  }

  onCurriculumSelected($event: MatAutocompleteSelectedEvent) {
    const selectedCurriculum = $event.option.value;
    const _selectedCurricula = this._selectedCurricula$.value;
    _selectedCurricula.push($event.option.value);
    this._selectedCurricula$.next(_selectedCurricula);
    this.onClearSelectedCurriculum();

    this.curricula = this.curricula.filter( c => c.id !== selectedCurriculum.id );
  }

  onSelect() {
    this.dialogRef.close(this._selectedCurricula$.value.map(c => c.id));
  }

  rendeCurriculum(c: AdminCurriculumLegacy) {
    if (c == null) {
      return null;
    }
    return c.name;
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      curriculum: [null, []]
    });

    this.selectBUttonDisabled$ = this.selectedCurricula$.pipe(map(collection => collection.length === 0));
  }

  private _searchForCurriculum(value: string | null | AdminCurriculumLegacy): AdminCurriculumLegacy[] {
    if (value == null) {
      return this.curricula;
    }
    if (typeof value === 'object') {
      return [value];
    }
    return this.curricula
      .filter(c => c.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }

}
