import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { PrincipalService } from '../../../core/principal/principal.service';
import { CatalogService } from '../../../core/catalog/catalog.service';
import { RedirectHelper } from '../../../core/redirect.helper';


@Injectable({ providedIn: 'root' })
export class PublicCatalogRedirectGuard
  implements CanActivate {

  constructor(
    private catalogService: CatalogService,
    private principalService: PrincipalService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.principalService.isLogged$
      .pipe(switchMap(isLogged => {
        if ( isLogged ) {

          // user is logged in -> prevent access to login component
          const url = route.paramMap.get('url');
          const queryParams = RedirectHelper.isValidRedirect(url) ? { url } : null;
          return of(this.router.createUrlTree(['/post-login'], { queryParams }));
        } else {

          return this.checkCatalogRedirect();
        }
      }))
      // map all undefined states to true
      .pipe(map(result => result ?? true))
      // complete queue after first value
      .pipe(take(1));
  }

  private checkCatalogRedirect(): Observable<boolean | UrlTree> {
    return this.catalogService.checkAccess(false)
      .pipe(switchMap(result => {
        if ( result === '/login' ) {

          // catalog requires login -> keep current route
          return of(true);
        } else if ( result === true ) {

          // user is not logged in -> check we should display as home
          return this.catalogService.getCatalogState()
            .pipe(map(catalogState => {
              if ( catalogState.displayAsHome ) {

                // public catalog is available and defined as landing page -> redirect to catalog
                return this.router.parseUrl('/catalog');
              }
            }));
        } else {

          return of(void (0));
        }
      }));
  }

}
