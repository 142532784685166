<mat-menu #matMenu>
  <ng-template matMenuContent>
    <ng-container
      *ngIf="!isNew"
    >
      <button
        (click)="onSaveAsReport()"
        [disabled]="loading || !canSaveNewReport || hasSelections"
        mat-menu-item
        type="button"
      >
        <mat-icon svgIcon="content-save"></mat-icon>
        <span i18n="@@global_save_as">Save As...</span>
      </button>
      <mat-divider></mat-divider>
    </ng-container>

    <button
      *ngIf="permissions?.userMessagesSend"
      (click)="onSendMessage()"
      [disabled]="loading || !maySendMessage()"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="email"></mat-icon>
      <span i18n="@@global_messaging">Send message</span>
    </button>

    <button
      *ngIf="permissions?.hasCtrlEditCurriculumAccount && reportConfig?.targetType === 'Curriculum'"
      (click)="onSwitchPath()"
      [disabled]="loading || !maySave()"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="call-split"></mat-icon>
      <span i18n="@@curriculum_switch_path">Switch path</span>
    </button>

    <button
      (click)="onForceReload()"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="refresh"></mat-icon>
      <span i18n="@@refresh">Refresh</span>
    </button>

    <mat-divider></mat-divider>

    <button
      (click)="onCSVExport()"
      [disabled]="loading"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="file-delimited"></mat-icon>
      <span i18n="@@pivot_table_export_csv">Export to CSV</span>
    </button>

    <button
      (click)="onXLSXExport()"
      [disabled]="loading"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span i18n="@@pivot_table_export_xlsx">Export to XLSX</span>
    </button>

    <button
      (click)="onXLSXExportLearningTime()"
      *ngIf="reportConfig?.targetType === 'Curriculum'"
      [disabled]="loading"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="file-excel"></mat-icon>
      <span i18n="@@pivot_table_export_xlsx_learning_time">Export learning time to XLSX</span>
    </button>

    <button
      (click)="onDownloadCertificates()"
      *ngIf="reportConfig?.targetType === 'Curriculum'"
      [disabled]="loading || !mayDownloadCertificates()"
      mat-menu-item
      type="button"
    >
      <mat-icon svgIcon="certificate"></mat-icon>
      <span i18n="@@pivot_table_download_certificates">Download certificates</span>
    </button>

    <ng-container *ngIf="!isNew && permissions?.navCtrlReportDashboard">
      <mat-divider></mat-divider>

      <button
        (click)="addWidgetPieChart()"
        *ngIf="reportConfig?.targetType === 'Curriculum'"
        [disabled]="loading || isDirty || isNew"
        mat-menu-item
        type="button"
      >
        <mat-icon svgIcon="chart-pie"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_pie_chart">Add pie chart to dashboard</span>
      </button>

      <button
        (click)="addWidgetBarChart()"
        *ngIf="reportConfig?.targetType === 'Curriculum'"
        [disabled]="loading || isDirty || isNew"
        mat-menu-item
        type="button"
      >
        <mat-icon svgIcon="chart-bar"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_bar_chart">Add bar chart to dashboard</span>
      </button>

      <button
        (click)="addWidgetReportLinks()"
        [disabled]="loading || isDirty || isNew"
        mat-menu-item
        type="button"
      >
        <mat-icon svgIcon="link"></mat-icon>
        <span i18n="@@pivot_table_add_widget_report_links">Add quick link to dashboard</span>
      </button>
    </ng-container>

    <ng-container *ngIf="permissions?.navAdminTargetGroups">
      <mat-divider></mat-divider>

      <div mat-menu-item class="hidden-menu-item" aria-hidden="true"></div>
      <rag-select-target-groups-menu
        [users]="getSelectionsForTargetGroup()"
        [showButtonNew]="true"
        xPosition="before"
        (click)="$event.stopImmediatePropagation()"
        [disabled]="loading"
      ></rag-select-target-groups-menu>

    </ng-container>
  </ng-template>
</mat-menu>

<button
  [matMenuTriggerFor]="matMenu"
  mat-stroked-button
  type="button"
>
  <mat-icon svgIcon="menu"></mat-icon>
</button>
