<ng-container *ngIf="!isNothing(content)">
  <rag-breadcrumbs>
    <a *ngIf="(styleSettings$ | async) as styleSettings">{{styleSettings.acc.myContentsName | localeText}}</a>

    <rag-breadcrumbs-assignment-type
      [assignmentType]="content.assignmentType"
    ></rag-breadcrumbs-assignment-type>

    <a>{{content.title}}</a>
  </rag-breadcrumbs>

  <rag-curriculum-title [curriculum]="content" [subtitle]="getSubtitle()">
    <rag-important-content-info
      [content]="content"
      [displayMore]="true"
      (readDocumentAcknowledge)="onReadDocumentAcknowledge()"
    ></rag-important-content-info>

    <button
      *ngIf="content?.booked"
      class="content-action"
      type="button"
      mat-flat-button
      color="warn"
      [disabled]="isCancelBookingDisabled()"
      (click)="onCancelBooking()"
    >
      <span i18n="@@cancel_booking">Cancel booking</span>
    </button>

  </rag-curriculum-title>

  <ng-container *ngIf="(content?.description | localeText) as description">
    <div [innerHTML]="description | safe:'html'"></div>
  </ng-container>

  <rag-attachments [attachments]="attachments"></rag-attachments>

  <rag-item-list
    [highlightItemId]="highlightItemId"
    [account]="account"
    [curriculum]="content"
    [parent]="content"
    [showHeaders]="true"
  ></rag-item-list>
</ng-container>
