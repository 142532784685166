<div class="general-design-container">
  <div class="actions">
    <mat-form-field class="language-selection" appearance="outline">
      <mat-label>
        <span i18n="@@login_conf_language_select">Language to edit</span>
      </mat-label>
      <mat-select [(value)]="selectedLanguage" (selectionChange)="updateDisplayedTexts()">
        <ng-container *ngFor="let language of languages; let i = index">
          <mat-option value="{{language.key}}">
            <span class="flag-icon flag-icon-{{language.country}}">
            </span><span>{{language.title | localeText}}</span>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="action-buttons">
      <!-- Save button -->
      <button (click)="reset()" [disabled]="(isDirty$ | async) === false" mat-flat-button>
        <mat-icon svgIcon="refresh"></mat-icon>
        <span i18n="@@account_design_reset_login_colors_button">Reset</span>
      </button>
      <button (click)="onSave()" [disabled]="(isDirty$ | async) === false" color="primary" mat-flat-button>
        <mat-icon svgIcon="content-save"></mat-icon>
        <span i18n="@@global_save">Save</span>
      </button>
    </div>
  </div>

  <div class="login-wrapper">
    <div class="centering">
      <div class="login-picture">
        <button (click)="editItem('welcomePictureId', pendingWelcomePictureId)" class="edit" color="primary"
                mat-mini-fab>
          <mat-icon svgIcon="pencil"></mat-icon>
        </button>
        <div *ngIf="welcomePictureURL !== undefined" [style.background-image]="'url('+welcomePictureURL+')'"
             class="login-picture-background">
        </div>
      </div>
      <div id="loginForm">
        <div class="login-container">
          <div class="edit-wrapper">
            <h2 [innerHTML]="this.displayedLoginFormText | safe: 'html'"></h2>
            <button (click)="editItem('loginFormTexts', pendingLoginFormTexts)" class="edit" color="primary"
                    mat-mini-fab>
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </div>

          <div class="edit-wrapper">
            <mat-form-field appearance="outline">
              <mat-label>
                <mat-icon svgIcon="account"></mat-icon>
                <span [innerHTML]="this.displayedLoginPlaceholder | safe:'html'"></span>
              </mat-label>

              <input matInput placeholder="Login" type="text">
            </mat-form-field>
            <button (click)="editItem('loginPlaceholder', pendingLoginPlaceholder)" class="edit" color="primary"
                    mat-mini-fab>
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </div>

          <div class="edit-wrapper">
            <mat-error [innerHTML]="this.displayedLoginErrorElement | safe:'html'"></mat-error>
            <button (click)="editItem('loginError', pendingLoginError)" class="edit" color="primary" mat-mini-fab>
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </div>


          <mat-form-field class="error-placeholder" appearance="outline">
            <mat-label>
              <mat-icon svgIcon="lock"></mat-icon>
              <span class="label-text" i18n="@@password">Password</span>
            </mat-label>
            <input i18n-placeholder="@@login_form_enter_password"
                   matInput placeholder="Enter your password here"
                   [type]="hidePassword ? 'text' : 'password'">
            <button (click)="this.hidePassword = ! this.hidePassword;"
                    type="button"  matSuffix mat-icon-button>
              <mat-icon [svgIcon]="hidePassword ? 'eye' : 'eye-off'"></mat-icon>
            </button>
            <mat-error>
              <span i18n="@@login_form_enter_password_error">You must enter a password</span>
            </mat-error>
          </mat-form-field>

          <button
            id="forgot-password"
            mat-button type="button"
            *ngIf="!anonymousRegistrationEnabled"
          >
            <span i18n="@@login_page_forgot_password_button">Forgot password?</span>
          </button>

          <button class="loginButton login" color="primary" mat-flat-button>
            <span i18n="@@sign_in_button">SIGN IN</span>
          </button>
          <button *ngIf="pendingRegistrationButtonActive"
                  class="loginButton register" color="primary" mat-stroked-button
                  type="button">
            <span i18n="@@login_page_register_button">Register account</span>
          </button>
          <div class="checkbox-registration-button">
            <mat-checkbox (change)="this.checkIfSomethingIsDirty()" [(ngModel)]="pendingRegistrationButtonActive"
                          color="primary">
              <span i18n="@@account_design_show_registration_button">Show the registration button</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="edit-wrapper">
    <div [innerHTML]="this.displayedWelcomeText | safe:'html'"
         class="welcome-text"></div>
    <button (click)="editItem('welcomeTexts', pendingWelcomeTexts)" class="edit" color="primary" mat-mini-fab>
      <mat-icon svgIcon="pencil"></mat-icon>
    </button>
  </div>

  <div class="options">
    <div *ngIf="permissionStates.ngCustomColorsAdmin" class="apply-custom-colors">
      <mat-checkbox
        (change)="changeApplyCustomColors()"
        [(ngModel)]="pendingApplyCustomColors"
        color="primary">
        <span i18n="@@login_conf_apply_custom_colors">Apply custom colors</span>
      </mat-checkbox>
    </div>
  </div>

</div>
