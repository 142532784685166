<rag-beta-toggle *ngIf="showBetaToggle"></rag-beta-toggle>
<mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
<rag-header *ngIf="viewModeDefault$ | async"></rag-header>
<mat-sidenav-container id="sidenavContainer" (backdropClick)="toggleSideNav()" autosize>
  <mat-sidenav [autoFocus]="false" [opened]="sidenavVisible$ | async" mode="over">
    <nav rag-header-sidenav></nav>
  </mat-sidenav>
  <mat-sidenav-content class="app-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- <div class="blocker" [ngClass]="{'active': (loading$ | async)}"></div> -->
<rag-footer *ngIf="viewModeDefault$ | async"></rag-footer>
