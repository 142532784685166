import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { TableGroupingControllerComponent } from '../../../../../component/table/table-grouping/table-grouping-controller.component';
import { TableColumnMenuService } from '../../../../../component/table/table-column-menu/table-column-menu.service';
import {
  ReportTableColumnMenuData,
  ReportTableRow,
  ReportTableRowChild,
  ReportTableRowParent,
} from './report-table.types';
import {
  ColumnSettings,
  Report,
  ReportConfig,
  ReportRowData,
  ReportRowStatistics,
} from '../../../../../core/report/report.types';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '../../../../../core/reactive/until-destroyed';
import { catchError, debounceTime, first, map, take, tap } from 'rxjs/operators';
import { ReportTableHelper } from './report-table.helper';
import { REPORT_TABLE_MENU_DEFAULTS } from './report-table.defaults';
import { TableControllerTypes } from '../../../../../component/table/table-controller/table-controller.types';
import { ReportTableService } from './report-table.service';
import { ColumnFilterV2 } from '../../../../../core/column-settings/column-filter.types';
import { TableGroupingHelper } from '../../../../../component/table/table-grouping/table-grouping.helper';
import { ReportGeneratorV2RouteData } from '../report-generator-v2.types';
import { ReportGeneratorV2Helper } from '../report-generator-v2.helper';
import { ReportGeneratorV2UpdateHelper } from '../report-generator-v2-update.helper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AdminOfflineService } from 'src/app/route/admin/admin-offline/admin-offline.service';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { MatMenuTrigger } from '@angular/material/menu';
import { Core } from '../../../../../core/core.types';
import { UserHelper } from 'src/app/core/users.helper';
import { ReportService } from '../../../../../core/report/report.service';
import { EMPTY } from 'rxjs';
import { VirtualRoomAttendance } from '../../../../../core/virtual-room-attendance.types';
import { EventParticipantsService } from '../../../../admin/admin-offline/components/content-events/event-participants/event-participants.service';
import { InfoType, MessageConstants } from '../../../../../core/info/info.types';
import { InfoService } from '../../../../../core/info/info.service';
import { MatTableDataSource } from '@angular/material/table';
import { mergeWith } from 'lodash';


@Component({
  selector: 'rag-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: [ './report-table.component.scss' ],
})
export class ReportTableComponent
  extends TableGroupingControllerComponent<ReportTableRow, ReportTableRowChild, ReportTableRowParent,
    ReportTableColumnMenuData, ReportRowData, ReportRowStatistics> {

      @ViewChild('menuTrigger', { static: false }) menuTrigger: MatMenuTrigger;
  eventSchedule: OfflineContent.EventSchedule;
  offlineContent: OfflineContent.Event;
  participant: OfflineContent.Participant;
  localReload: boolean;

  private _defaultColumnSettings: ColumnSettings[];
  private _filterUpdate$ = new EventEmitter<void>();
  private _filterBlur$ = new EventEmitter<void>();
  private _tableRebuildTrigger$ = new EventEmitter<void>();
  private _report: Report;
  private _reportRows: ReportTableRow[];

  constructor(
    tableColumnMenuService: TableColumnMenuService,
    private eventParticipantsService: EventParticipantsService,
    private infoService: InfoService,
    private reportService: ReportService,
    private reportTableService: ReportTableService,
    private route: ActivatedRoute,
    private adminOfflineService: AdminOfflineService,
  ) {
    super(tableColumnMenuService, new MatTableDataSource <ReportTableRow>());

    this.route.data
      .pipe(map(data => data?.reportGeneratorData))
      .pipe(tap(this.updateRouteData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.reportTableService.report$
      .pipe(tap(this.updateReportData))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.reportTableService.repaint$
      .pipe(tap(() => this.buildTable(false)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this._filterBlur$.asObservable();

    this._filterUpdate$.asObservable()
      .pipe(debounceTime(1500))
      // update filter settings
      .pipe(tap(this.updateFilterState))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this._tableRebuildTrigger$.asObservable()
      .pipe(tap(() => this.localReload = true))
      .pipe(debounceTime(100))
      .pipe(tap(() => this.buildTable(true)))
      .pipe(catchError(() => {
        this.localReload = false;
        return EMPTY;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();


    this.parentColumns = [ 'checkbox', 'parent', 'actions' ];
  }

  get reportConfig(): ReportConfig | null {
    if ( this._report == null ) {
      return null;
    }

    return this._report.reportConfig;
  }

  get isOffline() {
    return this._report.reportConfig.targetType === 'Offline';
  }

  get targetType(): Core.DistributableType {
    switch ( this._report.reportConfig?.targetType ?? 'Curriculum' ) {
      case 'Course':
        return Core.DistributableType.lms_course;
      case 'Curriculum':
        return Core.DistributableType.lms_curriculum;
      case 'Offline':
        return Core.DistributableType.lms_offlineCnt;
      default:
        return null;
    }
  }

  getFilterTemplate(
    column: TableControllerTypes.ColumnMenuItem<ReportTableRow>,
    tplFilterDefault: TemplateRef<any>,
    tplFilterDisplayStatus: TemplateRef<any>,
  ): TemplateRef<any> {

    switch ( column?.id ) {

      case 'displayStatus':
        return tplFilterDisplayStatus;

      default:
        return tplFilterDefault;
    }
  }

  getSelectableRows(): ReportTableRow[] {
    return this.filterForSelection(this._reportRows);
  }

  isSortingDisabled(column: TableControllerTypes.ColumnMenuItem<ReportTableRow>) {
    return this._report.reportConfig?.groupings.indexOf(column.id) >= 0;
  }

  hasActions(row: ReportTableRow): boolean {
    if ( this.inputDisabled || (row == null) ) {
      return false;
    }

    if ( row.$rowType === 'parent' ) {
      return this.hasMultiActions(TableGroupingHelper.getChildren(row));
    }

    const childRow = this.asChild(row);
    return childRow.$filterVisible ||
      this.reportTableService.maySave(childRow) ||
      this.reportTableService.maySendMessage(childRow);
  }

  hasMultiActions(rows: ReportTableRow[] = []): boolean {
    // check only children
    return rows.map(entry => this.asChild(entry))
      // only if they are visible
      .filter(entry => entry?.$filterVisible !== false)
      // find the first entry which may be saved
      .find(entry => this.hasActions(entry)) != null;
  }

  hasNoFilteredData(): boolean {
    return this.hasTableData() &&
      // has data, but none are visible
      !(this.dataSource.data?.length > 0);
  }

  hasTableData(): boolean {
    return this._reportRows?.length > 0;
  }

  isSelectAllDisabled(): boolean {
    if ( this.inputDisabled ) {
      return true;
    }

    return !this.hasMultiActions(this._reportRows);
  }

  maySave(row: ReportTableRowChild): boolean {
    if ( this.inputDisabled ) {
      return false;
    }

    return this.reportTableService.maySave(row);
  }

  participantForRow(row: ReportTableRow) {
    return UserHelper.reportRowToParticipant(row.$data);
  }

  onMenuOpened($event: void, row: ReportTableRow) {
    if ( !this.isOffline ) {
      return;
    }
    const eventId = row['eventId'];
    const eventScheduleId = row['eventScheduleId'];

    this.adminOfflineService.fetchContent(eventId)
      .pipe(map(response => {
        this.offlineContent = response.offlineContent;
        this.eventSchedule = this.offlineContent.offlineEvents.find(e => e.id === eventScheduleId);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onMenuFinished() {
    this.offlineContent = null;
    this.eventSchedule = null;
  }

  onColumnsChanged(columns: string[]): void {

    const menuItems = this.columnMenuData?.menuItems;
    const changed = ReportGeneratorV2UpdateHelper
      .fromColumnOptionsToReportConfig(menuItems, this.reportConfig);
    this.reportTableService.updateColumns();

    if ( changed && this.reportTableService.isNew ) {
      const columnSettings = ReportGeneratorV2UpdateHelper.asColumnSettings(menuItems ?? {});
      this.reportService
        .saveColumnSettingsForType(this.reportConfig?.targetType ?? 'Curriculum', columnSettings)
        .pipe(first())
        .subscribe();
    }

    this.setColumns(columns);
  }

  onFilterChange<K>(f: ColumnFilterV2<string, K | ReportTableRow>, column: TableControllerTypes.ColumnMenuItem<K | ReportTableRow>) {
    if ( !this.isDataLoaded ) {
      // not yet initialized
      return;
    }

    // start debounce time for filter
    this._filterUpdate$.emit();
  }

  onFilterBlur() {
    if ( !this.isDataLoaded ) {
      // not yet initialized
      return;
    }

    this._filterBlur$.emit();
  }

  onOfflineRemoveParticipant(
    changes: VirtualRoomAttendance.AssignmentUpdate,
  ): void {

    if ( this.inputDisabled || !(changes.unassign?.length > 0) ) {
      return;
    }

    this.inputDisabled = true;
    const contentId = this.offlineContent?.id;
    const eventId = this.eventSchedule?.id;
    this.eventParticipantsService.updateParticipants(contentId, eventId, changes)
      .pipe(tap(() => {
        this.infoService
          .showMessage(MessageConstants.API.SUCCESS, { infoType: InfoType.Success });
        const removedUserIds = changes.unassign.map(o => o.userId);
        this._report.data = this._report.data
          .filter(rowData => {
            if ( (rowData?.eventId !== contentId) || (rowData?.eventScheduleId !== eventId) ) {
              // not the modified offline event -> do not exclude
              return true;
            }

            // only exclude the rows matching the removed users
            return !removedUserIds.includes(rowData.userId);
          });
        this.updateReportData(this._report);
      }))
      .pipe(take(1))
      .subscribe();
  }

  onToggleAll(): void {
    if ( this.isSelectAllChecked() ) {
      this.selection.clear();
    } else {
      this.selection.clear();
      this.selection.select(...this.filterForSelection(this._reportRows));
    }
    this.postOnToggleAll();
    this.checkMultiActionsDisabled();

    this.reportTableService.setSelectedRows(this.selection.selected);
  }

  onToggleSelection($event: MatCheckboxChange, row: ReportTableRow) {
    super.onToggleSelection($event, row);
    this.reportTableService.setSelectedRows(this.selection.selected);
  }

  protected filterPredicateDefault = (data: ReportTableRow): boolean => {
    if ( data.$rowType === 'parent' ) {
      // parents should only appear indirectly by matching child rows
      return false;
    }

    return this.filterPredicateForColumns(data,
      (filter) => this.filterPredicateDefaultCheck(data, filter));
  };

  private buildTable(checkFilter: boolean): void {

    if ( checkFilter ) {
      this.checkFilter();
    }

    // filter data
    const tableData = TableGroupingHelper
      .applyFilter(this._reportRows, this.filterPredicateDefault);

    const report = this.reportTableService.report;
    TableGroupingHelper.updateStatistics(report, tableData);

    const filteredData = tableData.filter(row => TableGroupingHelper.isFilterVisible(row));
    this.setTableData(filteredData);

    // filter should always be active to hide children in collapsed parents
    this.nextDataSourceFilter(String(this.dataSource.filter !== 'true'));
    this.inputDisabled = false;
    this._recalculateSticky.emit();
    this.localReload = false;
  }

  /**
   * Clear menu items to allow switching between report types.
   *
   * @private
   */
  private clearMenuData() {
    const menuData = this.columnMenuData;
    if ( menuData?.menuItems != null ) {
      menuData.menuItems = null;
    }
  }

  /**
   * Initialize data to prevent first rendering of expanded groupings.
   *
   * @private
   */
  private clearTableData() {
    this.dataSource.data = [];
    this.dataSource.filter = 'start';
  }

  private updateFilterState = (): void => {

    const filterChanged = ReportGeneratorV2UpdateHelper
      .fromColumnOptionsToReportConfig(this.columnMenuData?.menuItems, this.reportConfig);
    if ( !filterChanged ) {
      return;
    }


    this.selection.clear();
    this.dataSource.data.forEach(row => row.$selectionStatus = 'unchecked');
    this.reportTableService.updateFilter();
    this._tableRebuildTrigger$.emit();
  };

  private updateMenuData(
    reportConfig: ReportConfig,
  ): void {

    let columnsNew = false;
    let menuData = this.columnMenuData;
    if ( menuData?.menuItems == null ) {
      menuData = TableColumnMenuService.createFromDefaults(REPORT_TABLE_MENU_DEFAULTS, this._defaultColumnSettings);
      columnsNew = true;
    }

    const columnsChanged = ReportGeneratorV2UpdateHelper
      .fromReportConfigToColumnOptions(reportConfig, menuData);
    if ( columnsNew || columnsChanged ) {
      this.setMenuData(menuData);
    }
  }

  private updateReportData = (report: Report | null): void => {
    this._report = report;

    if ( report == null ) {
      // clear table if report is missing
      this._reportRows = [];
      this._tableRebuildTrigger$.emit();
      return;
    }

    const reportConfig = report.reportConfig;
    this.updateMenuData(reportConfig);

    const groupedData = ReportGeneratorV2Helper.asGroupedData(report.data, report.reportConfig.groupings);
    if ( groupedData != null ) {

      const reportRows = ReportTableHelper.toTableRows(groupedData);
      ReportTableHelper.copyState(reportRows, this._reportRows);
      this._reportRows = reportRows;

      const selectedRows = ReportTableHelper.filterSelectedRows(reportRows, this.reportTableService.selectedRows);
      selectedRows.map(row => TableGroupingHelper.onToggleSelection(true, row, this.selection));
      TableGroupingHelper.updateSelectionStatus(selectedRows, this.selection);
      this.reportTableService.setSelectedRows(selectedRows);

    } else {

      this._reportRows = ReportTableHelper.toTableRows(report.data);
    }

    this.clearTableData();
    this._tableRebuildTrigger$.emit();
  };

  private updateRouteData = (
    data: ReportGeneratorV2RouteData | null,
  ): void => {
    this.clearMenuData();

    this._defaultColumnSettings = data?.columnSettings;
    this.reportTableService.setReport(data?.report);
  };

}
