<rag-content-card
  [accessoryTemplate]="accessoryTemplate"
  [subtitleAccessoryTemplate]="subtitleAccessoryTemplate"
  [content]="content"
  [contentLink]="contentLink"
>
  <div class="validity-wrapper" *ngIf="certificate.validSince || certificate.validUntil">
    <div class="valid-since" *ngIf="certificate.validSince">
      <div class="label" i18n="@@certificate_card_valid_since">Valid since:</div>
      <span class="date">{{certificate.validSince | formatDate}}</span>
    </div>

    <div class="valid-until" *ngIf="certificate.validUntil">
      <div class="label" i18n="@@certificate_card_valid_until">Until:</div>
      <span class="date">{{certificate.validUntil | formatDate}}</span>
    </div>
  </div>
</rag-content-card>

<ng-template #subtitleAccessoryTemplate>
  <span *ngIf="(certificate.validUntil > 0) && (certificate.validUntil < today)" class="expired"
        i18n="@@certificate_Expired">Expired</span>
</ng-template>

<ng-template #accessoryTemplate>
  <button
    *ngIf="certificate.templateAvailable || certificate.certUploaded"
    (click)="onDownloadClicked()"
    type="button"
    mat-icon-button
    [disabled]="certificate.certBlocked || (!certificate.availableForUser && !certificate.certUploaded)"
    matTooltip="Download"
    i18n-matTooltip="@@download"
  >
    <mat-icon class="downloadIcon" svgIcon="download"></mat-icon>
  </button>

  <button
    (click)="onDetailsClicked()"
    type="button"
    mat-icon-button
  >
    <mat-icon svgIcon="information-outline"></mat-icon>
  </button>
</ng-template>
