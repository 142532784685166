<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@global_contribution_overview">Contribution overview</span>
  </div>

  <div class="page-header-buttons">
    <button
      type="button"
      [matMenuTriggerFor]="headerMenu"
      mat-stroked-button
      [disabled]="this.selection.selected.length === 0"
    >
      <mat-icon svgIcon="menu"></mat-icon>
      <mat-menu #headerMenu>
        <ng-template matMenuContent>
          <button
            type="button"
            mat-menu-item
            [matMenuTriggerFor]="exportMenu"
          >
            <mat-icon svgIcon="cloud-download-outline"></mat-icon>
            <span i18n="@@global_contribution_export">Contribution export</span>
          </button>
        </ng-template>
      </mat-menu>
    </button>
  </div>
</rag-page-header>

<mat-menu #exportMenu>
  <button
    type="button"
    mat-menu-item
    (click)="onExportQuestion('xlsx')"
  >
    <mat-icon svgIcon="file-excel-outline"></mat-icon>
    <span i18n="@@pivot_table_export_xlsx">Export to XLSX</span>
  </button>

  <button
    type="button"
    mat-menu-item
    (click)="onExportQuestion('csv')"
  >
    <mat-icon svgIcon="file-delimited-outline"></mat-icon>
    <span i18n="@@pivot_table_export_csv_utf">Export to CSV (UTF-8)</span>
  </button>

  <button
    type="button"
    mat-menu-item
    (click)="onExportQuestion('csv-iso')"
  >
    <mat-icon svgIcon="file-delimited-outline"></mat-icon>
    <span i18n="@@pivot_table_export_csv_iso">Export to CSV (ISO-8859-1)</span>
  </button>
</mat-menu>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="tableColumns@admin-qa-list"
></rag-table-column-menu>

<mat-menu #questionActionsMenu xPosition="before">
  <ng-template let-question="question" matMenuContent>
    <a
      mat-menu-item
      [routerLink]="'/admin/qa/edit/' + question.uuid"
    >
      <mat-icon svgIcon="cog-outline"></mat-icon>
      <span i18n="@@global_edit_contribution">Edit contribution</span>
    </a>
    <button
      type="button"
      mat-menu-item
      [matMenuTriggerFor]="changeQuestionStatus"
    >
      <mat-icon svgIcon="pencil-outline"></mat-icon>
      <span i18n="@@change_status">Change Status</span>
    </button>
    <mat-menu #changeQuestionStatus="matMenu" xPosition="before">
      <ng-template  matMenuContent>
        <button
          mat-menu-item
          *ngFor="let status of allStatus"
          type="button"
          (click)="onChangeStatus(status.value, question)"
          [disabled]="status.value === question.status"
        >
          <mat-icon [svgIcon]="status.icon"></mat-icon>
          {{status.title}}
        </button>
      </ng-template>
    </mat-menu>
    <button
      type="button"
      mat-menu-item
      (click)="onChangeStatus(allQAStatus.DELETED, question)"
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@global_no_data">There are no entries to display.</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-container
  *ngIf="isDataLoaded; else tplDataLoading">
  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded; else tplDataLoading">
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        [dataSource]="dataSource"
        mat-table
        matSort
        matSortActive="creationDate"
        matSortDirection="desc"
      >

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [column]="column"
              [row]="row"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="batchCheckbox">
          <th *matHeaderCellDef class="batch-checkbox" mat-header-cell>
            <mat-checkbox
              (change)="onToggleAll()"
              [checked]="isSelectAllChecked()"
              [disabled]="isDataEmpty"
              [indeterminate]="isSelectAllIndeterminate()"
            ></mat-checkbox>
          </th>
          <td *matCellDef="let question" class="batch-checkbox" mat-cell>
            <mat-checkbox
              (change)="onToggleSelectedQuestion($event, question)"
              [checked]="selection.isSelected(question)"
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="batchCheckboxFilter">
          <th *matHeaderCellDef class="batch-checkbox filter" mat-header-cell></th>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let question" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{question: question}"
              [matMenuTriggerFor]="questionActionsMenu"
              mat-icon-button
            >
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>

      <div *ngIf="isFilteredDataEmpty" class="no-data">
        <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
      </div>
    </ng-container>
  </rag-sticky-scroll>
  <div [ngClass]="{paginatorInVisibility: isFilteredDataEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
