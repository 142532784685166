import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, concat, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Principal, PrincipalType } from 'src/app/core/core.types';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { MailComposerData, MailEntry, MailRequest, MessageRequest } from '../../../../core/mail.types';
import { MailComposerService } from './mail-composer.service';
import * as Editor from 'extras/ckeditor5-33.0.0/build/ckeditor';
import { CKEditorDefaults } from 'src/app/core/ckeditor.types';
import { MailComposerDialogData } from '../../../../core/mail-composer.types';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { RagCKEditorHelper } from '../../../../core/input/rag-ckeditor/ragCkeditorHelper';
import { MergeHelper } from '../../../../core/primitives/merge.helper';
import { CachedSubject } from 'src/app/core/cached-subject';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';

@Component({
  selector: 'rag-mail-composer',
  templateUrl: './mail-composer.component.html',
  styleUrls: [ './mail-composer.component.scss' ],
})
export class MailComposerComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('ngselect') ngSelectComponent: NgSelectComponent;
  @ViewChild('ckeditor', {static: false}) ckeditor: CKEditorComponent;

  box: string;
  canSaveAsDraft$: Observable<boolean>;
  ckOptions = MergeHelper.cloneDeep(CKEditorDefaults.CKEDITOR_OPTIONS);
  Editor = Editor;
  editorData = '';
  emailSettings: MailComposerData = null;
  inputDisabled = false;
  readonly isActionButtonSend$: Observable<boolean>;
  readonly saveAsDraftButtonDisabled$: Observable<boolean>;
  readonly sendButtonDisabled$: Observable<boolean>;
  // flag to indicate when a communication with the server is in progress
  isLoading = false;
  mailEntry?: MailEntry;
  messageId?: number;
  receiverInput$ = new Subject<string>();
  receivers$: Observable<Principal[]>;
  selectedReceivers: Principal[] = [];
  selectedSenderId = 0;
  // emails senders data
  senders: Principal[];
  subjectControl: FormControl;
  dialogTitle: string;
  mailSettingsValid$: Observable<boolean>;

  private _mailSettingsValid$ = new CachedSubject<boolean>(true);
  private _isActionButtonSend$ = new BehaviorSubject<boolean>(true);
  private _allRequiredFieldsFilledIn$ = new CachedSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MailComposerDialogData,
    private dialogRef: MatDialogRef<MailComposerComponent>,
    private mailComposerService: MailComposerService,
    private infoService: InfoService,
    private principalService: PrincipalService,
  ) {
    this.subjectControl = new FormControl();
    this.subjectControl.valueChanges.pipe(map(_ => this.onRequiredFieldChange()))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.mailSettingsValid$ = this._mailSettingsValid$.asObservable();
    this.isActionButtonSend$ = this._isActionButtonSend$.asObservable();
    this.canSaveAsDraft$ = this.principalService.permissionStates$
      .pipe(map(s => s.userMessagesSaveDraft));

    this.sendButtonDisabled$ = combineLatest([
      this._allRequiredFieldsFilledIn$,
      this._mailSettingsValid$
    ]).pipe(map(([allRequiredFieldsFilledIn, mailSettingsValid]) => {
      return !allRequiredFieldsFilledIn || !mailSettingsValid;
    }))
    .pipe(startWith(true));

    this.saveAsDraftButtonDisabled$ = this.sendButtonDisabled$
      .pipe(map(sendButtonDisabled => this.inputDisabled || sendButtonDisabled))
      .pipe(startWith(true));

    this.ckOptions.trainMacros = data.trainMacros ?? {
      textModules: new Map(),
      macroGroups: []
    };

    this.box = data.box;
    const mailEntry: MailEntry = data.mailEntry;
    if ( mailEntry ) {
      this.mailEntry = mailEntry;
      this.messageId = mailEntry.id;
    }

    if (this.data.dialogTitle == null) {
      this.dialogTitle = $localize`:@@new_message:New Message`;
    } else {
      this.dialogTitle = this.data.dialogTitle;
    }
  }

  ngOnInit() {
    this.setupSenders();
    this.setupReceivers();
    this.decomposeExistingMessage(this.mailEntry);
  }

  ngAfterViewInit(): void {
    this.ckeditor.disabled = this.inputDisabled;

    this._allRequiredFieldsFilledIn$.asObservable().pipe(map(requiredFields => {
      console.log("requiredFields", requiredFields);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onMailSettingsValidityChange(valid: boolean) {
    this._mailSettingsValid$.next(valid);
    this._isActionButtonSend$.next(valid);
  }

  onRequiredFieldChange() {
    setTimeout(() => {
      this._allRequiredFieldsFilledIn$.next(
        this.selectedSenderId > 0 &&
        this.selectedReceivers.length > 0 &&
        this.subjectControl.valid &&
        this.editorData.length > 0
      );
    });
  }

  onSendMessage() {

    // handle scheduled message
    if ( this._isActionButtonSend$.value === false ) {
      this.onSave();
      return;
    }

    const emailRequest: MailRequest = this.prepareEmailRequest();

    if ( !(emailRequest.from > 0) ) {
      this.infoService.showMessage($localize`:@@mail_composer_sender_empty:You have to select a valid sender address.`,
        { infoType: InfoType.Error });
      return;
    }

    if ( emailRequest.planDate ) {
      const planDate = moment(emailRequest.planDate);
      if ( planDate.isSameOrBefore(moment().add(1, 'm').startOf('m')) ) {
        // messages have to scheduled in the future
        this.infoService.showSnackbar(MessageKey.Mails.MAILS_ERROR_PLANNED_IN_PAST, InfoType.Error);
        return;
      }
    }

    this.disableInput();
    this.mailComposerService.post(emailRequest)
      .pipe(finalize(this.enableInput))
      .pipe(tap(() => {
        this.dialogRef.close(true);
        this.infoService.showSnackbar(MessageKey.Mails.MAILS_SUCCESSFULLY_SENT, InfoType.Success);
      }))
      .pipe(catchError(err => {
        /**@todo err is string -> should be object due to interceptor*/
        let message;
        if ( err && err.validationErrors && err.validationErrors.length ) {
          message = err.validationErrors[0];
          message = message && message.message;
        }
        if ( err === 'File download was rejected' ) { /*should be rather: if (err.code === '#TAFDRJ1') {*/
          this.infoService.showSnackbar(MessageKey.Mails.ATTACHMENT_REPORT_GENERATION_AS_RECEIVER_FAILED, InfoType.Error);
        } else {
          if ( !message && typeof (err.error) === 'string' ) {
            message = err.error;
          } else if ( typeof err === 'string' ) {
            message = err;
          }
          this.infoService.showSnackbar(MessageKey.Mails.MAILS_ERROR_WHEN_SENDING, InfoType.Error, { message });
        }
        return EMPTY;
      }))
      .pipe(take(1))
      .subscribe();
  }

  onDismiss() {
    if ( this.inputDisabled ) {
      // ignore when input disabled
      return;
    }

    this.dialogRef.close();
  }

  onSave() {

    const messageRequest: MessageRequest = this.prepareMessageRequest();
    this.mailComposerService.save(messageRequest).subscribe(savedMessage => {
      this.messageId = savedMessage.id;
      if ( this.mailEntry ) {
        this.savedToCurrent(messageRequest, savedMessage);
      }
      this.infoService.showSnackbar(MessageKey.Mails.MAILS_SAVED, InfoType.Success);
    }, () => {
      this.infoService.showSnackbar(MessageKey.Mails.MAILS_ERROR_WHEN_SAVING, InfoType.Error);
    });
  }

  onSaveAsDraft() {
    const messageRequest: MessageRequest = this.prepareMessageRequest();

    this.mailComposerService.saveAsDraft(messageRequest).subscribe(savedMessage => {
      const shouldReload = this.messageId == null ? true : null;
      this.messageId = savedMessage.id;
      if ( this.mailEntry ) {
        this.savedToCurrent(messageRequest, savedMessage);
      }
      this.infoService.showSnackbar(MessageKey.Mails.MAILS_SAVED_AS_DRAFT, InfoType.Success);
      this.dialogRef.close(shouldReload);
    }, () => {
      this.infoService.showSnackbar(MessageKey.Mails.MAILS_ERROR_WHEN_SAVING, InfoType.Error);
    });
  }

  onComposerData($event: MailComposerData) {
    this.emailSettings = $event;
    setTimeout(() => {
      this._isActionButtonSend$.next(
        ($event?.planDate == null && this.messageId == null) ||
        this.mailEntry?.draft === true,
      );
    });
  }

  private decomposeExistingMessage(mailEntry: MailEntry) {
    if ( mailEntry == null ) {
      return;
    }
    this.subjectControl.setValue(mailEntry.subject);
    if ( mailEntry['receivers']?.length > 0 ) {
      this.selectedReceivers.push(...mailEntry['receivers']);
    }
    this.editorData = mailEntry.body;
  }

  private disableInput = (): void => {
    this.subjectControl.disable();
    this.inputDisabled = true;
    this.ckeditor.disabled = this.inputDisabled;
  };

  private enableInput = (): void => {
    this.subjectControl.enable();
    this.inputDisabled = false;
    this.ckeditor.disabled = this.inputDisabled;
  };

  private prepareEmailRequest() {
    RagCKEditorHelper.disableSourceEditing(this.ckeditor);
    const emailRequest = new MailRequest();
    // selected sender
    emailRequest.from = this.selectedSenderId;
    // selected receivers
    const receiversUsers = new Array<number>();
    const receiversGroups = new Array<number>();
    this.selectedReceivers.forEach(receiver => {
      if ( receiver.type === PrincipalType.user ) {
        receiversUsers.push(receiver.id);
      } else {
        receiversGroups.push(receiver.id);
      }
    });

    emailRequest.to = receiversUsers;
    if ( receiversGroups.length > 0 ) {
      emailRequest.toTg = receiversGroups;
    }
    // subject
    emailRequest.subject = this.subjectControl.value;
    // body
    let messageBody = this.editorData ?? '';

    messageBody = messageBody
      // TF-2902 fix for encoded quotes in salutation macro
      .replace(/[{]usr:salutation:&quot;(.*?)&quot;}/gmi, '{usr:salutation:"$1"}');

    emailRequest.body = messageBody;

    if ( this.emailSettings ) {
      // time settings
      if ( this.emailSettings.planDate ) {
        emailRequest.planDate = this.emailSettings.planDate;
      }
      if ( this.emailSettings.resendInterval ) {
        emailRequest.resendInterval = this.emailSettings.resendInterval;
      }
      if ( this.emailSettings.planTitle ) {
        emailRequest.planTitle = this.emailSettings.planTitle;
      }
      // attachments
      emailRequest.attachmentUuids = this.emailSettings.attachments;
      // reports
      emailRequest.workContextMode = this.emailSettings.workContextMode;
      emailRequest.reports = this.emailSettings.reports;
    }

    emailRequest.context = this.data?.mailEntry?.context ?? {};

    return emailRequest;
  }

  private prepareMessageRequest(): MessageRequest {
    RagCKEditorHelper.disableSourceEditing(this.ckeditor);
    const messageRequest = new MessageRequest();
    messageRequest.messageId = this.messageId;
    messageRequest.senderAccountId = this.selectedSenderId;
    messageRequest.receiversAccountIds = this.selectedReceivers.map(receiver => receiver.id);
    messageRequest.subject = this.subjectControl.value;
    messageRequest.body = this.editorData;
    if ( this.emailSettings ) {
      // time settings
      messageRequest.scheduledDate = this.emailSettings.planDate;
      messageRequest.scheduledInterval = this.emailSettings.resendInterval;
      messageRequest.scheduledTitle = this.mailEntry?.draftTitle;
      // attachments
      messageRequest.filesUUIDs = this.emailSettings.attachments;
      // reports
      messageRequest.contextMode = this.emailSettings.workContextMode;
      messageRequest.reportsIds = this.emailSettings.reports;
    }

    return messageRequest;
  }

  private savedToCurrent(messageRequest: MessageRequest, savedMessage: MailEntry) {
    this.mailEntry.senderId = messageRequest.senderAccountId;
    this.mailEntry.subject = messageRequest.subject;
    this.mailEntry.draftTitle = messageRequest.scheduledTitle;
    this.mailEntry.body = messageRequest.body;
    this.mailEntry.sendDate = messageRequest.scheduledDate;
    this.mailEntry.receivers = this.selectedReceivers;
    this.mailEntry.resendInterval = messageRequest.scheduledInterval;
    this.mailEntry.workContextMode = messageRequest.contextMode;
    this.mailEntry.attachments = savedMessage.attachments;
  }

  private setupReceivers() {

    if ( this.data.selectedReceivers != null ) {
      // selectedReceivers is populated with receiverIds from external datasource
      this.selectedReceivers = this.data.selectedReceivers;
    } else if ( this.mailEntry ) {
      // this is existing (in most cases a draft) message
      this.selectedReceivers = this.mailEntry.receivers || [];
      // this.receivers$ = of(this.mailEntry.receivers);
      // return;
    }

    this.receivers$ = concat(
      of([]),
      this.receiverInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term => this.mailComposerService.searchForPrincipalsByText(term).pipe(
          catchError(() => of([])),
          tap(() => this.isLoading = false),
        )),
      ),
    );
  }

  private setupSenders() {
    this.mailComposerService.getSender().subscribe(senders => {
      this.senders = senders || [];
      if ( senders.length === 1 ) {
        this.selectedSenderId = senders[0].id;
        return;
      }
      if ( this.mailEntry ) {
        this.selectedSenderId = this.mailEntry.senderId;
      }
    }, error => {
      console.error(error);
    });
  }
}
