<div class="table-wrapper">
  <table [dataSource]="dataSource" mat-table matSort>

    <ng-container matColumnDef="importantInfo">
      <th *matHeaderCellDef class="col-important-info" mat-header-cell>
        <span i18n="@@global_information">Information</span>
      </th>
      <td *matCellDef="let item" class="col-important-info" mat-cell>
        <div>
          <rag-learner-ofl-events-state
            [content]="item">
          </rag-learner-ofl-events-state>
          <rag-important-content-info
            (needsReload)="reloadData()"
            (readDocumentAcknowledge)="onReadDocumentAcknowledge($event)"
            [content]="item"
          ></rag-important-content-info>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef class="col-actions" mat-header-cell></th>
      <td *matCellDef="let item" class="col-actions" mat-cell>
        <ng-container *ngIf="item.$view.hasContent">
          <rag-details-row-toggle
            (toggleChange)="onToggleExpanded($event, item)"
            [expanded]="item.$view.detailsExpanded"
          ></rag-details-row-toggle>
        </ng-container>
        <rag-content-action
          *ngIf="hasAction(item)"
          [content]="item"
          [parent]="parent"
        ></rag-content-action>
      </td>
    </ng-container>

    <ng-container matColumnDef="index">
      <th *matHeaderCellDef class="col-order-index" mat-header-cell mat-sort-header>
        #
      </th>
      <td *matCellDef="let item" class="col-order-index" mat-cell>
        <span class="col-label">{{item.$view.indexPrefix}}{{item.$view.index}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="displaystatus">
      <th
        *matHeaderCellDef
        class="col-display-status"
        mat-header-cell>
        <span i18n="@@global_status" >Status</span>
      </th>
      <td *matCellDef="let content" class="col-display-status" mat-cell>
        <!-- content type for learner account is always sent as DisplayStatus -->
        <rag-status-lights
          [displayStatus]="content.displaystatus"
          statusType="DisplayStatus"
          [targetType]="content.objType"
          [courseType]="content.courseType"
          [hasConfirmation]="content.hasConfirmation"
        ></rag-status-lights>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th
        *matHeaderCellDef
        class="col-title"
        mat-header-cell
        mat-sort-header>
        <span i18n="@@item_list_col_title" >Title</span>
      </th>
      <td
        *matCellDef="let item"
        class="col-title"
        mat-cell
        ngPreserveWhitespaces
      >
        <div class="col-label">
          <rag-item-recursion-indent [content]="item"></rag-item-recursion-indent>
          <div>
            <rag-clamp [clamp]="3" [input]="item.title"></rag-clamp>
            <rag-sub-title
              *ngIf="hasSubTypeText(item)"
              [objType]="item.objType"
              [objSubType]="item.objSubType ?? item.courseType"
            ></rag-sub-title>
          </div>
          <rag-curriculum-locked-by
            [content]="item"
          ></rag-curriculum-locked-by>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th
        *matHeaderCellDef
        class="col-duration"
        mat-header-cell
        mat-sort-header>
        <span i18n="@@item_list_col_duration" >Duration</span>
      </th>
      <td *matCellDef="let item" class="col-duration" mat-cell>
        <rag-content-duration
          [content]="item"
        ></rag-content-duration>
      </td>
    </ng-container>

    <ng-container matColumnDef="details">
      <th *matHeaderCellDef class="col-details" mat-header-cell></th>
      <td *matCellDef="let item" class="col-details" mat-cell>
        <rag-curriculum-info-button
          *ngIf="hasDescription(item)"
          [content]="item">
        </rag-curriculum-info-button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let item; columns: displayedColumns;"
        [attr.data-item-type]="item.type"
        [attr.data-item-id]="item.id"
        [class.highlight]="isHighlighted(item)"
        [class.expanded]="isExpanded(item)"
        [class.index-prefixed]="hasIndexPrefix(item)"
        mat-row></tr>
  </table>

</div>
