<rag-dialog-header>
  <div class="page-header-title">
    <!-- <span i18n="@@admin_target_group">Target group</span>: -->
    <span>{{data.title| localeText}}</span>
  </div>
</rag-dialog-header>

<mat-dialog-content>
  <!-- <rag-sticky-scroll [recalculate]="recalculateSticky$"> -->
  <table
    [dataSource]="dataSource"
    mat-table
    matSortActive="date"
    matSortDirection="desc"
    matSort
  >
    <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        {{column.title | localeText}}
      </th>
      <td *matCellDef="let row" mat-cell>
        <rag-tables-column-render-default
          [ngClass]="{'overflowCell': column.id === 'message'}"
          [row]="row"
          [column]="column"
        ></rag-tables-column-render-default>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
      <th *matHeaderCellDef mat-header-cell>
        <rag-table-header-filter-default
          [column]="column"
          (filterChange)="onFilterChange($event.filter, column)"
        ></rag-table-header-filter-default>
      </th>
    </ng-container>

    <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
    <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns;" mat-row></tr>
  </table>

  <div class="no-data" *ngIf="isFilteredDataEmpty">
    <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
  </div>

  <!-- </rag-sticky-scroll> -->
</mat-dialog-content>

<mat-dialog-actions>

  <div class="paginator" [ngClass]="{paginatorInVisibility: isFilteredDataEmpty}">
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>

  <button
    type="button"
    mat-flat-button
    color="primary"
    mat-dialog-close="">
    <span i18n="@@global_close">Close</span>
  </button>
</mat-dialog-actions>
