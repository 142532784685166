import { Component, OnInit } from '@angular/core';
import { TableColumnMenuService } from '../../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerComponent } from '../../../../component/table/table-controller/table-controller.component';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { SIGNATURES_LIST_COLUMNS } from './admin-signatures.columns';
import { SignatureDetails } from '../admin-signatures.types';
import { InfoService } from '../../../../core/info/info.service';
import { CancelButton, ConfirmButton, DeleteButton, DeleteCancelButtons, InfoType, YesButton } from '../../../../core/info/info.types';
import { AdminSignaturesService } from '../admin-signatures.service';
import { GenericMessageDialogComponent } from 'src/app/component/generic-message-dialog/generic-message-dialog.component';
import { GenericTextInputDialogComponent } from 'src/app/component/generic-text-input-dialog/generic-text-input-dialog.component';
import { GenericTextInputDialogData } from 'src/app/component/generic-text-input-dialog/generic-text-input-dialog.types';
import { combineLatest, EMPTY, of } from 'rxjs';

@Component({
  selector: 'rag-admin-signatures-list',
  templateUrl: './admin-signatures-list.component.html',
  styleUrls: ['./admin-signatures-list.component.scss']
})
export class AdminSignaturesListComponent
  extends TableControllerComponent<SignatureDetails> implements OnInit {

  signatures: SignatureDetails[];
  constructor(
    private adminSignaturesService: AdminSignaturesService,
    private infoService: InfoService,
    private route: ActivatedRoute,
    private router: Router,
    protected tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();
  }

  onCopy(signature: SignatureDetails) {
    this.infoService
    .showDialog<GenericTextInputDialogComponent, GenericTextInputDialogData, string>(GenericTextInputDialogComponent, {
      title: $localize`:@@admin_signatures_copy_header:Copy signature`,
      message: $localize`:@@admin_signature_copy_macro_name:Enter a macro name for the copy`,
      buttons: CancelButton | ConfirmButton,
      data: { text: '' },
    })
    .pipe(takeWhile(macroName => !!macroName))
    .pipe(switchMap(macroName => combineLatest([
      of(macroName),
      this.adminSignaturesService.preCheckMacroExistsV2(macroName)
    ])))
    .pipe(switchMap(([macroName, foundSignatures]) => {
      if (foundSignatures !== undefined && foundSignatures.length > 0) {
        this.infoService.showMessage($localize`:@@admin_signatures_details_macro_exist:
        The signature could not be saved because the macro already exists.`, {
          infoType: InfoType.Warning,
          durationInSeconds: 5
        });
        return EMPTY;
      }
      return this.adminSignaturesService.copySignature(signature, macroName);
    }))
    .pipe(tap(_ => {
      this.infoService.showMessage($localize`:@@admin_signatures_copy_success:The signature was copied successfully`, {
        infoType: InfoType.Success
      })
    }))
    .pipe(switchMap(signatureDetails => this.router.navigate(['admin', 'notifications', 'signatures', 'details', signatureDetails.id])))
    .subscribe();
  }

  onDelete(signatureId: number): void {
    this.infoService.showMessage($localize`:@@admin_signatures_delete_confirm:Do you want to delete this signature?`,
      {
        buttons: DeleteCancelButtons,
        title: $localize`:@@general_dialog_confirm_title:Confirm`,
      })
      .pipe(takeWhile(button => button === DeleteButton))
      .pipe(switchMap(() => this.adminSignaturesService.deleteSignatureV2(signatureId)))
      .pipe(tap(() => {
        this.infoService.showMessage($localize`:@@general_delete_success:The target has been deleted.`, {
          infoType: InfoType.Success,
        });
        this.signatures = this.signatures.filter(signature => signature.id !== signatureId);
        this.setTableData(this.signatures);
      }))
      .pipe(catchError(() =>
      this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`, {
        infoType: InfoType.Error,
      })
      ))
      .pipe(take(1))
      .subscribe();
  }

  private updateAvailableColumns() {
    const menuData = TableColumnMenuService.createFromDefaults(SIGNATURES_LIST_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateRouteData(data) {
    // console.log(data);
    this.signatures = data.signatures;
    this.updateAvailableColumns();
    this.setTableData(this.signatures);
    this.inputDisabled = false;
    this.checkFilter();
  }
}
