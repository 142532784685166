import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forIn, mapKeys } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/profile.service';
import { AccountDataDivision, AccountDataField, AccountDataTab, EMAIL_PATTERN } from '../../../core/core.types';
import { InfoService } from '../../../core/info/info.service';
import { InfoType, YesButton, YesNoButtons } from '../../../core/info/info.types';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { PrincipalService } from '../../../core/principal/principal.service';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ContactRequest, PasswordForgottenService } from '../password-forgotten/password-forgotten.service';
import { SysCheck } from '../tech-check/tech-check.types';
import { Contacts } from './contact.types';
import { TimePickerDirective } from '../../../component/input/time-picker/time-picker.directive';
import { takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';

const Errors = {
  notValid: true,
  not_Match: true,
  required: true,
};

type ERRORS = typeof Errors;

@Component({
  selector: 'rag-contact',
  templateUrl: './contact.component.html',
  styleUrls: [ './contact.component.scss' ],
})
export class ContactComponent
  implements OnInit, OnDestroy {

    @ViewChild('validation_time_between')
    transValidationTimeBetween: ElementRef;

    errors: ERRORS;
  dateControl = new UntypedFormControl();
  emailControl = new UntypedFormControl();
  emailRepeateControl = new UntypedFormControl();
  firstNameControl = new UntypedFormControl();
  includeSysCheck = new UntypedFormControl();
  lastNameControl = new UntypedFormControl();
  maxDateTime = moment().startOf('day').hours(17);
  messageControl = new UntypedFormControl();
  minDateTime = moment().startOf('day').hours(9);
  reasonControl = new UntypedFormControl();
  requestIsSent = false;
  selectedDate: Moment = null;  // no selected date by default
  telControl = new UntypedFormControl();
  timeControl = new UntypedFormControl(null, [
    TimePickerDirective.min(moment().startOf('day').hours(9).format('HH:mm')),
    TimePickerDirective.max('17:00'),
  ]);
  contactElements: Contacts.ContactDefinition[] = [];

  constructor(
    private deviceDetector: DeviceDetectorService,
    private infoService: InfoService,
    private navigationService: NavigationService,
    private pswdService: PasswordForgottenService,
    private route: ActivatedRoute,
    private principalService: PrincipalService,
    private profileService: ProfileService,
  ) {
    this.principalService.isLogged$
      .pipe(tap(isLogged => {
        let loginStatus: Contacts.LoginStatus;
        if ( isLogged ) {
          loginStatus = 'loggedIn';
        } else {
          loginStatus = 'loggedOut';
        }
        const predicate = a => a.loginStatus === loginStatus || a.loginStatus === 'both';

        this.contactElements = Contacts.classicContactDefinitions.filter(predicate)
          .sort((a, b) => a.order - b.order);
      }))
      .subscribe();
    this.timeControl.disable();
  }

  get invalidTimeErrorMessage(): string {
    return this.transValidationTimeBetween.nativeElement.innerText
      .replace('$0', this.minDateTime.format('LT'))
      .replace('$1', this.maxDateTime.format('LT'));
  }

  static dateFilterImpl(now: Moment, date: Moment): boolean {
    const today = now.clone()
      .startOf('day');
    const then = date.clone()
      .startOf('day');
    const diffDays = then.diff(today, 'days');

    if ( diffDays < 0 ) {
      // reject anything before today
      return false;
    } else if ( diffDays === 0 ) {
      // today can be selected only before noon
      return now.hours() < 13;
    }
    // allow anything up to 6 months
    return diffDays < 180;
  }

  cancel() {
    // ask for confirmation is there is at least one dirty component
    if ( this.reasonControl.dirty ||
      this.firstNameControl.dirty ||
      this.lastNameControl.dirty ||
      this.emailControl.dirty ||
      this.telControl.dirty ||
      this.messageControl.dirty ) {

      const message = $localize`:@@contact_validation_leave_without_save:Would you like to leave the page without submitting a contact request?`;

      const title = $localize`:@@contact_validation_leave_without_save_title:You are about to leave the site`;

      this.infoService.showMessage(message, {
        title,
        buttons: YesNoButtons,
      })
        .pipe(tap(button => {
          if ( button === YesButton ) {
            this.navigationService.navigateBack();
          }
        }))
        // .pipe(take(1))
        .subscribe();

      return;
    }
    this.navigationService.navigateBack();
  }

  dateFilter(date: Moment): boolean {
    if (date === undefined) {
      return false;
    }
    return ContactComponent.dateFilterImpl(moment(), date);
  }

  dateFilterChanged($event: any): void {
    this.selectedDate = $event.value as Moment;

    const minDate = moment().startOf('hour');
    if ( this.selectedDate && this.selectedDate.isValid() && minDate.isSame(this.selectedDate, 'day') ) {
      minDate.hours(minDate.hours() + 2);
    } else {
      minDate.hours(9);
    }

    if ( !minDate.isSame(this.minDateTime) ) {
      this.minDateTime = minDate;
      this.maxDateTime = minDate.clone().hours(17);
      this.timeControl.setValidators([
        TimePickerDirective.min(minDate.format('HH:mm')),
        TimePickerDirective.max('17:00'),
      ]);
      this.timeControl.updateValueAndValidity();
    }
  }

  getUserData() {
    combineLatest([ this.principalService.principal$, this.profileService.getUserData() ])
      .pipe(map(([ principal, userData ]) => {
        let email = '';
        forIn(userData && userData.tabs || [], (tab: AccountDataTab) => {
          forIn(tab && tab.divisions || [], (division: AccountDataDivision) => {
            forIn(division && division.fields || [], (field: AccountDataField) => {
              if ( field.fieldId === 'email' ) {
                email = field.value;
              }
              return !email;
            });
            return !email;
          });
          return !email;
        });
        return [ principal.firstname, principal.lastname, email ];
      }))
      .pipe(take(1))
      .pipe(map(([ firstName, lastName, email ]) => {
        this.firstNameControl.setValue(firstName);
        this.lastNameControl.setValue(lastName);
        this.emailControl.setValue(email);
      }))
      .subscribe();
  }

  isFormInvalid() {
    return this.reasonControl.invalid || this.firstNameControl.invalid || this.lastNameControl.invalid ||
      this.messageControl.invalid || this.emailControl.invalid || this.emailRepeateControl.invalid ||
      this.dateControl.invalid || this.timeControl.invalid;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    this.getUserData();
    this.route.queryParams.subscribe(queryParams => {
      const includeSysCheck = queryParams['includeSysCheck'];
      this.includeSysCheck.setValue(includeSysCheck != null && includeSysCheck);
    });
    this.errors = Errors;
    this.errors.notValid = false;
    this.errors.not_Match = false;
    this.errors.required = true;

    // Email Validation
    subscribeUntilDestroyed(this.emailControl.valueChanges.pipe(map(changes => {

      const regExp = EMAIL_PATTERN.test(changes);
      this.errors.notValid = !regExp;

      if ( this.emailControl.value !== this.emailRepeateControl.value ) {
        this.errors.not_Match = true;
        this.emailRepeateControl.setErrors(this.errors);
        if ( this.emailRepeateControl.value == null || '' ) {
          this.errors.required = false;
          this.errors.not_Match = false;
          this.emailControl.setErrors(this.errors);
        } else {
          this.errors.required = true;
          this.errors.not_Match = true;
          this.emailRepeateControl.setErrors(this.errors);
        }

      } else {
        this.emailControl.setErrors(null);
        this.emailRepeateControl.setErrors(null);
      }
      if ( this.errors.notValid ) {
        this.errors.notValid = true;
        this.errors.required = false;
        this.emailControl.setErrors(this.errors);
      } else {
        this.errors.notValid = false;
        this.errors.required = false;
        this.emailControl.setErrors(null);
      }

    })), this);


    // Email Repeate Validation
    subscribeUntilDestroyed(this.emailRepeateControl.valueChanges.pipe(map(changes => {
      this.errors.required = true;
      this.emailRepeateControl.setErrors(this.errors);
      if ( this.emailRepeateControl.value !== this.emailControl.value ) {
        this.errors.not_Match = true;
        this.errors.required = false;
        this.emailRepeateControl.setErrors(this.errors);
      } else {
        this.errors.not_Match = false;
        this.errors.required = false;
        this.emailRepeateControl.setErrors(null);
      }
      if ( changes === '' && this.emailControl.value === '' ) {
        this.errors.not_Match = false;
        this.emailRepeateControl.setErrors(this.errors);
      }

    })), this);

    this.dateControl.valueChanges.pipe(map(date => {
      if (date == '' || date == null) {
        this.timeControl.setValue(null, {emitEvent: false});
        this.dateControl.setValue(null, {emitEvent: false})
        this.timeControl.disable();
      } else {
        this.timeControl.enable();
      }
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }


  tryPostContact() {

    let content = (this.messageControl.value || '') + '\n\n\n';
    const time = TimePickerDirective.formatTime(this.timeControl.value);
    const contactRequest: ContactRequest = {
      reason: this.reasonControl.value,
      firstname: this.firstNameControl.value,
      lastname: this.lastNameControl.value,
      email: this.emailControl.value,
      phonenr: this.telControl.value,
      content,
      type: this.reasonControl.value,
      date: this.dateControl.value,
      time: time,
      control: '',
    };

    let needsSeparator = false;
    const date = moment(contactRequest.date);
    if ( contactRequest.date != null && date.isValid() ) {
      needsSeparator = true;
      content += '\nContact day: ' + date.format('L');
      if (contactRequest.time != null && contactRequest.time != '') {
        content += '\nContact time: ' + contactRequest.time;
      }
    }

    if ( needsSeparator ) {
      content += '\n\n\n';
    }

    if ( this.includeSysCheck.value ) {
      contactRequest.sysCheck = new SysCheck(this.deviceDetector);
      mapKeys(contactRequest.sysCheck, (value: any, key: string) => {
        if ( typeof (value) === 'object' ) {
          value = JSON.stringify(value);
        }
        content += '\n' + key + ': ' + value;
      });
      content += '\n' + 'Uninterpreted user agent: ' + navigator.userAgent;
    }

    contactRequest.content = content;

    this.pswdService.requestPasswordResetEmail(contactRequest).subscribe(() => {
      this.requestIsSent = true;
    }, error => {
      if ( error.error === 'no contacts set' ) {
        // show translated message for specific error
        this.infoService.showMessage($localize`:@@contact_no_contact_set_error:(Error: #SCM10) There is no recipient email address for this request. Please contact your administrator if you get this message!`,
          {
            durationInSeconds: 20,
            infoType: InfoType.Error,
          },
        );
      } else {
        // show untranslated error message from api call
        this.infoService.showMessage($localize`:@@general_error:The last operation failed. Please try again later.`,
          {
            durationInSeconds: 20,
            infoType: InfoType.Error,
          },
        );
      }
    });
  }
}
