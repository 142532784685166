import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseGroupToTgTypes } from './license-group-to-tg.types';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LicenseGroupToTgService } from './license-group-to-tg.service';
import { take } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class LicenseGroupToTgResolver
  implements Resolve<LicenseGroupToTgTypes.DistResponse> {

  constructor(
    private licenseGroupToTgService: LicenseGroupToTgService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LicenseGroupToTgTypes.DistResponse> {
    return this.licenseGroupToTgService.getAssignmentData()
      .pipe(take(1));
  }

}
