<ng-template #tplLoading>
  <rag-loading-indicator
  ></rag-loading-indicator>
</ng-template>


<ng-template #tplSelectLoginMethod>
  <mat-form-field *ngIf="loginMethods?.length > 1" class="error-placeholder">
    <mat-label i18n="@@login_form_login_method">Login method</mat-label>
    <mat-select [(value)]="selectedLoginMethod" required>
      <mat-option
        *ngFor="let method of loginMethods"
        [value]="method"
      >

          <span
            *ngIf="method.type === 'Credentials'"
            i18n="@@login_form_normal_login"
          >Normal Login</span>

        <span
          *ngIf="method.type === 'LDAP'"
          i18n="@@login_form_windows_login"
        >Windows Login</span>

        <span
          *ngIf="method.type === 'OAuth2'"
        >{{method.name | localeText}}</span>

      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>


<ng-template #tplDoubleOptInSuccess>
  <div class="confirmation">
    <span
      i18n="@@dbl_opt_in_email_is_sent"
    >An email has been sent successfully. Please check the SPAM folder of your email client if it takes longer.</span>
</div>
</ng-template>


<ng-template #tplLoginWithOAuth2>
  <div class="login-container">

    <h2 *ngIf="showTitle">{{getLoginFormTexts()}}</h2>

    <ng-container [ngTemplateOutlet]="tplSelectLoginMethod"></ng-container>

    <button
      (click)="onLogin()"
      [disabled]="loginButtonDisabled$ | async"
      class="loginButton login"
      color="primary"
      mat-flat-button
      type="button"
    >
      <span i18n="@@sign_in_button">SIGN IN</span>
    </button>
  </div>
</ng-template>


<ng-template #tplLoginWithCredentials>
  <form
    (submit)="onLogin()"
    [formGroup]="form"
    class="login-container"
  >

    <h2 *ngIf="showTitle">{{getLoginFormTexts()}}</h2>

    <ng-container [ngTemplateOutlet]="tplSelectLoginMethod"></ng-container>

    <mat-form-field class="error-placeholder" appearance="outline">
      <mat-label>
        <mat-icon svgIcon="account"></mat-icon>
        <span [innerHTML]="getLoginPlaceholder()" class="label-text"></span>
      </mat-label>

      <input
        [formControlName]="'login'"
        matInput
        placeholder="Login"
        type="text"
        name="login"
      />

      <mat-error *ngIf="hasError('login', 'required')"
                 [innerHTML]="getLoginError() | safe:'html'"></mat-error>
    </mat-form-field>

    <mat-form-field class="error-placeholder" appearance="outline">

      <mat-label>
        <mat-icon svgIcon="lock"></mat-icon>
        <span class="label-text" i18n="@@password">Password</span>
      </mat-label>

      <input
        [formControlName]="'passwd'"
        i18n-placeholder="@@login_form_enter_password"
        matInput
        placeholder="Enter your password here"
        [type]="showPassword ? 'text' : 'password'"
        name="password"
      />

      <button (click)="this.showPassword = ! this.showPassword "
              type="button" matSuffix mat-icon-button>
        <mat-icon [svgIcon]="showPassword ? 'eye' : 'eye-off'"></mat-icon>
      </button>
      <mat-error>
        <span *ngIf="hasError('passwd', 'required')"
              i18n="@@login_form_enter_password_error">You must enter a password</span>
      </mat-error>
    </mat-form-field>

    <a
      *ngIf="!anonymousRegistrationEnabled"
      class="forgot-password"
      mat-button
      routerLink="/forgot-password"
    >
      <span i18n="@@login_page_forgot_password_button">Forgot password?</span>
    </a>

    <button
      [disabled]="loginButtonDisabled$ | async"
      class="loginButton login"
      color="primary"
      mat-flat-button
      type="submit"
    >
      <span i18n="@@sign_in_button">SIGN IN</span>
    </button>

    <button
      *ngIf="shouldShowRegistration()"
      type="button"
      (click)="onRegister()"
      class="loginButton register"
      color="primary"
      mat-stroked-button
    >
      <span>{{registerText}}</span>
    </button>
  </form>
</ng-template>


<ng-container
  *ngTemplateOutlet="getTemplate(
    tplLoading,
    tplLoginWithCredentials,
    tplLoginWithOAuth2,
    tplDoubleOptInSuccess
  )"
></ng-container>
