import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep, forIn, isEqual, remove } from 'lodash';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { WIDGET_DEFAULTS, WidgetConf, WidgetContext, WidgetsUUID } from 'src/app/rag-layout/widgets/widgets.types';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey, OkButton } from '../../../../core/info/info.types';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { AccountDesignService } from '../account-design.service';
import { AnyObject } from '../../../../core/core.types';
import { EMPTY } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PermissionStates } from '../../../../core/principal/permission.states';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { LearnerGamificationService } from '../../../../core/gamification/learner-gamification.service';

@Component({
  selector: 'rag-widget-conf',
  templateUrl: './widget-conf.component.html',
  styleUrls: [ './widget-conf.component.scss' ],
})
export class WidgetConfComponent
  implements OnInit, OnDestroy {

  WidgetContext = WidgetContext;
  cleanCopyOfSavedWidgets: WidgetConf[] = [];
  itemsHaveDefaultValue = true;
  referenceToDetectChanges: WidgetConf[] = [];
  selectedContext = WidgetContext.LayoutHome;
  widgetArray: WidgetConf[] = [];
  widgetContexts = [ WidgetContext.LayoutHome, WidgetContext.LayoutControllingDashboard ];
  widgets: AnyObject<WidgetConf> = {};
  widgetsEditableWasChanged = false;
  widgetsEditable = true;
  gamificationEnabled = false;
  private itemsEdited_: string [] = [];

  constructor(
    private accountDesignService: AccountDesignService,
    private infoService: InfoService,
    private dirtyCheckService: DirtyCheckService,
    private principalService: PrincipalService,
    private learnerGamificationService: LearnerGamificationService,
  ) {
    this.learnerGamificationService.gamificationEnabled$
      .pipe(map(gamificationEnabled => this.gamificationEnabled = gamificationEnabled))
      .pipe(take(1))
      .subscribe();
  }

  get itemsEdited(): boolean {
    return this.itemsEdited_.length > 0;
  }

  detectChanges(triggeredByCheckAll: boolean, uuid: string): void {
    const workArray = this.widgetArray;
    const original: WidgetConf[] = this.referenceToDetectChanges;

    workArray.forEach(item => {
      // find item with same uuid in reference
      const referenceItem = original.find(originalItem =>
        originalItem && originalItem.uuid === uuid && item && item.uuid === uuid);

      // matching item found
      if ( referenceItem && referenceItem.uuid === item.uuid ) {
        // compare all values and update changed accordingly
        let changed = false;
        if ( !isEqual(referenceItem, item) ) {
          changed = true;
        }

        if ( changed ) {
          if ( this.itemsEdited_.indexOf(uuid) < 0 ) {
            this.itemsEdited_.push(uuid);
          }
        } else {
          remove(this.itemsEdited_, (value) => value === uuid);
        }
      }
    });

    // only check if it is triggered from one element
    if ( !triggeredByCheckAll ) {
      this.isSomethingDirty();
    }
  }

  detectChangesAnywhere(context?: string) {
    this.widgetArray.forEach((value) => {
      this.detectChanges(true, value.uuid);
    });

    // push after iterated over all items
    this.isSomethingDirty();

    // check for deviations from default widgets
    this.detectDeviationFromDefaultValues();
  }

  detectDeviationFromDefaultValues() {
    const workArray = cloneDeep(this.widgetArray);

    // reset value before check
    this.itemsHaveDefaultValue = true;

    forIn(WIDGET_DEFAULTS, (widget: WidgetConf) => {
      // check only until one value is different
      if ( this.itemsHaveDefaultValue ) {
        if ( widget.context === this.selectedContext ) {
          workArray.forEach(item => {
            // matching item found --> starting to compare if not already found an item with differences
            if ( item.uuid === widget.uuid && this.itemsHaveDefaultValue === true ) {
              // compare all values and update changed accordingly
              if ( !isEqual(widget, item) ) {
                this.itemsHaveDefaultValue = false;
              }
            }
          });
        }
      }
    });
  }

  drop(event: CdkDragDrop<WidgetConf>) {
    // move the item
    moveItemInArray(this.widgetArray, event.previousIndex, event.currentIndex);

    this.calculateIndex();
    this.detectChangesAnywhere();
  }

  getStyleSettings() {
    subscribeUntilDestroyed(
      this.accountDesignService.getStyleSettings()
        .pipe(tap(accountDesign => {
            this.widgetsEditable = accountDesign?.acc?.widgetsEditableByUser ?? true;
        }))
        .pipe(map(accountDesign => (accountDesign?.acc?.widgets ?? {})))
        .pipe(tap(widgetConfig => {
          if (!this.gamificationEnabled) {
            delete widgetConfig[WidgetsUUID.GamificationWidgetUUID];
          }
          this.widgets = widgetConfig;

          // make a copy to be able to reset widgets to this state
          this.referenceToDetectChanges.splice(0);
          this.cleanCopyOfSavedWidgets.splice(0);
          forIn(this.widgets, (widget: WidgetConf) => {
            this.cleanCopyOfSavedWidgets.push(cloneDeep(widget));

            if ( widget.context === this.selectedContext ) {
              this.referenceToDetectChanges.push(cloneDeep(widget));
            }
          });

          this.loadContextWidgets();
        })), this);
  }

  isSomethingDirty(): void {
    // If no elements have been changed return true
    const isDirty = this.itemsEdited_.length > 0;
    // console.log('isSomethingDirty', isDirty);
    this.dirtyCheckService.submitNextState('WidgetConfComponent', isDirty);
  }

  loadContextWidgets() {
    this.widgetArray = Object.values(this.widgets)
      .filter(widget => widget.context === this.selectedContext)
      .map(widget => {
        // make sure title has value from default settings
        widget.title = WIDGET_DEFAULTS[widget.uuid].title;
        return widget;
      })
      .sort((o1: WidgetConf, o2: WidgetConf) => o1.index - o2.index);

    // update reference to detect changes with working array
    this.referenceToDetectChanges.splice(0);
    forIn(this.cleanCopyOfSavedWidgets, (widget: WidgetConf) => {
      if ( widget.context === this.selectedContext ) {
        this.referenceToDetectChanges.push(cloneDeep(widget));
      }
    });

    // validate inputs
    this.widgetArray.forEach(item => {
      this.validateShowAtTop(item);
      this.validateStatic(item);
    });

    this.detectChangesAnywhere('loadContextWidgets');
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    // get account styleInfo
    this.getStyleSettings();
  }

  onResetChanges() {
    // reset by taking object from getStyleSettings
    this.widgetArray.splice(0);

    // get a fresh reference widget array
    this.accountDesignService.getStyleSettings(true)
      .pipe(take(1))
      .pipe(map(accountDesign => accountDesign.acc.widgets))
      .pipe(map(data => {
        this.widgets = data;
        this.referenceToDetectChanges.splice(0);
        this.cleanCopyOfSavedWidgets.splice(0);
        forIn(data || {}, (widget: WidgetConf) => {
          // make a fresh copy with live data
          this.cleanCopyOfSavedWidgets.push(cloneDeep(widget));

          // update reference for change detection
          if ( widget.context === this.selectedContext ) {
            this.referenceToDetectChanges.push(cloneDeep(widget));
          }
        });
      }));

    // update displayed array with fresh values
    this.referenceToDetectChanges.forEach(widget => this.widgetArray.push(cloneDeep(widget)));

    // reset items edit
    this.itemsEdited_.splice(0);

    this.detectChangesAnywhere();
  }

  onResetToDefaultValues() {
    // swap loaded widgets with default widgets
    forIn(this.widgets, (widget: WidgetConf) => {
      if ( widget.context === this.selectedContext ) {
        this.widgets[widget.uuid] = cloneDeep(WIDGET_DEFAULTS[widget.uuid]);
      }
    });

    // reload displayed array with updated values from this.widgets
    this.loadContextWidgets();
  }

  onUpdateStyleSettings() {
    this.postNewSettings(this.widgets);
  }

  onWidgetsEditableChanged($event: MatCheckboxChange): void {
    this.widgetsEditable = $event.checked;
    this.widgetsEditableWasChanged = true;
  }

  showInformation(key: string) {
    let messageKey = '';
    switch ( key ) {
      case 'initial':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_INITIAL;
        break;
      case 'static':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_STATIC;
        break;
      case 'resizable':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_RESIZABLE;
        break;
      case 'dragable':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_DRAGABLE;
        break;
      case 'selectable':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_SELECTABLE;
        break;
      case 'deletable':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_DELETABLE;
        break;
      case 'onlyOnce':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_ONLY_ONCE;
        break;
      case 'showAtTop':
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_SHOW_AT_TOP;
        break;
      default:
        messageKey = MessageKey.WIDGET_CONF_DESCRIPTION_FAIL;
        break;

    }
    this.infoService.showConfirm(messageKey, OkButton);
  }

  validateColInput(event, widget: WidgetConf) {
    const value = Math.round(Number(event.target.value));
    // correct input if higher than maxItemCols
    if ( value > widget.maxItemCols ) {
      event.target.value = widget.maxItemCols;
    }
    // correct input if lower than minItemCols
    if ( value < widget.minItemCols ) {
      event.target.value = widget.minItemCols;
    }

    // update reference object
    widget.cols = Math.round(Number(event.target.value));
    event.target.value = Math.round(Number(event.target.value));
    this.detectChanges(false, widget.uuid);
  }

  validateMaxColInput(event, widget: WidgetConf) {
    const value = Math.round(Number(event.target.value));
    // correct input if lower than minItemCols
    if ( value < widget.minItemCols ) {
      event.target.value = widget.minItemCols;
    }
    // correct input if higher than 3
    if ( value > 3 ) {
      event.target.value = 3;
    }
    // check if max value is under value and update accordingly
    if ( event.target.value < widget.cols ) {
      widget.cols = Number(event.target.value);
    }

    // update reference object
    widget.maxItemCols = Math.round(Number(event.target.value));
    event.target.value = Math.round(Number(event.target.value));
    this.detectChanges(false, widget.uuid);
  }

  validateMaxRowInput(event, widget: WidgetConf) {
    const WidgetDefaults = WIDGET_DEFAULTS[widget.uuid];
    let value = Math.round(Number(event.target.value));

    value = Math.min(Math.max(value, WidgetDefaults.minItemRows), WidgetDefaults.maxItemRows);
    if ( value < widget.rows ) {
      widget.rows = value;
    }
    if ( value < widget.minItemRows ) {
      widget.minItemRows = value;
    }

    // update reference object
    widget.maxItemRows = value;
    event.target.value = value;
    this.detectChanges(false, widget.uuid);
  }

  validateMinColInput(event, widget: WidgetConf) {
    const value = Math.round(Number(event.target.value));
    // correct input if higher than maxItemCols
    if ( value > widget.maxItemCols ) {
      event.target.value = widget.maxItemCols;
    }
    // correct input if lower than 1
    if ( value < 1 ) {
      event.target.value = 1;
    }
    // check if min value is above value and update accordingly
    if ( event.target.value > widget.cols ) {
      widget.cols = Math.round(Number(event.target.value));
    }

    // update reference object
    widget.minItemCols = Math.round(Number(event.target.value));
    event.target.value = Math.round(Number(event.target.value));
    this.detectChanges(false, widget.uuid);
  }

  validateMinRowInput(event, widget: WidgetConf) {

    const WidgetDefaults = WIDGET_DEFAULTS[widget.uuid];
    let value = Math.round(Number(event.target.value));

    value = Math.min(Math.max(value, WidgetDefaults.minItemRows), WidgetDefaults.maxItemRows);
    if ( value > widget.rows ) {
      widget.rows = value;
    }
    if ( value > widget.maxItemRows ) {
      widget.maxItemRows = value;
    }

    // update reference object
    widget.minItemRows = value;
    event.target.value = value;
    this.detectChanges(false, widget.uuid);
  }

  validateRowInput(event, widget: WidgetConf) {
    let value = Math.round(Number(event.target.value));
    // correct input to max value if higher than 3
    if ( value > widget.maxItemRows ) {
      value = widget.maxItemRows;
    }
    // correct input to min value if lower than 1
    if ( value < widget.minItemRows ) {
      value = widget.minItemRows;
    }

    // update reference object
    widget.rows = value;
    event.target.value = value;
    this.detectChanges(false, widget.uuid);
  }

  validateShowAtTop(widget: WidgetConf) {
    if ( widget.showAtTop ) {
      // make sure it cannot be dragged when positioned at top
      widget.dragEnabled = false;
    }
  }

  validateStatic(widget: WidgetConf) {
    if ( widget.static ) {
      // make sure it is initial if its static and cannot be deleted
      widget.initial = true;
      widget.deletable = false;
      widget.selectable = false;
      widget.onlyOnce = true;
    }
  }

  private calculateIndex() {
    // update index of all manipulated items
    for ( let i = 0; i < this.widgetArray.length; i++ ) {
      this.widgetArray[i].index = i + 1;
    }
  }

  private postNewSettings(widgets: { [key: string]: WidgetConf }) {
    // todo prevent overriding current state when refreshing
    this.accountDesignService.getStyleSettings()
      .pipe(take(1))
      .pipe(map(accountDesign => (accountDesign?.acc?.widgets ?? {}) as AnyObject<WidgetConf>))
      .pipe(tap(data => {
        Object.values(data)
          // filter current context
          .filter(widget => widget.context === this.selectedContext)
          .forEach(widget => {
            const uuid = widget.uuid;
            // replace stored settings with local
            data[uuid] = widgets[uuid];
          });
      }))
      .pipe(switchMap(data => this.accountDesignService.uploadStyleSettings({
        widgets: data,
        widgetsEditableByUser: this.widgetsEditable,
      })))
      .pipe(catchError(() => {
        // failed to update -> show error
        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_NOT_SAFED, InfoType.Error);
        return EMPTY;
      }))
      .pipe(tap(() => {
        // update successful -> refresh settings and show message
        this.itemsEdited_ = [];
        this.widgetsEditableWasChanged = false;
        this.isSomethingDirty();

        this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_SAFED, InfoType.Success);
      }))
      .subscribe();
  }
}
