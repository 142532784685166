import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminConfigurationService } from 'src/app/core/admin/configuration/admin-configuration.service';
import { AdminConfiguration } from './admin-configuration.types';
import { PermissionStates } from '../../../core/principal/permission.states';
import { PrincipalService } from '../../../core/principal/principal.service';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';

@Component({
  selector: 'rag-admin-configuration',
  templateUrl: './admin-configuration.component.html',
  styleUrls: ['./admin-configuration.component.scss']
})
export class AdminConfigurationComponent implements OnInit, OnDestroy {

  saveButtonDisabled$: Observable<boolean>;
  saveButtonVisible$: Observable<boolean>;
  permissions: PermissionStates;

  constructor(
    private adminConfigurationService: AdminConfigurationService,
    private principalService: PrincipalService,
  ) {
    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnInit(): void {
    this.saveButtonVisible$ = this.adminConfigurationService.saveButtonVisible$;
    this.saveButtonDisabled$ = this.adminConfigurationService.saveButtonDisabled$;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onSave() {
    this.adminConfigurationService.emitEvent(AdminConfiguration.Event.SAVE);
  }

}
