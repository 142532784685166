import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ImageableContentReference} from '../../../../core/core.types';
import {Observable} from 'rxjs';
import {CachedSubject} from '../../../../core/cached-subject';
import {DistributionTypeHelper} from '../../../../core/distribution-type-helper';
import {ViewHelper} from '../../../../core/view-helper';
import {ImageUrlHelper} from '../../../../core/image-url-helper';
import {FinancialsHelper} from '../../../../core/financials/financials.helper';
import {Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Catalogs} from '../../../../core/catalog/catalog.types';
import {CatalogHelper} from '../../../../core/catalog/catalog.helpers';

@Component({
  selector: 'rag-catalog-course-card',
  templateUrl: './catalog-course-card.component.html',
  styleUrls: ['./catalog-course-card.component.scss']
})
export class CatalogCourseCardComponent implements OnChanges, OnInit {

  @Input() content!: ImageableContentReference;
  @Input() contentLink: string | null;
  @ViewChild('title') title:ElementRef;

  price: string;
  height = 0;

  readonly cardBackgroundStyles$: Observable<any | null>;
  readonly DEFAULT_PICTURE_URL = "assets/images/card_event_online.jpg";
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL)

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get objSubTypeAsText(): string {
    return DistributionTypeHelper
      .asText(this.content?.objType, this.content?.objSubType ?? this.content?.courseType);
  }

  ngAfterViewInit(): void {
    this.height = this.title?.nativeElement?.offsetHeight ?? 0;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('content')) {
      this.updateImageUrl();
    }
  }

  ngOnInit(): void {
    if (!(this.content?.priceForUserInCent > 0)) {
      this.price = $localize`:@@global_price_free:free`;
    } else {
      this.price = FinancialsHelper.fromPriceInCent(this.content.priceForUserInCent) + ' €';
    }
  }

  isAssigned(bookable: Catalogs.Bookable): boolean {
    return CatalogHelper.isAssigned(bookable);
  }

  isBooked(catalogBooking?: Catalogs.CatalogBooking): boolean {
    return CatalogHelper.isBooked(catalogBooking);
  }

  onNavigateToDetails(): void {
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }
}
