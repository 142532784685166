import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { DidReceiveResponseHandler } from 'src/app/rag-layout/widgets/widget-wrapper/widget-wrapper.types';
import { ContentService } from '../../../core/content/content.service';
import { Content, UserContentActionEnum } from '../../../core/content/content.types';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { CardGrowOnEnterDynamic, ListItemsFadeIn } from '../../../core/animations';
import { CachedSubject } from '../../../core/cached-subject';
import { takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { ContentFilterHelper } from '../../../component/content-filter/content-filter.helper';
import { QueryParamsService } from '../../../core/storage/query-params.service';
import { LanguageHelper } from '../../../core/language.helper';
import { TableControllerComponent } from '../../../component/table/table-controller/table-controller.component';
import { TableColumnMenuService } from '../../../component/table/table-column-menu/table-column-menu.service';
import { TableControllerTypes } from '../../../component/table/table-controller/table-controller.types';
import { CONTENT_OVERVIEW_MENU_COLUMNS, ContentOverviewColumnMenuItemMap } from './content-overview.columns';
import { ContentOverviewService } from './content-overview.service';
import { AnyObject, ImageableContentReference } from '../../../core/core.types';
import { PrincipalService } from '../../../core/principal/principal.service';
import { PermissionStates } from '../../../core/principal/permission.states';
import { MatSort } from '@angular/material/sort';
import { FilterContext } from '../../../core/column-settings/filter-api.types';
import { ItemListReadConfirmDialogComponent } from '../../../component/elearning/item-list/item-list-read-confirm-dialog/item-list-read-confirm-dialog.component';
import {
  ItemListReadConfirmDialogData,
  ItemListReadConfirmDialogResult,
} from '../../../component/elearning/item-list/item-list.types';
import { InfoService } from '../../../core/info/info.service';
import { WithTags } from 'src/app/core/primitives/primitives.types';


@Component({
  selector: 'rag-content-overview',
  templateUrl: './content-overview.component.html',
  styleUrls: [ './content-overview.component.scss' ],
  animations: [
    ListItemsFadeIn,
    CardGrowOnEnterDynamic,
  ],
})
export class ContentOverviewComponent
  extends TableControllerComponent<ImageableContentReference & WithTags>
  implements OnChanges, OnInit {

  @Input() didReceiveResponse: DidReceiveResponseHandler;
  /**
   * true, if the component should not display any title, filter and so on and can be managed by outside
   */
  @Input() embedded = false;
  readonly filterContext = FilterContext.LearnerAccount;
  readonly filterOptions$: Observable<TableControllerTypes.ColumnOptions<Content>[]>;
  isCardView: boolean;
  permissionStates: PermissionStates;
  readonly pageTitle$: Observable<string>;
  sortedData: ImageableContentReference[];
  private _filterOptions$ = new CachedSubject<TableControllerTypes.ColumnOptions<Content>[]>(null);
  private _filterState: TableControllerTypes.ColumnOptions[];
  private _maxItems: number;
  private _sort = new MatSort();
  private _sortAttribute = 'title';
  private _updateSortData = new EventEmitter<void>(true);
  private _settingsContext: string;

  constructor(
    private accountDesignService: AccountDesignService,
    private contentOverviewService: ContentOverviewService,
    private principalService: PrincipalService,
    private queryParamsService: QueryParamsService,
    private route: ActivatedRoute,
    private service: ContentService,
    private infoService: InfoService,
    private contentService: ContentService,
    tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);


    this.defaultSort = 'title';
    this.dataSource.filterPredicate = this.filterPredicate;


    this.filterOptions$ = this._filterOptions$.withoutEmptyValues();

    this.principalService.permissionStates$
      .pipe(map(permissions => this.permissionStates = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.pageTitle$ = this.accountDesignService.getStyleSettings()
      .pipe(map(styleSettings =>
        LanguageHelper.objectToText(styleSettings?.acc?.myContentsName) ??
        $localize`:@@content-overview-title:My Training Content`));

    this.observeQueryParams();

    this._updateSortData
      .pipe(debounceTime(200))
      .pipe(tap(() => this.updateSortedData()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.route.data
      // prevent clearing settingsContext in widgets
      .pipe(filter(data => data?.settingsContext != null))
      .pipe(map(data => {
        this._settingsContext = data.settingsContext;
        this.toggleViewMode(data.userSettings?.isCards ?? this.isCardView);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  get maxItems(): number {
    return this._maxItems;
  }

  @Input()
  set maxItems(value: number) {
    this._maxItems = value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.hasOwnProperty('maxItems') ) {
      this._updateSortData.next();
    }
  }

  dateFormatted(timestamp: number): string {
    return moment(timestamp).fromNow();
  }

  getContentHref(content: ImageableContentReference): string {
    return ContentService.getContentHref(content);
  }

  ngOnInit(): void {
    // try to initialize the menu data if user is switched
    const menuData = TableColumnMenuService.createFromDefaults(CONTENT_OVERVIEW_MENU_COLUMNS);
    this.setMenuData(menuData);

    this.loadData(!this.embedded)
      .pipe(take(1))
      .subscribe();
  }

  onFilterChanged(filters: TableControllerTypes.ColumnOptions<Content>[]) {
    this.checkFilter();

    // update query params - does not modify url if embedded in widget
    const queryParams = ContentFilterHelper.asQueryParams(filters);
    this.queryParamsService.patchQueryParams(queryParams, !this.embedded);

    // trigger update of sorted data
    this._updateSortData.next();
  }

  onSortChanged(column: string): void {
    this._sortAttribute = column;

    // trigger update of sorted data
    this._updateSortData.next();
  }

  reload = (): void => {
    this.loadData(false)
      .pipe(take(1))
      .subscribe();
  };

  toggleViewMode(isCardView?: boolean) {
    if ( (isCardView != null) && (this.isCardView === isCardView) ) {
      // skip updates if state is unchanged
      return;
    }

    // if no explicit state is given, toggle the current value
    this.isCardView = isCardView ?? !this.isCardView;

    const menuItems = this.columnMenuData?.menuItems as ContentOverviewColumnMenuItemMap;
    if ( menuItems != null ) {
      // hide sorting option for table view
      menuItems.sortAttribute.options.filterHidden = !this.isCardView;
    }

    if (this._settingsContext) {
      const payloadSettings = { isCards: this.isCardView };
      this.principalService
        .saveUserSettings(this._settingsContext, JSON.stringify(payloadSettings))
        .subscribe();
    }

    // trigger update of sorted data
    this._updateSortData.next();
  }

  onReadDocumentAcknowledge(content: ImageableContentReference): void {
    this.contentService.fetchCourseInfo(content.id)
      .pipe(switchMap(courseInfo =>
        this.infoService.showDialog<ItemListReadConfirmDialogComponent, ItemListReadConfirmDialogData, ItemListReadConfirmDialogResult>(ItemListReadConfirmDialogComponent, {
          content: courseInfo,
          hasSupervisor: content?.hasSupervisor,
        })
          .pipe(map(result => {
            if ( result == null || !result.confirm ) {
              // close button
              return;
            }

            const action = result.text != null ? UserContentActionEnum.NEED_CLARIFICATION : UserContentActionEnum.READ_ACKNOWLEDGE;

            this.contentService.handleUserContentAction({
              objId: content.id,
              objType: content.objType,
              action,
              text: result.text,
            });
          }))
      ))
      .subscribe();
  }



  private filterPredicate = (data: ImageableContentReference): boolean => {
    if ( !(this._filterState?.length > 0) ) {
      // no filters available, yet
      return true;
    }

    return ContentFilterHelper.filtersMatch(data, this._filterState);
  };

  private loadData(fromRoute: boolean): Observable<void> {
    this.clearData();

    if ( this.didReceiveResponse != null ) {
      this.didReceiveResponse(null);
    }

    let query: Observable<ImageableContentReference[]>;
    if ( fromRoute ) {
      query = this.route.data
        .pipe(map(routeData => this.contentOverviewService.prepareViewData(routeData.content)));
    } else {
      query = this.service.fetchAccountData()
        .pipe(map(contents => this.contentOverviewService.prepareViewData(contents)));
    }

    if ( this.embedded ) {
      // only show open contents if view is embedded
      query = query.pipe(map(contents => contents.filter(c => DisplayStatusHelper.isToDoContentRecursive(c))));
    }

    return query
      .pipe(switchMap(contents => forkJoin([
        of(contents),
        this.queryParamsService.queryParams$.pipe(take(1)),
        this.principalService.permissionStates$.pipe(take(1)),
      ])))
      .pipe(map(([ contents, queryParams, permissions ]) => {
        const filteredContents = ContentService.filterHiddenAndIndirectAssignments(contents);
        this.loadFilterOptions(filteredContents, queryParams.queryParams, permissions);

        this.setTableData(filteredContents);
      }))

      .pipe(finalize(() => {
        if ( this.didReceiveResponse != null ) {
          // notify widget wrapper that we have content
          this.didReceiveResponse({ hasContent: !this.isDataEmpty });
        }

        // trigger update of sorted data
        this._updateSortData.next();
      }));
  }

  private loadFilterOptions(contents: ImageableContentReference[], queryParams: AnyObject<string>,
    permissions: PermissionStates): Observable<void> {

    const menuItems = this.columnMenuData?.menuItems as ContentOverviewColumnMenuItemMap;
    if ( menuItems == null ) {
      // no menu items?
      return EMPTY;
    }

    this._filterState = ContentOverviewService
      .getFilterOptions(contents, queryParams, permissions, this.isCardView, menuItems);

    this._filterOptions$.next(this._filterState);

    // touch the filter state to force recalculation
    this.dataSource.filter = 'touched';

    // initialize filter in parent component
    this.checkFilter();

    return of(void (0));
  }

  private observeQueryParams() {
    this.queryParamsService.pick([ 'es' ])

      // remove any filter action from param
      .pipe(map(params => ContentFilterHelper.parseFilterParam(params.es).value))

      // update local state variables
      .pipe(tap(sortBy => this.onSortChanged(sortBy ?? 'title')))

      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateSortedData(): void {
    if ( !this.isCardView ) {
      // manual sorting happens only for card view
      return;
    }

    if ( !(this.dataSource.data?.length > 0) ) {
      this.sortedData = [];
      return;
    }

    // sort by null is required to prevent alternating asc / desc
    this._sort.sort({ id: null, start: 'asc', disableClear: true });

    const sortAttribute = this.getValidSort(this._sortAttribute);
    this._sort.sort({ id: sortAttribute, start: 'asc', disableClear: true });

    const data = this.dataSource.sortData(this.dataSource.filteredData, this._sort);
    if ( this._maxItems > 0 ) {
      this.sortedData = data.slice(0, this._maxItems);
    } else {
      this.sortedData = data;
    }
    this._dataSourceSortChange$.value = { active: sortAttribute, direction: 'asc' };
  }

}
