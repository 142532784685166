<rag-dialog-header>
  <span i18n="@@version_info_dialog_header">Version information</span>
</rag-dialog-header>

<mat-dialog-content class="mat-typography">
  <mat-accordion multi *ngIf="hasVersionData(); else noVersionInfoMsg">
    <mat-expansion-panel
      *ngFor="let version of data?.versionInfo;"
      [expanded]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>{{version.title}}</mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <div class="details-container">
          <div class="details-label" i18n="@@version_info_dialog_content_tag_version_label">Version</div>
          <div class="details-description">{{version.version}}</div>
        </div>

        <div class="details-container">
          <div class="details-label" i18n="@@version_info_dialog_content_branch_label">Branch</div>
          <div class="details-description">{{version.branch}}</div>
        </div>

        <div class="details-container">
          <div class="details-label" i18n="@@version_info_dialog_content_creation_date_label">Build date</div>
          <div class="details-description">{{version.buildDate}}</div>
        </div>

        <div class="details-container">
          <div class="details-label" i18n="@@version_info_dialog_content_commit_id_label">Commit hash</div>
          <div class="details-description">{{version.commitHash}}</div>
        </div>
      </ng-template>

    </mat-expansion-panel>
  </mat-accordion>
  <ng-template #noVersionInfoMsg>
    <p>{{data?.noVersionInfo}}</p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>

  <a
    *ngIf="data.permissions?.forbidden"
    mat-flat-button
    color="primary"
    routerLink="/change-notes"
  >
    <span i18n="@@change_notes_title">Change notes</span>
  </a>

  <button
    type="button"
    mat-stroked-button
    [mat-dialog-close]="true"
    class="rag-stroked-button"
  >
    <span i18n="@@global_close">Close</span>
  </button>
</mat-dialog-actions>
