import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationTableData } from '../admin-notifications.types';
import { AdminNotificationsService } from '../admin-notifications.service';

@Injectable({ providedIn: 'root' })
export class AdminNotificationsListResolver
  implements Resolve<NotificationTableData[]> {

  constructor(
    private adminNotificationsService: AdminNotificationsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NotificationTableData[]> {
    const filterType = route.data['filterType'] ?? null;
    return this.adminNotificationsService.getNotificationList(filterType);
  }

}
