import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, EMPTY, from, Observable } from 'rxjs';
import { RedirectHelper } from '../../../../core/redirect.helper';
import { LoginFormService } from '../../../../component/login/login-form/login-form.service';
import { LoginFormData } from '../../../../component/login/login-form/login-form.types';


@Component({
  selector: 'rag-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: [ './login-redirect.component.scss' ],
})
export class LoginRedirectComponent
  implements OnDestroy, OnInit {

  readonly loginFormData$: Observable<LoginFormData>;
  private _showLoadingPlaceholder = true;

  constructor(
    private loginFormService: LoginFormService,
    private principalService: PrincipalService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loginFormData$ = this.loginFormService.getLoginFormData();
  }

  getTemplate(
    tplLoading: TemplateRef<any>,
    tplLogin: TemplateRef<any>,
  ): TemplateRef<any> {

    if ( this._showLoadingPlaceholder ) {
      return tplLoading;
    }

    return tplLogin;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {

    combineLatest([
      this.route.queryParamMap,
      this.principalService.isLogged$,
    ])
      .pipe(switchMap(([ params, isLogged ]) => this.checkData(isLogged, params.get('url'))))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private checkData = (
    isLogged: boolean,
    redirectUrl: string | null,
  ): Observable<void> => {

    if ( redirectUrl == null || redirectUrl.trim() === '' ) {
      // initialize to /home, in case the url parameter is empty
      redirectUrl = '/home';
    }

    // clear any previous redirect
    RedirectHelper.clearRedirect();

    if ( isLogged === true ) {
      // todo find way to handle routing errors - catchError does not trigger if resolvers fail
      return from(this.router.navigateByUrl(redirectUrl))
        .pipe(map(() => void (0)));
    }

    this._showLoadingPlaceholder = false;
    return EMPTY;
  };

}
