import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { Catalogs } from 'src/app/core/catalog/catalog.types';
import { Core } from '../../../core/core.types';
import { ContentCardComponent } from '../../cards/content-card/content-card.component';
import { MyContentActionsModule } from '../../content/components/my-content-actions/my-content-actions.module';
import { CatalogCardCourseModule } from '../catalog-card-course/catalog-card-course.module';
import { CatalogCardCurriculumModule } from '../catalog-card-curriculum/catalog-card-curriculum.module';
import { CatalogCardSchedulesPagerModule } from '../catalog-card-schedules-pager/catalog-card-schedules-pager.module';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    ContentCardComponent,
    CatalogCardSchedulesPagerModule,
    CatalogCardCourseModule,
    MyContentActionsModule,
    CatalogCardCurriculumModule,
  ],
  selector: 'rag-catalog-card-v2',
  templateUrl: './catalog-card-v2.component.html',
  styleUrls: [ './catalog-card-v2.component.scss' ],
})
export class CatalogCardV2Component {

  @Input() catalogEntry: Catalogs.CatalogEntry;
  @Input() contentLink: string | null;
  @Input() accessoryTemplate: TemplateRef<any>;

  getTemplate(
    tplOfflineContent: TemplateRef<any>,
    tplCourse: TemplateRef<any>,
    tplCurriculum: TemplateRef<any>,
  ): TemplateRef<any> {

    switch ( this.catalogEntry?.objType ?? Core.DistributableType.lms_course ) {

      case Core.DistributableType.lms_offlineCnt:
        return tplOfflineContent;

      case Core.DistributableType.lms_curriculum:
        return tplCurriculum;

      case Core.DistributableType.lms_course:
      default:
        return tplCourse;
    }
  }

}
