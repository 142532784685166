<rag-dialog-header [closingEnabled]="false">
  <span i18n="@@select_curriculum">Curriculum</span>
</rag-dialog-header>

<mat-dialog-content>
  <form [formGroup]="form">
    <div>
      <span i18n="@@select_curriculum_for_report">Select curriculum for the report</span>
    </div>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>
        <span i18n="@@global_curriculum">Curriculum</span>
      </mat-label>
      <input type="text"
            placeholder="Curriculum"
            i18n-placeholder="@@select_curriculum"
            aria-label="Curriculum"
            matInput
            [formControlName]="'curriculum'"
            [matAutocomplete]="curriculaAutoComplete">
      <button
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="onClearSelectedCurriculum()">
          <mat-icon svgIcon="close"></mat-icon>
      </button>
      <mat-autocomplete
        autoActiveFirstOption
        #curriculaAutoComplete="matAutocomplete"
        [displayWith]="rendeCurriculum"
        (optionSelected)="onCurriculumSelected($event)">
        <mat-option *ngFor="let c of filteredCurriculaOptions$ | async" [value]="c">
          {{c.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>

  <div class="list">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let curriculum of selectedCurricula$ | async">
        <div class="item">
          <div>{{curriculum.name}}</div>
          <button
            mat-icon-button
            (click)="onDelete(curriculum)">
            <mat-icon svgIcon="delete-outline"></mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>

  <div class="count">
    {{(selectedCurricula$ | async).length}} <span i18n="@@selected">selected</span>
  </div>

  <div class="spacer"></div>

  <button
    type="button"
    mat-stroked-button
    [matDialogClose] = "null">
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    type="button"
    color="primary"
    mat-stroked-button
    [disabled]="selectBUttonDisabled$ | async"
    (click) = "onSelect()">
    <span i18n="@@global_select">Select</span>
  </button>
</mat-dialog-actions>