<rag-dialog-header *ngIf="title || titleKey" [closingEnabled]="closeable">
  <rag-messages [key]="titleKey" [message]="title"></rag-messages>
</rag-dialog-header>

<div class="content" mat-dialog-content>
  <rag-messages [key]="messageKey" [message]="message" [messages]="messages"></rag-messages>
</div>

<mat-dialog-actions *ngIf="buttons > 0">

  <button *ngIf="showButtonCancel" [mat-dialog-close]="CancelButton"
          class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button *ngIf="showDiscardButton" [mat-dialog-close]="DiscardButton"
          color="warn" mat-flat-button>
    <span i18n="@@global_discard">Discard changes</span>
  </button>

  <button *ngIf="showButton(ActivateButton)" [mat-dialog-close]="ActivateButton"
          color="warn" mat-flat-button>
    <span i18n="@@global_activate">Activate</span>
  </button>

  <button *ngIf="showButton(DeactivateButton)" [mat-dialog-close]="DeactivateButton"
          color="warn" mat-flat-button>
    <span i18n="@@global_deactivate">Deactivate</span>
  </button>

  <button *ngIf="showButton(NextButton)" [mat-dialog-close]="NextButton"
          color="primary" mat-flat-button>
    <span i18n="@@global_go_next">Next</span>
  </button>

  <button *ngIf="showButtonOk" [mat-dialog-close]="OkButton"
          autofocus color="primary" mat-flat-button>
    <span i18n="@@global_ok">OK</span>
  </button>

  <button *ngIf="showButtonNo" [mat-dialog-close]="NoButton"
          class="rag-stroked-button" mat-stroked-button>
    <span i18n="@@global_no">No</span>
  </button>

  <button *ngIf="showButtonYes" [mat-dialog-close]="YesButton"
          autofocus color="primary" mat-flat-button>
    <span i18n="@@global_yes">Yes</span>
  </button>

  <button *ngIf="showButtonDelete" [mat-dialog-close]="DeleteButton"
          color="warn" mat-flat-button>
    <span i18n="@@global_delete">Delete</span>
  </button>

  <button *ngIf="showButtonConfirm" [mat-dialog-close]="ConfirmButton"
          color="primary" mat-flat-button>
    <span i18n="@@global_confirm">Confirm</span>
  </button>

  <button *ngIf="showRemoveButton" [mat-dialog-close]="RemoveButton"
          color="warn" mat-flat-button>
    <span i18n="@@global_remove">Remove</span>
  </button>

</mat-dialog-actions>
