import {
  NavigationData,
  NavItem,
  NavItemIcon,
  NavItemMenu,
  NavItemMenuIcon,
  NavItemSpecial,
  NavItemType,
} from './navigation.types';


export const DEFAULT_NAVIGATION_VTP_GUEST: NavigationData = {
  isGuest: true,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.menuIcon,
      special: NavItemSpecial.language,
    } as NavItemMenuIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@global_login:Login`,
      icon: 'login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
    } as NavItemIcon,
  ],
  main: [],
};

export const DEFAULT_NAVIGATION_VTP_USER: NavigationData = {
  isGuest: false,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@logout_button:Logout`,
      icon: 'logout',
      special: NavItemSpecial.logout,
    } as NavItemIcon,
  ],
  main: [

    {
      type: NavItemType.link,
      title: $localize`:@@create_new_contribution:Create new contribution`,
      routerLink: '/qa/new',
      checkPermissions: 'navNewQA',
    },

    {
      type: NavItemType.link,
      title: $localize`:@@read_contributions:Read contributions`,
      routerLink: '/qa',
      routerLinkActiveOptions: {queryParams: 'ignored', paths: 'exact', matrixParams: 'exact', fragment: 'exact'},
      checkPermissions: 'navQA',
    },

    {
      type: NavItemType.menu,
      title: $localize`:@@header_nav_administrator:Administration`,
      checkPermissions: 'navAdmin',
      children: [
        {
          type: NavItemType.link,
          title: $localize`:@@admin_news:News`,
          routerLink: '/admin/news',
          checkPermissions: 'navAdminNews',
        },

        {
          type: NavItemType.link,
          title: $localize`:@@global_contribution_moderation:Contribution moderation`,
          routerLink: '/admin/qa',
          checkPermissions: 'navAdminQA',
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@account_design:Account design`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_general:General`,
              routerLink: '/admin/account-design/general',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_login:Login`,
              routerLink: '/admin/account-design/login',
              checkPermissions: 'navAdminAccountDesignLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_footer:Footer`,
              routerLink: '/admin/account-design/footer',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@global_configurations:Configurations`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_widgets:Widgets`,
              routerLink: '/admin/configuration/widgets',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },
      ],
    } as NavItemMenu,
  ],
};
