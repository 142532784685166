import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep, remove } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { DeleteDialogComponent } from '../../../../component/delete-dialog/delete-dialog.component';
import { FaviconService } from '../../../../core/favicon/favicon.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { ModalDialog } from '../../../../core/modal-dialog';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { PermissionStates } from '../../../../core/principal/permission.states';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { ThemeService } from '../../../../core/theme.service';
import { DirtyCheckService } from '../../../../core/dirty-check.service';
import { AccountDesignService } from '../account-design.service';
import { TrainImage } from '../account-design.types';
import { LanguageHelper, LanguageInfo } from '../../../../core/language.helper';

@Component({
  selector: 'rag-general-conf',
  templateUrl: './general-conf.component.html',
  styleUrls: [ './general-conf.component.scss' ],
})
export class GeneralConfComponent
  implements OnInit, OnDestroy {

  accentColor = '';
  accentColorPickerActive = false;
  accentColorPreview = [];
  editedItems: string [] = [];
  faviconId: number;
  images$ = new BehaviorSubject<TrainImage[]>(null);
  isFaviconSelectionActive = false;
  isLogoSelectionActive = false;
  logoId: number;
  mainColor = '';
  mainColorPickerActive = false;
  mainColorPreview = [];
  myContentsName: { [key: string]: string };
  myContentsNameIsDirty = false;
  pageTitle: string;
  pageTitleIsDirty = false;
  pendingAccentColor = '';
  pendingMainColor = '';
  pendingMyContentsName: { [key: string]: string } = { de: '', en: '' };
  pendingPageTitle: string;
  permissionStates: PermissionStates;

  constructor(
    private accountDesignService: AccountDesignService,
    private dirtyCheckService: DirtyCheckService,
    private faviconService: FaviconService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private themeService: ThemeService,
    public dialog: ModalDialog,
  ) {
    this.mainColorPickerActive = false;
    this.accentColorPickerActive = false;
    this.isLogoSelectionActive = false;
  }

  get languages(): LanguageInfo[] {
    return LanguageHelper.LANGUAGES;
  }

  activateColorPicker(selectedColorPicker: string): void {
    // close all color pickers
    this.accentColorPickerActive = false;
    this.mainColorPickerActive = false;

    // activate only the selected color picker
    if ( selectedColorPicker === 'accentColor' ) {
      this.accentColorPickerActive = true;
    }

    if ( selectedColorPicker === 'mainColor' ) {
      this.mainColorPickerActive = true;
    }
  }

  changeAccentColor(event) {
    this.pendingAccentColor = event.color.hex;
    this.accentColorPreview = this.themeService.calculateTheme(event.color.hex);
    this.themeService.replaceThemePalette(this.accentColorPreview, 'accent');
  }

  changeFavicon(value: number) {
    // post new value to server
    this.postStyleSettings('faviconId', value);
  }

  changeLogo(value: number) {
    // post new value to server
    this.postStyleSettings('logoId', value);
  }

  changeMainColor(event) {
    this.pendingMainColor = event.color.hex;
    this.mainColorPreview = this.themeService.calculateTheme(event.color.hex);
    this.themeService.replaceThemePalette(this.mainColorPreview, 'primary');
  }

  deactivateColorPicker(selectedColorPicker: string): void {
    // close all color pickers
    this.accentColorPickerActive = false;
    this.mainColorPickerActive = false;

    // reset color of selected picker
    if ( selectedColorPicker === 'accentColor' ) {
      this.accentColorPreview = this.themeService.calculateTheme(this.accentColor);
      this.themeService.replaceThemePalette(this.accentColorPreview, 'accent');
    }

    if ( selectedColorPicker === 'mainColor' ) {
      this.mainColorPreview = this.themeService.calculateTheme(this.mainColor);
      this.themeService.replaceThemePalette(this.mainColorPreview, 'primary');
    }
  }

  deactivateColorPickerAndMarkForUpdate(modifiedColorPicker: string) {
    // close all color pickers
    this.accentColorPickerActive = false;
    this.mainColorPickerActive = false;

    // post/mark new value to server and update preview
    if ( modifiedColorPicker === 'accentColor' ) {
      this.postStyleSettings('accentColor', this.pendingAccentColor);
      this.accentColor = this.pendingAccentColor;
    }

    if ( modifiedColorPicker === 'mainColor' ) {
      this.postStyleSettings('mainColor', this.pendingMainColor);
      this.mainColor = this.pendingMainColor;
    }
  }

  deleteImage(id: number) {
    this.accountDesignService.deleteImage(id).pipe(
      switchMap(() => this.accountDesignService.getImages()),
    ).pipe(map(images => {
      this.images$.next(images);
    })).pipe(take(1)).subscribe(
      () => {
        this.infoService.showSnackbar(MessageKey.ACC_PICTURE_DELETED, InfoType.Success);
      }, () => {
        this.infoService.showSnackbar(MessageKey.ACC_PICTURE_NOT_DELETED, InfoType.Error);
      },
    );
  }

  getStyleSettings() {
    subscribeUntilDestroyed(this.accountDesignService.getStyleSettings()
      .pipe(map(response => {
        this.mainColor = (response.acc.mainColor) ? response.acc.mainColor : '#1D4994';
        this.pendingMainColor = (response.acc.mainColor) ? response.acc.mainColor : '#1D4994';
        this.accentColor = (response.acc.accentColor) ? response.acc.accentColor : '#fff';
        this.pendingAccentColor = (response.acc.accentColor) ? response.acc.accentColor : '#fff';
        this.logoId = (response.acc.logoId) ? response.acc.logoId : 0;
        this.pageTitle = (response.acc.pageTitle) ? response.acc.pageTitle : 'Train Frontend';
        this.pendingPageTitle = (response.acc.pageTitle) ? response.acc.pageTitle : 'Train Frontend';
        this.myContentsName = cloneDeep(response.acc.myContentsName);
        this.pendingMyContentsName = cloneDeep(response.acc.myContentsName);
        this.faviconId = (response.acc.faviconId) ? response.acc.faviconId : 0;
        this.accentColorPreview = this.themeService.calculateTheme(this.accentColor);
        this.mainColorPreview = this.themeService.calculateTheme(this.mainColor);
        // console.log('getStyleSettings', response);
      })), this);
  }

  hideFaviconImages() {
    this.isFaviconSelectionActive = false;
  }

  hideLogoImages() {
    this.isLogoSelectionActive = false;
  }

  isMyContentsNameDirty(): void {
    this.myContentsNameIsDirty = false;
    LanguageHelper.LANGUAGES.forEach(value => {
      this.myContentsNameIsDirty = this.myContentsNameIsDirty
          || (this.myContentsName[value.key] !== this.pendingMyContentsName[value.key]);
    });

    if ( this.myContentsNameIsDirty && !this.editedItems.includes('myContentsName') ) {
      this.editedItems.push('myContentsName');
    } else if ( !this.myContentsNameIsDirty && this.editedItems.includes('myContentsName') ) {
      remove(this.editedItems, (value) => value === 'myContentsName');
    }

    this.isSomethingDirty();
  }

  isPageTitleDirty(): void {
    this.pageTitleIsDirty = this.pageTitle !== this.pendingPageTitle;
    if ( this.pageTitleIsDirty && !this.editedItems.includes('pageTitle') ) {
      this.editedItems.push('pageTitle');
    } else if ( !this.pageTitleIsDirty && this.editedItems.includes('pageTitle') ) {
      remove(this.editedItems, (value) => value === 'pageTitle');
    }

    this.isSomethingDirty();
  }

  isSomethingDirty(): void {
    // console.log('isSomethingDirty', this.editedItems);
    // check if something is dirty and push the new state to dirtyCheckService
    const isDirty = this.editedItems.length > 0;
    this.dirtyCheckService.submitNextState('GeneralConfComponent', isDirty);
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit() {
    subscribeUntilDestroyed(this.accountDesignService.getImages()
      .pipe(map(images => {
        this.images$.next(images);
      })), this);
    // this.mainColor = '#1D4994';
    // this.accentColor = '#fff';

    // get account styleInfo
    this.getStyleSettings();

    subscribeUntilDestroyed(this.principalService.permissionStates$
        .pipe(tap(permissionStates => this.permissionStates = permissionStates))
      , this);
  }

  openDialog(id: number): void {
    const subscription = this.dialog.openModal(DeleteDialogComponent).afterClosed().subscribe(result => {
      if ( result ) {
        this.deleteImage(id);
      }
    }, finalize(() => subscription.unsubscribe()));
  }

  postStyleSettings(modified: string, value: string | number | { [key: string]: string }) {
    const newMainColor = (modified === 'mainColor') ? value : this.mainColor;
    const newAccentColor = (modified === 'accentColor') ? value : this.accentColor;
    const newLogoId = (modified === 'logoId') ? value : this.logoId;
    const newFaviconId = (modified === 'faviconId') ? value : this.faviconId;
    const newPageTitle = (modified === 'pageTitle') ? value : this.pageTitle;
    const newMyContentsName = (modified === 'myContentsName') ? value : this.myContentsName;

    const styleInfo = {
      mainColor: newMainColor,
      accentColor: newAccentColor,
      logoId: newLogoId,
      faviconId: newFaviconId,
      pageTitle: newPageTitle,
      myContentsName: newMyContentsName,
    };

    // console.log('postStyleSettings', styleInfo);
    this.accountDesignService.uploadStyleSettings(styleInfo)
      .subscribe(
        () => {
          // cleanup of is dirty
          if ( modified === 'pageTitle' ) {
            this.pageTitle = cloneDeep(this.pendingPageTitle);
            this.isPageTitleDirty();
          }
          if ( modified === 'myContentsName' ) {
            this.myContentsName = cloneDeep(this.pendingMyContentsName);
            this.isMyContentsNameDirty();
          }
          this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_SAFED, InfoType.Success);
          // this.service.reloadDesignSettings();
        }, () => {
          this.infoService.showSnackbar(MessageKey.ACC_STYLE_SETTINGS_NOT_SAFED, InfoType.Error);
        });
  }

  reloadImages() {
    subscribeUntilDestroyed(this.accountDesignService.getImages().pipe(map(images => {
      this.images$.next(images);
    })), this);
  }

  resetFavicon() {
    this.changeFavicon(0);
    this.faviconService.resetFavicon();
  }

  showFaviconImages() {
    this.isFaviconSelectionActive = true;
    this.isLogoSelectionActive = false;
  }

  showLogoImages() {
    this.isLogoSelectionActive = true;
    this.isFaviconSelectionActive = false;
  }
}
