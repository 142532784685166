import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { StartPageSettings } from '../../admin/account-design/account-design.types';


@Component({
  selector: 'rag-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
})
export class LoginComponent {

  readonly startPage$: Observable<StartPageSettings>;

  constructor(
    private accountDesignService: AccountDesignService,
  ) {
    this.startPage$ = this.accountDesignService.getStartPage();
  }

}
