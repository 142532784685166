import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { take, tap } from 'rxjs/operators';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { PasswordService } from './password.service';
import { InfoType } from '../../../core/info/info.types';
import { InfoService } from '../../../core/info/info.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { InputHelper } from '../input.types';


/**
 * You have to add [formGroup]="form" to the parent form!
 */
@Component({
  selector: 'rag-password',
  templateUrl: './password.component.html',
  styleUrls: [ './password.component.scss' ],
})
export class PasswordComponent
  implements OnChanges, OnDestroy, OnInit {

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() disabled: boolean;
  @Input() validationOffsetY: number;
  form: UntypedFormGroup;
  @Input() passwordControlName = 'password';
  @Input() passwordRepeatControlName = 'passwordRepeat';
  @Input() showGeneratePassword = false;
  showPasswordValidationOverlay = false;
  position: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  hidePassword = false;

  constructor(
    private controlContainer: ControlContainer,
    private infoService: InfoService,
    private passwordService: PasswordService,
  ) {
  }

  getControl(isRepeat = false): UntypedFormControl {
    const controlName = isRepeat ? this.passwordRepeatControlName : this.passwordControlName;
    return this.form?.get(controlName) as UntypedFormControl;
  }

  getError(
    isRepeat?: boolean,
  ): string | null {

    const control = this.getControl(isRepeat === true);
    if ( (control != null) && control.hasError('not_match') && control.value ) {
      return $localize`:@@valid_no_match:The values do not match.`;
    }

    return InputHelper.getError(control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.hasOwnProperty('disabled') ) {
      this.checkDisabled();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    const form = this.form = this.controlContainer.control as UntypedFormGroup;

    if ( !form.contains(this.passwordControlName) ) {
      form.addControl(this.passwordControlName, new UntypedFormControl({
        value: null,
        disabled: this.disabled,
      }, [ Validators.required ]));
    }

    if ( !form.contains(this.passwordRepeatControlName) ) {
      form.addControl(this.passwordRepeatControlName, new UntypedFormControl({
        value: null,
        disabled: true,
      }, [ Validators.required, this.compareInputs ]));
    }

    this.getControl().valueChanges
      .pipe(tap(() => this.checkDisabledRepeat()))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  onFocus(
    focusActive: boolean,
  ): void {

    if (!focusActive) {
      // only show or hide validation overlay
      this.showPasswordValidationOverlay = false;
      return;
    }

    const control = this.getControl();
    if (control?.value && /^[*]+$/.test(control.value)) {
      // clear value if it is a placeholder value
      control.setValue('');
    }
    this.showPasswordValidationOverlay = true;
  }

  onGeneratePassword() {
    this.passwordService.generatePassword()
      .pipe(tap(pass => {
        const passwordControl = this.getControl();
        const repeatControl = this.getControl(true);

        passwordControl.setValue(pass, {emitEvent: true});
        passwordControl.markAsDirty();
        repeatControl.enable();
        repeatControl.setValue(pass);
        repeatControl.markAsDirty();
        this.infoService
          .showMessage(
            $localize`:@@global_generate_password_success:The generated password is: ` + pass,
            {
              durationInSeconds: 60,
              infoType: InfoType.Information,
            });
      }))
      .pipe(take(1))
      .subscribe();
  }

  shouldShowPasswordRepeat(): boolean {
    if ( this.disabled === true ) {
      return false;
    }

    const control = this.form.get(this.passwordControlName);
    return control?.dirty && control.valid;
  }

  protected checkDisabled() {

    const control = this.getControl();
    if ( control == null ) {
      // ignore for now
      return;
    }

    if ( this.disabled && control.enabled ) {
      control.disable();
    } else if ( !this.disabled && control.disabled ) {
      control.enable();
    }

    if ( this.disabled ) {
      this.checkDisabledRepeat();
    }
  }

  private checkDisabledRepeat() {

    const control = this.getControl();
    const repeatControl = this.getControl(true);
    if ( (control == null) || (repeatControl == null) ) {
      return;
    }

    // clear repeat value to force re-validation
    repeatControl.setValue(null);

    // unlock repeat control if original is not empty
    if ( !this.disabled && control.dirty && (control.value != null) && (control.value !== '') ) {
      repeatControl.enable();
    } else {
      repeatControl.disable();
    }
  }

  private compareInputs = (): ValidationErrors | null => {
    const value = this.getControl()?.value;
    const valueRepeat = this.getControl(true)?.value;
    if ( (value?.length > 0) && (value !== valueRepeat) ) {
      return { not_match: true };
    } else {
      return null;
    }
  };

}
