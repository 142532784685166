import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import * as uuid from 'uuid';
import { MailService } from '../../mail.service';
import { AttachmentsSettingsFunction, MailEntry, Upload } from '../../../../../core/mail.types';
import { InfoService } from 'src/app/core/info/info.service';
import { InfoType, MessageKey } from 'src/app/core/info/info.types';
import { FileUploadService } from 'src/app/component/input/file-upload/file-upload.service';
import { takeUntil } from 'rxjs/operators';

const KB = 1024;
const MB = KB * KB;
const GB = KB * MB;

declare interface UploadWrapper {
  file?: File;
  name: string;
  size: number;
  type: string;
}

@Component({
  selector: 'rag-mail-attachments',
  templateUrl: './mail-attachments.component.html',
  styleUrls: [ './mail-attachments.component.scss' ],
})
export class MailAttachmentsComponent
  implements OnInit, OnDestroy {

    @Input() mailEntry: MailEntry;
    @Input() setAttachmentSettings: AttachmentsSettingsFunction;

    files: UploadWrapper[] = [];
  uploadProgress: { [fileName: string]: Upload } = {};

  private destroy$ = new Subject<void>();

  constructor(
    private infoService: InfoService,
    private mailService: MailService,
    private fileService: FileUploadService
  ) {
  }

  deleteByIndex(index: number) {
    const file: UploadWrapper = this.files[index];
    const upload = this.uploadProgress[file.name];
    if ( upload && upload.uuid ) {

      const complition = () => {
        index = this.files.indexOf(file);
        if (index >= 0) {
          // file is still included in files array -> remove it
          this.files.splice(index, 1);
        }
        delete this.uploadProgress[file.name];
        this.reportAttachments();
      };

      if (this.mailEntry == null) {
        this.fileService
          .deleteFile(upload.uuid)
          .pipe(takeUntil(this.destroy$))
          .subscribe(success => {
            if (success) {
              complition();
            }
          });
        return;
      }
      this.mailService
        .deleteAttachmentForMessage(this.mailEntry.id, upload.uuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe(success => {
        if ( success ) {
          complition();
        } else {
          this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        }
      });
    }
  }

  formatSize(size: number): string {
    if ( size < KB ) {
      return size + ' bytes';
    } else if ( size < MB ) {
      return Math.ceil(size / KB) + ' KB';
    } else if ( size < GB ) {
      return (Math.ceil((size / MB) * 10) / 10) + ' MB';
    } else {
      return (Math.ceil((size / GB) * 10) / 10) + ' GB';
    }
  }

  ngOnInit() {
    if ( this.mailEntry && this.mailEntry.attachments ) {
      this.mailEntry.attachments.forEach(attachment => {
        if ( attachment.type !== 'report' ) {
          this.files.push({ name: attachment.fileName, size: attachment.size, type: attachment.fileType });
          const upload = new Upload(of(0));
          upload.uuid = attachment.uuid;
          this.uploadProgress[attachment.fileName] = upload;
        }
      });
      this.reportAttachments();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onFileChanged(event: any) {
    const file: File = event.target.files[0];
    const wrapper: UploadWrapper = {
      file,
      name: file.name,
      size: file.size,
      type: file.type
    };

    const progress = new Subject<number>();
    const upload = new Upload(progress.asObservable());
    upload.uuid = uuid.v4();

    this.files.push(wrapper);
    this.uploadProgress[file.name] = upload;

    this.fileService
      .uploadAttachmentWithProgress(upload.uuid, file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(_event => {

      if ( _event.type === HttpEventType.UploadProgress ) {

        const _progress = Math.floor(100 * _event.loaded / _event.total);

        progress.next(_progress < 100 ? _progress : 0);

      } else if ( _event instanceof HttpResponse ) {

        progress.next(0);
        progress.complete();

        if ( _event.status === 200 ) {
          this.infoService.showSnackbar(MessageKey.FILES.UPLOAD.SUCCESS, InfoType.Success);
          this.reportAttachments();
        }
      }
    });
  }

  reportAttachments() {
    this.setAttachmentSettings(Object.values(this.uploadProgress).map(_upload => _upload.uuid));
  }

}
