<rag-page-header>
  <div class="page-header-title" i18n="@@header_nav_ext_oidc">OpenID Connect</div>

  <div class="page-header-buttons">
    <button
      (click)="onAdd()"
      color="primary"
      mat-flat-button
      type="button"
    >
      <span i18n="@@general_create">Create</span>
    </button>
  </div>
</rag-page-header>

<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
  [menuData$]="columnMenuData$"
  menuContext="ext-oauth2-list"
></rag-table-column-menu>

<mat-menu #rowActionsMenu xPosition="before">
  <ng-template let-row="row" matMenuContent>

    <a
      [routerLink]="'/admin/ext/oauth2/'+row.registrationId+'/users'"
      [disabled]="inputDisabled"
      mat-menu-item
    >
      <mat-icon svgIcon="account-group-outline"></mat-icon>
      <span i18n="@@global_users">Users</span>
    </a>

    <mat-divider></mat-divider>

    <button
      (click)="onEdit(row)"
      [disabled]="inputDisabled"
      type="button"
      mat-menu-item
    >
      <mat-icon svgIcon="pencil"></mat-icon>
      <span i18n="@@global_edit">Edit</span>
    </button>

    <button
      (click)="onDelete(row)"
      [disabled]="inputDisabled"
      type="button"
      mat-menu-item
    >
      <mat-icon color="warn" svgIcon="delete-outline"></mat-icon>
      <span i18n="@@global_delete">Delete</span>
    </button>

  </ng-template>
</mat-menu>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@catalog-no-data">There are no catalog entries available.</span>
  </div>
</ng-template>


<ng-container
  *ngIf="isDataLoaded; else tplDataLoading"
>
  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <ng-container
      *ngIf="isPaginatorSet && isColumnContextLoaded; else tplDataLoading">
      <table
        *ngIf="!isDataEmpty; else tplDataEmpty"
        mat-table
        [dataSource]="dataSource"
        class="table-container"
        matSort
      >

        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{column.title | localeText}}
          </th>
          <td *matCellDef="let row" mat-cell>
            <rag-tables-column-render-default
              [column]="column"
              [row]="row"
            ></rag-tables-column-render-default>
          </td>
        </ng-container>
        <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
          <th *matHeaderCellDef mat-header-cell>
            <rag-table-header-filter-default
              [column]="column"
              (filterChange)="onFilterChange($event.filter, column)"
            ></rag-table-header-filter-default>
          </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button
              (click)="tableColumnMenu.openMenu($event)"
              mat-icon-button
            >
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
          </th>
          <td *matCellDef="let row" class="actions" mat-cell>
            <button
              [disabled]="inputDisabled"
              [matMenuTriggerData]="{row: row}"
              [matMenuTriggerFor]="rowActionsMenu"
              mat-icon-button
            >
              <mat-icon svgIcon="menu"></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionsFilter">
          <th *matHeaderCellDef class="actions" mat-header-cell>
            <button mat-icon-button (click)="onResetFilter()" [disabled]="!isFilterChanged">
              <mat-icon svgIcon="filter-off-outline"></mat-icon>
            </button>
          </th>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns;" mat-row></tr>
      </table>
    </ng-container>
  </rag-sticky-scroll>

  <div [ngClass]="{paginatorInVisibility: isFilteredDataEmpty}">
    <mat-paginator
      [disabled]="isDataEmpty"
      [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>
