import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ProfileFieldTypes } from '../../../../core/input/profile-field/profile-field.types';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { debounceTime, filter, tap } from 'rxjs/operators';


@Component({
  selector: 'rag-profile-field-consent',
  templateUrl: './profile-field-consent.component.html',
  styleUrls: [ './profile-field-consent.component.scss' ],
})
export class ProfileFieldConsentComponent
  implements OnChanges, OnDestroy {

  @Input() disabled = false;
  @Input() field: ProfileFieldTypes.ProfileField;
  @Input() fieldControl: AbstractControl;
  @Input() form: UntypedFormGroup;
  inputRequiresConsent = new FormControl<boolean>(false, [ Validators.required ]);
  @Input() required = false;
  private _fieldIdConsent: string;

  constructor() {
    this.inputRequiresConsent.valueChanges
      .pipe(filter(checked => !checked && (this.fieldControl != null)))
      .pipe(tap(() => {
        if ( !this.fieldControl.dirty ) {
          // clear input value if consent is retracted
          this.fieldControl.setValue('');
          this.fieldControl.markAsTouched();
          this.fieldControl.markAsDirty();
        }
        const hasChangedValue = this.fieldControl.dirty &&
          !ProfileFieldConsentComponent.isValueEmpty(this.fieldControl.value);
        this.updateValidators(hasChangedValue);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  static isValueEmpty(value: any): boolean {

    if ( (value == null) || (value.length === 0) ) {
      // null-value or collection / string without length
      return true;
    }

    if ( typeof (value.filter) === 'function' ) {
      // exclude empty values of arrays
      return (value.filter(o => (o != null) && (o !== '')).length === 0);
    }

    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.hasOwnProperty('fieldControl') && (changes.fieldControl?.previousValue != null) ) {
      // clear all currently active subscriptions and create new ones
      destroySubscriptions(this);
    }

    const fieldId = this.field?.fieldId;
    if ( !fieldId ) {
      // remove control from form and abort other code
      return this.detachControls();
    }

    const fieldIdConsent = `${this.field?.fieldId}Consent`;
    if ( this._fieldIdConsent && (this.form != null) && (this._fieldIdConsent !== fieldIdConsent) ) {
      // attach detach changed consent control from form
      this.detachControls();
    }

    this.form.addControl(fieldIdConsent, this.inputRequiresConsent);
    this.attachControls();

    const hasValue = !ProfileFieldConsentComponent
      .isValueEmpty(this.field?.value ?? this.fieldControl?.value);
    this.updateValidators(hasValue);
    this.setConsentValue(hasValue, false);
  }

  ngOnDestroy(): void {
    this.detachControls();
    destroySubscriptions(this);
  }

  private attachControls(): void {
    const control = this.fieldControl;
    if ( control == null ) {
      return;
    }

    control.valueChanges
      .pipe(debounceTime(10))
      .pipe(tap(() => {

        const hasChangedValue = control.dirty &&
          !ProfileFieldConsentComponent.isValueEmpty(control.value);
        if ( control.dirty ) {
          // clear consent checkbox if a new value is given, preserve in admin pages
          const disabledChecked = this.disabled && hasChangedValue;
          this.setConsentValue(disabledChecked, true);
        }

        this.updateValidators(hasChangedValue);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private detachControls(): void {
    if ( this._fieldIdConsent && (this.form?.get(this._fieldIdConsent) != null) ) {
      this.form.removeControl(this._fieldIdConsent);
      this._fieldIdConsent = null;
    }
  }

  private setConsentValue(checked: boolean, dirty: boolean): void {
    this.inputRequiresConsent.setValue(checked, { emitEvent: false });
    if ( dirty ) {
      this.inputRequiresConsent.markAsTouched();
      this.inputRequiresConsent.markAsDirty();
    } else {
      this.inputRequiresConsent.markAsUntouched();
      this.inputRequiresConsent.markAsPristine();
    }
  }

  private updateValidators(hasChangedValue: boolean): void {
    // consent is always required for mandatory fields
    // consent is also necessary for changed values
    this.required = (hasChangedValue || this.field?.required);
    const validators = this.required ? [ Validators.requiredTrue ] : [];
    this.inputRequiresConsent.setValidators(validators);
  }

}
