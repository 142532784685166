import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CachedSubject } from '../../core/cached-subject';
import { naturalCompare } from '../../core/natural-sort';
import { destroySubscriptions, subscribeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { ViewHelper } from '../../core/view-helper';
import { AssignmentDialogTypes } from './assignment-dialog.types';
import { Core } from '../../core/core.types';
import { MergeHelper } from '../../core/primitives/merge.helper';

@Component({
  selector: 'rag-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: [ './assignment-dialog.component.scss' ],
})
export class AssignmentDialogComponent<T, C = any>
  implements OnDestroy {

  assignmentType: Core.DistAssignmentType = 'mandatory';
  readonly enableAssignmentType: boolean;
  isInvalid = false;
  isMaxSelected = false;
  isPristine = true;
  readonly listData$: Observable<AssignmentDialogTypes.AssignmentDialogEntries<T, C>>;
  loading: boolean;
  maxSelections = 0;
  selectionCount = 0;
  private _inputAvailable: AssignmentDialogTypes.AssignmentEntry<T, C>[];
  private _inputSelected: AssignmentDialogTypes.AssignmentEntry<T, C>[];
  private _listData = new CachedSubject<AssignmentDialogTypes.AssignmentDialogEntries<T, C>>(null);
  private _result: AssignmentDialogTypes.AssignmentDialogEntries<T, C>;

  constructor(
    private dialogRef: MatDialogRef<AssignmentDialogComponent<T, C>, AssignmentDialogTypes.AssignmentDialogEntries<T, C>>,
    @Inject(MAT_DIALOG_DATA) public data: AssignmentDialogTypes.AssignmentDialogData<T, C>,
  ) {
    this.listData$ = this._listData.asObservable();
    this.loading = data?.data == null;
    this.enableAssignmentType = data?.enableAssignmentType === true;
    setTimeout(() => this.setData(data?.data, data?.query$));
  }

  private static getEntryValueAsAssignmentInfo(entry: AssignmentDialogTypes.AssignmentEntry<any>): AssignmentDialogTypes.AssignmentInfo {
    return entry.value;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onSelectionChanged(value: AssignmentDialogTypes.AssignmentDialogEntries<T, C>): void {
    const available = value.available
      .map(entry => this.updateItem(entry, false));
    const selected = value.selected
      .map(entry => this.updateItem(entry, true));
    if ( this.enableAssignmentType ) {
      const entries = [
        ...available,
        ...selected,
      ];

      this._result = {
        available: entries.filter(entry => !entry.selected),
        selected: entries.filter(entry => entry.selected),
      };
    } else {
      this._result = {
        available,
        selected,
      };
    }

    this.validateMaxSelections();

    this.isPristine = false;
  }

  onSetAssignmentType(assignmentType: Core.DistAssignmentType): void {
    const doUpdate = assignmentType && (this.assignmentType !== assignmentType);
    this.assignmentType = assignmentType;
    if ( !doUpdate ) {
      return;
    }

    this.emitListData();
  }

  onUpdateClick(): void {
    let result: AssignmentDialogTypes.AssignmentDialogEntries<T, C> = null;
    if ( this._result ) {
      result = {
        available: [ ...this._result.available ],
        selected: [ ...this._result.selected ],
      };
    }
    this.dialogRef.close(result);
  }

  private emitListData(): void {
    let listData: AssignmentDialogTypes.AssignmentDialogEntries<T, C>;
    if ( this.enableAssignmentType ) {
      listData = this.emitListDataGetForAssignmentInfo();
    } else {
      listData = {
        available: this._inputAvailable,
        selected: this._inputSelected,
      };
    }

    this._listData.next(listData);
  }

  private emitListDataGetForAssignmentInfo(): AssignmentDialogTypes.AssignmentDialogEntries<T, C> {
    const assignmentType: Core.DistAssignmentType = this.assignmentType === 'voluntary' ? 'voluntary' : 'mandatory';

    const selected: AssignmentDialogTypes.AssignmentEntry<T, C>[] = [];
    const available: AssignmentDialogTypes.AssignmentEntry<T, C>[] = [];

    const entries: { [key: string]: AssignmentDialogTypes.AssignmentEntry<T, C> } = {};
    [
      // first all selected entries
      ...this._inputSelected,
      // then available ones - ignoring duplicates
      ...this._inputAvailable,
    ].forEach(entry => {
      const assignmentInfo = AssignmentDialogComponent.getEntryValueAsAssignmentInfo(entry);
      const key = `${assignmentInfo.principalType}#${assignmentInfo.principalId}`;
      if ( entries[key] == null ) {
        entries[key] = entry;

        const entryAssignmentType = assignmentInfo.assignmentType;
        if ( (entryAssignmentType === 'both') || (entryAssignmentType === assignmentType) ) {
          selected.push(entry);
        } else {
          available.push(entry);
        }
      }
    });

    return {
      available,
      selected,
    };
  }

  private prepareData(
    data: AssignmentDialogTypes.AssignmentEntry<T, C>[] = [],
    categories: AssignmentDialogTypes.AssignmentEntryCategoryMap<C>,
  ): AssignmentDialogTypes.AssignmentEntry<T>[] {

    let result = MergeHelper.cloneDeep(data);
    if ( !this.data.allowSortingInContainers ) {
      // sorting is not allowed -> sort by title
      result = result.sort((a, b) => naturalCompare(a.title, b.title));
    }

    result.forEach(entry => {
      const viewData = ViewHelper.getViewData(entry);
      viewData.icon = categories[String(entry.type)]?.icon || '';
    });

    if ( Object.keys(categories).length > 1 ) {
      // sort by categories
      result = result.sort((a, b) => {
        const catA = categories[String(a.type)]?.orderIndex || 0;
        const catB = categories[String(b.type)]?.orderIndex || 0;
        return catA - catB;
      });
    }

    if ( this.enableAssignmentType ) {
      // sort by active state (inactive should move down)
      result = result.sort((a, b) => {
        const activeA = (a.value as any).principalActive ?? true;
        const activeB = (b.value as any).principalActive ?? true;
        if ( activeA === activeB ) {
          return 0;
        } else if ( activeA ) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    return result;
  }

  private setData(immediateData: AssignmentDialogTypes.AssignmentDialogEntries<T>,
    observable: Observable<AssignmentDialogTypes.AssignmentDialogEntries<T>>): void {
    if ( immediateData ) {
      this.setDataValues(immediateData);
    } else if ( observable ) {
      subscribeUntilDestroyed(observable.pipe(map(this.setDataValues)), this);
    } else {
      throw Error('data required to use the AssignmentDialogComponent!');
    }
  }

  private setDataValues = (value: AssignmentDialogTypes.AssignmentDialogEntries<T, C>) => {
    this.loading = false;

    const categories = (value.categories || [])
      .reduce((pV, category, index) => {
        // offset by 1 to allow fallback to 0
        category.orderIndex = 1 + index;
        pV[String(category.type)] = category;
        return pV;
      }, {});

    this.maxSelections = value.maxSelections || 0;
    this._inputAvailable = this.prepareData(value.available, categories);
    this._inputSelected = this.prepareData(value.selected, categories);

    this._result = {
      available: this._inputAvailable,
      selected: this._inputSelected,
    };

    this.validateMaxSelections();
    this.emitListData();
  };

  private updateItem(item: AssignmentDialogTypes.AssignmentEntry<T, C>, selected: boolean):
    AssignmentDialogTypes.AssignmentEntry<T, C> {
    const viewData = ViewHelper.getViewData(item);
    if ( this.enableAssignmentType ) {
      const assignmentInfo = AssignmentDialogComponent.getEntryValueAsAssignmentInfo(item);
      if ( !viewData.hasOwnProperty('originalAssignmentType') ) {
        viewData.originalAssignmentType = assignmentInfo.assignmentType;
      }
      this.updateItemAssignmentType(assignmentInfo, selected);
      item.selected = assignmentInfo.assignmentType != null;
      item.changed = (viewData.originalAssignmentType !== assignmentInfo.assignmentType);
    } else {
      if ( !viewData.hasOwnProperty('originalSelected') ) {
        viewData.originalSelected = item.selected;
      }
      item.selected = selected;
      item.changed = (viewData.originalSelected !== item.selected);
    }
    return item;
  }

  private updateItemAssignmentType(assignmentInfo: AssignmentDialogTypes.AssignmentInfo, selected: boolean):
    Core.DistAssignmentType {
    const assignmentType = assignmentInfo.assignmentType;
    if ( selected ) {
      // add current assignmentType
      if ( !assignmentType ) {
        // none set -> apply directly
        assignmentInfo.assignmentType = this.assignmentType;
      } else if ( assignmentInfo.assignmentType !== this.assignmentType ) {
        // at the moment the other type is set -> set to both
        assignmentInfo.assignmentType = 'both';
      } else {
        // unchanged
      }
    } else {
      // remove current assignmentType
      if ( assignmentType === this.assignmentType ) {
        // exact match -> set to null
        assignmentInfo.assignmentType = null;
      } else if ( assignmentInfo.assignmentType === 'both' ) {
        assignmentInfo.assignmentType = this.assignmentType === 'voluntary' ? 'mandatory' : 'voluntary';
      } else {
        // unchanged
      }
    }
    return assignmentInfo.assignmentType;
  }

  private validateMaxSelections = (): void => {
    const selected = this._result?.selected;
    if ( selected == null ) {
      // don't change anything while initializing
      return;
    }

    const maxSelections = this.maxSelections;
    if ( !(maxSelections > 0) ) {
      this.selectionCount = 0;
      this.isInvalid = this.isMaxSelected = false;
    } else {
      const selectionCount = this.selectionCount = selected.length;
      this.isInvalid = selectionCount > maxSelections;
      this.isMaxSelected = selectionCount >= maxSelections;
    }
  };

}
