import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ImageableContentReference} from '../../../../core/core.types';
import {Observable} from 'rxjs';
import {CachedSubject} from '../../../../core/cached-subject';
import {FinancialsHelper} from '../../../../core/financials/financials.helper';
import {DistributionTypeHelper} from '../../../../core/distribution-type-helper';
import {ViewHelper} from '../../../../core/view-helper';
import {ImageUrlHelper} from '../../../../core/image-url-helper';
import {IconHelper} from '../../../../core/icon.helper';
import {OfflineContent} from '../../../../core/admin-offline.types';
import {Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {CatalogHelper} from '../../../../core/catalog/catalog.helpers';
import {Catalogs} from '../../../../core/catalog/catalog.types';

@Component({
  selector: 'rag-catalog-event-card',
  templateUrl: './catalog-event-card.component.html',
  styleUrls: ['./catalog-event-card.component.scss']
})
export class CatalogEventCardComponent implements OnChanges {

  @Input() content!: ImageableContentReference;
  @Input() contentLink: string | null;
  iconForType: string = '';
  eventScheduleCount = 0;
  offlineEvents: OfflineContent.EventSchedule[] = [];
  selectedSchedule: OfflineContent.EventSchedule;
  selectedScheduleIndex = 0;

  readonly DEFAULT_PICTURE_URL = 'assets/images/card_event.jpg';
  readonly imageUrl$: Observable<string | ArrayBuffer>;
  readonly cardBackgroundStyles$: Observable<any | null>;
  private _imageUrl$: CachedSubject<string | ArrayBuffer> = new CachedSubject(this.DEFAULT_PICTURE_URL);

  constructor(
    private router: Router,
  ) {
    this.imageUrl$ = this._imageUrl$.asObservable();
    this.cardBackgroundStyles$ = this.imageUrl$
      .pipe(filter(url => !!url))
      .pipe(map(url => ({
        'background-size': 'cover',
        'background-image': `url(${url})`
      })));
  }

  get objSubTypeAsText(): string {
    if (this.content?.blockEvent) {
      return $localize`:@@appointment_series:Appointment series`
    }
    return $localize`:@@global_event:Event`;
  }

  get offlineContentSubType(): string | null {
    return DistributionTypeHelper.getOfflineContentSubType(this.content?.objType, this.content?.objSubType);
  }

  get price(): string {
    if (this.selectedSchedule?.priceForUserInCent > 0) {
      return FinancialsHelper.fromPriceInCent(this.selectedSchedule.priceForUserInCent) + ' €';
    } else {
      return $localize`:@@global_price_free:free`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('content')) {
      this.updateImageUrl();
    }

    this.iconForType = IconHelper.svgIconForObjType(this.content?.objType, this.content?.objSubType);

    this.offlineEvents = this.content?.offlineEventsViews ?? [];
    if (this.offlineEvents.length > 0 && this.selectedSchedule == null) {
      this.selectedSchedule = this.offlineEvents[0];
    }
    this.eventScheduleCount = this.offlineEvents.length;
  }

  isAssigned(bookable: Catalogs.Bookable): boolean {
    return CatalogHelper.isAssigned(bookable);
  }

  isBooked(catalogBooking?: Catalogs.CatalogBooking): boolean {
    return CatalogHelper.isBooked(catalogBooking);
  }

  onNavigateToDetails(): void {
    if (this.contentLink == null || this.contentLink === '') {
      return;
    }
    this.router.navigateByUrl(this.contentLink).then();
  }

  selectedScheduleChanged(mode: 'previous' | 'next', $event: MouseEvent ): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (mode === 'previous') {
      const previousSchedule = this.offlineEvents?.[this.selectedScheduleIndex - 1];
      if (previousSchedule != null) {
        this.selectedSchedule = previousSchedule;
        this.selectedScheduleIndex -= 1;
        return;
      }
      this.selectedSchedule = this.offlineEvents[this.eventScheduleCount - 1];
      this.selectedScheduleIndex = (this.eventScheduleCount - 1);
      return;
    }

    const nextSchedule = this.offlineEvents?.[this.selectedScheduleIndex + 1];
    if (nextSchedule != null) {
      this.selectedSchedule = nextSchedule;
      this.selectedScheduleIndex += 1;
      return;
    }
    this.selectedSchedule = this.offlineEvents[0];
    this.selectedScheduleIndex = 0;
  }

  private updateImageUrl(): void {
    if (!this.content) {
      return;
    }

    // fallback
    const pictureFile = ViewHelper.getViewData(this.content)?.cardPictureFile;
    if (pictureFile != null) {
      const reader = new FileReader();
      reader.readAsDataURL(pictureFile);
      reader.onload = () => this._imageUrl$.next(reader.result);
      reader.onerror = () => this._imageUrl$.next(this.DEFAULT_PICTURE_URL);
      return;
    }

    const imageUrl = ImageUrlHelper.urlForPicture(
        this.content.pictureId,
        this.content.cardPictureUUID ??
        this.content.cardPicture?.uuid ??
        this.content.pictureUUID) ??
      this.DEFAULT_PICTURE_URL;
    this._imageUrl$.next(imageUrl);
  }


}
